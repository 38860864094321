import React, { useRef } from 'react';
import { makeStyles, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-canvas';
import { DropDown, RadioButton, CheckBox } from '@aspida/react-components';

import Utils from 'views/eapp/renderforms/utils';
import EappMainPDF from 'assets/images/eAppMainPDF.JPG';
import styles from './style';

const useStyles = makeStyles(styles);

export default function OwnerSign(props) {
  const classes = useStyles();
  // curently we are not able to connect service so it have hard_cord.
  const signedState = [
    { id: '1', value: 'Alabama' },
    { id: '2', value: 'California' },
    { id: '3', value: 'New York' },
  ];
  const esignRdbtn = [{ id: 'Draw New', value: 'Draw New' }];
  const esignSysGen = [{ id: 'Use System Generated1', value: 'Use System Generated' }];
  const checkVal = [
    { id: '1', value: 'By clicking here I sign the above document and agree to its terms and conditions.' },
  ];
  const sign = useRef({});

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" className={classes.label}>
            Which signature would you like to use?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <RadioButton
            name="esignRdbtn"
            listItem={esignRdbtn}
            onChange={event => props.onChange(event)}
            color="#f44336"
            fontVariant="h6"
            selectedValue={props.values.esignRdbtn}
            isDisabled={Utils.isUserViewOnly()}
          />
          <SignaturePad ref={sign} canvasProps={{ className: classes.signature }} />
        </Grid>
        <Grid item xs={12} sm={1} md={1} lg={1}>
          <Typography variant="h6" className={classes.orLabel} gutterBottom>
            -OR-
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <RadioButton
            name="eSignSysGen"
            listItem={esignSysGen}
            onChange={event => props.onChange(event)}
            color="#f44336"
            fontVariant="h6"
            selectedValue={props.values.eSignSysGen}
            isDisabled={Utils.isUserViewOnly()}
          />
          <Paper className={classes.paperEmptySign}>
            <Typography variant="h6">Bob Agent</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" className={classes.label}>
            Where are you signing?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DropDown
            listItem={signedState}
            name="signingState"
            isFullWidth
            value={props.values.signingState}
            onChange={event => props.onChange(event)}
            labelText="State"
            variant="outlined"
            disabled={Utils.isUserViewOnly()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField label="City" name="emal" fullWidth variant="outlined" onChange={event => props.onChange(event)} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" className={classes.label}>
            Application Review
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <img src={EappMainPDF} alt="logo" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckBox
            name="ClkDoc"
            listItem={checkVal}
            onChange={event => props.onChange(event, 'checkbox', 'ClkDoc')}
            color="#f44336"
            fontVariant="h6"
            selectedValue={props.values.ClkDoc}
            className={classes.KBA}
            isDisabled={Utils.isUserViewOnly()}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2} className={classes.rowButton}>
        <Grid item xs={12} sm={6} md={2} lg={2} className={classes.gridAlignLeftBackButton}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => props.onClick(props.esignRoleName, 0)}
            disabled={Utils.isUserViewOnly()}
          >
            COMPLETE
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

OwnerSign.propTypes = {
  esignRoleName: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  values: PropTypes.object,
};
