import React, { useState } from 'react';
import PropTypes from 'prop-types';
import store from 'redux/store';
import { makeStyles, Grid, LinearProgress, Link, Typography, IconButton, Button } from '@material-ui/core';
import { CloudUpload, Cancel, CheckCircle, Delete } from '@material-ui/icons';
import MasterService from 'api/masterService';
import { useSelector } from 'react-redux';
import { setInputValues } from 'redux/getstarted/actions';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import GroupTrackerFunctions from 'views/eapp/utils/groupTrackerFunctions';
import NotificationService from 'services/notificationService';
import constants from 'constants/common';
import styles from '../style';

const useStyles = makeStyles(styles);

export default function MSAContent(props) {
  const classes = useStyles();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadNoteText, setUploadNote] = useState('');
  const [progress, setInProgress] = useState(0);
  const getStarted = useSelector(state => state.getStarted);
  const state = store.getState();
  const inputData = getStarted.inputvalues;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setInProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const saveApplicationData = async () => {
    const applicationData = state.getStarted;

    if (Utils.isUserViewOnly()) {
      return;
    }

    if (applicationData.inputvalues['#IsSignatureCreated']) {
      return;
    }

    applicationData.inputerrors = CommonFunctions.cleanJSON(applicationData.inputerrors);
    applicationData.inputvalues = CommonFunctions.cleanJSON(applicationData.inputvalues);
    store.dispatch(setInputValues(applicationData.inputvalues));
    const payload = {
      applicationId: applicationData.policyapplicationid,
      completionPercentage: GroupTrackerFunctions.calculateCompletePercentage(applicationData.trackergroupnames),
      agentPartyId: applicationData.inputvalues['#writingAgentPartyId'],
      applicationStatusId: applicationData.inputvalues['#applicationStatusId'],
      response: applicationData,
      productId: applicationData.product,
      signedStateId: applicationData.signedstateid,
      applicationTypeId: applicationData.applicationtype,
    };

    const [applicationError] = await MasterService.saveApplicationData(payload);
    return applicationError;
  };

  const uploadReplacementDoc = async (event, index) => {
    const file = event.target.files[0];
    setUploadPercentage(0);

    const fileExt = file.name.split('.').at(-1);
    const allowedFileTypes = ['pdf'];
    if (!allowedFileTypes.includes(fileExt.toLowerCase())) {
      setUploadNote('Note: Allowed document types: pdf');
      return;
    }

    const tenMB = 10 * 1000 * 1000;
    if (file.size > tenMB) {
      NotificationService.error('Uploaded file is too large: Max 10MB');
      return;
    }

    const msafileName = file.name.split('.').at(0);
    const fileExtension = CommonFunctions.checkIsNullOrUndefined(file.name) !== '' ? /.+\.(.+)$/.exec(file.name) : '';
    const msadocFileKey = `${msafileName}_${index + 1}`;
    let docFileName = Number.isFinite(index) ? `${msadocFileKey}` : file.name;
    const checkDocName = `${docFileName}.${fileExtension[1]}`;
    const uploadmsaDocs = inputData['#uploadMSADocument'].filter(x => x.fileName === checkDocName);
    if (uploadmsaDocs.length > 0) {
      docFileName = `${docFileName}${index + 1}`;
    }
    const UploadFile = new File([file], `${docFileName}.${fileExtension[1]}`, {
      type: file.type,
      lastModified: file.lastModified,
    });

    if (!UploadFile) {
      return;
    }

    setUploadPercentage(100);
    const partyId = getStarted.inputvalues['#writingAgentPartyId'];
    const docTypeId = constants.documentType.eApplication.msaBackOfficeDocument;
    const appId = getStarted.policyapplicationid;
    const [err, uploadedfile] = await MasterService.uploadAdditionalDocument(partyId, docTypeId, UploadFile, appId);

    if (err !== null) {
      NotificationService.error(err.data.message);
      setUploadNote('');
      setUploadPercentage(0);
      return;
    }

    if (!uploadedfile) {
      setUploadNote('');
      setUploadPercentage(0);
      return;
    }

    const uploadDoc = inputData[`#uploadMSADocument`];
    if (uploadDoc.length > 0) {
      uploadDoc.forEach((main, esignIndex) => {
        if (main.documentTypeId === uploadedfile.documentTypeId && esignIndex === index) {
          uploadDoc[esignIndex].documentFileKeyId = uploadedfile.documentFileKeyId;
          uploadDoc[esignIndex].completionDate = uploadedfile.completionDate;
          uploadDoc[esignIndex].fileName = UploadFile.name;
        }
      });
    }
    store.dispatch(setInputValues(inputData));
    setUploadNote('');

    await saveApplicationData();
    setUploadPercentage(0);
  };

  const DeleteDocfromS3 = async (document, index) => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const queryString = `?partyIdQuery=${getStarted.inputvalues['#writingAgentPartyId']}
&docTypeId=${document.documentTypeId}&appAlias=${getStarted.policyapplicationid}
&docFileKey=${document.documentFileKeyId}`;
    const [deletionError] = await MasterService.deleteDocument(queryString);
    if (deletionError !== null) {
      NotificationService.error(deletionError.data.message);
      return;
    }
    const uploadDoc = inputData[`#uploadMSADocument`];
    if (uploadDoc.length > 0) {
      uploadDoc.forEach((main, esignIndex) => {
        if (main.documentFileKeyId === document.documentFileKeyId && esignIndex === index) {
          uploadDoc[esignIndex].documentFileKeyId = '';
          uploadDoc[esignIndex].completionDate = '';
          uploadDoc[esignIndex].fileName = '';
        }
      });
    }
    store.dispatch(setInputValues(inputData));
  };

  const DeleteDocSection = async document => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    if (document.documentFileKeyId) {
      const queryString = `?partyIdQuery=${getStarted.inputvalues['#writingAgentPartyId']}
&docTypeId=${document.documentTypeId}&appAlias=${getStarted.policyapplicationid}
&docFileKey=${document.documentFileKeyId}`;
      const [deletionError] = await MasterService.deleteDocument(queryString);
      if (deletionError !== null) {
        NotificationService.error(deletionError.data.message);
        return;
      }
    }
    const uploadedDocuments = [...inputData[`#uploadMSADocument`]];
    const index = uploadedDocuments.indexOf(document);
    uploadedDocuments.splice(index, 1);
    inputData[`#uploadMSADocument`] = uploadedDocuments;
    store.dispatch(setInputValues(inputData));
  };

  const DownloadUploadedDoc = async doc => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    const selectedDoc = response.data.find(
      record => record.documentTypeId === doc.documentTypeId && record.documentFileKeyId === doc.documentFileKeyId,
    );
    if (selectedDoc) {
      const [err, downloadedFile] = await MasterService.downloadDocument(
        getStarted.inputvalues['#writingAgentPartyId'],
        selectedDoc.id,
        getStarted.policyapplicationid,
        null,
      );
      if (err !== null) {
        NotificationService.error(err.data.message);
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, doc.fileName);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" className={classes.fileLabelStyle}>
          Financial Institution Document #{props.indexNumber + 1}
        </Typography>
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.uploadGrid}>
        <div>
          <input
            disabled={
              Utils.isUserViewOnly() || !!props.document.fileName || getStarted.inputvalues['#IsSignatureCreated']
            }
            accept=".pdf"
            className={classes.displayNone}
            id={`uploadMSAbtn${props.indexNumber}`}
            name={`uploadMSAbtnbtn${props.indexNumber}`}
            multiple
            type="file"
            onChange={event => {
              uploadReplacementDoc(event, props.indexNumber);
              event.target.value = null; // eslint-disable-line
            }}
          />
          {/* eslint-disable */}
          <label htmlFor={`uploadMSAbtn${props.indexNumber}`}>
            <Button
              variant="contained"
              disabled={
                Utils.isUserViewOnly() || !!props.document.fileName || getStarted.inputvalues['#IsSignatureCreated']
              }
              component="span"
              className={
                Utils.isUserViewOnly() || !!props.document.fileName || getStarted.inputvalues['#IsSignatureCreated']
                  ? classes.uploadBtnDisabled
                  : classes.uploadBtn
              }
              color="secondary"
              startIcon={<CloudUpload />}
            >
              Upload
            </Button>
          </label>
          {/* eslint-enable */}
        </div>
      </Grid>
      {uploadPercentage === 0 && (
        <Grid item xs={8} sm={8} md={8} lg={8} className={classes.uploadNameGrid}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography
                name={`MSAfilename${props.indexNumber}`}
                variant="body1"
                className={classes.fileNameStyle}
                component={Link}
                onClick={() => DownloadUploadedDoc(props.document)}
              >
                {props.document.fileName}
              </Typography>
            </Grid>
            <Grid item className={props.document.fileName ? classes.displayBlock : classes.displayNone}>
              <CheckCircle name={`uploadMSAcheck${props.indexNumber}`} className={classes.checkIcon} />
            </Grid>
            <Grid
              item
              className={
                props.document.fileName && inputData['#IsSignatureCreated'] !== true
                  ? classes.displayBlock
                  : classes.displayNone
              }
            >
              <Cancel
                name={`uploadMSAcancel${props.indexNumber}`}
                className={classes.cancelIcon}
                onClick={() => DeleteDocfromS3(props.document, props.indexNumber)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {uploadPercentage > 0 && (
        <Grid item xs={5}>
          <LinearProgress
            name={`linearProgress${props.indexNumber}`}
            variant="determinate"
            value={progress}
            className={classes.linerProg}
            classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
          />
        </Grid>
      )}

      {props.indexNumber !== 0 ? (
        <Grid item xs={1} md={1}>
          <IconButton
            aria-label="delete"
            onClick={() => DeleteDocSection(props.document)}
            name={`msaTrash${props.indexNumber}`}
            disabled={Utils.isUserViewOnly() || getStarted.inputvalues['#IsSignatureCreated']}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Grid>
      ) : (
        <Grid item xs={1} md={1} className={classes.displayNone}>
          empty div
        </Grid>
      )}
      {CommonFunctions.checkIsNullOrUndefined(uploadNoteText) !== '' && (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.docErrorSection}>
            {uploadNoteText}
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
}
MSAContent.propTypes = {
  indexNumber: PropTypes.number,
  document: PropTypes.object,
};
