export default {
  table: {
    textAlign: 'left',
    border: '1px solid #ddd',
    borderRadius: '0px 4px 4px',
    marginTop: '-3px',
    height: '100%',

    '& tbody .MuiTableRow-root:hover': {
      '& .MuiTableCell-root:not(.datatables-noprint)': {
        color: '#0d8bc2',
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    '& .MuiTableCell-body': {
      '@media (max-width:960px)': {
        width: 'calc(50% - 32px) !important',
      },
    },
    '& tfoot td': {
      'border-bottom': 'none',
    },
  },
  tableIconHolder: {
    margin: '-0.25rem',
  },
  tableIcon: {
    padding: '0.25rem',
    marginRight: '0.25rem',
    borderRadius: '0.25rem',

    '&:hover': {
      backgroundColor: '#ccc',
      cursor: 'pointer',
    },
  },

  appDetailsCard: {
    display: 'block',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginTop: '-3px',
    marginBottom: '3px',
    height: '100%',
  },

  modal: {
    margin: '2.5rem',
    textAlign: 'center',
    overflowY: 'hidden',
    position: 'relative',
  },
  modalClear: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
  },
  modalTitle: {
    marginBottom: '1rem',
  },
  modalSelect: {
    width: '225px',
  },
  modalText: {
    marginBottom: '2rem',
    fontSize: '14px',
  },
  modalTextBold: {
    fontWeight: 'bold',
  },
  modalButton: {
    display: 'block',
    margin: '1rem auto',
    width: '225px',
  },
  uploadIcon: {
    display: 'block',
    margin: '1rem auto',
    width: '5rem',
    height: '5rem',
    color: '#ddd',
  },
  link: {
    color: '#0d8bc2',
  },
  fieldLabel: {
    backgroundColor: 'white',
    padding: '0 5px 5px',
    'border-radius': '4px',
  },
  alertStyles: {
    '& .MuiAlert-icon': {
      color: '#fff',
    },
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    backgroundColor: '#3B4F6B',
    color: '#fff',
  },
};
