import MasterService from 'api/masterService';
import NotificationService from 'services/notificationService';

const getAgentEligError = async (partyId, query) => {
  const [err, res] = await MasterService.checkAgentEligibility(partyId, query);
  if (err !== null) {
    NotificationService.error(err.data.message);
    return ['error'];
  }

  return res;
};

const fnCheckSalesEligibility = async response => {
  const eligibilityError = [];
  const eligibilityCheck = [];
  const writingAgentPartyid = response['#writingAgentPartyId'];
  let query = `?productId=${response['#product']}&state=${response['#signedstateid']}`;
  if (response['#residentStateId']) {
    query += `&resState=${response['#residentStateId'].split('~')[0]}`;
  }

  eligibilityCheck.push(getAgentEligError(writingAgentPartyid, query));

  const SplitAgentData = response['#SplitAgentData'];
  if (SplitAgentData.length > 0) {
    SplitAgentData.map(async (agentData, index) => {
      if (index !== 0) {
        const partyId = response[`#splitAgent${index}PartyId`];
        eligibilityCheck.push(getAgentEligError(partyId, query));
      }

      return false;
    });
  }

  const eligErr = await Promise.all(eligibilityCheck);

  eligErr.forEach(err => {
    err.forEach(e => eligibilityError.push(...e.errors));
  });

  return eligibilityError;
};

export default {
  fnCheckSalesEligibility,
};
