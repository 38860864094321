import React from 'react';
import { makeStyles, Button, Grid, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DropDown, CheckBox } from '@aspida/react-components';

import Paperleaf from 'assets/images/leaf.svg';
import Utils from 'views/eapp/renderforms/utils';
import styles from './style';

const useStyles = makeStyles(styles);

export default function OwnerDelivery(props) {
  const classes = useStyles();
  // curently we are not able to connect service so it have hard_cord.
  const drpCharityVal = [{ id: '1', value: 'Charity of Owners Choice' }];
  const checkGoPaperVal = [{ id: '1', value: '' }];
  const checkNothanksVal = [{ id: '1', value: "No thanks, I'd rather get my contract and all docs via snail mail" }];
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom className={classes.label}>
            Delivery Options
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Paper className={classes.paperSign}>
            <Grid container spacing={1}>
              <Grid item>
                <CheckBox
                  name="chkGoPaper"
                  listItem={checkGoPaperVal}
                  onChange={event => props.onChange(event, 'checkbox', 'chkGoPaper')}
                  color="#000000"
                  fontVariant="h6"
                  selectedValue={props.values.chkGoPaper}
                  isDisabled={Utils.isUserViewOnly()}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" className={classes.goPaper}>
                  Go Paperless
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <img src={Paperleaf} alt="logo" className={classes.leaf} />
              </Grid>
              <Grid item xs={12}>
                <span className={classes.fast}>(Fastest Option!)</span>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottomm>
                  I want to get my contract, documents, and all communications electronically, saving the planet and
                  passing along my printing savings of $5 to:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  listItem={drpCharityVal}
                  name="personName"
                  isFullWidth
                  value={props.values.personName}
                  onChange={event => props.onChange(event)}
                  variant="outlined"
                  disabled={Utils.isUserViewOnly()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckBox
            name="checkNothanks"
            listItem={checkNothanksVal}
            onChange={event => props.onChange(event, 'checkbox', 'checkNothanks')}
            selectedValue={props.values.checkNothanks}
            isDisabled={Utils.isUserViewOnly()}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={1} className={classes.rowButton}>
        <Grid item xs={12} sm={6} md={2} lg={2} className={classes.gridAlignRightBackButton}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => props.onClick(props.esignRoleName, 1)}
            disabled={Utils.isUserViewOnly()}
          >
            BACK
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2} className={classes.gridAlignLeftBackButton}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => props.onClick(props.esignRoleName, 3)}
            disabled={Utils.isUserViewOnly()}
          >
            NEXT
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

OwnerDelivery.propTypes = {
  esignRoleName: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  values: PropTypes.object,
};
