import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import Jsonfiles from 'assets/JSONFiles';
import store from 'redux/store';
import { setInputValues } from 'redux/getstarted/actions';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../views/eapp/styles';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.Co1035Name,
});

const setCompanyDetails = (companyDetails, companyName) => {
  const state = store.getState();
  const inputData = { ...state.getStarted.inputvalues };
  inputData['#transferCompanyList'] = inputData['#transferCompanyList'] || [];
  if (companyDetails.Automation1035.find(v => v.Co1035Name === companyName)) {
    return;
  }
  const companyData = inputData['#transferCompanyList'].filter(v => v.Co1035Name === companyName);
  if (companyData.length === 0) {
    const companyTemplate = {
      Co1035Name: companyName,
      Co1035Phone: '',
      Co1035Extn: '',
      Co1035Fax: '',
      Co1035Email: '',
      Co1035Addr: '',
      Co1035Addr2: '',
      Co1035City: '',
      Co1035State: '',
      Co1035Zip: '',
      Co1035Sign: 'WetMail',
      Co1035AmtForMail: 0,
      Co1035Note: '',
    };
    inputData['#transferCompanyList'].push(companyTemplate);
    store.dispatch(setInputValues({ ...state.getStarted.inputvalues, ...inputData }));
  }
};

const getAutomation1035Info = (Co1035Name, autocompletePrefixName) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const companyDetails = Jsonfiles.Automation1035;
  const foundResult = companyDetails.Automation1035.filter(d => d.Co1035Name === Co1035Name);
  if (foundResult.length > 0) {
    inputData[`${autocompletePrefixName}CompanyName`] = foundResult[0].Co1035Name;
    inputData[`${autocompletePrefixName}Phone`] = foundResult[0].Co1035Phone;
    inputData[`${autocompletePrefixName}Extn`] = foundResult[0].Co1035Extn;
    inputData[`${autocompletePrefixName}Addr`] = foundResult[0].Co1035Addr;
    inputData[`${autocompletePrefixName}Addr2`] = foundResult[0].Co1035Addr2;
    inputData[`${autocompletePrefixName}City`] = foundResult[0].Co1035City;
    inputData[`${autocompletePrefixName}State`] = foundResult[0].Co1035State;
    inputData[`${autocompletePrefixName}Zip`] = foundResult[0].Co1035Zip;
    inputData[`${autocompletePrefixName}Sign`] = foundResult[0].Co1035Sign;
    inputData[`${autocompletePrefixName}Fax`] = foundResult[0].Co1035Fax;
    inputData[`${autocompletePrefixName}Email`] = foundResult[0].Co1035Email;
    inputData[`${autocompletePrefixName}Note`] = foundResult[0].Co1035Note;
    inputData[`${autocompletePrefixName}AmtForMail`] = foundResult[0].Co1035AmtForMail;
  } else {
    inputData[`${autocompletePrefixName}CompanyName`] = Co1035Name;
    inputData[`${autocompletePrefixName}Sign`] = 'WetMail';
  }
  setCompanyDetails(companyDetails, Co1035Name);
};
const useStyles = makeStyles(styles);
export default function AutocompleteComponent(props) {
  const classes = useStyles();
  const [canFocused, setCanFocused] = useState([]);
  const handleFocusEvent = (fieldName, inputValue) => {
    setCanFocused({ fieldName: inputValue });
  };
  const data = Jsonfiles.Automation1035.Automation1035;
  const tasks = data.concat(props.Values['#transferCompanyList'] || []);
  const [value, setValue] = React.useState(null);
  const intsearch = () => {
    const companyValue = props.Values[props.group.fieldName];
    if (CommonFunctions.checkIsNullOrUndefined(companyValue) === '') {
      setValue('');
    } else {
      setValue(tasks.find(v => v.Co1035Name === companyValue));
    }
  };
  useEffect(intsearch, [props.Values[props.group.fieldName]]);
  return (
    <React.Fragment>
      <Autocomplete
        options={tasks}
        getOptionLabel={option => (option ? option.Co1035Name : '')}
        freeSolo
        disableClearable
        filterOptions={filterOptions}
        disabled={Utils.handleElementDisable(props)}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            name={props.group.fieldName}
            className={classes.percentageStyle}
            label={props.group.fieldProperty.infieldLabelText}
            variant="outlined"
            InputLabelProps={{
              shrink:
                CommonFunctions.checkIsNullOrUndefined(props.Values[props.group.fieldName]) !== '' ||
                canFocused[props.group.fieldName],
              className: props.group.fieldProperty.isEditable && classes.labelBackgroundColor,
            }}
            onFocus={() => handleFocusEvent(props.group.fieldName, true)}
            onBlur={event => getAutomation1035Info(event.target.value, props.group.fieldProperty.autocompletePrefix)}
            disabled={Utils.handleElementDisable(props)}
            error={
              props.Errors[props.group.fieldName] !== undefined ? props.Errors[props.group.fieldName] !== '' : false
            }
            helperText={props.Errors[props.group.fieldName]}
          />
        )}
      />
    </React.Fragment>
  );
}

AutocompleteComponent.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  Errors: PropTypes.object,
};
