import React from 'react';
import PropTypes from 'prop-types';
import store from 'redux/store';
import { CurrencyTextbox } from '@aspida/react-components';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Button, Grid, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const CurrencyComp = props => {
  const classes = useStyles();
  const getGroupIndex = tabName => {
    const state = store.getState();
    const groupValue = [];
    if (state.getStarted.trackergroupnames.length > 0) {
      state.getStarted.trackergroupnames.forEach((group, index) => {
        if (group.displayname === tabName) {
          groupValue.push({ Id: index, Value: 0 });
        }
        state.getStarted.trackergroupnames[index].SubGroup.forEach((subGroup, subGroupIndex) => {
          if (subGroup.displayname === tabName) {
            groupValue.push({ Id: index, Value: subGroupIndex });
          }
        });
      });
    }
    return groupValue;
  };
  const fieldRequired = text => {
    if (text) {
      return (
        <p style={{ margin: 0 }}>
          <span style={{ color: 'red' }}>*</span>
          {text}
        </p>
      );
    }
  };
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-sm-12
            MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        key={props.group.fieldName}
        item
        id={`grd${props.group.fieldName}`}
        className={[props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone, 'currency'].join(
          ' ',
        )}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
          marginLeft: `${
            props.group.fieldProperty.styles.marginLeft !== undefined ? props.group.fieldProperty.styles.marginLeft : 0
          }rem`,
        }}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 3}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 3}
      >
        {!!props.group.fieldProperty.labelText && (
          <Typography
            variant={props.group.fieldProperty.styles.labelFontVariant}
            gutterBottom
            style={{
              color: props.group.fieldProperty.styles.labelFontColor,
              fontWeight: `${props.group.fieldProperty.styles.labelFontWeight}`,
              fontSize: `${props.group.fieldProperty.styles.labelFontSize}em`,
              marginBottom: `${props.group.fieldProperty.styles.marginBottom}em`,
            }}
          >
            {props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.labelText)
              : props.group.fieldProperty.labelText}
          </Typography>
        )}
        <CurrencyTextbox
          id={`txt${props.group.fieldName}`}
          label={
            props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.infieldLabelText)
              : props.group.fieldProperty.infieldLabelText
          }
          name={props.group.fieldName}
          onChange={event => props.onChange(event, undefined, 'currency', props.group.fieldName)}
          onBlur={event => props.onChange(event, props.group.fieldValidation, 'currency', props.group.fieldName)}
          error={props.Errors[props.group.fieldName] !== undefined ? props.Errors[props.group.fieldName] !== '' : false}
          helperText={
            CommonFunctions.checkIsNullOrUndefined(props.SpecialErrors[props.group.fieldName]) === '' &&
            props.Errors[props.group.fieldName]
          }
          fullWidth
          disabled={Utils.handleElementDisable(props)}
          value={props.Values[props.group.fieldName]}
          InputLabelProps={{
            className: props.group.fieldProperty.isEditable && classes.labelBackgroundColor,
          }}
          variant="outlined"
          tooltipText={props.group.fieldProperty.tooltipText}
          className={classes.percentageStyle}
        />
      </Grid>
      {CommonFunctions.checkIsNullOrUndefined(props.SpecialErrors[props.group.fieldName]) !== '' && (
        <Alert
          severity="error"
          className={classes.alert}
          action={
            props.group.fieldProperty.isLinkError ? (
              <Button
                color="inherit"
                size="small"
                classname={classes.button}
                endIcon={<ChevronRight />}
                onClick={event => {
                  const groupInd = getGroupIndex('Your Annuity');
                  props.onTabClick(event, groupInd[0].Id, groupInd[0].Value);
                }}
              >
                Link
              </Button>
            ) : (
              undefined
            )
          }
        >
          {props.Errors[props.group.fieldName]}
        </Alert>
      )}
    </React.Fragment>
  );
};

CurrencyComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  SpecialErrors: PropTypes.array,
  Errors: PropTypes.object,
  onChange: PropTypes.func,
  onTabClick: PropTypes.func,
};

export default CurrencyComp;
