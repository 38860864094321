import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, makeStyles, Grid, Typography, Paper, Divider } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import constants from 'constants/common';
import CommonFunctions from '../../utils/commonFunctions';
import AddlUploadSection from './docs/AddlUploadDoc';
import UploadSection from './docs/UploadDoc';
import UploadMSASection from './docs/UploadMSADoc';
import DownloadSection from './docs/DownloadDoc';
import styles from './style';

const useStyles = makeStyles(styles);

export default function RequiredDocs(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const checkToFollow = CommonFunctions.getCheckToFollow();
  const companySignData = CommonFunctions.getCompanySignData();

  const getUploadContent = () => {
    return (
      <Paper className={classes.paperHeader} elevation={3} variant="elevation" square>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom className={classes.label}>
              Upload these documents.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const getUploadSection = (name, label, value, index, documentTypeId) => {
    return (
      <UploadSection
        name={name}
        label={label}
        value={value}
        onChange={props.onChange}
        indexNumber={index}
        documentTypeId={documentTypeId}
      />
    );
  };

  const getAddlUploadContent = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom className={classes.headStyle}>
          <span style={{ color: 'red' }}>*</span>Applicant: Additional Documentation
        </Typography>
        <Typography variant="body2" gutterBottom className={classes.label}>
          We require additional information before we can begin processing this application. Please upload the
          appropriate documents to both sections below.
        </Typography>
      </Grid>
    );
  };

  const getAddlDocSection = (RoleName, IDDescription, IdologyReason) => {
    return (
      <AddlUploadSection
        name="dropUpload"
        docRoleName={RoleName}
        description={IDDescription}
        IdCheckReason={IdologyReason}
        onChange={props.onChange}
      />
    );
  };

  const getBannerNotification = () => {
    return (
      <Alert
        severity="info"
        className={classes.alert}
        action={
          <Button
            color="inherit"
            size="small"
            className={classes.Button}
            endIcon={<ChevronRight />}
            onClick={event => {
              const groupInd = CommonFunctions.getGroupIndex('Personal Info');
              props.onTabClick(event, groupInd[0].Id, groupInd[0].Value);
            }}
          >
            Go To Personal Info
          </Button>
        }
      >
        SSN or DOB information provided does not match. Please double check client(s) information. If the entered
        information is correct, please upload documents to continue.
      </Alert>
    );
  };

  const setIdologyRequiredSection = roleName => {
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.marginBtn}>
          <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
            {getAddlUploadContent()}
          </Paper>
          <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
            <Grid container className={classes.paperBodyDoc} spacing={1}>
              {getAddlDocSection(roleName, `${roleName}SSN`, 'SSN Does Not Match', 1)}
              <Grid item xs={12} className={classes.dividerStyle}>
                <Divider />
              </Grid>
              {getAddlDocSection(roleName, `${roleName}DOB`, 'PA DOB Does Not Match', 2)}
            </Grid>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  };

  const setDtcIdologyRequiredSection = roleName => {
    if (!user.isDtcCustomer) {
      return null;
    }
    if (
      (!getStarted.inputvalues['#ifJointOwner'] && roleName !== 'owner') ||
      (getStarted.inputvalues['#ifJointOwner'] === false && roleName === 'jointOwner')
    ) {
      return null;
    }
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.marginBtn}>
          <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
            {getAddlUploadContent()}
          </Paper>
          <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
            <Grid container className={classes.paperBodyDoc} spacing={1}>
              {getAddlDocSection(roleName, `${roleName}DOB`, 'PA DOB Does Not Match', 2)}
            </Grid>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {(CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('owner')) !== '' ||
          CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('jointOwner')) !== '' ||
          CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('annuitant')) !== '') &&
          getBannerNotification()}
        {checkToFollow.map((checkData, index) => {
          return (
            CommonFunctions.checkIsNullOrUndefined(checkData.checkAmount) !== '' && (
              <DownloadSection
                checkData={checkData}
                indexNumber={checkToFollow.length > 0 ? index + 1 : ''}
                onChange={props.onChange}
                documentTypeId={constants.documentType.smartComm.checkToFollow}
                loaDocumentTypeId={
                  getStarted.inputvalues['#annuityType'] === 'Qualified' &&
                    getStarted.inputvalues['#fundExistingMoneyDirectRollover'] ===
                    'Existing Money - Trustee to Trustee/ Direct Rollover'
                    ? constants.documentType.smartComm.letterOfAssignment
                    : null
                }
              />
            )
          );
        })}
        {getStarted.inputvalues['#IsMSA'] && getStarted.inputvalues['#IsMSAwithBackoffice'] && (
          <Grid item xs={12}>
            <UploadMSASection />
          </Grid>
        )}
        <Grid item xs={12}>
          {getStarted.inputvalues['#ownerPOA'] && (
            <React.Fragment>
              {getUploadContent}
              {getUploadSection(
                'POA',
                'POA',
                'Affidavit to Affirm POA Form',
                null,
                constants.documentType.smartComm.powerOfAttorney,
              )}
            </React.Fragment>
          )}
          {getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Trust && (
            <React.Fragment>
              {getUploadContent}
              {getUploadSection(
                'Trust',
                'For Trusts',
                'Trust Documents',
                null,
                constants.documentType.eApplication.trustDocument,
              )}
            </React.Fragment>
          )}
          {getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Corporation &&
            getStarted.inputvalues['#corpTypeofEntity'] === constants.corpTypeofEntity.corporation && (
              <>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corppof',
                    'For Corporations',
                    'Corporation Proof of Existence Documents (within last 6 months)',
                    null,
                    constants.documentType.eApplication.corporationProofOfExistence,
                  )}
                </React.Fragment>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corpaoi',
                    'For Corporations',
                    'Articles of Incorporation with state or county',
                    null,
                    constants.documentType.eApplication.articlesOfIncorporation,
                  )}
                </React.Fragment>
              </>
            )}
          {getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Corporation &&
            getStarted.inputvalues['#corpTypeofEntity'] === constants.corpTypeofEntity.taxExemptEntity && (
              <>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corppof',
                    'For Corporations',
                    'Tax Exempt Entity Proof of Existence (within last 6 months)',
                    null,
                    constants.documentType.eApplication.corporationProofOfExistence,
                  )}
                </React.Fragment>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corpaoi',
                    'For Corporations',
                    'Articles of Incorporation With State or County',
                    null,
                    constants.documentType.eApplication.articlesOfIncorporation,
                  )}
                </React.Fragment>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corpirs',
                    'For Corporations',
                    'IRS Certificate of Determination',
                    null,
                    constants.documentType.eApplication.irsCertificateOfDetermination,
                  )}
                </React.Fragment>
              </>
            )}
          {getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Corporation &&
            getStarted.inputvalues['#corpTypeofEntity'] === constants.corpTypeofEntity.govEntity && (
              <>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corppof',
                    'For Corporations',
                    'State or Government Entity Proof of Existence (within last 6 months)',
                    null,
                    constants.documentType.eApplication.corporationProofOfExistence,
                  )}
                </React.Fragment>
                <React.Fragment>
                  {getUploadContent}
                  {getUploadSection(
                    'Corpaoi',
                    'For Corporations',
                    'Articles of Incorporation With State or County',
                    null,
                    constants.documentType.eApplication.articlesOfIncorporation,
                  )}
                </React.Fragment>
              </>
            )}
          {companySignData.map((companyData, index) => {
            if (CommonFunctions.checkIsNullOrUndefined(companyData.transferFW) !== '') {
              return getUploadSection(
                `OriginalContract${index + 1}`,
                'Original Contract',
                `${companyData.companyName} Contract`,
                index + 1,
                constants.documentType.eApplication.surrenderingCompanyOriginalContract,
              );
            }
            return false;
          })}
        </Grid>
        <Grid item xs={12}>
          {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ownerIDFlag'])
            ? setIdologyRequiredSection('owner')
            : setDtcIdologyRequiredSection('owner')}
          {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#jointOwnerIDFlag'])
            ? setIdologyRequiredSection('jointOwner')
            : setDtcIdologyRequiredSection('jointOwner')}
          {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#annuitantIDFlag']) &&
            setIdologyRequiredSection('annuitant')}
        </Grid>
        {CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.UploadDocument) !== '' && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom className={classes.docErrorSection}>
              Upload Document is required.
            </Typography>
          </Grid>
        )}
        {(CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.OwneradditionalUploadDocument) !== '' ||
          CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.JointOwneradditionalUploadDocument) !== '' ||
          CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.AnnuitantadditionalUploadDocument) !== '') && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom className={classes.docErrorSection}>
                Additional Document is required.
              </Typography>
            </Grid>
          )}
      </Grid>
    </React.Fragment>
  );
}

RequiredDocs.propTypes = {
  onChange: PropTypes.func,
  onTabClick: PropTypes.func,
};
