import constants from 'constants/common';

export const ROLES = {
  ADMINISTRATOR: {
    LICENSING_OPS: constants.personRole.licensingOps,
    PRINCIPAL_AGENT: constants.personRole.principalAgent,
    ROSTER_ADMIN: constants.personRole.rosterAdmin,
  },
  REPORTING: {
    REPORTING_ADMIN: constants.personRole.reportingAdmin,
    LIMITED_REPORTING_ADMIN: constants.personRole.limitedReportingAdmin,
  },
  PRODUCER: {
    PRODUCER: constants.personRole.producer,
    PRINCIPAL_AGENT: constants.personRole.principalAgent,
  },
  VIEWONLY: {
    NON_PRODUCING_PRINCIPAL_AGENT: constants.personRole.nonProducingPrincipalAgent,
    ROSTER_ADMIN: constants.personRole.rosterAdmin,
    REPORTING_ADMIN: constants.personRole.reportingAdmin,
    LIMITED_REPORTING_ADMIN: constants.personRole.limitedReportingAdmin,
  },
  DTCCUCTOMER: {
    DTC_CUCTOMER: constants.personRole.dtcCustomer,
  },
};

export default class RoleHelper {
  /**
   * Get the ids of the specified ROLES Catergory.
   *
   * @param {*} roleCatergory ROLES Catergory
   * @returns array of ids in the catergory
   */
  static getIds(roleCatergory) {
    return Object.values(roleCatergory);
  }

  /**
   * Checks a user's roleType for a given list of roles.
   *
   * @param {object} user user object
   * @param {array} roles role ids array or ROLES catergory
   * @returns true if found, or false if not found
   */
  static userHasRoles(user, roles) {
    const rolesList = Array.isArray(roles) ? roles : this.getIds(roles);

    if (user && user.types) {
      return user.types.find(role => {
        return rolesList.includes(role.id);
      });
    }

    return false;
  }
}
