import * as GetStartedTypes from './types';

export const initialState = {
  loading: false,
  IsSubmitted: 0,
  product: '',
  signedstate: '',
  signedstateabb: '',
  signedstateid: '',
  applicationtype: '',
  currentpage: { Group: 0, SubGroup: 0, JsonData: '' },
  inputvalues: {},
  inputerrors: { product: '', applicationtype: '', signedstate: '' },
  policyapplicationid: '',
  productname: '',
  trackergroupnames: [],
  errorlists: [],
  errorpanellists: [],
  lockfields: [],
  specialerrors: [],
  saleseligibilityerrors: [],
  partyesignerslist: [],
  hidetab: [],
  productrates: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GetStartedTypes.LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }
    case GetStartedTypes.ISSUBMITTED: {
      return {
        ...state,
        IsSubmitted: action.value,
      };
    }
    case GetStartedTypes.POLICYAPPLICATIONID: {
      return {
        ...state,
        policyapplicationid: action.value,
      };
    }
    case GetStartedTypes.PRODUCTNAME: {
      return {
        ...state,
        productname: action.value,
      };
    }
    case GetStartedTypes.PRODUCT: {
      return {
        ...state,
        product: action.value,
      };
    }
    case GetStartedTypes.APPLICATIONTYPE: {
      return {
        ...state,
        applicationtype: action.value,
      };
    }
    case GetStartedTypes.SIGNEDSTATE: {
      return {
        ...state,
        signedstate: action.value,
      };
    }
    case GetStartedTypes.SIGNEDSTATEABB: {
      return {
        ...state,
        signedstateabb: action.value,
      };
    }
    case GetStartedTypes.SIGNEDSTATEID: {
      return {
        ...state,
        signedstateid: action.value,
      };
    }
    case GetStartedTypes.JSONFILENAME: {
      return {
        ...state,
        jsonfilename: action.value,
      };
    }
    case GetStartedTypes.CURRENTPAGE: {
      return {
        ...state,
        currentpage: action.value,
      };
    }
    case GetStartedTypes.TRACKERGROUPNAMES: {
      return {
        ...state,
        trackergroupnames: action.value,
      };
    }
    case GetStartedTypes.INPUTVALUES: {
      return {
        ...state,
        inputvalues: action.value,
      };
    }
    case GetStartedTypes.INPUTERRORS: {
      return {
        ...state,
        inputerrors: action.value,
      };
    }
    case GetStartedTypes.ERRORLISTS: {
      return {
        ...state,
        errorlists: action.value,
      };
    }
    case GetStartedTypes.ERRORPANELLISTS: {
      return {
        ...state,
        errorpanellists: action.value,
      };
    }
    case GetStartedTypes.LOCKFIELDS: {
      return {
        ...state,
        lockfields: action.value,
      };
    }
    case GetStartedTypes.SPECIALERRORS: {
      return {
        ...state,
        specialErrors: action.value,
      };
    }
    case GetStartedTypes.SALESELIGIBILITYERRORS: {
      return {
        ...state,
        saleseligibilityerrors: action.value,
      };
    }
    case GetStartedTypes.PARTYESIGNERSLIST: {
      return {
        ...state,
        partyesignerslist: action.value,
      };
    }
    case GetStartedTypes.HIDETAB: {
      return {
        ...state,
        hidetab: action.value,
      };
    }
    case GetStartedTypes.PRODUCTRATES: {
      return {
        ...state,
        productrates: action.value,
      };
    }
    case GetStartedTypes.RESET: {
      return {
        ...state,
        IsSubmitted: 0,
        product: '',
        signedstate: '',
        signedstateabb: '',
        signedstateid: '',
        applicationtype: '',
        currentpage: { Group: 0, SubGroup: 0, JsonData: '' },
        inputvalues: {},
        inputerrors: { product: '', applicationtype: '', signedstate: '' },
        policyapplicationid: '',
        productname: '',
        trackergroupnames: [],
        errorlists: [],
        errorpanellists: [],
        saleseligibilityerrors: [],
        partyesignerslist: [],
        hidetab: [],
        productrates: [],
      };
    }
    case GetStartedTypes.SETVALUE: {
      return {
        ...state,
        product: action.value.product,
        signedstate: action.value.signedstate,
        signedstateabb: action.value.signedstateabb,
        signedstateid: action.value.signedstateid,
        applicationtype: action.value.applicationtype,
        currentpage: action.value.currentpage,
        inputvalues: action.value.inputvalues,
        inputerrors: action.value.inputerrors,
        policyapplicationid: action.value.policyapplicationid,
        productname: action.value.productname,
        trackergroupnames: action.value.trackergroupnames,
        errorlists: action.value.errorlists,
        errorpanellists: action.value.errorpanellists,
        saleseligibilityerrors: action.value.saleseligibilityerrors,
        partyesignerslist: action.value.partyesignerslist,
        hidetab: action.value.hidetab,
        productrates: action.value.productrates,
      };
    }
    default: {
      return { ...state };
    }
  }
};
