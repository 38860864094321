import store from 'redux/store';
import CommonFunctions from 'views/eapp/utils/commonFunctions';

const handleOnPasteEvent = (e, isAction) => {
  if (!isAction && isAction !== undefined) {
    e.preventDefault();
  }
};

const onlyNumbers = (e, isAction) => {
  if (isAction && isAction !== undefined) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  }
};

const handleOnCopyEvent = (e, isAction) => {
  if (!isAction && isAction !== undefined) {
    e.preventDefault();
  }
};

const handleOnCutEvent = (e, isAction) => {
  if (!isAction && isAction !== undefined) {
    e.preventDefault();
  }
};

const handleDisableNumericEvent = (e, isAction) => {
  if (isAction) {
    const charCode = e.which ? e.which : e.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) {
      e.preventDefault();
    }
  }
};

const handleElementDisable = props => {
  const state = store.getState();
  const { user } = state;

  return (
    !props.group.fieldProperty.isEditable ||
    CommonFunctions.checkIsNullOrUndefined(props.LockFields[props.group.fieldName]) !== '' ||
    CommonFunctions.checkIsNullOrUndefined(props.Values['#isReviewComplete']) === true ||
    CommonFunctions.checkIsNullOrUndefined(props.Values['#isViewOnly']) === true ||
    user.isViewOnly
  );
};

const isUserViewOnly = () => {
  const state = store.getState();
  const { user, getStarted } = state;

  return user.isViewOnly || CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isViewOnly']) === true;
};

export default {
  handleOnPasteEvent,
  handleOnCopyEvent,
  handleOnCutEvent,
  handleDisableNumericEvent,
  onlyNumbers,
  handleElementDisable,
  isUserViewOnly,
};
