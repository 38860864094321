import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { CurrencyTextbox } from '@aspida/react-components';
import aspidalogo from 'assets/images/aspida-icon-logo-primary.svg';
import store from 'redux/store';
import styles from './style';

const useStyles = makeStyles(styles);

export default function EftComponent(props) {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const classes = useStyles();
  const [isFocused, setisFocused] = useState([]);
  const [checkDate] = useState(moment(new Date()).format('MM/DD/YYYY'));
  const handlefocus = (fieldName, vla) => {
    setisFocused({ fieldName: vla });
  };

  return (
    <React.Fragment>
      <Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} className={classes.marginBtmPer}>
          <Paper className={classes.paperDownload} square elevation={0}>
            <Grid
              container
              spacing={1}
              style={{
                backgroundImage: `url(${aspidalogo})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <Grid item xs={6} sm={9} className={classes.marginBtmPer}>
                <Typography variant="body2" gutterBottom className={classes.floatRight}>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id={`${props.group.fieldProperty.eftPrefix}CheckdateId`}
                  name={`${props.group.fieldProperty.eftPrefix}Checkdate`}
                  variant="standard"
                  value={checkDate}
                  color="primary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  className={classes.checkDate}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" gutterBottom className={classes.textalign}>
                  Pay to the Order of
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputProps={{ className: classes.aspidaStyle }}
                  disabled
                  name={`${props.group.fieldProperty.eftPrefix}PayOrder`}
                  fullWidth
                  value="Aspida Financial"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <CurrencyTextbox
                  id={`${props.group.fieldProperty.eftPrefix}AmountId`}
                  name={`${props.group.fieldProperty.eftPrefix}Amount`}
                  value={inputData[`${props.group.fieldProperty.eftPrefix}AmountString`]}
                  disabled
                  InputLabelProps={{
                    shrink:
                      inputData[`${props.group.fieldProperty.eftPrefix}Amount`] !== '' ||
                      isFocused[`${props.group.fieldProperty.eftPrefix}Amount`],
                    className: classes.textstyle,
                  }}
                  InputProps={{ className: classes.textstyle }}
                  variant="outlined"
                  label="Amount ($)"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                <TextField
                  label="Routing Number"
                  name={`${props.group.fieldProperty.eftPrefix}RoutingNumber`}
                  onFocus={() => handlefocus(`${props.group.fieldProperty.eftPrefix}AccountNumber`, true)}
                  value={inputData[`${props.group.fieldProperty.eftPrefix}RoutingNumber`]}
                  InputLabelProps={{
                    shrink:
                      inputData[`${props.group.fieldProperty.eftPrefix}RoutingNumber`] !== '' ||
                      isFocused[`${props.group.fieldProperty.eftPrefix}RoutingNumber`],
                    className: classes.textstyle,
                  }}
                  InputProps={{ className: classes.textstyle }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={5}>
                <TextField
                  onChange={event =>
                    props.onChange(
                      event,
                      props.group.fieldValidation,
                      '',
                      `${props.group.fieldProperty.eftPrefix}AccountNumber`,
                    )
                  }
                  label="Account Number"
                  name={`${props.group.fieldProperty.eftPrefix}AccountNumber`}
                  InputProps={{ className: classes.textstyle }}
                  value={inputData[`${props.group.fieldProperty.eftPrefix}AccountNumber`]}
                  onFocus={() => handlefocus(`${props.group.fieldProperty.eftPrefix}AccountNumber`, true)}
                  InputLabelProps={{
                    shrink:
                      inputData[`${props.group.fieldProperty.eftPrefix}AccountNumber`] !== '' ||
                      isFocused[`${props.group.fieldProperty.eftPrefix}AccountNumber`],
                    className: classes.textstyle,
                  }}
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

EftComponent.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};
