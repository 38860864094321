import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from 'hookrouter';
import { useAsyncEffect } from 'use-async-effect';
import { Box, CircularProgress } from '@material-ui/core';
import { setPageFlow } from 'redux/remoteSign/action';
import { useBeforeunload } from 'react-beforeunload';
import masterService from 'api/masterService';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import BrowserHelper from 'helpers/browserHelper';
import NotificationService from 'services/notificationService';
import styles from './styles';
import Identity from './identity';
import Success from './Success';
import MSASuccess from './MSASuccess';
import MSALogin from './MSALogin';

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  const [queryParams] = useQueryParams();
  let sessionSignReqId;
  let sessionSignerId;
  const { SignatureRequestId, SignerId } = queryParams;
  if (SignatureRequestId) {
    sessionStorage.setItem('signatureRequestId', SignatureRequestId);
    sessionStorage.setItem('signerId', SignerId);
    sessionSignReqId = SignatureRequestId;
    sessionSignerId = SignerId;
  } else {
    sessionSignReqId = sessionStorage.getItem('signatureRequestId');
    sessionSignerId = sessionStorage.getItem('signerId');
  }
  const [remoteData, setRemoteData] = useState([]);
  const dispatch = useDispatch();
  const remoteSign = useSelector(state => state.remoteSign);

  useBeforeunload(async event => {
    let signerDetails = [];
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) === '') {
      return;
    }
    event.preventDefault();
    signerDetails = JSON.parse(sessionStorage.getItem('signerDetails'));
    const payload = {
      partyId: signerDetails.partyId,
      docusignEnvelopeId: signerDetails.docusignEnvelopeId,
      docusignClientUserId: signerDetails.docusignClientUserId,
    };
    const beforeMessage = `trigger browser tab close endpoint in remote ${remoteSign.applicationId}`;
    BrowserHelper.saveBrowserInfo(remoteSign.applicationId, beforeMessage);
    const [remoteSignResError, remoteSignRes] = await masterService.browserTabSubmit(
      remoteSign.applicationAlias,
      payload,
    );
    if (remoteSignResError) {
      NotificationService.error(remoteSignResError.data.message);
      return;
    }
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) !== '') {
      sessionStorage.removeItem('signerDetails');
    }
    return remoteSignRes;
  });

  useAsyncEffect(async () => {
    if (sessionSignReqId) {
      const [error, remoteSignData] = await masterService.getRemoteSignData(sessionSignReqId, sessionSignerId);
      if (error) {
        NotificationService.error(error.data.message);
      }
      setRemoteData(remoteSignData);
      if (remoteSignData.isMSA) {
        dispatch(setPageFlow(2));
        return;
      }
      dispatch(setPageFlow(3));
    }
  }, []);

  const renderSwitch = pageFlow => {
    switch (pageFlow) {
      case 1:
        return <Success />;
      case 2:
        return <MSALogin remoteData={remoteData} SignatureRequestId={sessionSignReqId} SignerId={sessionSignerId} />;
      case 3:
        return <Identity remoteData={remoteData} SignatureRequestId={sessionSignReqId} SignerId={sessionSignerId} />;
      case 4:
        return <MSASuccess />;
      default:
        return '';
    }
  };

  return (
    <Box className={classes.root} display="flex" flexDirection="column" justifyContent="space-evenly">
      <div>{remoteSign.loading ? <CircularProgress /> : <div>{renderSwitch(remoteSign.pageFlow)}</div>}</div>
    </Box>
  );
};
