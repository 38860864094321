import React from 'react';
import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { useAsyncEffect } from 'use-async-effect';
import { GetApp } from '@material-ui/icons';
import CircleDocuSign from 'assets/images/circledocusign.svg';
import MasterService from 'api/masterService';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import constants from 'constants/common';
import NotificationService from 'services/notificationService';
import BrowserHelper from 'helpers/browserHelper';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function EsignSuccess() {
  const classes = useStyles();
  const eSignDownloadInput = JSON.parse(sessionStorage.getItem('eSignDownloadInput'));
  const remoteSignerData = JSON.parse(sessionStorage.getItem('remoteSignerData'));
  if (eSignDownloadInput) {
    BrowserHelper.saveBrowserInfo(
      eSignDownloadInput.applicationId,
      `Agent clicked finish button in docusign pop up with signer details
      ${eSignDownloadInput.signatureRequestID},${eSignDownloadInput.applicationAlias}`,
    );
  }
  if (remoteSignerData) {
    BrowserHelper.saveBrowserInfo(
      remoteSignerData.applicationId,
      `RemoteSign: Agent clicked finish button in docusign pop up with signer details
      ${remoteSignerData.SignatureRequestID},${remoteSignerData.applicationAlias}`,
    );
  }

  const DownloadSignedDoc = async () => {
    if (eSignDownloadInput) {
      const [err, downloadedFile] = await MasterService.downloadSignDocument(
        eSignDownloadInput.signatureRequestID,
        eSignDownloadInput.emailAddress,
      );
      if (err !== null) {
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, eSignDownloadInput.applicationAlias);
      }
    }
  };

  useAsyncEffect(async () => {
    let signerDetails = [];
    signerDetails = JSON.parse(sessionStorage.getItem('signerDetails'));
    const applicationAliasId = remoteSignerData
      ? remoteSignerData.applicationAlias
      : eSignDownloadInput.applicationAlias;
    const eSignUpdateStatus = {
      docusignEnvelopeId: signerDetails.docusignEnvelopeId,
      docusignClientUserId: signerDetails.docusignClientUserId,
      esignStatusId: constants.esignStatusId.Signed,
      esignCompletionDate: new Date(),
      inPerson: CommonFunctions.checkIsNullOrUndefined(remoteSignerData) === '',
      eApplicationAlias: applicationAliasId,
    };
    const [remoteSignResError, remoteSignRes] = await MasterService.getRemoteSignStatus(
      signerDetails.docusignEnvelopeId,
      signerDetails.partyId,
    );
    if (remoteSignResError) {
      NotificationService.error(remoteSignResError.data.message);
      return;
    }
    if (remoteSignRes.status === 'completed') {
      const [updateEsignError] = remoteSignerData
        ? await MasterService.updateRemoteEsignStatus(eSignUpdateStatus)
        : await MasterService.updateEsignStatus(eSignUpdateStatus);
      return updateEsignError;
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container className={classes.gridWidth} spacing={3}>
        <Grid item xs={12} className={classes.gridAlignCenterNextButton}>
          <img src={CircleDocuSign} alt="logo" className={classes.logo} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.signed}>
            Your document has been completed!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.signed}>
            Please close the Pop up in order to complete the esign process
          </Typography>
        </Grid>
        {eSignDownloadInput && (
          <Grid item xs={12} className={classes.gridAlignCenterNextButton}>
            <Button
              name="esignDownload"
              variant="contained"
              className={classes.openIcon}
              color="secondary"
              startIcon={<GetApp />}
              onClick={() => DownloadSignedDoc()}
            >
              Download Document
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
