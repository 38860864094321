import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      borderRadius: '0.25rem',
      padding: '0.625rem',

      '& > *:not(:last-child)': {
        paddingRight: '0.5rem',
      },
    },

    border: {
      color: '#4D4D4D',
      width: '100%',
      border: '1px solid #464646',
      height: '76px',
      fontSize: '18px',
      boxShadow: 'inset 8px 0 0 0 #497915',
      borderRadius: '8px',
      textTransform: 'capitalize',
      backgroundColor: '#fff',
      paddingLeft: '1rem',
    },
    iconStyle: {
      borderRadius: '9px',
      backgroundColor: '#EAF9DB',
      marginTop: '.9rem',
      color: '#497915',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#EAF9DB',
      },
    },
    accountNo: {
      fontFamily: 'Muli',
      margin: '.9rem',
      color: '#4D4D4D',
      fontSize: '18px',
      textTransform: 'capitalize',
    },
    paddingIcon: {
      padding: '0',
    },
    question: {
      color: '#000000',
      fontFamily: 'Muli',
      textAlign: 'center',
    },
    displayNone: {
      display: 'none',
    },
  });

export default styles;
