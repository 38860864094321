import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import constants from 'constants/common';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const ButtonComp = props => {
  const classes = useStyles();

  const getIcon = iconName => {
    const tabIcon = {
      AddCircle: <AddCircle className={props.group.fieldProperty.specialBtn && classes.specialIconstyle} />,
      default: '',
    };
    return tabIcon[iconName] || tabIcon.default;
  };
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && <Grid item xs={12} />}
      <Grid
        key={props.group.fieldName}
        item
        id={`grd${props.group.fieldName}`}
        className={props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
          marginLeft: `${
            props.group.fieldProperty.styles.marginLeft !== undefined ? props.group.fieldProperty.styles.marginLeft : 0
          }rem`,
          textAlign: `${
            props.group.fieldProperty.styles.textAlign !== undefined ? props.group.fieldProperty.styles.textAlign : ''
          }`,
        }}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 12}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 12}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 12}
      >
        <Button
          variant="contained"
          onClick={event => props.onChange(event, props.group.fieldValidation, 'button', props.group.fieldName)}
          value={props.group.fieldProperty.labelText}
          disabled={Utils.handleElementDisable(props)}
          name={props.group.fieldName}
          className={props.group.fieldProperty.specialBtn ? classes.specialBtn : classes.buttonStyle}
          startIcon={getIcon(props.group.fieldProperty.iconName)}
          style={{
            backgroundColor: Utils.handleElementDisable(props)
              ? constants.disabledBGColor
              : `${props.group.fieldProperty.styles.backgroundColor}`,
            color: Utils.handleElementDisable(props)
              ? constants.disabledColor
              : `${props.group.fieldProperty.styles.labelFontColor}`,
            fontSize: `${props.group.fieldProperty.styles.labelFontSize}rem`,
            width: `${props.group.fieldProperty.styles.btnWidth}`,
          }}
        >
          {props.group.fieldProperty.labelText}
        </Button>
      </Grid>
    </React.Fragment>
  );
};

ButtonComp.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};

export default ButtonComp;
