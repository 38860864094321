const checkcorpVal = [
  { id: '1', value: 'Amazon' },
  { id: '2', value: 'Facebook' },
  { id: '3', value: 'Apple' },
  { id: '4', value: 'None of these' },
];
const checkaddrVal = [
  { id: '1', value: '350 Fifth Avenue' },
  { id: '2', value: '11 Wall Street' },
  { id: '3', value: '1600 Pennsylvania Ave.' },
  { id: '4', value: 'None of these' },
];
const checkvehVal = [
  { id: '1', value: '2018 Tesla Model X' },
  { id: '2', value: '2020 Hyundai Palisade' },
  { id: '3', value: '2016 Honda Odyssey' },
  { id: '4', value: 'None of these' },
];
const checkcountVal = [
  { id: '1', value: 'Wake, NC' },
  { id: '2', value: 'Suffolk, NY' },
  { id: '3', value: 'Absaroka, WY' },
  { id: '4', value: 'None of these' },
];

export default {
  checkcountVal,
  checkvehVal,
  checkaddrVal,
  checkcorpVal,
};
