/* eslint-disable no-control-regex */
import React from 'react';
import moment from 'moment';
import _, { first } from 'lodash';
import store from 'redux/store';
import { navigate } from 'hookrouter';
import {
  setInputValues,
  setInputErrors,
  setTrackerGroupName,
  setErrorLists,
  setHideTab,
  setReset,
  setJSONFilename,
  setCurrentPage,
  setLoading,
  setErrorPanelLists,
} from 'redux/getstarted/actions';
import { setAuthenticatedUser } from 'redux/user/actions';
import GroupTrackerFunctions from 'views/eapp/utils/groupTrackerFunctions';
import EsignEventValidation from 'views/eapp/utils/esignEventValidation';
import constants from 'constants/common';
import masterService from 'api/masterService';
import { Tooltip } from '@aspida/react-components';
import Jsonfiles from 'assets/JSONFiles';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import defaultProps from 'assets/JSONFiles/default.json';
import defaultActionProps from 'assets/JSONFiles/defaultAction.json';
import NotificationService from 'services/notificationService';
import ValidationFunctions from '../validationFunctions';

const renderHTML = rawHTML => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });
const ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/;
const tinRegex = /^\d{2}-?\d{7}$/;
const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const specialCharRegex = /^[a-zA-Z0-9#-\s]+$/;
const companyRegex = /\b(?:aspida|alic)\b/;
// eslint-disable-next-line max-len
const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};
const formatTin = (tinFieldName, isTIN) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  if (inputData[`${tinFieldName}SSN`] === undefined) {
    return;
  }
  // Format SSN/TIN value and set in response based on trustee type
  const cleaned = inputData[`${tinFieldName}SSN`].replace(/\D/g, '');
  if (isTIN) {
    inputData[`${tinFieldName}SSN`] = cleaned.replace(/^(\d{2})/, '$1-').replace(/(\d)-(\d{7}).*/, '$1-$2');
  } else {
    const val = cleaned
      .replace(/^(\d{3})/, '$1-')
      .replace(/-(\d{2})/, '-$1-')
      .replace(/(\d)-(\d{4}).*/, '$1-$2');
    inputData[`${tinFieldName}SSN`] = val;
  }
};
const handleExitClick = () => {
  const state = store.getState();
  const userObj = state.user;
  const isViewOnly = RoleHelper.userHasRoles(userObj, ROLES.VIEWONLY);
  store.dispatch(setAuthenticatedUser({ ...userObj, verified: userObj.verified, isViewOnly }));
  store.dispatch(setReset(state.getStarted));
  sessionStorage.removeItem('eAppPolicyData');
  navigate('/getStarted');
  navigate('/');
};

const getFieldIsAppear = rightVariable => {
  let checkFieldIsAppear = null;
  rightVariable.every(itm => {
    if (String(itm).indexOf('#') === 0) {
      checkFieldIsAppear = true;
    }
    return '';
  });
  return checkFieldIsAppear;
};

const checkIfEmptyInFieldValue = val => {
  if (val === null || val === undefined) {
    return '';
  }
  return val;
};

const setSignedStatesEvent = (value, allStates) => {
  if (!allStates.data) {
    return [];
  }

  return allStates.data
    .filter(state => state.approvedProducts.find(x => x === value))
    .map(states => {
      return {
        id: states.id.concat('~', states.abbreviation),
        value: states.name,
      };
    });
};

const tooltipMessageFn = (tooltipTextVal, tooltipStyle) => {
  return (
    <sup>
      <Tooltip message={tooltipTextVal} className={tooltipStyle} placement="right" />
    </sup>
  );
};

const checkIsNullOrUndefined = val => {
  const state = store.getState();

  let checkValue = val;
  if (checkValue === null || checkValue === undefined) {
    return '';
  }
  if (String(checkValue).indexOf('#') === 0) {
    checkValue = state.getStarted.inputvalues[checkValue];
    if (checkValue === null || checkValue === undefined) {
      return '';
    }
  }
  return checkValue;
};
/*
 * This cleanJSON function is used to delete invalid property like value contains
 * null, undefined and empty.
 */
const cleanJSON = inputFormData => {
  const returnObject = { ...inputFormData };
  Object.keys(returnObject).forEach(key => {
    if (typeof returnObject[key] === 'string') {
      returnObject[key] = returnObject[key].trim();
    }
    if (checkIsNullOrUndefined(returnObject[key]) === '') {
      delete returnObject[key];
    }
  });
  return returnObject;
};

const getErrorPanelLists = () => {
  const state = store.getState();
  const errorPanelLists = [];
  let errorData = [];

  if (state.getStarted.trackergroupnames.length > 0) {
    state.getStarted.trackergroupnames.forEach((group, index) => {
      const errorMsg = state.getStarted.errorlists.filter(p => p.groupIndex === index);
      errorData = [];
      if (errorMsg.length > 0) {
        errorMsg.forEach(errorDetails => {
          errorData.push({ subGroupIndex: errorDetails.subGroupIndex, errorMessage: errorDetails.errorMessage });
        });

        errorPanelLists.push({
          groupIndex: index,
          subGroupIndex: errorMsg.length > 0 ? errorMsg[0].subGroupIndex : 0,
          displayName: group.displayname,
          errorMessages: errorData,
        });
      }
    });
  }
  store.dispatch(setErrorPanelLists(errorPanelLists));
};

const appValidation = async applicationAlias => {
  const [appValidError, appValid] = await masterService.appValidation(applicationAlias);
  if (appValidError !== null) {
    return appValidError.data.message;
  }

  if (appValid.status === 'Failed') {
    const state = store.getState();
    const errorLists = state.getStarted.errorlists;
    const errorFields = state.getStarted.inputerrors;
    const trackerGroupNames = state.getStarted.trackergroupnames;

    appValid.errorFields.map(itm => {
      if (errorLists.findIndex(p => p.widgetName === itm.fieldName) === -1) {
        errorLists.push({
          groupIndex: itm.mainIndex,
          subGroupIndex: itm.subIndex,
          errorMessage: itm.errorMessage,
          widgetName: itm.fieldName,
        });
      }

      errorFields[itm.fieldName] = itm.errorMessage;
      trackerGroupNames[itm.mainIndex].SubGroup[itm.subIndex].isPageValid = false;

      return false;
    });
    store.dispatch(setErrorLists(errorLists));
    store.dispatch(setInputErrors(errorFields));
    store.dispatch(setTrackerGroupName(trackerGroupNames));
    getErrorPanelLists();
  }

  return appValid.status;
};

const getTrusteeEsign = () => {
  const state = store.getState();
  const transferSignCount = [];
  const maxTrustees = 5;
  for (let count = 1; count <= maxTrustees; count += 1) {
    const trusteeSign = state.getStarted.inputvalues[`#trustee${count}eSign`];
    const trusteeFN = state.getStarted.inputvalues[`#trustee${count}FN`];
    if (checkIsNullOrUndefined(trusteeFN) !== '' && checkIsNullOrUndefined(trusteeSign) === '') {
      const partyId = state.getStarted.inputvalues[`#trustee${count}PartyId`];
      transferSignCount.push(partyId);
    }
  }
  return transferSignCount;
};

const getCheckToFollow = () => {
  const state = store.getState();
  const checkToFollowData = [];
  for (let count = 1; count <= 3; count += 1) {
    if (
      Object.entries(state.getStarted.inputvalues).filter(([k]) => k.indexOf(`check${count}AmountString`) !== -1)
        .length !== 0
    ) {
      Object.entries(state.getStarted.inputvalues)
        .filter(([k]) => k.indexOf(`check${count}AmountString`) !== -1)
        .forEach(checkAmount => {
          if (checkIsNullOrUndefined(checkAmount[1]) !== '') {
            checkToFollowData.push({ checkAmount: checkAmount[1] });
          }
        });
    }
  }
  return checkToFollowData;
};

const checkIdologyReason = roleName => {
  const state = store.getState();
  const IDFlag = state.getStarted.inputvalues[`#${roleName}IDFlag`];
  return IDFlag ? state.getStarted.inputvalues[`#${roleName}IDFlag`] : '';
};

const downloadDoc = (file, docName) => {
  // Required for IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([file], { type: 'application/pdf' }));
    document.body.appendChild(link);
    const name = docName;
    link.setAttribute('download', name);
    link.click();
  }
};

const getCompanySignData = () => {
  const state = store.getState();
  const transferSignCount = [];
  for (let count = 1; count <= 9; count += 1) {
    if (
      Object.entries(state.getStarted.inputvalues).filter(([k]) => k.indexOf(`transfer${count}Sign`) !== -1).length !==
      0
    ) {
      Object.entries(state.getStarted.inputvalues)
        .filter(([k]) => k.indexOf(`#transfer${count}Sign`) !== -1)
        .forEach(coSignType => {
          Object.entries(state.getStarted.inputvalues)
            .filter(([k]) => k.indexOf(`#transfer${count}CompanyName`) !== -1)
            .forEach(companyName => {
              const transferNote = state.getStarted.inputvalues[`#transfer${count}Note`];
              const transferPolicyNumber = state.getStarted.inputvalues[`#transfer${count}PolicyNumber`];
              const transferFW =
                state.getStarted.inputvalues[`#transfer${count}SurrInstFW`] === 'Original contract attached'
                  ? 'Yes'
                  : '';
              transferSignCount.push({
                coNote: checkIsNullOrUndefined(transferNote) !== '' ? transferNote : '',
                coSignType: coSignType[1],
                companyName: companyName[1],
                transferFW: checkIsNullOrUndefined(transferFW) !== '' ? transferFW[1] : '',
                transferPolicyNumber: checkIsNullOrUndefined(transferPolicyNumber) !== '' ? transferPolicyNumber : '',
              });
            });
        });
    }
  }
  return transferSignCount;
};

const getFundingData = () => {
  const state = store.getState();
  let checkValidData = null;
  const modifyInProgress = state.getStarted.inputvalues[`#modifyInProgress`];
  const ifEFTInProgress = state.getStarted.inputvalues[`#ifEFTInProgress`];
  const ifTransferInProgress = state.getStarted.inputvalues[`#ifTransferInProgress`];
  const ifCheckInProgress = state.getStarted.inputvalues[`#ifCheckInProgress`];
  if (checkIsNullOrUndefined(modifyInProgress) !== '' || ifEFTInProgress || ifTransferInProgress || ifCheckInProgress) {
    checkValidData = false;
  }
  return checkValidData;
};

const setTrackerGroupNames = group => {
  const state = store.getState();
  const { getStarted } = state;
  getStarted.hidetab.forEach(item => {
    const data = group.SubGroup.findIndex(x => x.displayname === item);
    if (data !== -1) {
      // eslint-disable-next-line no-param-reassign
      group.SubGroup[data].isPageValid = true;
    }
  });
};
const getInvalidMenuCount = () => {
  const state = store.getState();
  let checkValidTab = null;
  if (state.getStarted.trackergroupnames.length > 0) {
    state.getStarted.trackergroupnames.forEach((group, index) => {
      if (index !== state.getStarted.trackergroupnames.length - 1) {
        setTrackerGroupNames(group);
        const validLength = GroupTrackerFunctions.getLength(state.getStarted.trackergroupnames, index, false);
        const inValidLength = GroupTrackerFunctions.getLength(state.getStarted.trackergroupnames, index, undefined);
        if (validLength !== 0 || inValidLength !== 0) checkValidTab = false;
      }
    });
  }
  return checkValidTab;
};

const getGroupIndex = tabName => {
  const state = store.getState();
  const groupValue = [];
  if (state.getStarted.trackergroupnames.length > 0) {
    state.getStarted.trackergroupnames.forEach((group, index) => {
      if (group.displayname === tabName) {
        groupValue.push({ Id: index, Value: 0 });
      }
      state.getStarted.trackergroupnames[index].SubGroup.forEach((subGroup, subGroupIndex) => {
        if (subGroup.displayname === tabName) {
          groupValue.push({ Id: index, Value: subGroupIndex });
        }
      });
    });
  }
  return groupValue;
};

const getMYAFIAProducts = (allProducts, getProductContracts) => {
  const state = store.getState();
  const tempProducts = [];
  const applicationData = state.getStarted;
  if (checkIsNullOrUndefined(allProducts) === '') {
    return;
  }
  allProducts.forEach(prod => {
    const isProductPresent = getProductContracts.find(x => x === prod.id);
    if (isProductPresent) {
      tempProducts.push({ id: prod.id, value: prod.description, productTypeAbbr: prod.productTypeAbbr });
    }
  });
  const isMYAPresent = tempProducts.filter(x => x.productTypeAbbr === 'MYA');
  const isFIAPresent = tempProducts.filter(x => x.productTypeAbbr === 'FIA');
  if (isMYAPresent.length > 0 && isFIAPresent.length > 0) {
    applicationData.inputvalues['#isMYAFIAPresent'] = 'MYAFIA';
  } else if (isMYAPresent.length > 0) {
    applicationData.inputvalues['#isMYAFIAPresent'] = 'MYA';
  } else {
    applicationData.inputvalues['#isMYAFIAPresent'] = 'FIA';
  }
};

const setDefaultValues = JSONContent => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  JSONContent.forEach(group => {
    if (group.fieldProperty.defaultValue != null) {
      if (inputData[group.fieldName] === '' || inputData[group.fieldName] === undefined) {
        inputData[group.fieldName] = group.fieldProperty.defaultValue;
      }
    }
  });
  store.dispatch(setInputValues(inputData));
};

const getJsonWithDef = JsonName => {
  let jsonContent = JSON.stringify(Jsonfiles[JsonName][JsonName]);
  if (Jsonfiles[`${JsonName}Def`]) {
    Object.keys(Jsonfiles[`${JsonName}Def`][`${JsonName}Def`]).forEach(key => {
      const regex = new RegExp(`"def/${key}"`, 'gm');
      jsonContent = jsonContent.replace(regex, JSON.stringify(Jsonfiles[`${JsonName}Def`][`${JsonName}Def`][key]));
    });
  }
  jsonContent = JSON.parse(jsonContent);
  return jsonContent;
};

const getNextPageContent = (groupIndex, subGroupIndex, trackerGroupNames) => {
  try {
    const jsonFileName = trackerGroupNames[groupIndex].SubGroup[subGroupIndex].$ref;
    const jsonFileNameWithoutExt = jsonFileName.replace('.json', '');
    const jsonContent = getJsonWithDef(jsonFileNameWithoutExt);
    setDefaultValues(jsonContent);
    return jsonContent;
  } catch (error) {
    return '';
  }
};

const getSpecificationName = async () => {
  const state = store.getState();
  let query = `applicationTypeId=${state.getStarted.applicationtype}`;
  query += `&productId=${state.getStarted.product}`;
  query += `&signStateId=${state.getStarted.signedstateid}`;
  query += `&hasMasterSellingAgreement=${state.getStarted.inputvalues['#IsMSA']}`;
  const [specError, Specification] = await masterService.getSpecificationName(query);
  if (specError !== null) {
    NotificationService.error(specError.data.message);
    store.dispatch(setLoading(false));
    return;
  }
  if (Specification === undefined) {
    NotificationService.error(constants.errorMessage);
    store.dispatch(setLoading(false));
    return;
  }
  store.dispatch(setJSONFilename(Specification.specification));
  const jsonfileName = Specification.specification;
  const groupNames = [];
  const mainRootName = jsonfileName.replace('.json', '');
  const jsonData = Jsonfiles[mainRootName];

  if (jsonData === undefined) {
    NotificationService.error(constants.errorMessage);
    store.dispatch(setLoading(false));
    return;
  }

  Object.entries(jsonData[mainRootName]).forEach(([mainKey]) => {
    const groupName = {};
    Object.entries(jsonData[mainRootName][mainKey]).forEach(([key]) => {
      groupName[key] = jsonData[mainRootName][mainKey][key];
    });
    groupName.SubGroup = {};
    groupNames.push(groupName);
  });
  groupNames.forEach((grpName, index) => {
    const subGroupNames = [];
    const rootName = grpName.$ref.replace('.json', '');
    const subJsonData = Jsonfiles[rootName];
    if (grpName.$ref !== '') {
      Object.entries(subJsonData[rootName]).forEach(([mainKey]) => {
        const subGroupName = {};
        Object.entries(subJsonData[rootName][mainKey]).forEach(([subKey]) => {
          subGroupName[subKey] = subJsonData[rootName][mainKey][subKey];
          subGroupName.isPageValid = undefined;
        });
        subGroupNames.push(subGroupName);
      });
    }
    groupNames[index].SubGroup = subGroupNames;
  });
  groupNames[0].SubGroup[0].isPageValid = true;
  if (state.getStarted.trackergroupnames.length === 0) {
    store.dispatch(setTrackerGroupName(groupNames));
  }
  const jsonContent = getNextPageContent(
    state.getStarted.currentpage.Group,
    state.getStarted.currentpage.SubGroup,
    state.getStarted.trackergroupnames.length === 0 ? groupNames : state.getStarted.trackergroupnames,
  );
  const currentPage = {
    Group: state.getStarted.currentpage.Group,
    SubGroup: state.getStarted.currentpage.SubGroup,
    JsonData: jsonContent,
  };
  store.dispatch(setCurrentPage(currentPage));
  store.dispatch(setLoading(false));
  sessionStorage.setItem('eAppPolicyData', JSON.stringify(state.getStarted));
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const getRoutingNumberBankName = async (routingNumber, eftPrefixName) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const [foundResultError, foundResult] = await masterService.getBankInformation(inputData[routingNumber]);
  if (checkIsNullOrUndefined(foundResultError) !== '') {
    inputData[`${eftPrefixName}BankName`] = '';
  }
  if (checkIsNullOrUndefined(foundResult) !== '') {
    inputData[`${eftPrefixName}BankName`] = foundResult.customerName;
  }
};

const approvedStateCheck = prefix => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const { approvedProducts } = state.appData;
  const joResState = inputData[`#${prefix}State`];

  inputData[`#${prefix}StateApproveCheck`] = approvedProducts.some(p => p.id.includes(joResState));
};

const getAPITotalPremium = async (prefix, JoPrefix) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;

  const payloadPerson = {
    ssn: inputData[`#${prefix}SSN`],
    joSsn: inputData[`#${JoPrefix}SSN`],
  };
  const [personError, personData] = await masterService.getTotalPremium(payloadPerson);
  if (personError !== null) {
    inputData[`#${prefix}TotalPremium`] = 0;
    return;
  }
  if (checkIsNullOrUndefined(personData) !== '') {
    inputData[`#${prefix}TotalPremium`] = personData.totalPremium;
  }
  store.dispatch(setInputValues(inputData));
};

const getInternalAppPremium = PolicyNumber => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const selectedInternalPolicyDetails = inputData['#existingPolicyDetailsforInternal'].find(
    p => p.applicationAlias === inputData[PolicyNumber],
  );
  inputData[`#transferDisplayPrevPlannedValue`] = selectedInternalPolicyDetails.plannedValue;
};

const getAPIPersonDetails = async (prefix, isTotalPremiumCheck) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;
  const errorLists = state.getStarted.errorlists;
  inputData[`#${prefix}SSN400`] = false;

  const payloadPerson = {
    ssn: inputData[`#${prefix}SSN`],
    dob: inputData[`#${prefix}DoB`],
    isTotalPremium: isTotalPremiumCheck,
    appTypeId: inputData['#applicationtype'],
    annuityType: inputData['#annuityType'],
    qualifiedType: inputData['#qualifiedType'],
    productId: inputData['#product'],
  };
  const [personError, personData] = await masterService.getPeopleInfo(payloadPerson);
  if (personError !== null) {
    inputData[`#${prefix}MatchFound`] = false;
    inputData[`#${prefix}EmailMatchFound`] = false;
    inputData['#existingPolicyNumberforInternal'] = null;
    if (constants.clonePrefixes.includes(prefix)) {
      inputData['#hasCloneAppData'] = false;
      inputData['#haslockCopyData'] = false;
    }
    if (personError.status === 400) {
      inputData[`#${prefix}SSN400`] = true;
    }
    store.dispatch(setLoading(false));
    return;
  }
  if (checkIsNullOrUndefined(personData) !== '') {
    constants.agentInfo.forEach(itm => {
      const errorObjectIndex = errorLists.findIndex(p => p.widgetName === `#${prefix}${itm}`);
      if (errorObjectIndex !== -1) {
        errorLists.splice(errorObjectIndex, 1);
      }
      inputErrorData[`#${prefix}${itm}`] = '';
    });
    store.dispatch(setErrorLists(errorLists));
    getErrorPanelLists();
    inputData[`#${prefix}MatchFound`] = true;
    inputData[`#${prefix}FN`] = personData.firstName;
    inputData[`#${prefix}MN`] = personData.middleName;
    inputData[`#${prefix}LN`] = personData.lastName;
    inputData[`#${prefix}Marital`] = personData.maritalStatus;
    inputData[`#${prefix}Sex`] = personData.sex;
    inputData[`#${prefix}SF`] = personData.suffix;
    inputData[`#${prefix}TotalPremium`] = personData.totalPremium;
    inputData[`#${prefix}Email`] =
      personData.emailAddresses.length > 0 ? personData.emailAddresses[0].emailAddress : '';
    inputData[`#${prefix}ConfirmEmail`] =
      personData.emailAddresses.length > 0 ? personData.emailAddresses[0].emailAddress : '';
    inputData[`#${prefix}EmailMatchFound`] = personData.emailAddresses.length > 0;
    inputData['#hasCloneAppData'] = !!(personData.cloneAppData && personData.cloneAppData['#cloneapplicationId']);
    inputData['#cloneAppData'] = personData.cloneAppData;
    inputData['#existingPolicyNumberforInternal'] = personData.existingPolicyNumberforInternal.map(
      application => application.applicationAlias,
    );
    inputData['#existingPolicyDetailsforInternal'] = personData.existingPolicyNumberforInternal;
    inputData['#haslockCopyData'] = false;
    personData.postalAddresses.map(postalAddress => {
      if (postalAddress.addressType === 'Residential') {
        inputData[`#${prefix}ResAddr`] = postalAddress.address1;
        inputData[`#${prefix}ResAddr2`] = postalAddress.address2;
        inputData[`#${prefix}ResCity`] = postalAddress.city;
        inputData[`#${prefix}ResState`] = postalAddress.state;
        inputData[`#${prefix}ResZip`] = postalAddress.postalCode;
      }
      if (postalAddress.addressType === 'Mailing') {
        inputData[`#${prefix}MailAddr`] = postalAddress.address1;
        inputData[`#${prefix}MailAddr2`] = postalAddress.address2;
        inputData[`#${prefix}MailCity`] = postalAddress.city;
        inputData[`#${prefix}MailState`] = postalAddress.state;
        inputData[`#${prefix}MailZip`] = postalAddress.postalCode;
      }
      if (inputData[`#${prefix}ResAddr`] === inputData[`#${prefix}MailAddr`]) {
        inputData[`#${prefix}MailAddrSameAsResAddr`] = true;
      }
      return false;
    });
    inputData[`#${prefix}Phone`] =
      personData.telephoneNumbers.length > 0 ? formatPhoneNumber(personData.telephoneNumbers[0].subscriberNumber) : '';
  }
};

const validateAddress = async prefix => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const addressPayload = {
    address1: inputData[`#${prefix}Addr`] ?? '',
    address2: inputData[`#${prefix}Addr2`] ?? '',
    city: inputData[`#${prefix}City`] ?? '',
    state: inputData[`#${prefix}State`] ?? '',
    postalCode: inputData[`#${prefix}Zip`] ?? '',
  };
  const [err, response] = await masterService.validateAddress(addressPayload);

  if (err) {
    NotificationService.error('Unable to load address.');
    return;
  }

  if (
    (response && response.AddressValidateResponse && response.AddressValidateResponse.Address) ||
    (response && response.Error)
  ) {
    inputData[`#${prefix}ValidationFailed`] = true;
  } else {
    inputData[`#${prefix}ValidationFailed`] = false;
    inputData[`#${prefix}AddrUsps`] = response.AddressValidateResponse.Address2 ?? '';
    inputData[`#${prefix}Addr2Usps`] = response.AddressValidateResponse.Address1 ?? '';
    inputData[`#${prefix}CityUsps`] = response.AddressValidateResponse.City ?? '';
    inputData[`#${prefix}StateUsps`] = response.AddressValidateResponse.State ?? '';
    inputData[`#${prefix}ZipUsps`] = response.AddressValidateResponse.Zip5 ?? '';
  }

  return [err, response];
};

const setOpenCopyPopUp = fieldName => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  if (
    inputData['#ownerCopyApplicationsRadio'] &&
    fieldName === '#ownerCopyApplicationsRadio' &&
    inputData['#hasCloneAppData']
  ) {
    inputData['#openCopyInfoPopUp'] = true;
  }
};

const verifyPartyDetails = async prefix => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const payloadParty = {
    emailAddress: inputData[`#${prefix}Email`],
    ssn: inputData[`#${prefix}SSN`],
    dateOfBirth: moment(inputData[`#${prefix}DoB`]).format('YYYY-MM-DD'),
  };
  const [SSNDOBError, SSNDOBData] = await masterService.verifyPartyDetails(payloadParty);
  if (SSNDOBError !== null) {
    inputData[`#${prefix}SSDOBFound`] = false;
    store.dispatch(setLoading(false));
    return;
  }
  if (checkIsNullOrUndefined(SSNDOBData) !== '') {
    inputData[`#${prefix}SSDOBFound`] = SSNDOBData.personExists;
  }
};

const validateDocTab = () => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  if (
    (checkIsNullOrUndefined(inputData['#ownerPOA']) === '' || inputData['#ownerPOA'] === false) &&
    getCompanySignData().filter(x => x.transferFW !== '').length === 0 &&
    checkIsNullOrUndefined(checkIdologyReason('owner')) === '' &&
    !state.user.isDtcCustomer &&
    checkIsNullOrUndefined(checkIdologyReason('jointOwner')) === '' &&
    checkIsNullOrUndefined(checkIdologyReason('annuitant')) === '' &&
    getCheckToFollow().length === 0 &&
    inputData['#applicationTypeName'] === constants.applicationTypeName.Natural
  ) {
    return false;
  }
  if (checkIsNullOrUndefined(checkIdologyReason('owner')) !== '') {
    inputData['#ownerSSNAddlUploadDoc'] = inputData['#ownerSSNAddlUploadDoc'] || [];
    inputData['#ownerDOBAddlUploadDoc'] = inputData['#ownerDOBAddlUploadDoc'] || [];
  }
  if (checkIsNullOrUndefined(checkIdologyReason('jointOwner')) !== '') {
    inputData['#jointOwnerSSNAddlUploadDoc'] = inputData['#jointOwnerSSNAddlUploadDoc'] || [];
    inputData['#jointOwnerDOBAddlUploadDoc'] = inputData['#jointOwnerDOBAddlUploadDoc'] || [];
  }
  if (checkIsNullOrUndefined(checkIdologyReason('annuitant')) !== '') {
    inputData['#annuitantSSNAddlUploadDoc'] = inputData['#annuitantSSNAddlUploadDoc'] || [];
    inputData['#annuitantDOBAddlUploadDoc'] = inputData['#annuitantDOBAddlUploadDoc'] || [];
  }
  const resultDocument = EsignEventValidation.getDocumentPageValidation(state.getStarted, true);
  return resultDocument;
};

// Calculate percentage method.
const calculatePercentage = (
  totalPrimaryPercent,
  totalContingentPercent,
  beneficiaryType,
  benePercent,
  benePrefix,
  totalBene,
) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;

  inputData[totalContingentPercent] = 0;
  inputData[totalPrimaryPercent] = 0;
  inputErrorData[totalPrimaryPercent] = '';
  inputErrorData[totalContingentPercent] = '';

  for (let count = 1; count <= totalBene; count += 1) {
    if (
      checkIsNullOrUndefined(inputData[benePrefix + count + beneficiaryType]) === constants.beneficiaryType.Primary ||
      checkIsNullOrUndefined(inputData[benePrefix + count + beneficiaryType]) ===
        constants.beneficiaryType.SurvivingSpouse
    ) {
      if (
        checkIsNullOrUndefined(inputData[benePrefix + count + benePercent] !== '') &&
        Number.isNaN(parseFloat(inputData[benePrefix + count + benePercent], 0)) === false
      ) {
        inputData[totalPrimaryPercent] =
          parseFloat(inputData[totalPrimaryPercent], 0) + parseFloat(inputData[benePrefix + count + benePercent], 0);
      }
    }
    if (
      checkIsNullOrUndefined(inputData[benePrefix + count + beneficiaryType]) === constants.beneficiaryType.Contingent
    ) {
      if (
        checkIsNullOrUndefined(inputData[benePrefix + count + benePercent] !== '') &&
        Number.isNaN(parseFloat(inputData[benePrefix + count + benePercent], 0)) === false
      ) {
        inputData[totalContingentPercent] =
          parseFloat(inputData[totalContingentPercent], 0) + parseFloat(inputData[benePrefix + count + benePercent], 0);
      }
    }
  }
  if (inputData[totalPrimaryPercent] !== 0 && Number.isNaN(parseFloat(inputData[totalPrimaryPercent], 0)) === false) {
    if (inputData[totalPrimaryPercent] !== 100) {
      inputErrorData[totalPrimaryPercent] = constants.validationErrorMessages.BenePercentage100;
    }
  }
  if (
    inputData[totalContingentPercent] !== 0 &&
    Number.isNaN(parseFloat(inputData[totalContingentPercent], 0)) === false
  ) {
    if (inputData[totalContingentPercent] !== 100) {
      inputErrorData[totalContingentPercent] = constants.validationErrorMessages.BenePercentage100;
    }
  }
};

const calculateAllocPercentage = (totalAllocationPercent, allocationNames) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;

  inputData[totalAllocationPercent] = 0;
  inputErrorData[totalAllocationPercent] = '';

  allocationNames.forEach(allocation => {
    if (
      checkIsNullOrUndefined(inputData[allocation]) !== '' &&
      Number.isNaN(parseFloat(inputData[allocation], 0)) === false
    ) {
      inputData[totalAllocationPercent] =
        parseFloat(inputData[totalAllocationPercent], 0) + parseFloat(inputData[allocation], 0);
    }
  });
  const totalAmount = inputData[totalAllocationPercent].toFixed(2);
  inputData[totalAllocationPercent] = parseFloat(totalAmount, 0);
  if (
    inputData[totalAllocationPercent] !== 0 &&
    Number.isNaN(parseFloat(inputData[totalAllocationPercent], 0)) === false
  ) {
    if (inputData[totalAllocationPercent] !== 100) {
      inputErrorData[totalAllocationPercent] = 'Allocation percentage must be 100%';
    }
  }
};

const calculateAgentSplitPercent = (totalAgentSplitPercent, agentPercent, splitAgentPercent, isSplitAgent) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;

  inputData[totalAgentSplitPercent] = 0;
  inputErrorData[totalAgentSplitPercent] = '';
  inputErrorData[agentPercent] = '';

  if (
    checkIsNullOrUndefined(inputData[isSplitAgent]) === '' ||
    checkIsNullOrUndefined(inputData[isSplitAgent]) === false
  ) {
    if (inputData[agentPercent] !== 0 && Number.isNaN(parseFloat(inputData[agentPercent], 0)) === false) {
      if (inputData[agentPercent] !== 100) {
        inputErrorData[agentPercent] = 'Percentage must be 100%';
      }
    }
  }
  if (checkIsNullOrUndefined(inputData[isSplitAgent]) === true) {
    if (checkIsNullOrUndefined(inputData[agentPercent]) !== '') {
      inputData[totalAgentSplitPercent] =
        parseFloat(inputData[totalAgentSplitPercent], 0) + parseFloat(inputData[agentPercent], 0);
    }

    splitAgentPercent.forEach(perc => {
      if (checkIsNullOrUndefined(inputData[perc]) !== '') {
        inputData[totalAgentSplitPercent] =
          parseFloat(inputData[totalAgentSplitPercent], 0) + parseFloat(inputData[perc], 0);
      }
    });
    if (
      inputData[totalAgentSplitPercent] !== 0 &&
      Number.isNaN(parseFloat(inputData[totalAgentSplitPercent], 0)) === false
    ) {
      if (inputData[totalAgentSplitPercent] !== 100) {
        inputErrorData[totalAgentSplitPercent] = 'Percentage must be 100%';
      }
    }
  }
};

const calculateTotalAmount = (fundTotalAmount, splitAmount) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;
  const specialErrors = state.getStarted.specialerrors;
  inputData[fundTotalAmount] = 0;
  inputErrorData[fundTotalAmount] = '';

  splitAmount.forEach(amount => {
    if (checkIsNullOrUndefined(inputData[amount]) !== '' && Number.isNaN(parseFloat(inputData[amount], 0)) === false) {
      inputData[fundTotalAmount] = parseFloat(inputData[fundTotalAmount], 0) + parseFloat(inputData[amount], 0);
    }
  });
  inputData[fundTotalAmount] = parseFloat(inputData[fundTotalAmount].toFixed(2), 0);
  inputData[`${fundTotalAmount}String`] = `$${inputData[fundTotalAmount]
    .toFixed(2)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
  if (inputData[fundTotalAmount] !== 0 && Number.isNaN(parseFloat(inputData[fundTotalAmount], 0)) === false) {
    if (inputData[fundTotalAmount] !== checkIsNullOrUndefined('#plannedValue')) {
      inputErrorData[
        fundTotalAmount
      ] = `Total Amount must match with the Expected Premium Amount ${inputData['#plannedValueString']}.`;
      specialErrors[fundTotalAmount] = true;
    }
    if (inputData[fundTotalAmount] === checkIsNullOrUndefined('#plannedValue')) {
      delete specialErrors[fundTotalAmount];
    }
  }
  if (inputData[fundTotalAmount] === 0) {
    delete specialErrors[fundTotalAmount];
  }
};

const clearEappFields = isServiceCall => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;

  if (isServiceCall) {
    constants.FieldList.forEach(fieldName => {
      inputData[fieldName] = null;
    });
  }
};

const removeMailAddress = (mailingFields, isServiceCall) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;

  if (isServiceCall) {
    mailingFields.forEach(fieldName => {
      inputData[fieldName] = '';
    });
  }
};

const dollarFormat = currencyFieldName => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;

  currencyFieldName.forEach(fieldName => {
    if (
      checkIsNullOrUndefined(inputData[fieldName]) !== '' &&
      Number.isNaN(parseFloat(inputData[fieldName], 0)) === false
    ) {
      inputData[`${fieldName}String`] = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }).format(inputData[fieldName]);
    }
  });
};

const getNonInternalFundingData = () => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const nonInternalFundingData = inputData['#nonInternalFundingData'];
  if (nonInternalFundingData) {
    nonInternalFundingData.forEach((fundingDetails, count) => {
      inputData[`#reptransfer${count + 1}Type`] = fundingDetails.transferTempType;
      inputData[`#reptransfer${count + 1}PolicyNumber`] = fundingDetails.transferTempPolicyNumber;
      inputData[`#reptransfer${count + 1}CompanyName`] = fundingDetails.transferTempCompanyName;
    });
    inputData['#reptransferCount'] = nonInternalFundingData.length;
  }
};

const internalFundAmt = () => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const maxtransfer = 9;
  const nonInternalFundingData = [];
  let totalInternalAmt = 0;
  inputData['#ifInternalReplacement'] = false;
  for (let count = 1; count <= maxtransfer; count += 1) {
    const transferFundType = inputData[`#transfer${count}Type`];
    if (checkIsNullOrUndefined(transferFundType) !== '') {
      if (checkIsNullOrUndefined(transferFundType) === 'Internal') {
        const transferAmt = inputData[`#transfer${count}Amount`];
        totalInternalAmt += parseFloat(checkIsNullOrUndefined(transferAmt), 0);
      }
      if (
        ['FL', 'KS', 'IN'].includes(inputData['#residentState']) ||
        (checkIsNullOrUndefined(transferFundType) !== 'Internal' &&
          !['FL', 'KS', 'IN'].includes(inputData['#residentState']))
      ) {
        nonInternalFundingData.push({
          transferTempType: inputData[`#transfer${count}Type`],
          transferTempPolicyNumber: inputData[`#transfer${count}PolicyNumber`],
          transferTempCompanyName: inputData[`#transfer${count}CompanyName`],
        });
        inputData['#nonInternalFundingData'] = nonInternalFundingData;
      }
    }
  }
  if (totalInternalAmt > 0) {
    inputData['#ifInternalReplacement'] = true;
  }
  inputData['#totalInternalFundAmoumt'] = totalInternalAmt;
};

const ageCalc = (inputDOB, annuitantAge) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  inputData[annuitantAge] = 0;

  const age = moment().diff(inputData[inputDOB], 'years', false);
  const ageDecimal = moment().diff(inputData[inputDOB], 'years', true);
  inputData[annuitantAge] = age;
  inputData[`${annuitantAge}Decimal`] = ageDecimal;
};

const removeItems = (itemFields, itemIndex, totalItems) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  let breakLoopIndex = 0;

  for (let index = itemIndex; index <= totalItems; index += 1) {
    if (document.getElementById(`grd${itemFields[0]}${index + 1}`) != null) {
      if (document.getElementById(`grd${itemFields[0]}${index + 1}`).style.display === 'block') {
        itemFields.forEach(itm => {
          inputData[itm.replace('_', index) + index] = inputData[itm + (index + 1)];
        });
        breakLoopIndex = index + 1;
      } else {
        break;
      }
    }
  }
  if (breakLoopIndex !== 0 && breakLoopIndex <= totalItems) {
    itemFields.forEach(itm => {
      document.getElementById(`grd${itm.replace('_', breakLoopIndex)}${breakLoopIndex}`).style.display = 'none';
      inputData[itm.replace('_', breakLoopIndex) + breakLoopIndex] = '';
    });
  }
  if (breakLoopIndex === 0) {
    itemFields.forEach(itm => {
      document.getElementById(`grd${itm.replace('_', itemIndex)}${itemIndex}`).style.display = 'none';
      inputData[itm.replace('_', itemIndex) + itemIndex] = '';
    });
  }
};

const beniCURDAddClick = templateFields => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const beniObj = {};
  if (checkIsNullOrUndefined(inputData.beniEditId) === '') {
    beniObj.id = moment().format('YYYYMMDDHHmmssSSS');
  } else {
    const temp = [...inputData.beni];
    const errorObjectIndex = temp.findIndex(p => p.id === inputData.beniEditId);
    temp.splice(errorObjectIndex, 1);
    inputData.beni = temp;
    beniObj.id = inputData.beniEditId;
    inputData.beniEditId = '';
  }
  templateFields.forEach(element => {
    beniObj[element] = inputData[element];
  });
  if (inputData.beni === undefined) {
    inputData.beni = [beniObj];
  } else {
    inputData.beni.push(beniObj);
  }
  templateFields.forEach(element => {
    inputData[element] = null;
  });
};

const setJsonFieldValues = (validation, inputData) => {
  if (validation.action.set !== undefined) {
    validation.action.set.forEach(itm => {
      if (typeof itm.to === 'object') {
        itm.to.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          inputData[item] = checkIsNullOrUndefined(itm.from);
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        inputData[itm.to] = checkIsNullOrUndefined(itm.from);
      }
    });
  }
};

const calculateTotalFundCount = (fundTotalCount, fundingAccountNumber) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  inputData[fundTotalCount] = 0;
  first(fundingAccountNumber).forEach(accountNumbers => {
    if (checkIsNullOrUndefined(accountNumbers) !== '') {
      inputData[fundTotalCount] = parseFloat(checkIsNullOrUndefined(inputData[fundTotalCount]), 0) + 1;
    }
  });
};

const hideOnlyFields = itm => {
  const splitedText = itm.split('~');
  if (document.getElementById(`grd${splitedText[0]}`) != null) {
    document.getElementById(`grd${splitedText[0]}`).style.display = 'none';
  }
};

const agentFieldValidaiton = (conditions, action) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  let checkBool = false;
  let checkString = '';

  conditions.forEach(condition => {
    checkBool = false;
    const operator = ValidationFunctions.fnConditionConfig[condition.operator];
    if (operator) {
      checkBool = ValidationFunctions[operator](inputData, inputData[condition.leftVariable], condition.rightVariable);
    }
    checkString += String(checkBool);
  });
  if (checkString.indexOf('false') === -1) {
    if (action.set !== undefined) {
      action.set.forEach(itm => {
        inputData[itm.to] = checkIsNullOrUndefined(itm.from);
      });
    }
    return true;
  }
  return false;
};

const validateMSAWithBKOFC = async applicationAlias => {
  let IsMSAwithBackoffice = false;
  const [backofficeError, backOfficeData] = await masterService.validateMSABackOffice(applicationAlias);
  if (backofficeError !== null) {
    return backofficeError.data.message;
  }
  const resData = backOfficeData.data.filter(
    d => d.applicationRole.id === constants.eapplicationRoles.backOfficeReviewer,
  );
  if (resData.length > 0) {
    IsMSAwithBackoffice = true;
  }
  return IsMSAwithBackoffice;
};

const hideSplButton = (itm, responseData, resErrorData) => {
  const splitedText = itm.split('||');
  if (document.getElementById(`grd${splitedText[0]}`) != null) {
    document.getElementById(`grd${splitedText[0]}`).style.display = 'none';
    if (document.getElementsByName(splitedText[0]).length > 0) {
      responseData[splitedText[0]] = ''; // eslint-disable-line
      resErrorData[splitedText[0]] = ''; // eslint-disable-line
    }
  }
};
const executeFieldValidation = async (
  fieldValidation,
  fieldName,
  isStore,
  isServiceCall,
  group,
  subGroup,
  ischeckError,
  currentField,
) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;
  const errorLists = state.getStarted.errorlists;
  const lockFields = state.getStarted.lockfields;
  const specialErrors = state.getStarted.specialerrors;
  const HideTab = checkIsNullOrUndefined(state.getStarted.hidetab) === '' ? [] : state.getStarted.hidetab;
  let groupIndex = group;
  let subGroupIndex = subGroup;

  if (checkIsNullOrUndefined(groupIndex) === '') {
    groupIndex = state.getStarted.currentpage.Group;
  }
  if (checkIsNullOrUndefined(subGroupIndex) === '') {
    subGroupIndex = state.getStarted.currentpage.SubGroup;
  }

  let fieldValidations = fieldValidation;
  if (fieldValidation.apiCall) {
    fieldValidations = fieldValidation.conditions;
  }
  if (isStore) {
    if (fieldValidation.apiCall) {
      switch (fieldValidation.apiCall.action.serviceCall) {
        case 'getPersonDetails':
          if (isServiceCall) {
            if (agentFieldValidaiton(fieldValidation.apiCall.condition, fieldValidation.apiCall.action)) {
              await getAPIPersonDetails(
                fieldValidation.apiCall.action.param.inputPrefix,
                fieldValidation.apiCall.action.param.ifTotalPremiumCheck,
              );
              if (fieldValidation.apiCall.action.param.ifTotalPremiumCheck === true) {
                await getAPITotalPremium(
                  fieldValidation.apiCall.action.param.inputPrefix,
                  fieldValidation.apiCall.action.param.inputJoPrefix,
                );
              }
            }
          }
          break;
        case 'getSSNDOBDetails':
          if (agentFieldValidaiton(fieldValidation.apiCall.condition, fieldValidation.apiCall.action)) {
            await verifyPartyDetails(fieldValidation.apiCall.action.param.inputPrefix);
          }
          break;
        case 'setOpenCopyPopUp':
          setOpenCopyPopUp(currentField);
          break;
        case 'getRoutingNumberBankName':
          if (agentFieldValidaiton(fieldValidation.apiCall.condition, fieldValidation.apiCall.action)) {
            await getRoutingNumberBankName(
              fieldValidation.apiCall.action.param.routingNumber,
              fieldValidation.apiCall.action.param.eftPrefixName,
            );
          }
          break;
        case 'validateAddress':
          if (agentFieldValidaiton(fieldValidation.apiCall.condition, fieldValidation.apiCall.action)) {
            await validateAddress(fieldValidation.apiCall.action.param.inputPrefix);
          }
          break;
        case 'approvedStateCheck':
          if (agentFieldValidaiton(fieldValidation.apiCall.condition, fieldValidation.apiCall.action)) {
            await approvedStateCheck(fieldValidation.apiCall.action.param.inputPrefix);
          }
          break;
        default:
          break;
      }
    }
  }

  fieldValidations.every(validation => {
    let checkBool = false;
    let checkString = '';
    validation.condition.forEach(condition => {
      checkBool = false;
      switch (condition.operator) {
        case 'notequal':
        case 'equal':
        case 'stringnotequal':
        case 'intLen':
        case 'stringequal':
          {
            const operator = ValidationFunctions.fnConditionConfig[condition.operator];
            if (operator) {
              checkBool = ValidationFunctions[operator](
                inputData,
                inputData[condition.leftVariable],
                condition.rightVariable,
              );
            }
          }
          break;
        case 'intLenCondition':
          if (checkIsNullOrUndefined(inputData[condition.leftVariable]) === '') {
            checkBool = false;
            break;
          }
          {
            const resLength = Math.floor(Math.log10(inputData[condition.leftVariable])) + 1;
            const totalLength = condition.rightVariable[0];
            checkBool = resLength <= totalLength;
          }
          break;
        case 'add':
          inputData[condition.leftVariable] = 0;
          condition.rightVariable.forEach(amount => {
            if (
              checkIsNullOrUndefined(amount) !== '' &&
              Number.isNaN(parseFloat(checkIsNullOrUndefined(amount), 0)) === false
            ) {
              inputData[condition.leftVariable] =
                parseFloat(inputData[condition.leftVariable], 0) + parseFloat(checkIsNullOrUndefined(amount), 0);
            }
          });
          checkBool = true;
          break;
        case 'sub':
          {
            let rightVariable1 = 0;
            let rightVariable2 = 0;
            inputData[condition.leftVariable] = 0;
            if (checkIsNullOrUndefined(condition.rightVariable[0]) !== '') {
              if (Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0)) === false) {
                rightVariable1 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0);
              }
            }
            if (checkIsNullOrUndefined(condition.rightVariable[1]) !== '') {
              if (Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0)) === false) {
                rightVariable2 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0);
              }
            }
            inputData[condition.leftVariable] = rightVariable1 - rightVariable2;
            inputData[condition.leftVariable] = `$${inputData[condition.leftVariable]
              .toFixed(0)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
            checkBool = true;
          }
          break;
        case 'multiply':
          {
            let rightVariable1 = 0;
            let rightVariable2 = 0;
            inputData[condition.leftVariable] = 0;
            if (checkIsNullOrUndefined(condition.rightVariable[0]) !== '') {
              if (Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0)) === false) {
                rightVariable1 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0);
              }
            }
            if (checkIsNullOrUndefined(condition.rightVariable[1]) !== '') {
              if (Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0)) === false) {
                rightVariable2 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0);
              }
            }
            inputData[condition.leftVariable] = rightVariable1 * rightVariable2;
            inputData[`${condition.leftVariable}String`] = `$${inputData[condition.leftVariable]
              .toFixed(0)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
            checkBool = true;
          }
          break;
        case 'ssnregex':
          checkBool = !ssnRegex.test(checkIsNullOrUndefined(inputData[condition.leftVariable]));
          break;
        case 'specialCharRegex':
          checkBool = !specialCharRegex.test(checkIsNullOrUndefined(inputData[condition.leftVariable]));
          break;
        case 'tinregex':
          checkBool = !tinRegex.test(checkIsNullOrUndefined(inputData[condition.leftVariable]));
          break;
        case 'emailregex':
          checkBool = !emailRegex.test(checkIsNullOrUndefined(inputData[condition.leftVariable]));
          break;
        case 'phoneregex':
          checkBool = !phoneRegex.test(checkIsNullOrUndefined(inputData[condition.leftVariable]));
          break;
        case 'companyRegex':
          checkBool = companyRegex.test(
            checkIsNullOrUndefined(inputData[condition.leftVariable])
              .trim()
              .toLowerCase(),
          );
          break;
        case 'validate':
          if (moment(checkIsNullOrUndefined(inputData[condition.leftVariable])).isValid()) {
            if (
              !moment(checkIsNullOrUndefined(inputData[condition.leftVariable])).isBetween('01/01/1900', '12/31/2099')
            ) {
              checkBool = true;
            }
          } else {
            checkBool = true;
          }
          break;
        case 'lessthan':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) <
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'addDate':
          inputData[condition.leftVariable] = moment(checkIsNullOrUndefined(inputData[condition.rightVariable[0]]))
            .add(checkIsNullOrUndefined(condition.rightVariable[1]), 'days')
            .format('MM/DD/YYYY');
          checkBool = true;
          break;
        case 'greaterthandt':
          checkBool = moment(checkIsNullOrUndefined(inputData[condition.leftVariable])).isAfter(
            checkIsNullOrUndefined(condition.rightVariable[0]),
          );
          break;
        case 'greaterthandtequal':
          checkBool = moment(checkIsNullOrUndefined(inputData[condition.leftVariable])).isSameOrAfter(
            checkIsNullOrUndefined(condition.rightVariable[0]),
          );
          break;
        case 'lessthanequal':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) <=
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'greaterthan':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) >
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'lessthandt':
          checkBool = moment(checkIsNullOrUndefined(inputData[condition.leftVariable])).isBefore(
            checkIsNullOrUndefined(condition.rightVariable[0]),
          );
          break;
        case 'lessthandtequal':
          checkBool = moment(checkIsNullOrUndefined(inputData[condition.leftVariable])).isSameOrBefore(
            checkIsNullOrUndefined(condition.rightVariable[0]),
          );
          break;
        case 'greaterthanequal':
          if (
            checkIsNullOrUndefined(inputData[condition.leftVariable]) >=
            checkIsNullOrUndefined(condition.rightVariable[0])
          ) {
            checkBool = true;
          }
          break;
        case 'in': {
          const leftVariableValue =
            checkIsNullOrUndefined(inputData[condition.leftVariable]) !== '' &&
            typeof inputData[condition.leftVariable] === 'string'
              ? inputData[condition.leftVariable].trim()
              : checkIsNullOrUndefined(inputData[condition.leftVariable]);
          const fieldIsAppear = getFieldIsAppear(condition.rightVariable);
          if (fieldIsAppear) {
            condition.rightVariable.every(itm => {
              if (checkIsNullOrUndefined(inputData[itm]) === leftVariableValue) {
                checkBool = true;
                return false;
              }
              return true;
            });
          } else {
            condition.rightVariable.every(itm => {
              if (itm === leftVariableValue) {
                checkBool = true;
                return false;
              }
              return true;
            });
          }
          break;
        }
        case 'notin': {
          const fieldIsAppearRes = getFieldIsAppear(condition.rightVariable);
          if (fieldIsAppearRes) {
            const foundCount = condition.rightVariable.filter(
              itm =>
                checkIsNullOrUndefined(inputData[itm]) === checkIsNullOrUndefined(inputData[condition.leftVariable]),
            ).length;
            if (foundCount === 0) {
              checkBool = true;
            }
          } else {
            const foundCount = condition.rightVariable.filter(
              itm => itm === checkIsNullOrUndefined(inputData[condition.leftVariable]),
            ).length;
            if (foundCount === 0) {
              checkBool = true;
            }
          }
          break;
        }
        case 'clicktriggered':
          checkBool = true;
          break;
        case 'contains':
          if (checkIsNullOrUndefined(inputData[condition.leftVariable]) !== '') {
            condition.rightVariable.every(itm => {
              if (inputData[condition.leftVariable].toLowerCase().indexOf(itm.toLowerCase()) === 0) {
                checkBool = true;
                return false;
              }
              return true;
            });
          }
          break;
        case 'resFieldIn':
          condition.rightVariable.every(itm => {
            if (
              checkIsNullOrUndefined(inputData[itm]) !== '' &&
              checkIsNullOrUndefined(inputData[condition.leftVariable]) !== ''
            ) {
              if (inputData[itm].toLowerCase() === inputData[condition.leftVariable].toLowerCase()) {
                checkBool = true;
                return false;
              }
            }
            return true;
          });
          break;
        case 'or':
          condition.rightVariable.every(itm => {
            if (checkIsNullOrUndefined(inputData[itm]) === condition.leftVariable) {
              checkBool = true;
              return false;
            }
            return true;
          });
          break;
        case 'checktransfer':
          condition.rightVariable.every(itm => {
            if (checkIsNullOrUndefined(inputData[itm]) !== '') {
              if (checkIsNullOrUndefined(inputData[itm]) !== condition.leftVariable) {
                checkBool = false;
                return false;
              }
              checkBool = true;
              return true;
            }
            return true;
          });
          break;
        case 'strLen':
          if (checkIsNullOrUndefined(inputData[condition.leftVariable]) === '') {
            checkBool = false;
            break;
          }
          {
            const resLength = inputData[condition.leftVariable].length;
            const totalLength = condition.rightVariable[0];
            checkBool = resLength <= totalLength;
          }
          break;
        case 'subtract':
          {
            let rightVariable1 = 0;
            let rightVariable2 = 0;
            inputData[condition.leftVariable] = 0;
            if (checkIsNullOrUndefined(condition.rightVariable[0]) !== '') {
              if (Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0)) === false) {
                rightVariable1 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[0]), 0);
              }
            }
            if (checkIsNullOrUndefined(condition.rightVariable[1]) !== '') {
              if (Number.isNaN(parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0)) === false) {
                rightVariable2 = parseFloat(checkIsNullOrUndefined(condition.rightVariable[1]), 0);
              }
            }
            inputData[condition.leftVariable] = rightVariable1 - rightVariable2;
            checkBool = true;
          }
          break;
        default:
          break;
      }
      checkString += String(checkBool);
    });
    if (checkString.indexOf('false') === -1) {
      // eslint-disable-next-line no-param-reassign
      validation.action = validation.action || {};
      setJsonFieldValues(validation, inputData);

      if (validation.action.show !== undefined) {
        validation.action.show.forEach(itm => {
          if (document.getElementById(`grd${itm}`) != null)
            document.getElementById(`grd${itm}`).style.display = 'block';
        });
      }
      if (validation.action.showTab !== undefined) {
        validation.action.showTab.forEach(itm => {
          if (checkIsNullOrUndefined(HideTab) !== '' && HideTab.includes(itm)) {
            HideTab.pop(itm);
            const trackergroupnamesData = state.getStarted.trackergroupnames;
            const data = trackergroupnamesData[groupIndex].SubGroup.findIndex(x => x.displayname === itm);
            if (data !== -1) {
              trackergroupnamesData[groupIndex].SubGroup[data].isPageValid = undefined;
              store.dispatch(setTrackerGroupName(trackergroupnamesData));
            }
          }
        });
      }
      if (validation.action.hideTab !== undefined) {
        validation.action.hideTab.forEach(itm => {
          if (checkIsNullOrUndefined(HideTab) !== '' && !HideTab.includes(itm)) {
            HideTab.push(itm);
          }
        });
      }

      if (validation.action.hide !== undefined) {
        validation.action.hide.forEach(itm => {
          if (String(itm).indexOf('||') !== -1) {
            hideSplButton(itm, inputData, inputErrorData);
          }
          if (String(itm).indexOf('~') !== -1) {
            hideOnlyFields(itm, inputData, inputErrorData);
          }
          if (document.getElementById(`grd${itm}`) != null) {
            document.getElementById(`grd${itm}`).style.display = 'none';
            if (document.getElementsByName(itm).length > 0) {
              if (document.getElementsByName(itm)[0].type !== 'button') {
                inputData[itm] = '';
                inputErrorData[itm] = '';
              }
            }
          }
        });
      }

      if (validation.action.hide !== undefined) {
        validation.action.hide.forEach(itm => {
          if (document.getElementById(`grd${itm}`) != null) {
            document.getElementById(`grd${itm}`).style.display = 'none';
            if (document.getElementsByName(itm).length > 0) {
              if (document.getElementsByName(itm)[0].type !== 'button') {
                inputData[itm] = '';
                inputErrorData[itm] = '';
              }
            }
          }
        });
      }

      if (validation.action.hidenoreset !== undefined) {
        validation.action.hidenoreset.forEach(itm => {
          if (document.getElementById(`grd${itm}`) != null) {
            document.getElementById(`grd${itm}`).style.display = 'none';
          }
        });
      }

      if (validation.action.serviceCall !== null && isStore) {
        switch (validation.action.serviceCall) {
          case 'calculatePercentage':
            calculatePercentage(
              validation.action.param.totalPrimaryPercent,
              validation.action.param.totalContingentPercent,
              validation.action.param.beneficiaryType,
              validation.action.param.benePercent,
              validation.action.param.benePrefix,
              validation.action.param.totalBeni,
            );
            break;
          case 'calculateAllocPercentage':
            calculateAllocPercentage(
              validation.action.param.totalAllocationPercent,
              validation.action.param.allocPercentage,
            );
            break;
          case 'calculateAgentSplitPercent':
            calculateAgentSplitPercent(
              validation.action.param.totalAgentSplitPercent,
              validation.action.param.agentPercent,
              validation.action.param.splitAgentPercent,
              validation.action.param.isSplitAgent,
            );
            break;
          case 'removeItems':
            removeItems(
              validation.action.param.itemFields,
              validation.action.param.itemIndex,
              validation.action.param.totalItems,
            );
            break;
          case 'beniCURDAddClick':
            beniCURDAddClick(validation.action.param.templateFields);
            break;
          case 'calculateTotalAmount':
            calculateTotalAmount(validation.action.param.newTotal, validation.action.param.addAmount);
            break;
          case 'ageCalc':
            ageCalc(validation.action.param.inputDoB, validation.action.param.calculatedAge);
            break;
          case 'calculateTotalFundCount':
            calculateTotalFundCount(
              validation.action.param.fundingTotalCount,
              validation.action.param.fundingAccountNumber,
            );
            break;
          case 'dollarFormat':
            dollarFormat(validation.action.param.currencyFieldName);
            break;
          case 'removeMailAddr':
            removeMailAddress(validation.action.param.mailingFields, isServiceCall);
            break;
          case 'formatTin':
            formatTin(validation.action.param.tinFieldName, validation.action.param.isTIN);
            break;
          case 'clearEappFields':
            clearEappFields(isServiceCall);
            break;
          case 'internalFundAmt':
            internalFundAmt();
            break;
          case 'getInternalAppPremium':
            getInternalAppPremium(validation.action.param.PolicyNumber);
            break;
          case 'getNonInternalFundingData':
            getNonInternalFundingData();
            break;
          default:
            break;
        }
      }

      if (validation.action.add !== undefined) {
        validation.action.add.forEach(itm => {
          inputData[itm.to] = 0;
          itm.from.forEach(fromItem => {
            if (checkIsNullOrUndefined(fromItem) !== '') {
              inputData[itm.to] =
                parseFloat(checkIsNullOrUndefined(inputData[itm.to]), 0) +
                parseFloat(checkIsNullOrUndefined(fromItem), 0);
              inputData[itm.to] = parseFloat(inputData[itm.to].toFixed(2), 0);
            }
          });
        });
      }
      if (validation.action.checkErrorRefresh && ischeckError === undefined) {
        const currentPageJSON = state.getStarted.currentpage.JsonData;
        if (checkIsNullOrUndefined(currentPageJSON) !== '') {
          validation.action.checkErrorRefresh.forEach(itm => {
            const checkField = currentPageJSON.find(f => f.fieldName === itm);
            if (checkIsNullOrUndefined(checkField) !== '') {
              executeFieldValidation(checkField.fieldValidation, itm, true, isServiceCall, undefined, undefined, true);
            }
          });
        }
      }
      if (validation.action.checkError && ischeckError === undefined) {
        const currentPageJSON = state.getStarted.currentpage.JsonData;
        if (checkIsNullOrUndefined(currentPageJSON) !== '') {
          validation.action.checkError.forEach(itm => {
            const checkField = currentPageJSON.find(f => f.fieldName === itm);
            if (checkIsNullOrUndefined(checkField) !== '') {
              executeFieldValidation(
                checkField.fieldValidation,
                itm,
                isStore,
                isServiceCall,
                undefined,
                undefined,
                true,
              );
            }
          });
        }
      }
      if (validation.action.checkFundTotalError && ischeckError === undefined) {
        const currentPageJSON = state.getStarted.currentpage.JsonData;
        if (checkIsNullOrUndefined(currentPageJSON) !== '') {
          validation.action.checkFundTotalError.forEach(itm => {
            const checkField = currentPageJSON.find(f => f.fieldName === itm);
            if (checkIsNullOrUndefined(checkField) !== '') {
              executeFieldValidation(checkField.fieldValidation, itm, true, isServiceCall, undefined, undefined, true);
            }
          });
        }
      }
      if (validation.action.lock !== undefined) {
        validation.action.lock.forEach(itm => {
          lockFields[itm] = true;
        });
      }
      if (validation.action.unlock !== undefined) {
        validation.action.unlock.forEach(itm => {
          delete lockFields[itm];
        });
      }
      if (isStore) {
        if (checkIsNullOrUndefined(inputErrorData[fieldName]) === '') {
          if (String(validation.action.errorMsg).indexOf('|') !== -1) {
            const splited = validation.action.errorMsg.split('#');
            const splitedText = splited[1].split('|');
            const result =
              checkIsNullOrUndefined(inputData[`#${splitedText[0]}`]) !== '' ? inputData[`#${splitedText[0]}`] : '';
            inputErrorData[fieldName] = checkIsNullOrUndefined(splited[0] + result + splitedText[1]);
          } else if (String(validation.action.errorMsg).indexOf('#') !== -1) {
            const splited = validation.action.errorMsg.split('#');
            const result =
              checkIsNullOrUndefined(inputData[`#${splited[1]}`]) !== '' ? inputData[`#${splited[1]}`] : '';
            inputErrorData[fieldName] = checkIsNullOrUndefined(splited[0] + result);
          } else {
            inputErrorData[fieldName] = checkIfEmptyInFieldValue(validation.action.errorMsg);
          }
        }
        if (
          checkIfEmptyInFieldValue(validation.action.errorMsg) !== '' &&
          String(validation.action.errorMsg).indexOf('|') === -1
        ) {
          inputErrorData[fieldName] = checkIfEmptyInFieldValue(validation.action.errorMsg);
        }
        if (validation.action.isSpecialError !== undefined) {
          if (validation.action.isSpecialError) specialErrors[fieldName] = true;
          else delete specialErrors[fieldName];
        } else {
          delete specialErrors[fieldName];
        }

        if (
          checkIfEmptyInFieldValue(validation.action.errorMsg) !== '' &&
          groupIndex !== undefined &&
          subGroupIndex !== undefined
        ) {
          const trackerGroupNames = state.getStarted.trackergroupnames;

          trackerGroupNames[groupIndex].SubGroup[subGroupIndex].isPageValid = false;
          store.dispatch(setTrackerGroupName(trackerGroupNames));
          if (errorLists.findIndex(p => p.widgetName === fieldName) === -1) {
            errorLists.push({
              groupIndex,
              subGroupIndex,
              errorMessage: inputErrorData[fieldName] || validation.action.errorMsg,
              widgetName: fieldName,
            });
          }
          getErrorPanelLists();
        }
      }
      if (checkIfEmptyInFieldValue(validation.action.errorMsg) !== '') return false;
    }
    if (isStore) {
      const errorObjectIndex = errorLists.findIndex(p => p.widgetName === fieldName);
      if (errorObjectIndex !== -1) {
        errorLists.splice(errorObjectIndex, 1);
      }
      if (checkString.indexOf('false') !== -1) inputErrorData[fieldName] = '';
      getErrorPanelLists();
    }
    return true;
  });
  if (isStore) {
    store.dispatch(setInputValues(inputData));
    store.dispatch(setInputErrors(inputErrorData));
    store.dispatch(setErrorLists(errorLists));
    store.dispatch(setHideTab(HideTab));
  }
};

const checkEappJsonValidation = () => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  let checkValidFields = null;
  let eappJsonData = [];
  // Validate All eApp JSON fields before review the applicaiton
  if (state.getStarted.trackergroupnames.length === 0) {
    return;
  }

  state.getStarted.trackergroupnames.forEach((group, gpIndex) => {
    state.getStarted.trackergroupnames[gpIndex].SubGroup.forEach((subGroup, subGpIndex) => {
      if (gpIndex === state.getStarted.trackergroupnames.length - 1) {
        return;
      }
      const currentDisplayName = state.getStarted.trackergroupnames[gpIndex].SubGroup[subGpIndex].displayname;
      if (state.getStarted.hidetab.filter(v => v === currentDisplayName).length > 0) {
        return;
      }
      const eAppJSONFields = getNextPageContent(gpIndex, subGpIndex, state.getStarted.trackergroupnames);
      if (checkIsNullOrUndefined(eAppJSONFields) !== '') {
        eAppJSONFields.map(obj => Object.assign(obj, { groupIndex: gpIndex, subGroupIndex: subGpIndex }));
        eappJsonData = [...new Set([...eappJsonData, ...eAppJSONFields.filter(x => x.fieldValidation !== undefined)])];
      }
    });
  });

  if (eappJsonData.length > 0) {
    eappJsonData.forEach(itm => {
      const isReviewPageValidation = constants.reviewPageValidation.some(x => x === itm.fieldName);
      if (
        checkIsNullOrUndefined(inputData[itm.fieldName]) === '' ||
        checkIsNullOrUndefined(inputData[itm.fieldName]) === 0 ||
        isReviewPageValidation
      ) {
        executeFieldValidation(itm.fieldValidation, itm.fieldName, true, false, itm.groupIndex, itm.subGroupIndex);
      }
    });
  }
  if (state.getStarted.errorlists.length > 0) {
    checkValidFields = true;
  }
  return checkValidFields;
};

const fillFieldDefaults = field => {
  const filledField = _.merge({}, defaultProps, field);

  if (filledField.fieldValidation) {
    if (filledField.fieldValidation.apiCall) {
      filledField.fieldValidation.apiCall.action = _.assign(
        {},
        defaultActionProps,
        filledField.fieldValidation.apiCall.action,
      );

      filledField.fieldValidation.conditions.forEach(validation => {
        // eslint-disable-next-line no-param-reassign
        validation.action = _.assign({}, defaultActionProps, validation.action);
      });
    } else {
      filledField.fieldValidation.forEach(validation => {
        // eslint-disable-next-line no-param-reassign
        validation.action = _.assign({}, defaultActionProps, validation.action);
      });
    }
  }

  return filledField;
};

export default {
  checkIsNullOrUndefined,
  executeFieldValidation,
  renderHTML,
  handleExitClick,
  tooltipMessageFn,
  getSpecificationName,
  getNextPageContent,
  getCompanySignData,
  getInvalidMenuCount,
  getCheckToFollow,
  getErrorPanelLists,
  downloadDoc,
  checkEappJsonValidation,
  setSignedStatesEvent,
  getFundingData,
  fillFieldDefaults,
  checkIdologyReason,
  appValidation,
  getTrusteeEsign,
  validateDocTab,
  validateMSAWithBKOFC,
  cleanJSON,
  getMYAFIAProducts,
  setTrackerGroupNames,
  getGroupIndex,
  getFieldIsAppear,
  setOpenCopyPopUp,
  validateAddress,
};
