import React, { useState } from 'react';
import { Paper, Button, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { Delete, ChevronRight, Group, Business, Lock, ThumbDown, ThumbUp } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { DropDown, PercentageTextbox } from '@aspida/react-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import constants from 'constants/common';
import ErrorNotification from 'components/errorNotification';
import SearchComponent from 'components/search';
import ScStateModal from 'components/scStateModal';
import Zendesk from 'api/zendesk';
import CommonFunctions from '../utils/commonFunctions';
import Utils from '../renderforms/utils';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ProductPicker(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const [editVisible, setEditVisible] = useState(false);
  const SplitCommission = [
    { id: true, value: 'Yes' },
    { id: false, value: 'No' },
  ];
  const annuityTypeQues = [
    { id: 'MYGA', value: 'MYGA' },
    { id: 'FIA', value: 'FIA' },
  ];
  const annuityTypeQuesMYGA = [{ id: 'MYGA', value: 'MYGA' }];
  const annuityTypeQuesFIA = [{ id: 'FIA', value: 'FIA' }];
  const inputData = getStarted.inputvalues;
  if (inputData[`#SplitAgentData`] === undefined) inputData[`#SplitAgentData`] = [];
  let appTypeName = '';
  if (getStarted.applicationtype && props.ApplicationTypes.length > 0) {
    appTypeName = props.ApplicationTypes.find(p => p.id === getStarted.applicationtype).value;
  }

  const getAnnuityTypeList = () => {
    const res = getStarted.inputvalues['#isMYAFIAPresent'];
    const annuityTypeList = {
      FIA: annuityTypeQuesFIA,
      MYA: annuityTypeQuesMYGA,
      default: annuityTypeQues,
    };
    return annuityTypeList[res] || annuityTypeList.default;
  };

  const getSignStateName = () => {
    const signStateTitle = {
      'Natural Person(s)': 'Owners Signed State',
      Trust: 'Formation State',
      default: 'Principal Place of Business State',
    };
    return signStateTitle[appTypeName] || signStateTitle.default;
  };

  const getStateResName = () => {
    return appTypeName === 'Natural Person(s)'
      ? 'State of residence'
      : 'Trustee/Authorized Signatory State of Residence';
  };

  const onClose = isModal => {
    setEditVisible(isModal);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {constants.consentFormStates.includes(getStarted.signedstateabb) && (
          <ScStateModal visible={editVisible} onClose={onClose} />
        )}
        {getStarted.policyapplicationid !== '' && (
          <Alert
            severity="error"
            className={classes.alert}
            action={
              <Button
                color="inherit"
                size="small"
                classname={classes.button}
                endIcon={<ChevronRight />}
                onClick={CommonFunctions.handleExitClick}
              >
                Create New Application
              </Button>
            }
          >
            Some items on this page cannot be changed. If an adjustment needs to be made, then a new application will
            need to be created.
          </Alert>
        )}
        {getStarted.policyapplicationid === '' && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="body1" component="span" className={classes.aspida}>
                Aspida eApplication
              </Typography>
              <br />
              <Typography variant="body1" component="span" className={classes.lets}>
                Let's Get Started...
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>Annuity Type
                </Typography>
                <DropDown
                  name="drAnnuitytype"
                  listItem={getAnnuityTypeList()}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.annuityTypeQues) !== ''}
                  disabled={getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()}
                  errorText={getStarted.inputerrors.annuityTypeQues}
                  labelText="AnnuityType"
                  variant="outlined"
                  isFullWidth
                  onChange={event => props.onChange(event, 'AQ', '#annuityTypeQues', undefined)}
                  value={getStarted.inputvalues['#annuityTypeQues']}
                  className={classes.dropStyle}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>Product
                </Typography>
                <DropDown
                  listItem={props.Products}
                  name="drpProducts"
                  value={getStarted.product}
                  onChange={event => props.onChange(event, 'P', undefined, undefined)}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.product) !== ''}
                  errorText={getStarted.inputerrors.product}
                  labelText="Product"
                  variant="outlined"
                  isFullWidth
                  disabled={
                    CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#annuityTypeQues']) === '' ||
                    getStarted.policyapplicationid !== '' ||
                    Utils.isUserViewOnly()
                  }
                  className={classes.dropStyle}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.appType}>
                  <span style={{ color: 'red' }}>*</span>Type of ownership?
                </Typography>
              </Grid>
              {props.ApplicationTypes.length > 0 &&
                props.ApplicationTypes.map((item, index) => {
                  if (props.ApplicationTypes.length - 1 === index) {
                    return (
                      <Grid item xs={12} sm={12} md={3} lg={3} className={classes.appTypeBtn}>
                        <Button
                          name={item.id}
                          fullWidth
                          disabled={getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()}
                          variant="outlined"
                          className={
                            item.id === getStarted.applicationtype
                              ? `${classes.activeTrustButton}`
                              : `${classes.appBtnTrustStyle}`
                          }
                          onClick={() => {
                            props.onChange(item.id, 'A', undefined, undefined);
                          }}
                          startIcon={<Lock className={classes.starticonstyle} />}
                        >
                          {item.value}
                        </Button>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} sm={12} md={4} lg={4} className={classes.appTypeBtn}>
                      <Button
                        name={item.id}
                        fullWidth
                        disabled={getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()}
                        variant="outlined"
                        className={
                          item.id === getStarted.applicationtype ? `${classes.activeButton}` : `${classes.appBtnStyle}`
                        }
                        onClick={() => {
                          props.onChange(item.id, 'A', undefined, undefined);
                        }}
                        startIcon={
                          item.id === '543f1d6f-4d9e-4df8-9a4d-53399f43168d' ? (
                            <Group className={classes.starticonstyle} />
                          ) : (
                            <Business className={classes.starticonstyle} />
                          )
                        }
                      >
                        {item.value}
                      </Button>
                    </Grid>
                  );
                })}
              {CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.applicationtype) !== '' && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.docErrorSection}>
                    Application Type is Required
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {getStarted.applicationtype && (
          <React.Fragment>
            <Grid item xs={12}>
              <Paper className={classes.paperGrid} square elevation={3}>
                <Grid container className={classes.paperBody} spacing={1}>
                  <Grid item xs={12} md={6} lg={6} className={classes.clickhere}>
                    <Typography variant="h5" gutterBottom className={classes.label}>
                      <span style={{ color: 'red' }}>*</span>
                      {getSignStateName()}
                    </Typography>
                    <DropDown
                      listItem={props.SignedStates}
                      name="drpSignedState"
                      value={getStarted.signedstate}
                      onChange={event => props.onChange(event, 'S', undefined, undefined)}
                      onBlur={() => setEditVisible(constants.consentFormStates.includes(getStarted.signedstateabb))}
                      isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.signedstate) !== ''}
                      errorText={getStarted.inputerrors.signedstate}
                      labelText="Select"
                      variant="outlined"
                      isFullWidth
                      disabled={
                        getStarted.product === '' || getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()
                      }
                      className={classes.dropStyle}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    className={appTypeName === 'Natural Person(s)' ? classes.clickhere : classes.displayNone}
                  >
                    <Typography variant="h5" gutterBottom className={classes.label}>
                      <span style={{ color: 'red' }}>*</span>
                      {getStateResName()}
                    </Typography>
                    <DropDown
                      listItem={props.SignedStates}
                      name="drpResidenceState"
                      value={getStarted.inputvalues['#residentStateId']}
                      onChange={event => props.onChange(event, 'R', '#residentState', undefined)}
                      isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.residentState) !== ''}
                      errorText={getStarted.inputerrors.residentState}
                      labelText="Select"
                      variant="outlined"
                      isFullWidth
                      disabled={
                        getStarted.product === '' || getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()
                      }
                      className={classes.dropStyle}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} className={getStarted.applicationtype === '' && classes.displayNone}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              {getStarted.applicationtype && (
                <React.Fragment>
                  <Grid item xs={12} className={classes.clickhere}>
                    <Typography variant="h5" className={classes.label}>
                      <span style={{ color: 'red' }}>*</span>Will there be a split commission(s)?
                    </Typography>
                  </Grid>

                  {SplitCommission.length > 0 &&
                    SplitCommission.map(item => {
                      return (
                        <Grid item xs={4} className={classes.appTypeBtn}>
                          <Button
                            name={item.id}
                            fullWidth
                            disabled={
                              CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isReviewComplete']) ===
                                true || Utils.isUserViewOnly()
                            }
                            variant="outlined"
                            className={
                              item.id === getStarted.inputvalues['#ifSplitCommission']
                                ? `${classes.activeButton}`
                                : `${classes.appBtnStyle}`
                            }
                            onClick={() => {
                              props.onChange(item.id, 'radio', '#ifSplitCommission', undefined);
                            }}
                            startIcon={
                              item.id === true ? (
                                <ThumbUp className={classes.starticonstyle} />
                              ) : (
                                <ThumbDown className={classes.starticonstyle} />
                              )
                            }
                          >
                            {item.value}
                          </Button>
                        </Grid>
                      );
                    })}
                </React.Fragment>
              )}
              {getStarted.inputvalues['#ifSplitCommission'] && (
                <React.Fragment>
                  <Grid item xs={12} className={classes.clickhere}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5" className={classes.label} gutterBottom>
                          What is the commission breakdown?
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className={classes.npnLabel}>Search for a Producer by their NPN.</Typography>
                        <SearchComponent
                          onChange={props.onChange}
                          values={getStarted}
                          isDisabled={
                            getStarted.inputvalues['#SplitAgentData'].length > 3 ||
                            CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isReviewComplete']) ===
                              true ||
                            Utils.isUserViewOnly()
                          }
                        />

                        <Typography variant="body2" gutterBottom className={classes.margin}>
                          If you are unable to find a producer, then please{' '}
                          <span
                            onClick={() => Zendesk.getZendeskUrl()}
                            onKeyPress={() => Zendesk.getZendeskUrl()}
                            role="link"
                            className={classes.link}
                            tabIndex={0}
                          >
                            click here
                          </span>
                          .
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#SplitAgentData']) !== '' &&
                getStarted.inputvalues['#SplitAgentData'].map((agentData, index) => {
                  return (
                    <React.Fragment>
                      {index === 0 && (
                        <Grid item xs={12} className={classes.dividerStyle}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={9} className="reviewbox">
                        <Typography variant="body2" gutterBottom className={classes.labelTop}>
                          {index === 0 ? 'Writing Agent' : 'Split Agent'}
                        </Typography>
                        <Typography variant="body1" gutterBottom className={classes.valueTop}>
                          {agentData.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <PercentageTextbox
                          id={`#splitAgent${index}Percentage`}
                          name={`#splitAgent${index}Percentage`}
                          error={
                            getStarted.inputerrors.splitAgentTotalPercentage !== undefined
                              ? getStarted.inputerrors.splitAgentTotalPercentage !== ''
                              : false
                          }
                          onBlur={event => props.onChange(event, 'percent', `#splitAgent${index}Percentage`, index)}
                          fullWidth
                          value={agentData.percentage}
                          variant="outlined"
                          isDecimal
                          className={classes.percentageStyleNew}
                          disabled={
                            CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isReviewComplete']) ===
                              true || Utils.isUserViewOnly()
                          }
                        />
                      </Grid>
                      {index > 0 && (
                        <Grid item xs={1}>
                          <IconButton
                            className={classes.deleteButton}
                            disabled={
                              CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isReviewComplete']) ===
                                true || Utils.isUserViewOnly()
                            }
                            aria-label="delete"
                            onClick={() => props.onChange(undefined, 'delete', `#splitAgent${index}Trash`, index)}
                            name={`#splitAgent${index}Trash`}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item xs={12} className={classes.dividerStyle}>
                        <Divider />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#SplitAgentData']) !== '' &&
                getStarted.inputvalues['#SplitAgentData'].length > 0 && (
                  <React.Fragment>
                    <Grid item xs={9}>
                      <Typography className={classes.totalText} variant="body1">
                        Total
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={
                        CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#splitAgentTotalPercentage']) !==
                          '' && classes.totalErrorPercentGrid
                      }
                    >
                      <Typography variant="body1" className={classes.totalErrorPercent}>
                        {CommonFunctions.checkIsNullOrUndefined(
                          getStarted.inputvalues['#splitAgentTotalPercentage'],
                        ) !== '' && (
                          <React.Fragment>
                            {getStarted.inputvalues['#splitAgentTotalPercentage']}
                            <span>%</span>
                          </React.Fragment>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.totalError}>
                        {getStarted.inputerrors.splitAgentTotalPercentage}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.totalError}>
                        {getStarted.inputerrors.IsSplitAgent}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
            </Grid>
          </Paper>
        </Grid>
        {CommonFunctions.checkIsNullOrUndefined(getStarted.saleseligibilityerrors) !== '' &&
          getStarted.saleseligibilityerrors.length > 0 &&
          getStarted.saleseligibilityerrors.map(eligibilityError => {
            return (
              <ErrorNotification
                errorText={eligibilityError.errorMessage}
                gotoText={eligibilityError.linkText}
                linkText={eligibilityError.link}
                agentName={getStarted.inputvalues['#ifSplitCommission'] && eligibilityError.agentName}
              />
            );
          })}
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2} className={classes.rowButton}>
        <Grid item xs={12} md={6} lg={4} className={classes.gridAlignLeftNextButton}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={
              CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isReviewComplete']) === true ||
              Utils.isUserViewOnly()
            }
            onClick={props.onClick}
          >
            CONFIRM &amp; PROCEED
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ProductPicker.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  SignedStates: PropTypes.array,
  ApplicationTypes: PropTypes.array,
  Products: PropTypes.array,
};
