export default {
  label: {
    fontWeight: 600,
  },
  paperDownload: {
    padding: '16px',
    boxShadow: '0px 3px 6px #00000029',
    border: '.5px solid #ABABAB',
    borderRadius: '4px 0px 0px 0px',
    opacity: 1,
    textAlign: 'center',
    height: '162px',
  },
  colorPrimary: {
    backgroundColor: '#b6d596',
  },
  barColorPrimary: {
    backgroundColor: '#6EAB2E',
  },
  fast: {
    color: '#b6d596',
    fontSize: '12px',
  },
  open: {
    color: '#fff',
    backgroundColor: '#EF426F',
    marginTop: '0.5rem',
  },
  orLabel: {
    color: '#4D4D4D',
    marginRight: '-2rem',
    marginTop: '3rem',
    textAlign: 'center',
  },
  saveIcon: {
    fontSize: '4rem',
    color: '#e4e4e4',
    cursor: 'pointer',
  },
  linerProg: {
    cursor: 'pointer',
    margin: '14px',
  },
  documentProcess: {
    cursor: 'pointer',
    width: '12rem',
    margin: '14px',
  },
  checkIcon: {
    width: '20px',
    color: '#92D050',
  },
  cancelIcon: {
    width: '20px',
    color: '#D1D1D1',
    cursor: 'pointer',
  },
  uploadName: {
    display: 'block',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#14043C',
    fontSize: '18px',
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  uploadIcon: {
    fontSize: '4rem',
    color: '#e4e4e4',
    cursor: 'pointer',
  },
  dragDrop: {
    color: '#e4e4e4',
  },
  fileFormat: {
    textAlign: 'left',
  },
  tooltipStyle: {
    marginBottom: '-4px',
    marginLeft: '9px',
  },
  dividerStyle: {
    width: '62rem',
    marginLeft: '9px',
  },
  openFile: {
    color: '#EF426F',
    fontWeight: '600',
  },
  signstyle: {
    fontSize: '.8rem',
  },
  upStyle: {
    marginTop: '-9px',
    marginLeft: '-30px',
    color: '#0084BC',
    textDecoration: 'underline',
    font: 'Bold 13px/16px Muli',
  },
  alignRight: {
    textAlign: 'right',
  },
  paperHeader: {
    padding: '1rem',
    opacity: 1,
    borderBottom: '0',
  },
  editIcon: {
    marginRight: '0.3125rem',
    fontSize: '0.9375rem',
  },
  reviewBtn: {
    textTransform: 'capitalize',
    fontSize: '0.625rem',
    paddingLeft: '0.5rem',
  },
  openIcon: {
    color: '#fff',
    backgroundColor: '#EF426F',
    marginTop: '0.5rem',
    textTransform: 'capitalize',
  },
  labelTop: {
    color: '#8695b1 !important',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: '500',
    overflowWrap: 'break-word',
  },
  valueTop: {
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    backgroundColor: '#f8fafc !important',
    fontSize: '16px',
    fontWeight: '500',
    overflowWrap: 'break-word',
  },
  lableColor: {
    fontWeight: '300',
    color: '#000000CC',
  },
  typoStyle: {
    fontSize: '1.25rem',
    fontWeight: '400',
  },
  docErrorSection: {
    color: '#f44336 !important',
  },
  paperHeaderGrid: {
    padding: '1rem',
    opacity: 1,
    borderBottom: '0',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    backgroundColor: '#F2F4F7',
  },
  paperBody: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F8FAFC',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
  },
  headStyle: {
    fontWeight: 600,
    marginTop: '1rem',
  },
};
