export default {
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  btnCanav: {
    marginLeft: '0.25rem',
  },
  paperHeader: {
    padding: '1rem',
    opacity: 1,
    borderBottom: '0',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
  },
  paperBody: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F2F4F7',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
  },
  editIcon: {
    marginRight: '0.3125rem',
    fontSize: '0.9375rem',
    width: '24px',
    height: '24px',
  },
  reviewBtn: {
    textTransform: 'capitalize',
    border: '1px solid #14043C',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF !important',
    color: '#14043C',
    fontWeight: '400',
    fontSize: '1rem',
    paddingLeft: '0.5rem',
    width: '169px',
    height: '36px',
  },
  infieldLabel: {
    color: '#768497',
    fontSize: '14px',
    fontWeight: '400',
  },
  labelText: {
    overflowWrap: 'break-word',
    color: '#4D4D4D',
    fontSize: '16px',
    fontWeight: '500',
  },
  list: {
    listStyle: 'disc',
    paddingLeft: '30px',
    lineHeight: '4px',
    '& > li': {
      display: 'list-item',
      paddingLeft: '1px',
    },
  },
};
