import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      borderRadius: '0.25rem',
      padding: '0.625rem',

      '& > *:not(:last-child)': {
        paddingRight: '0.5rem',
      },
    },

    border: {
      color: '#4D4D4D',
      width: '100%',
      border: '1px solid #464646',
      height: '76px',
      fontSize: '18px',
      boxShadow: 'inset 8px 0 0 0 #497915',
      borderRadius: '8px',
      textTransform: 'capitalize',
      backgroundColor: '#fff',
      paddingLeft: '1rem',
    },
    iconStyle: {
      borderRadius: '9px',
      backgroundColor: '#EAF9DB',
      marginTop: '.9rem',
      color: '#497915',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#EAF9DB',
      },
    },
    accountNo: {
      fontFamily: 'Muli',
      marginTop: '.5rem',
      color: '#4D4D4D',
      fontSize: '18px',
    },
    editAlign: {
      textAlign: 'right',
    },
    edit: {
      width: '0.7em',
    },
    paddingIcon: {
      padding: '0',
    },
    check: {
      width: '0.7em',
      color: '#6ABF4B',
      marginTop: '-0.2rem',
    },
    question: {
      color: '#000000',
      fontFamily: 'Muli',
      fontSize: '11px',
      textAlign: 'center',
    },
    lightPink: {
      borderLeft: '0.5rem solid #eba6b8',
    },

    purple: {
      borderLeft: '0.5rem solid #7878f5',
    },

    green: {
      borderLeft: '0.5rem solid #21c9c5',
    },

    yellow: {
      borderLeft: '0.5rem solid #ffc000',
    },

    blue: {
      borderLeft: '0.5rem solid #00b0f0',
    },

    paleGray: {
      borderLeft: '0.5rem solid #bec7d6',
    },

    lightGray: {
      borderLeft: '0.5rem solid #7e91af',
    },

    darkGray: {
      borderLeft: '0.5rem solid #3d4d66',
    },
  });

export default styles;
