import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from 'components/checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const CheckBoxComp = props => {
  const classes = useStyles();
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12
                        MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        className={[props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone, 'checkbox'].join(
          ' ',
        )}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
          paddingTop: `${
            props.group.fieldProperty.styles.paddingTop !== undefined ? props.group.fieldProperty.styles.paddingTop : ''
          }rem`,
          paddingBottom: `${
            props.group.fieldProperty.styles.paddingBottom !== undefined
              ? props.group.fieldProperty.styles.paddingBottom
              : ''
          }rem`,
        }}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 4}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 4}
      >
        <CheckBox
          name={props.group.fieldName}
          labelText={props.group.fieldProperty.labelText}
          listItem={props.group.fieldProperty.choices}
          isError={
            props.Errors[props.group.fieldName] !== undefined ? props.Errors[props.group.fieldName] !== '' : false
          }
          errorText={props.Errors[props.group.fieldName]}
          selectedValue={props.Values[props.group.fieldName]}
          onChange={event => props.onChange(event, props.group.fieldValidation, 'checkbox', props.group.fieldName)}
          color={props.group.fieldProperty.styles.labelFontColor}
          fontVariant={props.group.fieldProperty.styles.labelFontVariant}
          fontWeight={props.group.fieldProperty.styles.labelFontWeight}
          tooltipText={props.group.fieldProperty.tooltipText}
          checktextWrap={props.group.fieldProperty.styles.textWrap}
          checkmarginLeft={props.group.fieldProperty.styles.marginLeft}
          textPlacement="right"
          isDisabled={Utils.handleElementDisable(props)}
        />
      </Grid>
    </React.Fragment>
  );
};

CheckBoxComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  Errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default CheckBoxComp;
