export default {
  root: {
    textAlign: 'center',
  },
  gridItem: {
    '@media (max-width:1300px)': {
      'flex-grow': 0,
      'max-width': '100%',
      'flex-basis': '100%',
    },
  },
  textFieldContainer: {
    padding: '1rem 0 1rem 0',
  },
  textField: {
    flex: 1,
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
      fontWeight: '600',
    },
  },
  percentageStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
      fontWeight: '600',
    },
  },
  loadingButton: {
    height: '3rem',
    flex: 1,
    width: '100%',
    marginBottom: '1rem',

    '& h5': {
      fontWeight: 'bold',
      color: 'white',
    },
  },
  email: {
    color: '#21C9C5',
  },
  container: {
    width: '650px',
    margin: 'auto',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  dateField: {
    flex: 1,
    width: '100%',
    'margin-top': '0',
    'margin-bottom': '0',
  },
  esignText: {
    textAlign: 'center',
    fontFamily: 'Muli',
    color: '#EF426F',
    fontSize: '32px',
    fontWeight: '600',
    margin: '1rem',
  },
  esignSubText: {
    color: '#4D4D4D !important',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: '600',
  },
  esignSubTextNew: {
    color: '#4D4D4D !important',
    textAlign: 'center',
    marginBottom: '0.8em',
    fontFamily: 'Muli',
    fontSize: '24px',
    fontWeight: '600',
  },
  aspidaIcon: {
    width: '8.1875rem',
    marginTop: '4em',
    /*  '@media (max-width:410px)': {
      marginTop: '25em',
      marginLeft: '-3em',
    },
    '@media (min-width:411px) and (max-width:538px)': {
      marginTop: '15em',
      marginLeft: '-2em',
    },
    '@media (min-width:539px) and (max-width:768px)': {
      marginTop: '6em',
    },
    '@media (min-width:2152px)': {
      marginRight: '1em !important',
    }, */
  },
  rowButton: {
    marginTop: '0.5rem',
  },
  gridAlignLeftNextButton: {
    textAlign: 'left',
  },
  esignMessage: {
    margin: '0',
    fontSize: '1.3rem',
    marginTop: '3px',
    fontFamily: 'Muli,sans-serif',
    fontWeight: '400',
    lineHeight: '1.66',
    color: '#f44336',
    textAlign: 'center',
  },
  paperGrid: {
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    marginLeft: '4rem',
    marginRight: '3rem',
    '@media (min-width: 2400px)': {
      marginLeft: '30rem',
      marginRight: '30rem',
    },
  },
  paperBody: {
    padding: '1rem',
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
  },
  esignComplete: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
