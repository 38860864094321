import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import store from 'redux/store';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function SummaryLabel(props) {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const classes = useStyles();
  return (
    <React.Fragment>
      {CommonFunctions.checkIsNullOrUndefined(inputData[`#replacementDisplayPolicyNumber`]) !== '' ? (
        <Grid>
          <Typography
            variant={props.group.fieldProperty.labelFontVariant || 'body1'}
            gutterBottom
            className={classes.fontStyle}
          >
            Account {inputData[`#replacementDisplayPolicyNumber`]} {`(`}
            {inputData[`${props.group.fieldProperty.summaryPrefix}CurrentCount`]} {` of `}
            {inputData[`${props.group.fieldProperty.summaryPrefix}TotalCount`]}
            {`)`}
          </Typography>
        </Grid>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

SummaryLabel.propTypes = {
  group: PropTypes.object,
};
