import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    header: {
      height: '4rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: '1.3rem',
      paddingBottom: '1rem',
    },
    appNumberGrid: {
      paddingRight: '0rem',
      paddingLeft: '1rem',
      borderRightStyle: 'ridge',
      borderRightWidth: 'medium',
      height: '2.4rem',
    },
    appNumberNoGrid: {
      paddingRight: '0rem',
      paddingLeft: '1rem',
    },
    appNumberClient: {
      borderRightStyle: 'ridge',
      borderRightWidth: 'medium',
      height: '2.4rem',
    },
    personIcon: {
      color: '#EF426F',
      fontSize: '25px !important',
    },
    appNumberTxt: {
      color: '#4D4D4D',
      fontFamily: 'Muli',
      fontSize: '14px',
      fontWeight: '700',
      '@media (max-width: 800px)': {
        fontSize: '11px',
      },
    },
    appNumber: {
      color: '#4D4D4D',
      fontFamily: 'Muli',
      fontSize: '14px',
      fontWeight: '400',
      '@media (max-width: 800px)': {
        fontSize: '11px',
      },
    },
    prodNum: {
      fontSize: '1rem',
      padding: '1rem 1.5rem',

      '@media (min-width:59.9375rem) and (max-width:75rem)': {
        marginTop: '.5rem',
        marginLeft: '.5em',
        fontSize: '.7rem',
      },

      '@media (max-width:51.75rem)': {
        marginTop: '0',
        marginLeft: '2em',
        fontSize: '9pt',
      },

      '@media (max-width:31.5rem)': {
        marginTop: '0',
        marginLeft: '2em',
        fontSize: '6pt',
      },
    },
    editIcon: {
      marginRight: '0.3125rem',
      fontSize: '1.3rem',
    },
    gridAlignRightBackButton: {
      textAlign: 'right',
    },
    gridAlignCenterExitButton: {
      textAlign: 'center',
      display: 'block',
    },
    editBtn: {
      textTransform: 'capitalize',
      color: '#14043C',
      borderRadius: '2rem',
      boxSizing: 'border-box',
      border: '1px solid #F50057',
      cursor: 'default',
      fontSize: '12px',
    },
    displayNone: {
      display: 'none',
    },
    displayBlock: {
      display: 'block',
    },
    newApp: {
      fontSize: '0.9375rem',
      textDecoration: 'underline',
      fontWeight: '600',
      cursor: 'pointer',

      '@media (min-width:59.9375rem) and (max-width:75rem)': {
        fontSize: '.7235rem',
      },

      '@media (max-width:39.5rem)': {
        fontSize: '0.5375rem',
      },
    },
    progressStyle: {
      width: '100%',
      background: '#fff',
      paddingBottom: '8px',
    },
    eSignCompleteName: {
      textAlign: 'end',
      marginRight: '1rem',
    },
  });

export default styles;
