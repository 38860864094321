export default {
  root: {
    '& .MuiDialog-paperWidthSmt': {
      maxWidth: '75%',
    },
  },
  modal: {
    overflowY: 'hidden',
    position: 'relative',
  },
  modalTitle: {
    height: '35px',
    width: '202px',
    color: '#4D4D4D',
    fontSize: '24px',
    letterSpacing: '0.23px',
    lineHeight: '26px',
    '& > h2': {
      fontWeight: '600',
    },
  },
  modalText: {
    width: '857px',
    color: '#4D4D4D',
    fontSize: '22px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  iUnderstand: {
    width: '857px',
    color: '#4D4D4D',
    fontSize: '18.5px',
    lineHeight: '27px',
    fontWeight: '600',
  },
  theSinglePremiumCopy: {
    width: '850px',
    color: '#4D4D4D',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  list: {
    listStyle: 'disc',
    paddingLeft: '30px',
    '& > li': {
      display: 'list-item',
    },
  },
  modalButton: {
    display: 'block',
    margin: '1rem auto',
    width: '313px',
  },
  link: {
    color: '#0d8bc2',
  },
  checkbox: {
    height: '42px',
    '& > span': {
      fontWeight: '600',
      fontSize: '17px',
    },
  },
};
