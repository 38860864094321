import React from 'react';
import { makeStyles, Grid, Typography, Paper, Button } from '@material-ui/core';
import handMoneyLogo from 'assets/images/hand_money.svg';
import peopleLogo from 'assets/images/people.svg';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from './style';

const useStyles = makeStyles(styles);

export default function EsignComplete() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.esignComplete}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom className={classes.titleSuccessHead}>
            You're all set!
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.titleSuccess}>
            We'll get started on your submitted application.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} elevation={0}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.next}>
                  So what's next?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.gridAlignCenterNextButton}>
                    <img src={peopleLogo} alt="logo" className={classes.logo} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom className={classes.agent}>
                      Clients
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom className={classes.pendingText}>
                      Be on the lookout for your <span style={{ fontWeight: 'bold' }}>Welcome email.</span>
                      <br />
                      From there you can access the <span style={{ fontWeight: 'bold' }}>Customer Portal,</span>
                      <br />
                      create your account and view policy information.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.gridAlignCenterNextButton}>
                    <img src={handMoneyLogo} alt="logo" className={classes.logo} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom className={classes.agent}>
                      Producers
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom className={classes.pendingText}>
                      Track all pending and in-force business from the <br />
                      <span style={{ fontWeight: 'bold' }}>Book of Business</span> page in your portal
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.btnstyle}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={CommonFunctions.handleExitClick}
                  className={classes.logoutButton}
                >
                  CREATE NEW APPLICATION
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
