export const listOfPrefix = [
  // owner.json
  'ownerRes',
  'ownerMail',
  'ownerPOARes',
  'jointOwnerRes',
  'jointOwnerMail',
  // trust.json
  'trust',
  'annuitantRes',
  'annuitantMail',
  // trustee.json
  'trustee1Res',
  'trustee2Res',
  'trustee3Res',
  'trustee4Res',
  'trustee5Res',
  // corp.json
  'corp',
  // 'annuitantRes', // shared with trust
  // 'annuitantMail', // shared with trust
  // authorizedPerson.json
  'authPerson1Res',
  'authPerson2Res',
  'authPerson3Res',
  // beneficiaries.json
  'displayBenePrimaryRes',
  'displayBeneContingentRes',
];

export const listOfAddressFields = ['Addr', 'Addr2', 'City', 'State', 'Zip', 'AddressValidateButton'];

export const addressNames = () => {
  const namesObject = {};
  listOfPrefix.forEach(prefix => {
    namesObject[prefix] = [];
    listOfAddressFields.forEach(field => {
      namesObject[prefix].push(`${prefix}${field}`);
    });
  });
  return namesObject;
};
