import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CurrencyComp from './currency';
import CustomCurrencyComp from './customCurrency';
import RadioComp from './radio';
import RadioQuestionComp from './radioQuestion';
import CheckboxComp from './checkbox';
import ZipComp from './zip';
import TINComp from './tin';
import PhoneComp from './phone';
import ButtonComp from './button';
import DateComp from './date';
import PercentageComp from './percent';
import CustomPercentageComp from './customPercent';
import LabelComp from './label';
import TextBoxComp from './textbox';
import EmailComp from './email';
import AddressComp from './address';
import TextAreaComp from './textarea';
import DropdownComp from './dropdown';
import SSNComp from './ssn';
import NumericComp from './numeric';
import FileUploadComp from './fileupload';
import CheckComp from './check';
import DownloadComp from './downloadlink';
import AutoCompleteComp from './autocomplete';
import ConstantNotificationComp from './constantnotification';
import AllocationCheck from './allocationCheck';
import TrashButtonComp from './trashbutton';
import StartReviewComp from './startreview';
import YourAnnuityReviewComp from './yourannuityreview';
import OwnerReviewComp from './ownerreview';
import JownerReviewComp from './jownerreview';
import AnnuitantReviewComp from './annuitantreview';
import AuthReviewComp from './authreview';
import AllocationReviewComp from './allocationreview';
import CorpReviewComp from './corpreview';
import TrustReviewComp from './trustreview';
import BeneReviewComp from './benereview';
import FundsReviewComp from './fundsreview';
import TextboxAutoComp from './textboxAuto';
import EditButtonComp from './editbutton';
import TextboxRoutingComp from './textboxRouting';
import SummaryComp from './summarybox';
import FundSummaryComp from './fundsummarybox';
import BeneSummaryComp from './beneSummarybox';
import SummaryLabelComp from './summarylabel';
import CommonFunctions from '../utils/commonFunctions';

export default function RenderForms(props) {
  const getStarted = useSelector(state => state.getStarted);
  const values = getStarted.inputvalues;
  const errors = getStarted.inputerrors;
  const lockFields = getStarted.lockfields;
  const specialErrors = getStarted.specialerrors;
  let jsonData = [];
  jsonData = props.JsonData;
  return (
    <React.Fragment>
      {jsonData !== ''
        ? jsonData.map(fieldGroup => {
            const group = CommonFunctions.fillFieldDefaults(fieldGroup);
            const { widgetType } = group.fieldProperty;
            switch (widgetType) {
              case 'currency':
                return (
                  <CurrencyComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    SpecialErrors={specialErrors}
                    onChange={props.onChange}
                    onTabClick={props.onTabClick}
                  />
                );
              case 'customCurrency':
                return (
                  <CustomCurrencyComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    SpecialErrors={specialErrors}
                    onChange={props.onChange}
                    onTabClick={props.onTabClick}
                  />
                );
              case 'radio':
                return (
                  <RadioComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    SpecialErrors={specialErrors}
                    onChange={props.onChange}
                  />
                );
              case 'checkbox':
                return (
                  <CheckboxComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'radioQuestion':
                return (
                  <RadioQuestionComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    SpecialErrors={specialErrors}
                    onChange={props.onChange}
                  />
                );
              case 'zip':
                return (
                  <ZipComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'tin':
                return (
                  <TINComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'phone':
                return (
                  <PhoneComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'button':
                return <ButtonComp group={group} Values={values} LockFields={lockFields} onChange={props.onChange} />;
              case 'date':
                return (
                  <DateComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'percent':
                return (
                  <PercentageComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'customPercent':
                return (
                  <CustomPercentageComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'label':
                return <LabelComp group={group} Values={values} />;
              case 'textbox':
                return (
                  <TextBoxComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'textAutoPopulate':
                return (
                  <TextboxAutoComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'email':
                return (
                  <EmailComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'textboxRouting':
                return (
                  <TextboxRoutingComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'address':
                return (
                  <AddressComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                    placeChangedCallBack={props.placeChangedCallBack}
                  />
                );
              case 'textarea':
                return (
                  <TextAreaComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'dropdown':
              case 'state':
              case 'suffix':
                return (
                  <DropdownComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    SpecialErrors={specialErrors}
                    onChange={props.onChange}
                    widget={widgetType}
                  />
                );
              case 'ssn':
                return (
                  <SSNComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case 'fileupload':
                return <FileUploadComp group={group} />;
              case 'check':
                return <CheckComp group={group} onChange={props.onChange} />;
              case 'downloadlink':
                return <DownloadComp group={group} values={values} />;
              case 'numeric':
                return (
                  <NumericComp
                    group={group}
                    Values={values}
                    Errors={errors}
                    LockFields={lockFields}
                    onChange={props.onChange}
                  />
                );
              case '1035autocomplete':
                return <AutoCompleteComp group={group} Values={values} LockFields={lockFields} Errors={errors} />;
              case 'constantnotification':
                return <ConstantNotificationComp group={group} />;
              case 'allocationcheck':
                return (
                  <AllocationCheck
                    group={group}
                    Values={values}
                    LockFields={lockFields}
                    Errors={errors}
                    onChange={props.onChange}
                  />
                );
              case 'trashbutton':
                return <TrashButtonComp group={group} Values={values} onChange={props.onChange} />;
              case 'editbutton':
                return <EditButtonComp group={group} Values={values} onChange={props.onChange} />;
              case 'fundSummaryBox':
                return <FundSummaryComp group={group} onChange={props.onChange} />;
              case 'beneSummaryBox':
                return <BeneSummaryComp group={group} onChange={props.onChange} />;
              case 'summaryBox':
                return <SummaryComp group={group} onChange={props.onChange} />;
              case 'summaryLabel':
                return <SummaryLabelComp group={group} />;
              case 'start':
                return (
                  <StartReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'yourannuity':
                return (
                  <YourAnnuityReviewComp
                    group={group}
                    Values={values}
                    JsonData={jsonData}
                    onTabClick={props.onTabClick}
                  />
                );
              case 'owner':
                return (
                  <OwnerReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'jowner':
                return (
                  <JownerReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'annuitant':
                return (
                  <AnnuitantReviewComp
                    group={group}
                    Values={values}
                    JsonData={jsonData}
                    onTabClick={props.onTabClick}
                  />
                );
              case 'auth':
                return (
                  <AuthReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'allocation':
                return (
                  <AllocationReviewComp
                    group={group}
                    Values={values}
                    JsonData={jsonData}
                    onTabClick={props.onTabClick}
                  />
                );
              case 'corp':
                return (
                  <CorpReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'trust':
                return (
                  <TrustReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'bene':
                return (
                  <BeneReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              case 'funds':
                return (
                  <FundsReviewComp group={group} Values={values} JsonData={jsonData} onTabClick={props.onTabClick} />
                );
              default:
                return '';
            }
          })
        : ''}
    </React.Fragment>
  );
}

RenderForms.propTypes = {
  placeChangedCallBack: PropTypes.func,
  onChange: PropTypes.func,
  onTabClick: PropTypes.func,
  JsonData: PropTypes.array,
};
