import React, { useState, useEffect } from 'react';
import store from 'redux/store';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LoadingButton } from '@aspida/react-components';
import standardLogo from 'assets/images/standard-logo.svg';
import { Grid, Typography, TextField, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import IFrameModal from 'components/docSignIframe';
import constants from 'constants/common';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import { setSSN, setPageFlow, setApplicationId, setApplicationAlias } from 'redux/remoteSign/action';
import masterService from 'api/masterService';
import BrowserHelper from 'helpers/browserHelper';
import NotificationService from 'services/notificationService';
import styles from '../styles';

const useStyles = makeStyles(styles);

const defaultCreateForm = {
  ssn: '',
  dob: null,
};

export default function MSAHome(props) {
  const classes = useStyles();
  const [createForm, setCreateForm] = useState(defaultCreateForm);
  const { errors, formState, handleSubmit, register, setValue, trigger } = useForm();
  const [submitCounter, setSubmitCounter] = useState(0);
  const [signURL, setSignURL] = useState('');
  const [appAlias, setAppAlias] = useState('');
  const [appId, setAppId] = useState('');
  const [editVisible, setEditVisible] = useState(false);
  const dispatch = useDispatch();
  const state = store.getState();
  const { remoteSign } = state;

  const handleTextChange = name => event => {
    setCreateForm({ ...createForm, [name]: event.target.value });
    if (formState.isSubmitted) {
      trigger({ name });
    }
  };

  const onSubmit = async () => {
    dispatch(setSSN(createForm.ssn));
    setSubmitCounter(submitCounter + 1);
    const query = `?crdNumber=${createForm.ssn}`;
    const [inputRemoteSignError, inputRemoteSignData] = await masterService.getRemoteSignStatus(
      props.SignatureRequestId,
      props.SignerId,
    );
    if (inputRemoteSignError) {
      NotificationService.error(inputRemoteSignError.data.message);
    }
    if (inputRemoteSignData) {
      const signerDetails = {
        partyId: props.SignerId,
        docusignEnvelopeId: props.SignatureRequestId,
        docusignClientUserId: inputRemoteSignData.clientUserId,
      };
      sessionStorage.setItem('signerDetails', JSON.stringify(signerDetails));
    }
    const [error, remoteSignResponse] = await masterService.getRemoteSignatureData(
      props.SignatureRequestId,
      props.SignerId,
      query,
    );
    if (error) {
      NotificationService.error(error.data.message);
      return;
    }
    if (remoteSignResponse) {
      setAppAlias(remoteSignResponse.applicationAlias);
      setAppId(remoteSignResponse.applicationId);
      dispatch(setApplicationId(remoteSignResponse.applicationId));
      dispatch(setApplicationAlias(remoteSignResponse.applicationAlias));
      setSignURL(remoteSignResponse.docusignUrl);
      setEditVisible(true);
      BrowserHelper.saveBrowserInfo(
        remoteSignResponse.applicationId,
        `MSASign: next button clicked with signer details
        envelopeId: ${props.SignatureRequestId}, ClientuserId: ${inputRemoteSignData.clientUserId}`,
      );
      const remoteSignerData = {
        applicationId: remoteSignResponse.applicationId,
        applicationAlias: remoteSignResponse.applicationAlias,
        SignatureRequestId: props.SignatureRequestId,
      };
      sessionStorage.setItem('remoteSignerData', JSON.stringify(remoteSignerData));
    }
  };

  useEffect(() => {
    register({ name: 'ssn', type: 'text' }, { required: true });
  }, [register]);

  const noModalClose = async () => {
    BrowserHelper.saveBrowserInfo(
      appId,
      `MSASign: Signer Pop up closed with this signer details ${sessionStorage.getItem('signerDetails')}`,
    );
    setEditVisible(false);
    const [remoteSignResError, remoteSignRes] = await masterService.getRemoteSignStatus(
      props.SignatureRequestId,
      props.SignerId,
    );
    if (remoteSignResError) {
      NotificationService.error(remoteSignResError.data.message);
      return;
    }
    if (remoteSignRes.status === 'completed') {
      const eSignUpdateStatus = {
        docusignEnvelopeId: props.SignatureRequestId,
        docusignClientUserId: remoteSignRes.clientUserId,
        esignStatusId: constants.esignStatusId.Signed,
        esignCompletionDate: new Date(),
        inPerson: false,
        eApplicationAlias: appAlias,
      };
      const [updateEsignError, updateEsignData] = await masterService.updateRemoteEsignStatus(eSignUpdateStatus);
      if (updateEsignError) {
        NotificationService.error(updateEsignError.data.message);
        return;
      }
      if (updateEsignData) {
        dispatch(setPageFlow(1));
      }
      BrowserHelper.saveBrowserInfo(
        appId,
        `MSASign: Signature completed with this signer details
        envelopeId: ${props.SignatureRequestId}, ClientuserId: ${remoteSignRes.clientUserId}`,
      );
    }
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) !== '') {
      sessionStorage.removeItem('signerDetails');
    }
  };

  return (
    <React.Fragment>
      {signURL && <IFrameModal docuSignURL={signURL} onNoClose={noModalClose} visible={editVisible} />}
      <Grid container className={classes.esignComplete}>
        <Grid item xs={12}>
          <img src={standardLogo} alt="logo" className={classes.aspidaIcon} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.esignText}>
            Hello {props.remoteData.firstName}{' '}
            {props.remoteData.lastName !== undefined && props.remoteData.lastName.replace(/(n\/a)+/g, '')}! Let's get
            started.
          </Typography>
          <Typography gutterBottom variant="h4" className={classes.esignSubText}>
            You've been asked to sign your contract agreement.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} elevation={0}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} sm={10} md={10} lg={12}>
                <Typography variant="body1" className={classes.esignSubTextNew}>
                  Please provide a few more details to confirm your identity:
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} className={classes.container} justify="center">
                  <Grid item xs={12} md={6}>
                    <div className={classes.ssnContainer}>
                      <RHFInput
                        as={<TextField />}
                        label="CRD Number"
                        name="ssn"
                        register={register}
                        setValue={setValue}
                        className={classes.textField}
                        variant="outlined"
                        defaultValue={createForm.ssn}
                        onChange={handleTextChange('ssn')}
                        error={!!errors.ssn}
                        helperText={errors.ssn && errors.ssn.type === 'required' && 'CRD Number is required.'}
                      />
                    </div>
                  </Grid>
                </Grid>
                {remoteSign.isError && (
                  <div>
                    <p className={classes.esignMessage}>
                      {remoteSign.message === 'This request has already been signed.'
                        ? remoteSign.message
                        : 'The information you entered does not match our records.'}
                    </p>
                    <br />
                  </div>
                )}
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.rowButton}
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="medium"
                      className={classes.loadingButton}
                    >
                      NEXT
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

MSAHome.propTypes = {
  remoteData: PropTypes.array,
  SignatureRequestId: PropTypes.string,
  SignerId: PropTypes.string,
};
