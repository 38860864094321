import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReviewGrid from 'components/reviewGrid';
import styles from '../../styles';

const useStyles = makeStyles(styles);

const JownerReviewComp = props => {
  const classes = useStyles();
  return (
    <React.Fragment key={props.group.fieldName}>
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 2}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 2}
        className={props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
        }}
      >
        <ReviewGrid Values={props.Values} JsonData={props.JsonData} onTabClick={props.onTabClick} widgetType="jowner" />
      </Grid>
    </React.Fragment>
  );
};

JownerReviewComp.propTypes = {
  group: PropTypes.object,
  JsonData: PropTypes.array,
  Values: PropTypes.object,
  onTabClick: PropTypes.func,
};

export default JownerReviewComp;
