export default {
  root: {
    flexGrow: 1,
    display: 'block',
    marginTop: '1rem',
    textAlign: '-webkit-center',
  },
  headerSection: {
    marginLeft: '1.5rem',
  },
  tabPanelSection: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tabPanelSectionnew: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 1300px)': {
      width: '78%',
    },
    '@media (min-width: 1500px)': {
      width: '78%',
      height: '100%',
    },
    '@media (max-width: 800px)': {
      width: '75%',
    },
    width: '70%',
  },
  tabPanelSectionEsign: {
    overflowY: 'auto',
    borderTop: '1px solid #eee',
    '@media (max-width:48.125rem)': {
      marginLeft: '1rem',
    },
    '@media (min-width:140rem)': {
      display: 'block',
    },
  },
  dropdown: {
    width: '21.875rem',
  },
  label: {
    font: 'Regular 25px/31px Muli SemiBold !important',
    color: '#EF426F',
  },
  labelBackgroundColor: {
    backgroundColor: '#fff',
    '@media (max-width: 86.75rem) and (min-width: 68.75rem)': {
      fontSize: '.8rem',
    },
    '@media (max-width: 68.75rem) and (min-width: 60rem)': {
      fontSize: '.7rem',
    },
    fontWeight: 600,
  },
  saveExit: {
    float: 'right',
    marginTop: '104px',
    marginRight: '-68px',
    textDecoration: 'underline',
    fontWeight: '600',
  },
  btnoutlined: {
    minWidth: '102px',
    color: '#EF426F',
    borderColor: '#ef426f',
  },
  btncontained: {
    minWidth: '102px',
    color: '#fff',
    backgroundColor: '#EF426F',
  },
  alignCenter: {
    textAlign: 'center',
    padding: '2rem !important',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  labelMarginTop: {
    marginTop: '1em',
  },
  longLabelMarginTop: {
    marginTop: '1em',
  },
  radioQuestionMarginTop: {
    marginTop: '1em',
  },
  buttonStyle: {
    width: '100%',
  },
  gridAlignLeftBackButton: {
    textAlign: 'left',
  },
  rowButton: {
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  circularProgress: {
    top: '50%',
    left: '50%',
    position: 'absolute',
  },
  eAppMainGrid: {
    paddingLeft: '50px',
    paddingTop: '135px',
    width: '97%',
  },
  tooltipLabel: {
    marginBottom: '-4px',
    marginLeft: '9px',
  },
  contentContainer: {
    height: '100vh',

    '@media (max-width: 960px)': {
      height: 'calc(100vh - 64px)',
    },

    '@media (max-width: 600px)': {
      height: 'calc(100vh - 56px)',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tabPanelWidth: {
    '@media (max-width: 600px)': {
      width: 'calc(100% - 1rem)',
      padding: '0.5rem 0.5rem 1rem',
    },
  },
  removeArrows: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  dropdownDownArrowMark: {
    '& svg.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined': {
      right: '40px',
    },
  },
  inputRoot: {
    '&$disabled': {
      color: '#4D4D4D',
    },
  },
  disabled: {},
  dividerStyle: {
    borderWidth: '.1px',
  },
  alert: {
    width: '100%',
  },
  paperBodyNotification: {
    padding: '1rem',
    textAlign: 'center',
  },
  TitleNotification: {
    color: '#0A0A0A !important',
    fontSize: '19px',
    fontWeight: '500',
  },
  subTitleNotification: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  paperBody: {
    padding: '1rem',
    marginTop: '2rem',
  },
  percentageStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  splPercentDefault: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '17px',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
      color: '#4D4D4D',
      fontSize: '18px',
      fontWeight: 'bold',
      height: '34px',
    },
  },
  splPercentError: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& > div': {
      backgroundColor: '#F44336',
      color: '#FFFFFF !important',
      fontSize: '18px',
      fontWeight: 'bold',
      borderRadius: '18px',
      height: '34px',
    },
  },
  spllabelStyle: {
    marginBottom: '0 !important',
    boxSizing: 'border-box',
    height: '55px',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '4px',
    backgroundColor: 'rgba(186,195,208,0.5)',
    padding: '1rem',
  },
  splPercentFilled: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& > div': {
      backgroundColor: '#497915',
      color: '#FFFFFF !important',
      fontSize: '18px',
      fontWeight: 'bold',
      borderRadius: '18px',
      height: '34px',
    },
  },
  paperGrid: {
    '@media (max-width: 800px)': {
      width: '33rem',
    },
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
  },
  paperGridNotification: {
    borderRadius: '14px',
    backgroundColor: '#FDE4EA',
    boxShadow: '0 2px 7px 0 #ABABAB',
    margin: '2rem',
  },
  gridStyle: {
    height: '161px',
    width: '857px',
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
  },
  alertButton: {
    fontWeight: 'bold',
  },
  navigateIcon: {
    marginLeft: '.5rem',
    fontSize: '1.2rem',
    fontWeight: '600',
    cursor: 'pointer',
  },
  starticonstyle: {
    fontSize: '26px',
  },
  activeButton: {
    height: '62px',
    width: '246.33px',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#D0CADC !important',
    },
  },
  appBtnStyle: {
    height: '62px',
    width: '246px',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  labelIconstyle: {
    verticalAlign: 'middle',
    marginBottom: '0.3rem',
    marginRight: '0.3rem',
    fontSize: '23px !important',
  },
  specialIconstyle: {
    fontSize: '23px !important',
    margin: '0.8rem',
    verticalAlign: 'middle',
    color: '#EF426F',
  },
  specialBtn: {
    height: '76px',
    border: '1px solid #464646',
    borderRadius: '8px',
    boxShadow: 'inset 8px 0 0 0 #EF426F',
    backgroundColor: '#fff',
    color: '#4D4D4D',
    textTransform: 'capitalize',
    paddingLeft: '0.5rem',
    fontSize: '18px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fff',
    },
  },
  specialtext: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
    '& > div': {
      fontWeight: 'bold',
    },
    '& > label': {
      fontWeight: 'bold',
    },
  },
  editIcon: {
    marginRight: '0.3125rem',
    fontSize: '1.3rem',
  },
  gridAlignRightBackButton: {
    textAlign: 'right',
  },
  gridAlignCenterExitButton: {
    textAlign: 'center',
    display: 'block',
  },
  editBtn: {
    width: '169px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    height: '32px',
    cursor: 'default',
    backgroundColor: '#EF426F',
    margin: '1rem',
  },
  aspidaIconStyle: {
    textAlign: 'center',
    marginTop: '3rem',
  },
  divWidth: {
    width: '100%',
  },
  textAlign: {
    textAlign: '-webkit-right',
  },
};
