import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, LinearProgress, Link, Typography } from '@material-ui/core';
import { CloudUpload, Cancel, CheckCircle } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useAsyncEffect } from 'use-async-effect';
import { DropDown } from '@aspida/react-components';

import constants from 'constants/common';
import MasterService from 'api/masterService';
import store from 'redux/store';
import { setInputValues } from 'redux/getstarted/actions';
import UploadButton from 'components/upload';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import NotificationService from 'services/notificationService';
import styles from '../style';

const useStyles = makeStyles(styles);

export default function AddlUploadDoc(props) {
  const classes = useStyles();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [progress, setInProgress] = useState(0);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [AddlUploadNoteText, setAddlUploadNote] = useState('');
  const getStarted = useSelector(state => state.getStarted);
  const inputData = getStarted.inputvalues;

  if (CommonFunctions.checkIsNullOrUndefined(inputData[`#${props.description}AddlUploadDoc`]) === '')
    inputData[`#${props.description}AddlUploadDoc`] = [];

  const getLabelText = (role, IdCheckReason) => {
    return (() => {
      switch (`${role}:${IdCheckReason}`) {
        case 'ownerSSN:SSN Does Not Match':
        case 'annuitantSSN:SSN Does Not Match':
          return "Applicant's Proof of SSN";
        case 'ownerDOB:PA DOB Does Not Match':
        case 'annuitantDOB:PA DOB Does Not Match':
          return "Applicant's Proof of DOB";
        case 'jointOwnerSSN:SSN Does Not Match':
          return "Joint Applicant's Proof of SSN";
        case 'jointOwnerDOB:PA DOB Does Not Match':
          return "Joint Applicant's Proof of DOB";
        default:
          return '';
      }
    })();
  };

  const getDocumnetDescription = (role, IdCheckReason) => {
    return (() => {
      switch (`${role}:${IdCheckReason}`) {
        case 'ownerSSN:SSN Does Not Match':
          return constants.addlDocTypeIdOwnerSSN;
        case 'ownerDOB:PA DOB Does Not Match':
          return constants.addlDocTypeIdOwnerDOB;
        case 'jointOwnerSSN:SSN Does Not Match':
          return constants.addlDocTypeIdJOwnerSSN;
        case 'jointOwnerDOB:PA DOB Does Not Match':
          return constants.addlDocTypeIdJOwnerDOB;
        case 'annuitantSSN:SSN Does Not Match':
          return constants.addlDocTypeIdAnnutSSN;
        case 'annuitantDOB:PA DOB Does Not Match':
          return constants.addlDocTypeIdAnnutDOB;
        default:
          return '';
      }
    })();
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setInProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const uploadAddlDoc = async event => {
    const file = event.target.files[0];
    setUploadPercentage(0);

    const fileExt = file.name.split('.').at(-1);
    const allowedFileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'gif'];
    if (!allowedFileTypes.includes(fileExt.toLowerCase())) {
      setAddlUploadNote('Note: Allowed document types: jpg, jpeg, pdf, png');
      return;
    }

    const tenMB = 10 * 1000 * 1000;
    if (file.size > tenMB) {
      NotificationService.error('Uploaded file is too large: Max 10MB');
      return;
    }

    const fileExtension = file.name !== undefined ? /.+\.(.+)$/.exec(file.name) : '';
    const addlDocTYpeName = getStarted.inputvalues[`#${props.description}AddlDocTypeName`].replace(/\//g, '');
    const UploadFile = new File([file], `${props.docRoleName} ${addlDocTYpeName}.${fileExtension[1]}`, {
      type: file.type,
      lastModified: file.lastModified,
    });

    if (!UploadFile) {
      return;
    }

    setUploadPercentage(100);
    const partyId =
      getStarted.inputvalues['#isDtcCustomer'] === true
        ? getStarted.inputvalues['#loggedInUserPartyId']
        : getStarted.inputvalues['#writingAgentPartyId'];
    const docTypeId = getStarted.inputvalues[`#${props.description}AddlDocTypes`];
    const appId = getStarted.policyapplicationid;
    const [err, uploadedfile] = await MasterService.uploadAdditionalDocument(partyId, docTypeId, UploadFile, appId);

    if (err !== null) {
      NotificationService.error(err.data.message);
      return;
    }

    if (!uploadedfile) {
      return;
    }

    const tempDocList = [];
    tempDocList.push({
      fileName: UploadFile.name,
      documentTypeId: uploadedfile.documentTypeId,
      documentFileKeyId: uploadedfile.documentFileKeyId,
      completionDate: uploadedfile.completionDate,
      applicationId: uploadedfile.applicationId,
    });

    const uploadDoc = inputData[`#${props.description}AddlUploadDoc`];
    if (uploadDoc.length > 0) {
      const filteredData = uploadDoc.filter(d => d.documentTypeId === uploadedfile.documentTypeId);
      if (filteredData.length === 0) {
        uploadDoc.push({
          fileName: UploadFile.name,
          documentTypeId: uploadedfile.documentTypeId,
          documentFileKeyId: uploadedfile.documentFileKeyId,
          completionDate: uploadedfile.completionDate,
          applicationId: uploadedfile.applicationId,
        });
      } else {
        uploadDoc.forEach((main, esignIndex) => {
          if (main.documentTypeId === uploadedfile.documentTypeId) {
            uploadDoc[esignIndex].documentFileKeyId = uploadedfile.documentFileKeyId;
            uploadDoc[esignIndex].completionDate = uploadedfile.completionDate;
            uploadDoc[esignIndex].fileName = UploadFile.name;
          }
        });
      }
    } else {
      inputData[`#${props.description}AddlUploadDoc`].push(tempDocList[0]);
    }

    store.dispatch(setInputValues(inputData));
    setAddlUploadNote('');

    setUploadPercentage(0);
  };

  const DeleteAddlDocfromS3 = async document => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const queryString = `?partyIdQuery=${getStarted.inputvalues['#writingAgentPartyId']}
&docTypeId=${document.documentTypeId}&appAlias=${getStarted.policyapplicationid}
&docFileKey=${document.documentFileKeyId}`;
    const [deletionError] = await MasterService.deleteDocument(queryString);
    if (deletionError !== null) {
      NotificationService.error(deletionError.data.message);
      return;
    }
    const additionalDocuments = [...inputData[`#${props.description}AddlUploadDoc`]];
    const index = additionalDocuments.indexOf(document);
    additionalDocuments.splice(index, 1);
    inputData[`#${props.description}AddlUploadDoc`] = additionalDocuments;
    store.dispatch(setInputValues(inputData));
    sessionStorage.setItem('eAppPolicyData', JSON.stringify(getStarted));
  };

  const DownloadAddlUploadedDoc = async doc => {
    if (Utils.isUserViewOnly()) {
      return;
    }

    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    const selectedDoc = response.data.find(record => record.documentTypeId === doc.documentTypeId);

    if (selectedDoc) {
      const [err, downloadedFile] = await MasterService.downloadDocument(
        getStarted.inputvalues['#writingAgentPartyId'],
        selectedDoc.id,
        getStarted.policyapplicationid,
        null,
      );

      if (err !== null) {
        NotificationService.error(err.data.message);
        return;
      }

      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, doc.fileName);
      }
    }
  };

  const documentAPICall = async () => {
    const tempDocuments = [];
    const [documentError, documentTypeData] = await MasterService.getDocumentTypes();
    if (documentError !== null) {
      NotificationService.error(documentError.data.message);
    }

    if (documentTypeData) {
      const addlDocumentType = getDocumnetDescription(props.description, props.IdCheckReason);
      addlDocumentType.forEach(doc => {
        const addlData = documentTypeData.data.filter(d => d.id === doc.id);
        if (addlData) tempDocuments.push({ id: doc.id, value: doc.description });
      });
    }
    setDocumentTypes(tempDocuments);
  };

  useAsyncEffect(documentAPICall, []);

  return (
    <React.Fragment>
      <Grid item xs={6} sm={6} md={5} lg={5}>
        <DropDown
          listItem={documentTypes}
          labelText={getLabelText(props.description, props.IdCheckReason)}
          name={`#${props.description}AddlDocTypes`}
          value={getStarted.inputvalues[`#${props.description}AddlDocTypes`]}
          onChange={event => props.onChange(event, documentTypes, props.description, 'D')}
          onBlur={event => props.onChange(event, documentTypes, props.description, 'D')}
          variant="outlined"
          isFullWidth
          labelClass={classes.labelBackground}
          isError={
            getStarted.inputerrors.additionalDocumentTypeName !== '' &&
            getStarted.inputerrors.additionalDocumentTypeName !== undefined
          }
          disabled={Utils.isUserViewOnly() || getStarted.inputvalues[`#${props.description}AddlUploadDoc`].length > 0}
          errorText={getStarted.inputerrors.additionalDocumentTypeName}
          className={classes.percentageStyle}
        />
      </Grid>
      <div className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12" />
      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridTextAlign}>
        <UploadButton
          name={`#${props.description}uploadbtn`}
          displayText="Upload"
          variant="contained"
          className={
            CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues[`#${props.description}AddlDocTypes`]) === ''
              ? classes.uploadDisabled
              : classes.openIcon
          }
          startIcon={<CloudUpload />}
          uploadFile={uploadAddlDoc}
          color="secondary"
          isDisabled={
            CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues[`#${props.description}AddlDocTypes`]) ===
              '' || Utils.isUserViewOnly()
          }
        />
      </Grid>
      {getStarted.inputvalues[`#${props.description}AddlUploadDoc`].length > 0 &&
        uploadPercentage === 0 &&
        getStarted.inputvalues[`#${props.description}AddlUploadDoc`].map(item => {
          return (
            <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridTextAlign}>
              <Typography
                variant="body1"
                className={classes.uploadName}
                component={Link}
                onClick={() => DownloadAddlUploadedDoc(item)}
              >
                {item.fileName}
              </Typography>
              <CheckCircle name={props.name} className={classes.checkIcon} />
              <Cancel name={props.name} className={classes.cancelIcon} onClick={() => DeleteAddlDocfromS3(item)} />
            </Grid>
          );
        })}
      {getStarted.inputvalues[`#${props.description}AddlUploadDoc`].length === 0 && uploadPercentage === 0 && (
        <Grid item xs={12} sm={10} className={classes.marginTp} />
      )}
      {uploadPercentage > 0 && (
        <Grid item xs={12} sm={10} className={classes.marginTp}>
          <LinearProgress
            name="linearProgress"
            variant="determinate"
            value={progress}
            className={classes.linerProg}
            classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
          />
        </Grid>
      )}
      {CommonFunctions.checkIsNullOrUndefined(AddlUploadNoteText) !== '' && (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.docErrorSection}>
            {AddlUploadNoteText}
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
}

AddlUploadDoc.propTypes = {
  docRoleName: PropTypes.string,
  description: PropTypes.string,
  IdCheckReason: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
