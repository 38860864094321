import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, IconButton } from '@material-ui/core';
import { Edit, Delete, CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import DeleteModal from 'components/deleteModal';
import Utils from 'views/eapp/renderforms/utils';
import store from 'redux/store';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function SummaryCard(props) {
  const state = store.getState();
  const [editVisible, setEditVisible] = useState(false);
  const inputData = state.getStarted.inputvalues;
  const summaryPrefix = props.group.fieldProperty.summaryPrefix.replace(/[0-9#]/g, '');
  const currentPrefix = props.group.fieldProperty.summaryPrefix.replace(/[#]/g, '');
  const modifyInProgress = inputData['#modifyInProgress'] ? inputData['#modifyInProgress'].toLowerCase() : '';
  const summaryDisabled = currentPrefix === modifyInProgress;
  const fundSummaryText = inputData[`${props.group.fieldProperty.summaryPrefix}SummaryText`];
  const accountNumber = inputData[`${props.group.fieldProperty.summaryPrefix}AccountNumber`];
  const policyNumber = inputData[`${props.group.fieldProperty.summaryPrefix}PolicyNumber`];
  let amount = inputData[`${props.group.fieldProperty.summaryPrefix}Amount`];
  amount = !!amount && `$${amount.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
  const isReviewComplete = inputData['#isReviewComplete'] || false;
  const classes = useStyles();

  const noModalClose = () => {
    setEditVisible(false);
  };

  const yesModalClose = () => {
    setEditVisible(false);
  };

  const callBackModal = () => {
    setEditVisible(true);
  };

  return (
    <Box
      className={classNames(classes.root, summaryDisabled ? classes.editModeborder : classes.border)}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <DeleteModal
        visible={editVisible}
        mainText="Are you sure you want to delete this fund?"
        onNoClose={noModalClose}
        onYesClose={event => {
          yesModalClose();
          props.onChange(event, props.group.fundSummaryBoxRemove, 'button', props.group.fieldName);
        }}
      />
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={2}>
          <IconButton aria-label="edit" name={props.group.fieldName} className={classes.iconStyle}>
            <CheckCircle fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h5" className={summaryDisabled ? classes.editModeaccountNo : classes.accountNo}>
            {fundSummaryText} {`- `}
            {summaryPrefix === 'eft' ? accountNumber : policyNumber}
            <br />
            <span>{amount !== undefined && amount}</span>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            onClick={event => props.onChange(event, props.group.fundSummaryBoxEdit, 'button', props.group.fieldName)}
            value={props.group.fieldProperty.labelText}
            name={props.group.fieldName}
            className={summaryDisabled ? classes.displayNone : classes.editIcon}
            disabled={Utils.isUserViewOnly() || isReviewComplete}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            onClick={callBackModal}
            value={props.group.fieldProperty.labelText}
            name={props.group.fieldName}
            className={summaryDisabled ? classes.displayNone : classes.paddingIcon}
            disabled={Utils.isUserViewOnly() || isReviewComplete}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

SummaryCard.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};
