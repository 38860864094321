export default theme => ({
  root: {
    display: 'flex',
    height: '64px',
    '@media (max-width:600px)': {
      height: '56px',
    },
  },
  title: {
    color: 'white !important',
  },
  appBar: {
    background: 'linear-gradient(90deg, rgba(50,68,93,1) 0%, rgba(94,116,148,1) 80%)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - 240px)`,
    marginLeft: '240px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '240px',
  },
  drawerPaper: {
    width: '240px',
    backgroundColor: '#14043C',
  },
  iconWhite: {
    color: '#fff',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  displayNone: {
    display: 'none',
  },
  textAlign: {
    textAlign: 'center',
  },
  box: {
    minHeight: '100vh',
  },
  iconLogo: {
    display: 'block',
    cursor: 'pointer',
    margin: '0 auto 0',
    width: '100px',
  },
  logoutButton: {
    margin: '0.3rem 1.5rem',
    border: 'none',
    width: 'calc(100% - 3rem)',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '24px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#8A829E',
    },
  },
  dividerStyle: {
    marginRight: '18px',
    marginLeft: '18px',
    backgroundColor: '#847c98',
  },
  legalRoot: {
    margin: '1rem',
    textAlign: 'center',
    color: 'white',
  },
  icon: {
    color: 'white',
  },
  iconStyle: {
    fontSize: '24px',
  },
  link: {
    margin: '0.375rem 0.5rem',
    cursor: 'pointer',
    textDecoration: 'underline',

    '@media (min-width:960px)': {
      color: 'white',
    },
  },
  linkStyle: {
    padding: '12px !important',
    textDecoration: 'none !important',
    fontSize: '25px !important',
    color: '#FFFFFF !important',
    display: 'block !important',
  },
});
