export default {
  gridAlignRightExitButton: {
    textAlign: 'right',
  },
  gridAlignLeftNextButton: {
    textAlign: 'left',
  },
  gridAlignCenterNextButton: {
    textAlign: 'center',
  },
  labelMarginTop: {
    marginTop: '2em',
  },
  typoFont: {
    fontSize: '1rem',
    color: '#4D4D4D !important',
    fontWeight: '600',
    margin: '.5rem',
  },
  createTxt: {
    color: '#EF5350',
    fontWeight: '700',
    fontSize: '.9rem',
    marginTop: '.9rem',
    cursor: 'pointer',
  },
  navigateIcon: {
    color: '#EF5350 !important',
    fontSize: '.856rem',
    cursor: 'pointer',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  nextIcon: {
    marginBottom: '-7px',
  },
  alert: {
    width: '100%',
    marginTop: '.8rem',
    color: '#fff',
    backgroundColor: '#3B4F6B',
    '& .MuiAlert-icon': {
      color: '#fff',
    },
  },
  alertButton: {
    fontWeight: 'bold',
  },
};
