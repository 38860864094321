import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncEffect } from 'use-async-effect';
import { navigate, useRoutes } from 'hookrouter';
import { AnalyticsBanner } from '@aspida/react-components';
import constants from 'constants/common';
import masterService from 'api/masterService';
import logger from 'logger';
import routes from 'routes';
import './app.css';
import { setFlags } from 'redux/appConfig/action';
import AlertDialog from 'components/alertDialog';

window.jsonToQueryString = json => {
  return `?${Object.keys(json)
    .map(key => {
      return `${key}=${json[key]}`;
    })
    .join('&')}`;
};

function App() {
  const user = useSelector(state => state.user);
  const routeResult = useRoutes(routes);
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    try {
      const remotesign = window.location.href.includes('/remotesign');
      if (remotesign) {
        setConnected(true);
        navigate('/getStarted');
        navigate('/remotesign');
        return;
      }

      const [err] = await masterService.getCurrentUser();
      if (err) {
        navigate('/signout');
      }
      setConnected(true);
      dispatch(setFlags());
      navigate(window.location.pathname, dispatch);
    } catch (e) {
      logger.error(e);
      setConnected(true);
    }
    return routeResult;
  }, []);

  const updateAnalyticsDecision = async decision => {
    const analyticsCollectionId = constants.analyticsCollection[decision ? 'accepted' : 'optOut'];
    const updatedUser = { analyticsCollectionId };
    await masterService.updatePerson(user.id, updatedUser);
  };

  return (
    <div>
      <AlertDialog />
      {connected && (routeResult || <p> Page Not Found </p>)}
      <AnalyticsBanner collect={user && user.analyticsCollectionId} onSelect={updateAnalyticsDecision} />
    </div>
  );
}

export default App;
