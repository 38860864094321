/* Address Validation Modal */

export const uspsToString = usps => {
  const label =
    `${usps.AddressValidateResponse.Address1 ?? ''} ` +
    `${usps.AddressValidateResponse.Address2 ?? ''} ` +
    `${usps.AddressValidateResponse.City ?? ''} ` +
    `${usps.AddressValidateResponse.State ?? ''} ` +
    `${usps.AddressValidateResponse.Zip5 ?? ''}`;
  return label;
};

export const googleToString = google => {
  return `${google.address1} ${google.address2} ${google.city} ${google.state} ${google.postalCode}`;
};

export const defaultUspsAddress = {
  AddressValidateResponse: {
    Address2: '',
  },
};

export const AddressTypes = {
  user: 'user',
  usps: 'usps',
};

export const emptyAddressFormData = {
  id: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  addressType: '',
  addressTypeId: '',
};

export const addressTypeLabels = ['Business', 'Residential', 'Mailing'];
