export default {
  label: {
    fontWeight: 600,
    color: '#4D4D4D',
    fontSize: '12px',
  },
  colorPrimary: {
    backgroundColor: '#b6d596',
  },
  barColorPrimary: {
    backgroundColor: '#6EAB2E',
  },
  uploadGrid: {
    marginTop: '0.5rem',
  },
  uploadNameGrid: {
    marginTop: '0.8rem',
  },
  addMoreTop: {
    marginTop: '1rem',
  },
  linerProg: {
    marginTop: '0.75rem',
  },
  displayBlock: {
    display: 'block',
  },
  displayNone: {
    display: 'none',
  },
  uploadName: {
    margin: '1rem',
    color: '#14043C',
    fontSize: '18px',
    '@media (max-width: 800px)': {
      fontSize: '12px',
    },
    cursor: 'pointer',
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  checkIcon: {
    width: '1.6rem',
    color: '#92D050',
    marginBottom: '-0.375rem',
  },
  cancelIcon: {
    width: '1.6rem',
    color: '#D1D1D1',
    cursor: 'pointer',
    marginBottom: '-0.375rem',
  },
  uploadIcon: {
    cursor: 'pointer',
    marginRight: '0.275rem',
  },
  uploadDocIcon: {
    cursor: 'pointer',
    marginTop: '1rem',
    marginRight: '0.175rem',
  },
  fileFormat: {
    marginLeft: '-5rem',
  },
  uploadColor: {
    color: '#0084BC',
  },
  upStyle: {
    marginTop: '-.2rem',
    marginLeft: '-1.4rem',
    color: '#0084BC',
    textDecoration: 'underline',
  },
  upDocStyle: {
    marginTop: '.9rem',
    marginLeft: '-1.4rem',
    color: '#0084BC',
    textDecoration: 'underline',
  },
  marginTp: {
    marginTop: '1.6rem',
  },
  dividerStyle: {
    marginLeft: '-0.625rem',
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
  alignLeft: {
    marginLeft: '.1rem',
  },
  paperHeader: {
    padding: '0.475rem',
    opacity: 1,
    borderBottom: '0',
  },
  dropdwn: {
    marginLeft: '0.475rem',
  },
  labelTop: {
    color: '#8695b1 !important',
    fontFamily: 'Muli',
    fontSize: '14px',
    overflowWrap: 'break-word',
    marginLeft: '0.475rem',
  },
  valueTop: {
    color: '#4D4D4D !important',
    marginLeft: '1rem',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontWeight: '500',
    overflowWrap: 'break-word',
  },
  docErrorSection: {
    color: '#f44336 !important',
    marginLeft: '.8rem',
  },
  Button: {
    fontWeight: 600,
  },
  headStyle: {
    fontWeight: 600,
    marginTop: '1rem',
  },
  fileNameStyle: {
    textDecoration: 'underline',
    cursor: 'pointer',
    overflowWrap: 'break-word',
    fontWeight: 400,
  },
  alert: {
    width: '100%',
    marginBottom: '1rem',
    fontSize: 'medium',
    color: '#fff',
    backgroundColor: '#3B4F6B',
    '& .MuiAlert-icon': {
      color: '#fff',
    },
  },
  fileLabelStyle: {
    fontWeight: 600,
    fontSize: '1.1rem',
  },
  paperGrid: {
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
  },
  paperBodyDoc: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F8FAFC',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
    borderBottom: '0',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    marginTop: '0.5rem',
  },
  uploadBtn: {
    width: '100%',
  },
  percentageStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  openIcon: {
    width: '100%',
  },
  uploadBtnDisabled: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#ABABAB !important',
    color: '#464646 !important',
  },
  uploadDisabled: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#ABABAB !important',
    color: '#464646 !important',
  },
  labelBackground: {
    fontWeight: 600,
  },
  paperHeaderGrid: {
    padding: '1rem',
    opacity: 1,
    borderBottom: '0',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    backgroundColor: '#F2F4F7',
  },
  paperBody: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F8FAFC',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
  },
  loaPaperBody: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F8FAFC',
    borderRadius: '24px',
  },
  downloadLabel: {
    fontWeight: 600,
    color: '#0A0A0A',
    fontSize: '22px',
  },
  gridTextAlign: {
    textAlign: 'right',
  },
  marginBtn: {
    marginBottom: '0.5rem',
  },
};
