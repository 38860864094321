export default {
  label: {
    paddingBottom: '1rem',
    fontSize: '1.3rem',
    fontWeight: '400',
  },
  npnLabel: {
    fontSize: '1rem',
    fontWeight: '600',
    marginBottom: '.5rem',
  },
  question: {
    margin: '2rem',
  },
  appType: {
    fontSize: '1.3rem',
    fontWeight: '400',
  },
  appTypeBtn: {
    margin: '.5rem',
  },
  lets: {
    color: '#4D4D4D',
    fontFamily: 'Playfair Display',
    fontSize: '43px',
    fontWeight: '900',
    '@media (max-width: 800px)': {
      fontSize: '29px',
    },
  },
  activeButton: {
    height: '62px',
    width: '246.33px',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
    '&:hover': {
      backgroundColor: '#D0CADC !important',
    },
  },
  activeTrustButton: {
    height: '62px',
    width: '206px',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
    '&:hover': {
      backgroundColor: '#D0CADC !important',
    },
  },
  appBtnStyle: {
    height: '62px',
    width: '246px',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
  },
  appBtnTrustStyle: {
    height: '62px',
    width: '206px',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'inherit',
    '@media (max-width: 1024px)': {
      width: '100%',
      fontSize: '15px',
    },
  },
  gridStyle: {
    height: '161px',
    width: '857px',
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
    margin: '0.5rem',
  },
  paperBody: {
    padding: '1rem',
  },
  paperGrid: {
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    boxShadow: '0 2px 7px 0 #ABABAB',
    marginTop: '1rem',
  },
  aspida: {
    color: '#4D4D4D',
    fontFamily: 'Playfair Display',
    fontSize: '27px',
    fontWeight: '900',
    '@media (max-width: 800px)': {
      fontSize: '20px',
    },
  },
  options: {
    marginTop: '-1rem',
  },
  rowButton: {
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  alert: {
    width: '100%',
    marginBottom: '1rem',
    color: '#fff',
    backgroundColor: '#3B4F6B',
    '& .MuiAlert-icon': {
      color: '#fff',
    },
  },
  dropStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '@media (min-width:125rem)': {
      width: '50%',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  percentageStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #14043C !important',
      boxSizing: 'border-box',
      height: '49px',
      borderRadius: '4px',
    },
    '@media (min-width:125rem)': {
      width: '50%',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  percentageStyleNew: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #14043C !important',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
    '@media (min-width:125rem)': {
      width: '50%',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  labelBackgroundColor: {
    backgroundColor: '#F2F4F7',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
  deleteButton: {
    height: '51px',
    width: '14px',
    color: '#000000',
  },
  tooltipStyle: {
    marginBottom: '-4px',
    marginLeft: '9px',
  },
  dividerStyle: {
    borderWidth: '1px',
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
  labelTop: {
    fontWeight: '500',
    overflowWrap: 'break-word',
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    fontSize: '12px',
    lineHeight: '24px',
  },
  valueTop: {
    color: '#4D4D4D',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    overflowWrap: 'break-word',
  },
  link: {
    color: '#EF426F',
    fontWeight: '700',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  totalText: {
    fontWeight: 'bold',
  },
  docErrorSection: {
    color: '#f44336 !important',
    marginLeft: '0.9rem',
    fontSize: '1rem',
  },
  totalError: {
    marginLeft: '1rem',
    color: '#f44336 !important',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  totalErrorGrid: {
    maxWidth: '11%',
    borderRadius: '12px',
    backgroundColor: '#497915',
  },
  totalErrorPercent: {
    borderRadius: '12px',
    color: '#fff !important',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#497915',
  },
  totalErrorPercentGrid: {
    textAlign: 'center',
    borderRadius: '12px',
    backgroundColor: '#497915',
  },
  margin: {
    marginTop: '1rem',
    marginBottom: '.5rem',
  },
  starticonstyle: {
    fontSize: '26px',
  },
};
