import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import CommonFunctions from '../../utils/commonFunctions';
import Mailsign from './mailSign';
import Wetsign from './wetSign';
import Esign from './eSign';
import styles from './style';

const useStyles = makeStyles(styles);

export default function TransferDocs() {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const companySignData = CommonFunctions.getCompanySignData();

  function getTransferDocs(companyData, IndexNumber) {
    return (() => {
      switch (companyData.coSignType) {
        case 'Mail':
          return <Mailsign companyData={companyData} indexNumber={companySignData.length > 0 ? IndexNumber + 1 : ''} />;
        case 'WetMail':
          return <Wetsign companyData={companyData} indexNumber={companySignData.length > 0 ? IndexNumber + 1 : ''} />;
        case 'Fax':
        case 'Email':
          return <Esign companyData={companyData} indexNumber={IndexNumber + 1} />;
        default:
          return '';
      }
    })();
  }
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" gutterBottom className={classes.titleStyle}>
            Transfer Paper Documents
          </Typography>
          <Typography variant="subtitle1" gutterBottom className={classes.lableColor}>
            Please note that some transfer companies allow signatures to be signed electronically. This will occur on
            the Sign page, but you will need to upload related forms on this page.
          </Typography>
        </Grid>
        {companySignData.map((companyData, index) => {
          return getTransferDocs(companyData, index);
        })}
        {CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.replacementComparision) !== '' && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom className={classes.docErrorSection}>
              Upload Transfer Document is Required
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
