import React from 'react';
import './app.css';
import { makeStyles, Hidden, Grid, Typography, Paper } from '@material-ui/core';
import SideNav from 'components/sideNav';
import TabletMenu from 'components/tabletMenu';
import compTablet from 'assets/images/compTablet.svg';
import standardLogo from 'assets/images/standard-logo.svg';
import styles from 'views/eapp/styles';
import App from './app';

const useStyles = makeStyles(styles);

function AppWrapper() {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);
  return (
    <div>
      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        <TabletMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
      </Hidden>
      <div className="app">
        <Hidden only={['xs', 'sm']}>
          <div className="menu">
            <SideNav />
          </div>
        </Hidden>
        <Hidden only="xs">
          <div id="divContainer" className="container">
            <div className="innerContainer">{<App /> || <p>Page Not Found </p>}</div>
          </div>
        </Hidden>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <div className={classes.divWidth}>
            <Grid container>
              <Grid item xs={12} className={classes.aspidaIconStyle}>
                <img src={standardLogo} alt="logo" />
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paperGridNotification} elevation={0}>
                  <Grid container className={classes.paperBodyNotification} spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom className={classes.TitleNotification}>
                        The eApp is currently not available on mobile devices. Please use a tablet or desktop browser to
                        continue.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <img src={compTablet} alt="logo" className={classes.aspidaIcon} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom className={classes.subTitleNotification}>
                        Thank You!
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Hidden>
      </div>
    </div>
  );
}

export default AppWrapper;
