import _ from 'lodash';
import moment from 'moment';

const fnConditionConfig = {
  equal: 'fnEqual',
  notequal: 'fnNotEqual',
  ssnregex: 'fnSSNRegex',
  tinregex: 'fnTINRegex',
  specialCharRegex: 'fnSpecialCharRegex',
  emailregex: 'fnEmailRegex',
  phoneregex: 'fnPhoneRegex',
  lessthan: 'fnLessThan',
  lessthanequal: 'fnLessThanEqual',
  greaterthan: 'fnGreaterThan',
  greaterthanequal: 'fnGreaterThanEqual',
  stringnotequal: 'fnStringNotEqual',
  stringequal: 'fnStringequal',
  greaterThanDt: 'fnGreaterThanDt',
  greaterthandtequal: 'fnGreaterthandtequal',
  intLen: 'fnIntLen',
};

const checkIsNuil = (value, appResponse) => {
  let checkValue = value;
  if (_.isNil(checkValue)) {
    return '';
  }

  if (String(checkValue).indexOf('#') === 0) {
    checkValue = appResponse[checkValue];
    if (_.isNil(checkValue)) {
      return '';
    }
  }

  return checkValue;
};

const fnEqual = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData) === checkIsNuil(rightVariable[0], appData)) {
    return true;
  }

  return false;
};

const fnNotEqual = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData) !== checkIsNuil(rightVariable[0], appData)) {
    return true;
  }

  return false;
};

const fnSSNRegex = (appData, leftVariable) => {
  const ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/;

  return !ssnRegex.test(this.checkIsNuil(leftVariable, appData));
};

const fnSpecialCharRegex = (appData, leftVariable) => {
  const specialCharRegex = /^[a-zA-Z0-9#-\s]+$/;
  return !specialCharRegex.test(this.checkIsNuil(leftVariable, appData));
};

const fnTINRegex = (appData, leftVariable) => {
  const tinRegex = /^\d{2}-?\d{7}$/;
  return !tinRegex.test(this.checkIsNuil(leftVariable, appData));
};

const fnEmailRegex = (appData, leftVariable) => {
  // eslint-disable-next-line no-control-regex, max-len
  const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return !emailRegex.test(this.checkIsNuil(leftVariable, appData));
};

const fnPhoneRegex = (appData, leftVariable) => {
  const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  return !phoneRegex.test(this.checkIsNuil(leftVariable, appData));
};

const fnLessThan = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData) < checkIsNuil(rightVariable[0], appData)) {
    return true;
  }

  return false;
};

const fnLessThanEqual = (appData, leftVariable, rightVariable) => {
  if (this.checkIsNuil(leftVariable, appData) <= this.checkIsNuil(rightVariable[0], appData)) {
    return true;
  }

  return false;
};

const fnGreaterThan = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData) > checkIsNuil(rightVariable[0], appData)) {
    return true;
  }

  return false;
};

const fnGreaterThanEqual = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData) >= checkIsNuil(rightVariable[0], appData)) {
    return true;
  }

  return false;
};

const fnIntLenCondition = (appData, leftVariable, rightVariable) => {
  if (this.checkIsNuil(leftVariable, appData) === '') {
    return false;
  }

  const resLength = Math.floor(Math.log10(Number(leftVariable))) + 1;
  const totalLength = rightVariable[0];
  return Number(resLength) === Number(totalLength);
};

const fnStringNotEqual = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData).toLowerCase() !== checkIsNuil(rightVariable[0], appData).toLowerCase()) {
    return true;
  }
  return false;
};

const fnStringequal = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData).toLowerCase() === checkIsNuil(rightVariable[0], appData).toLowerCase()) {
    return true;
  }
  return false;
};

const fnGreaterThanDt = (appData, leftVariable, rightVariable) => {
  return moment(this.checkIsNuil(leftVariable, appData), ['MM/DD/YYYY', 'YYYY-MM-DD']).isAfter(
    this.checkIsNuil(rightVariable[0], appData),
  );
};

const fnGreaterThanDtEqual = (appData, leftVariable, rightVariable) => {
  return moment(this.checkIsNuil(leftVariable, appData), ['MM/DD/YYYY', 'YYYY-MM-DD']).isSameOrAfter(
    this.checkIsNuil(rightVariable[0], appData),
  );
};

const fnIntLen = (appData, leftVariable, rightVariable) => {
  if (checkIsNuil(leftVariable, appData) === '') {
    return false;
  }

  const resLength = Math.floor(Math.log10(Number(leftVariable))) + 1;
  const totalLength = rightVariable[0];
  return Number(resLength) > Number(totalLength);
};

export default {
  fnConditionConfig,
  fnEqual,
  fnNotEqual,
  fnSSNRegex,
  fnSpecialCharRegex,
  fnTINRegex,
  fnEmailRegex,
  fnPhoneRegex,
  fnLessThan,
  fnGreaterThan,
  fnLessThanEqual,
  fnGreaterThanEqual,
  fnIntLenCondition,
  fnStringNotEqual,
  fnStringequal,
  fnGreaterThanDt,
  fnGreaterThanDtEqual,
  fnIntLen,
};
