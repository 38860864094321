import React from 'react';
import { Grid, Box, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DesktopCheckmarks from 'assets/images/desktop_checkmarks.svg';
import CommonFunctions from 'views/eapp/utils/commonFunctions';

import styles from './style';

const useStyles = makeStyles(styles);

export default function Header() {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Grid container className={classes.esignComplete}>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} className={classes.titleSuccessHead}>
                <img src={DesktopCheckmarks} alt="logo" className={classes.logo} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.titleSuccessHead}>
                  Thank you
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.agent}>
                  You're all done!
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.btnstyle}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={CommonFunctions.handleExitClick}
                  className={classes.createNewButton}
                >
                  CREATE NEW APPLICATION
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.btnstyle}>
                <Button
                  size="large"
                  variant="contained"
                  href={`${process.env.REACT_APP_CUSTOMERS_URL}`}
                  className={classes.returnToPortal}
                >
                  RETURN TO PORTAL
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
