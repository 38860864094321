import React from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import styles from './style';

const useStyles = makeStyles(styles);

const ScStateModal = ({ visible, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={visible} aria-labelledby="form-dialog-title">
      <IconButton aria-label="close" title="Close" className={classes.modalClear} onClick={() => onClose(true)}>
        <ClearIcon />
      </IconButton>
      <DialogContent className={classes.modal}>
        <Typography variant="body1" className={classes.modalText}>
          By starting this application, I understand that the application may only be submitted electronically and that
          all future communication from Aspida Life Insurance Company regarding this application and the issued contract
          will be done electronically.
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          className={classes.modalButton}
          onClick={() => onClose(false)}
        >
          I understand
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default ScStateModal;
ScStateModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};
