import React from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import styles from './styles';

const useStyles = makeStyles(styles);

const CopyConfirmationModal = ({ visible, applicationAlias, onYesClose, onNoClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={visible} aria-labelledby="form-dialog-title">
      <IconButton aria-label="close" title="Close" className={classes.modalClear} onClick={onNoClose}>
        <ClearIcon />
      </IconButton>
      <DialogContent className={classes.modal}>
        <Typography variant="h4" className={classes.modalTitle}>
          Are you sure?
        </Typography>
        <Typography variant="body2" className={classes.modalText}>
          You are about to <b>copy</b> information from application <b>{applicationAlias}</b>.
          <br />
          Do you wish to continue?
        </Typography>
        <Button color="secondary" variant="contained" size="large" className={classes.modalButton} onClick={onYesClose}>
          Yes, Continue
        </Button>
        <Button color="secondary" variant="outlined" size="large" className={classes.modalButton} onClick={onNoClose}>
          No, Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default CopyConfirmationModal;
CopyConfirmationModal.propTypes = {
  onNoClose: PropTypes.func,
  onYesClose: PropTypes.func,
  applicationAlias: PropTypes.string,
  visible: PropTypes.bool,
};
