import React, { useState } from 'react';
import { Paper, Button, Grid, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { DropDown } from '@aspida/react-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import constants from 'constants/common';
import ScStateModal from 'components/scStateModal';
import CommonFunctions from '../utils/commonFunctions';
import Utils from '../renderforms/utils';
import ProductRates from './productRates';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ProductPicker(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const [editVisible, setEditVisible] = useState(false);

  const onClose = isModal => {
    setEditVisible(isModal);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {constants.consentFormStates.includes(getStarted.signedstateabb) && (
          <ScStateModal visible={editVisible} onClose={onClose} />
        )}
        {getStarted.policyapplicationid !== '' && (
          <Alert
            severity="error"
            className={classes.alert}
            action={
              <Button
                color="inherit"
                size="small"
                className={classes.button}
                endIcon={<ChevronRight />}
                onClick={CommonFunctions.handleExitClick}
              >
                Create New Application
              </Button>
            }
          >
            Some items on this page cannot be changed. If an adjustment needs to be made, then a new application will
            need to be created.
          </Alert>
        )}
        {getStarted.policyapplicationid === '' && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="body1" component="span" className={classes.aspida}>
                Aspida eApplication
              </Typography>
              <br />
              <Typography variant="body1" component="span" className={classes.lets}>
                Let's Get Started...
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {props.ProductRates.length > 0 && (
          <Grid item xs={12}>
            <Paper className={classes.productRatePaper} square elevation={3}>
              <Grid container className={classes.gridContainer} spacing={3} alignItems="center">
                <ProductRates rates={props.ProductRates} />
              </Grid>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>Product
                </Typography>
                <DropDown
                  listItem={props.Products}
                  name="drpProducts"
                  value={getStarted.product}
                  onChange={event => props.onChange(event, 'P', undefined, undefined)}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.product) !== ''}
                  errorText={getStarted.inputerrors.product}
                  labelText="Product"
                  variant="outlined"
                  isFullWidth
                  disabled={getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()}
                  className={classes.dropStyle}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} square elevation={3}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} md={6} lg={6} className={classes.clickhere}>
                <Typography variant="h5" gutterBottom className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>
                  Owners Signed State
                </Typography>
                <DropDown
                  listItem={props.SignedStates}
                  name="drpSignedState"
                  value={getStarted.signedstate}
                  onChange={event => props.onChange(event, 'S', undefined, undefined)}
                  onBlur={() => setEditVisible(constants.consentFormStates.includes(getStarted.signedstateabb))}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.signedstate) !== ''}
                  errorText={getStarted.inputerrors.signedstate}
                  labelText="Select"
                  variant="outlined"
                  isFullWidth
                  disabled={
                    getStarted.product === '' || getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()
                  }
                  className={classes.dropStyle}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h5" gutterBottom className={classes.label}>
                  <span style={{ color: 'red' }}>*</span>
                  State of Residence
                </Typography>
                <DropDown
                  listItem={props.SignedStates}
                  name="drpResidenceState"
                  value={getStarted.inputvalues['#residentStateId']}
                  onChange={event => props.onChange(event, 'R', '#residentState', undefined)}
                  isError={CommonFunctions.checkIsNullOrUndefined(getStarted.inputerrors.residentState) !== ''}
                  errorText={getStarted.inputerrors.residentState}
                  labelText="Select"
                  variant="outlined"
                  isFullWidth
                  disabled={
                    getStarted.product === '' || getStarted.policyapplicationid !== '' || Utils.isUserViewOnly()
                  }
                  className={classes.dropStyle}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2} className={classes.rowButton}>
        <Grid item xs={12} md={6} lg={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={
              CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isReviewComplete']) === true ||
              Utils.isUserViewOnly()
            }
            onClick={props.onClick}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ProductPicker.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  SignedStates: PropTypes.array,
  Products: PropTypes.array,
  ProductRates: PropTypes.array,
};
