import React, { useState } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import store from 'redux/store';
import { setReset, setLoading, setInputValues } from 'redux/getstarted/actions';
import { setAuthenticatedUser } from 'redux/user/actions';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import { navigate } from 'hookrouter';
import PersonIcon from '@material-ui/icons/Person';
import masterService from 'api/masterService';
import BrowserHelper from 'helpers/browserHelper';
import NotificationService from 'services/notificationService';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import EditModal from 'components/editModal';
import ProgressBar from 'components/progressBar';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function Header() {
  const classes = useStyles();
  const state = store.getState();
  const [editModalVisible, setModalEditVisible] = useState(false);
  const annuityType = CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#annuityType']);
  const writingAgent = CommonFunctions.checkIsNullOrUndefined(`${state.user.firstName} ${state.user.lastName}`);
  const ownerName = `${CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#annuitantFN'])}
  ${CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#annuitantMN'])}
  ${CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#annuitantLN'])}
  ${CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#annuitantSF'])}`;
  const plannedValue = CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#plannedValueString']);
  const creditedRates = CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#creditedRates']);
  const hasDtcCustomer = state.getStarted.inputvalues['#isDtcCustomer'];

  const noEditModalClose = () => {
    setModalEditVisible(false);
  };

  const yesEditModalClose = async () => {
    setModalEditVisible(false);
    const userObj = state.user;
    const inputData = { ...state.getStarted.inputvalues };
    store.dispatch(setInputValues({ ...state.getStarted.inputvalues, ...inputData }));
    store.dispatch(setLoading(true));
    const [appDataError] = await masterService.editApplication(state.getStarted.policyapplicationid);
    if (appDataError !== null) {
      NotificationService.error(appDataError.data.message);
    }
    const message = `Agent Clicked Edit button inbetween filling app in
    ${BrowserHelper.getTabName(
      state.getStarted,
      state.getStarted.currentpage.Group,
      state.getStarted.currentpage.SubGroup,
    )}`;
    BrowserHelper.saveBrowserInfo(state.getStarted.inputvalues['#applicationAliasId'], message);
    store.dispatch(setLoading(false));
    let isViewOnly = false;
    const viewOnlyRole = RoleHelper.userHasRoles(userObj, ROLES.VIEWONLY);
    if (viewOnlyRole) {
      isViewOnly = true;
    }
    let isDtcCustomer = false;
    if (RoleHelper.userHasRoles(userObj, ROLES.DTCCUCTOMER)) {
      isDtcCustomer = true;
    }
    store.dispatch(setAuthenticatedUser({ ...userObj, verified: userObj.verified, isViewOnly, isDtcCustomer }));
    store.dispatch(setReset(state.getStarted));
    sessionStorage.removeItem('eAppPolicyData');
    navigate('/getStarted');
    navigate(`/applications/${state.getStarted.policyapplicationid}`);
  };
  return (
    <Box>
      <EditModal editVisible={editModalVisible} onNoEditClose={noEditModalClose} onYesEditClose={yesEditModalClose} />
      <Box className={classes.header}>
        {state.getStarted.IsSubmitted !== 4 ? (
          <>
            <Grid item xs={12} sm={6} md={2} lg={3} className={classes.appNumberClient}>
              <Typography variant="body2" className={classes.appNumberTxt} gutterBottom>
                Application Number
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.appNumber}>
                {state.getStarted.policyapplicationid ? `#${state.getStarted.policyapplicationid}` : '----'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={4} className={classes.appNumberGrid}>
              <Typography variant="body2" className={classes.appNumberTxt} gutterBottom>
                Product
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.appNumber}>
                {state.getStarted.productname ? `${state.getStarted.productname}` : '----'}
              </Typography>
            </Grid>
            {hasDtcCustomer && (
              <Grid item xs={12} sm={6} md={2} lg={2} className={classes.appNumberGrid}>
                <Typography variant="body2" className={classes.appNumberTxt} gutterBottom>
                  Credited Rate
                </Typography>
                <Typography variant="body1" gutterBottom className={classes.appNumber}>
                  {creditedRates ? `${creditedRates}` : '----'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2} lg={2} className={classes.appNumberGrid}>
              <Typography variant="body2" className={classes.appNumberTxt} gutterBottom>
                Plan Type
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.appNumber}>
                {annuityType ? `${annuityType}` : '----'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} className={classes.appNumberGrid}>
              <Typography variant="body2" className={classes.appNumberTxt} gutterBottom>
                Amount
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.appNumber}>
                {plannedValue ? `${plannedValue}` : '----'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={3} className={classes.appNumberNoGrid}>
              <Typography variant="body2" className={classes.appNumberTxt} style={{ marginTop: '0.5rem' }} gutterBottom>
                Client
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.appNumber} style={{ marginBottom: '0rem' }}>
                {ownerName && ownerName.trim() ? `${ownerName}` : '----'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={5}
              className={writingAgent ? classes.gridAlignCenterExitButton : classes.displayNone}
            >
              <Button
                variant="outlined"
                name="editEappBtn"
                className={classes.editBtn}
                startIcon={<PersonIcon className={classes.personIcon} />}
              >
                {writingAgent}
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={classes.eSignCompleteName}>
            <Button
              variant="outlined"
              name="editEappBtn"
              className={classes.editBtn}
              startIcon={<PersonIcon className={classes.personIcon} />}
            >
              {writingAgent}
            </Button>
          </Grid>
        )}
      </Box>
      <Box className={classes.progressStyle}>
        <ProgressBar />
      </Box>
    </Box>
  );
}
