import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import styles from '../styles';

const useStyles = makeStyles(styles);
export default function ProductRate({ rates }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.prHeader}>
          <TableRow>
            <TableCell className={classes.prHeading}>Guaranteed Period</TableCell>
            <TableCell className={classes.prHeading}>Credited Rates</TableCell>
            <TableCell className={classes.prHeading}>Sample Premium</TableCell>
            <TableCell className={classes.prHeading}>Projected Balance*</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rates.map(row => (
            <TableRow key={row.productId}>
              <TableCell>{row.duration}</TableCell>
              <TableCell className={classes.prItem}>{row.creditedRates}</TableCell>
              <TableCell>{row.samplePremium}</TableCell>
              <TableCell>{row.endingBalance}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className={classes.prFooter} colSpan={4}>
              *The Projected Balance amounts assume no withdrawals were taken in the initial guaranteed interest rate
              period of the contract. Any withdrawals taken will reduce the amount of Interest Earned and the Projected
              Balance.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ProductRate.propTypes = {
  rates: PropTypes.array,
};
