import React from 'react';
import { makeStyles, Button, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CheckBox } from '@aspida/react-components';

import ChkBoxConstantVal from 'constants/ChkBoxConstVal';
import Utils from 'views/eapp/renderforms/utils';
import styles from './style';

const useStyles = makeStyles(styles);

export default function OwnerKBA(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom className={classes.label}>
            Please answer the following confirmation questions.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckBox
            name="chkCorp"
            listItem={ChkBoxConstantVal.checkcorpVal}
            labelText="Which of the following corporations have you been affiliated with?"
            onChange={event => props.onChange(event, 'checkbox', 'chkCorp')}
            color="#000000"
            fontVariant="h6"
            selectedValue={props.values.chkCorp}
            className={classes.KBA}
            isDisabled={Utils.isUserViewOnly()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckBox
            name="chkAddress"
            listItem={ChkBoxConstantVal.checkaddrVal}
            labelText="Which of the following addresses have you been affiliated with?"
            onChange={event => props.onChange(event, 'checkbox', 'chkAddress')}
            color="#000000"
            fontVariant="h6"
            selectedValue={props.values.chkAddress}
            className={classes.KBA}
            isDisabled={Utils.isUserViewOnly()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckBox
            name="chkVehicles"
            listItem={ChkBoxConstantVal.checkvehVal}
            labelText="Which of the following vehicles have you previously owned, leased, or co-signed for?"
            onChange={event => props.onChange(event, 'checkbox', 'chkVehicles')}
            color="#000000"
            fontVariant="h6"
            selectedValue={props.values.chkVehicles}
            className={classes.KBA}
            isDisabled={Utils.isUserViewOnly()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckBox
            name="chkCounties"
            listItem={ChkBoxConstantVal.checkcountVal}
            labelText="In which of the following counties have you lived or owned property? Select all that apply"
            onChange={event => props.onChange(event, 'checkbox', 'chkCounties')}
            color="#000000"
            fontVariant="h6"
            selectedValue={props.values.chkCounties}
            className={classes.KBA}
            isDisabled={Utils.isUserViewOnly()}
          />
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2} className={classes.rowButton}>
          <Grid item xs={12} sm={6} md={2} lg={2} className={classes.gridAlignCenterExitButton}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={Utils.isUserViewOnly()}
              onClick={
                props.esignRoleName === 'Owner'
                  ? () => props.onClick(props.esignRoleName, 2)
                  : () => props.onClick(props.esignRoleName, 3)
              }
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

OwnerKBA.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  values: PropTypes.object,
  esignRoleName: PropTypes.string,
};
