export default {
  text1: {
    color: '#EF426F !important',
    fontSize: '32px',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontWeight: '600',
  },
  text2: {
    color: '#4D4D4D !important',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontWeight: '400',
  },
  logo: {
    width: '8.75rem',
  },
  gridAlignCenterNextButton: {
    textAlign: 'center',
    paddingBottom: '22px',
  },
  gridWidth: {
    padding: '15rem',
  },
};
