import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useForm } from 'react-hook-form';
import MasterService from 'api/masterService';
import store from 'redux/store';
import { setInputValues } from 'redux/getstarted/actions';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function SearchComponent(props) {
  const classes = useStyles();
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const [selectedAgent, setSelectedAgent] = useState(null);
  const { register, reset } = useForm();
  const [noSplitAgentFound, setNoSplitAgentFound] = useState(false);

  const addAgentToSplitAgents = () => {
    if (!selectedAgent) {
      return;
    }

    const foundResult = inputData['#SplitAgentData'].find(d => d.id === selectedAgent.id);
    if (foundResult) {
      return;
    }

    const newSplitAgent = {
      id: selectedAgent.id,
      name: selectedAgent.name,
      percentage: '',
    };
    const newInputValues = { ...inputData, '#SplitAgentData': [...inputData['#SplitAgentData'], newSplitAgent] };
    store.dispatch(setInputValues(newInputValues));
    reset({ agentSearchComp: '' });

    setSelectedAgent(null);
  };

  const getSplitAgentInfo = async agentName => {
    setSelectedAgent(null);
    const [agentListError, agentList] = await MasterService.getSearchByAgentName(agentName, state.getStarted.product);

    if (agentListError || !agentList || (agentList.data && agentList.data.length === 0)) {
      setNoSplitAgentFound(true);
      return;
    }
    setSelectedAgent(agentList.data[0]);
    setNoSplitAgentFound(false);
  };

  // need to debounce this action so that we don't call the server after every keystroke
  const getSplitAgentInfoDebounced = _.debounce(getSplitAgentInfo, 500);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <TextField
          className={classes.npnSearch}
          name="agentSearchComp"
          label="NPN"
          variant="outlined"
          inputRef={register({ noSplitAgentFound })}
          onChange={event => getSplitAgentInfoDebounced(event.target.value)}
          disabled={props.isDisabled}
          error={Boolean(noSplitAgentFound)}
          helperText={noSplitAgentFound && 'No agent found'}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          className={classes.producerName}
          variant="outlined"
          label="Producer Name"
          pointerEvents="none"
          value={selectedAgent?.name ? selectedAgent?.name : ''}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.addButtonHolder}>
        <Button
          variant="contained"
          /* disabled={!selectedAgent} */
          color="primary"
          onClick={() => {
            addAgentToSplitAgents();
          }}
          className={classes.addButton}
          startIcon={<AddIcon />}
        >
          Add Producer
        </Button>
      </Grid>
    </Grid>
  );
}
SearchComponent.propTypes = {
  isDisabled: PropTypes.bool,
};
