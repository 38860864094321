import { createLogger, format, transports } from 'winston';

const { combine, timestamp } = format;

const isObject = val => {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
};

const logger = createLogger({
  level: 'info',
  format: combine(timestamp(), format.json()),
  silent: true,
});

logger.add(
  new transports.Console({
    format: format.combine(
      format.colorize(),
      format.printf(info => {
        return `${info.timestamp} ${info.level}: ${
          isObject(info.message) ? JSON.stringify(info.message) : info.message
        }`;
      }),
    ),
  }),
);

export default logger;
