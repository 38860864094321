import React from 'react';
import PropTypes from 'prop-types';
import { TINTextbox } from '@aspida/react-components';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const TINTextBoxComp = props => {
  const classes = useStyles();
  const fieldRequired = text => {
    return (
      <p style={{ margin: 0 }}>
        <span style={{ color: 'red' }}>*</span>
        {text}
      </p>
    );
  };
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 2}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 2}
        className={[
          props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
          'TINTextbox',
        ].join(' ')}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
        }}
      >
        {!!props.group.fieldProperty.labelText && (
          <Typography
            variant={props.group.fieldProperty.styles.labelFontVariant}
            gutterBottom
            style={{
              color: props.group.fieldProperty.styles.labelFontColor,
              fontWeight: `${props.group.fieldProperty.styles.labelFontWeight}`,
              marginBottom: `${props.group.fieldProperty.styles.marginBottom}em`,
            }}
          >
            {props.group.fieldProperty.labelText}
          </Typography>
        )}
        <TINTextbox
          id={`txt${props.group.fieldName}`}
          name={props.group.fieldName}
          label={
            props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.infieldLabelText)
              : props.group.fieldProperty.infieldLabelText
          }
          onBlur={event => props.onChange(event, props.group.fieldValidation)}
          error={props.Errors[props.group.fieldName] !== undefined ? props.Errors[props.group.fieldName] !== '' : false}
          helperText={props.Errors[props.group.fieldName]}
          InputLabelProps={{
            className: props.group.fieldProperty.isEditable && classes.labelBackgroundColor,
          }}
          fullWidth
          disabled={Utils.handleElementDisable(props)}
          defaultValue={props.Values[props.group.fieldName]}
          variant="outlined"
          tooltipText={props.group.fieldProperty.tooltipText}
          className={classes.percentageStyle}
        />
      </Grid>
    </React.Fragment>
  );
};

TINTextBoxComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  Errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default TINTextBoxComp;
