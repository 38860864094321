import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import OwnerKBA from './ownerKBA';
import OwnerSign from './ownerSign';
import OwnerDelivery from './ownerDelivery';
import EsignComplete from './esignComplete';
import DtcEsignComplete from './dtcEsignComplete';

export default function EsignHome(props) {
  const getStarted = useSelector(state => state.getStarted);

  return (
    <React.Fragment>
      {(() => {
        switch (getStarted.IsSubmitted) {
          case 1:
            return (
              <OwnerKBA
                esignRoleName={props.esignRoleName}
                onClick={props.onClick}
                onChange={props.onChange}
                values={getStarted.inputvalues}
              />
            );
          case 2:
            return (
              <OwnerDelivery
                esignRoleName={props.esignRoleName}
                onClick={props.onClick}
                onChange={props.onChange}
                values={getStarted.inputvalues}
              />
            );
          case 3:
            return (
              <OwnerSign
                esignRoleName={props.esignRoleName}
                onClick={props.onClick}
                onChange={props.onChange}
                values={getStarted.inputvalues}
              />
            );
          case 4: {
            if (getStarted.inputvalues['#isDtcCustomer'] === true) {
              return <DtcEsignComplete />;
            }
            return <EsignComplete onClick={props.onClick} />;
          }
          default:
            return '';
        }
      })()}
    </React.Fragment>
  );
}

EsignHome.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  esignRoleName: PropTypes.string,
};
