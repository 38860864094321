import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    pinkCircle: {
      width: 200,
      height: 200,
      marginTop: '10px',
      backgroundColor: '#FDE4EA',
      borderRadius: '50%',
    },
    houseSearchLogo: {
      width: '18rem',
      maxWidth: '60%',
      margin: '44px 39px 51px 32px',
    },
    radioSelected: {
      display: 'grid',
      padding: '10px',
      marginRight: '10px',
      borderColor: '#14043c',
      borderRadius: '8px',
      borderWidth: '2px',
      borderStyle: 'solid',
      backgroundColor: '#f6f5f9',
    },
    radioNotSelected: {
      display: 'grid',
      padding: '10px',
      marginRight: '10px',
      borderColor: '#14043c',
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    radioGroup: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    radioCell: {
      justifyContent: 'center',
    },
  });

export default styles;
