import { useEffect } from 'react';

const useZendeskChat = isDtcCustomer => {
  useEffect(() => {
    if (isDtcCustomer && document.getElementById('ze-snippet') === null) {
      const script = document.createElement('script');

      script.defer = true;
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_CHAT_KEY}`;

      document.body.appendChild(script);
    }
  }, [isDtcCustomer]);
};

export default useZendeskChat;
