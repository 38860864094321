import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ExpandMore, Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import store from 'redux/store';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function Header({ errors, onErrorClick }) {
  const classes = useStyles();
  const errorRef = useRef(null);
  const [listOpen, setListOpen] = useState(false);
  const state = store.getState();
  const isReviewTab = state.getStarted.currentpage.Group === state.getStarted.trackergroupnames.length - 1;

  const errorListOpen = () => {
    setListOpen(!listOpen);
  };

  useEffect(() => {
    const onClickDocument = event => {
      if (errorRef.current && !errorRef.current.contains(event.target)) {
        setListOpen(false);
      }
    };

    document.addEventListener('mousedown', onClickDocument);
    return () => {
      document.removeEventListener('mousedown', onClickDocument);
    };
  }, [errorRef]);

  return (
    <Box ref={errorRef}>
      {errors.length > 0 && (
        <Box className={isReviewTab ? classes.errorCaption : classes.displayNone}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<ExpandMore />}
            onClick={errorListOpen}
            className={classes.errorsButton}
          >
            Errors {errors.length > 0 && `(${errors.length})`}
          </Button>
          {listOpen && (
            <Box id="divcont" className={classes.errorList}>
              {errors.map(item => (
                <Box
                  className={classes.errorItem}
                  onClick={event => {
                    onErrorClick(event, item);
                    setListOpen(false);
                  }}
                >
                  <Warning className={classes.errorIcon} />
                  <Typography variant="subtitle2" className={classes.errorText}>
                    {item.errorMessage}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
Header.propTypes = {
  errors: PropTypes.array,
  onErrorClick: PropTypes.func,
};
