import * as RemoteSignTypes from './types';

export const setRemoteData = data => dispatch => {
  dispatch({ type: RemoteSignTypes.SETREMOTESIGN, value: data });
};
export const setSignatureRequestId = value => dispatch => {
  dispatch({ type: RemoteSignTypes.SIGNATUREREQUESTID, value });
};
export const setPartyId = value => dispatch => {
  dispatch({ type: RemoteSignTypes.PARTYID, value });
};
export const setApplicationId = value => dispatch => {
  dispatch({ type: RemoteSignTypes.APPLICATIONID, value });
};
export const setApplicationAlias = value => dispatch => {
  dispatch({ type: RemoteSignTypes.APPLICATIONALIAS, value });
};
export const setFirstName = value => dispatch => {
  dispatch({ type: RemoteSignTypes.FIRSTNAME, value });
};
export const setLastName = value => dispatch => {
  dispatch({ type: RemoteSignTypes.LASTNAME, value });
};
export const setSSN = value => dispatch => {
  dispatch({ type: RemoteSignTypes.SOCIALSECRUITYNUMBER, value });
};
export const setDOB = value => dispatch => {
  dispatch({ type: RemoteSignTypes.DATEOFBIRTH, value });
};
export const setPageFlow = value => dispatch => {
  dispatch({ type: RemoteSignTypes.PAGEFLOW, value });
};
