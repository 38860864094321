import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button, Grid, Typography, Paper, List, ListItem } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from './style';

const useStyles = makeStyles(styles);

export default function Review(props) {
  const classes = useStyles();

  const getReviewBoxFieldValue = (reviewFieldValue, reviewFieldName) => {
    const value = CommonFunctions.checkIsNullOrUndefined(reviewFieldValue) !== '' ? reviewFieldValue : '';
    if (value !== '' && reviewFieldName.indexOf('SSN') !== -1) {
      return value.replace(/.(?=.{4})/g, 'x');
    }
    if (reviewFieldName === '#withdrawalBenefit') {
      return (
        <List className={classes.list}>
          <ListItem>Free interest withdrawal available starting 30 days after Contract Effective Date. </ListItem>
          <ListItem>10% free withdrawal available starting in Contract Year 2.</ListItem>
        </List>
      );
    }
    return value;
  };

  const getReviewLabelButton = (widget, group) => {
    if (widget === 'reviewlabel')
      return (
        <React.Fragment key={group.fieldName}>
          {group.fieldProperty.position.newLine === true && (
            <div
              className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-sm-12
                    MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
            />
          )}
          <Grid
            key={group.fieldName}
            item
            id={`grd${group.fieldName}`}
            style={{
              marginTop: `${
                group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
              }rem`,
            }}
            xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
            sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
            md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 4}
            lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 4}
          >
            <Typography
              variant={group.fieldProperty.styles.labelFontVariant}
              gutterBottom
              style={{
                color: group.fieldProperty.styles.labelFontColor,
                fontWeight: `${group.fieldProperty.styles.labelFontWeight}`,
                marginBottom: `${group.fieldProperty.styles.marginBottom}em`,
                padding: `${group.fieldProperty.styles.padding}`,
                fontSize: `${group.fieldProperty.styles.labelFontSize}em`,
              }}
            >
              {group.fieldProperty.labelText}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    if (widget === 'reviewbutton')
      return (
        <Grid
          key={group.fieldName}
          item
          id={`grd${group.fieldName}`}
          style={{
            marginTop: `${
              group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
            }rem`,
            textAlign: `${
              group.fieldProperty.styles.textAlign !== undefined ? group.fieldProperty.styles.textAlign : 0
            }`,
          }}
          xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
          sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 12}
          md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 12}
          lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 12}
        >
          <Button
            variant="contained"
            onClick={event => {
              const groupInd = CommonFunctions.getGroupIndex(group.fieldProperty.infieldLabelText);
              props.onTabClick(event, groupInd[0].Id, groupInd[0].Value);
            }}
            value={group.fieldProperty.labelText}
            disabled={!group.fieldProperty.isEditable || Utils.isUserViewOnly()}
            name={group.fieldName}
            className={classes.reviewBtn}
          >
            <Edit className={classes.editIcon} />
            {group.fieldProperty.labelText}
          </Button>
        </Grid>
      );
    return '';
  };

  const getReviewBox = (widget, group) => {
    if (widget === 'reviewbox')
      return (
        <React.Fragment key={group.fieldName}>
          {group.fieldProperty.position.newLine === true && (
            <div
              className="MuiGrid-root MuiGrid-grid-xs-12
                                MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
            />
          )}
          <Grid
            item
            id={`grd${group.fieldName}`}
            style={{
              marginTop: `${
                group.fieldProperty.styles.marginTop !== undefined ? group.fieldProperty.styles.marginTop : 0
              }rem`,
              backgroundColor: `#${
                group.fieldProperty.styles.backgroundColor !== undefined
                  ? group.fieldProperty.styles.backgroundColor
                  : 0
              }`,
              paddingRight: `${
                group.fieldProperty.styles.paddingRight !== undefined ? group.fieldProperty.styles.paddingRight : 0
              }rem`,
              paddingLeft: `${
                group.fieldProperty.styles.paddingLeft !== undefined ? group.fieldProperty.styles.paddingLeft : 0
              }rem`,
              borderRightStyle: `${
                group.fieldProperty.styles.borderRightStyle !== undefined
                  ? group.fieldProperty.styles.borderRightStyle
                  : 'hidden'
              }`,
              marginBottom: `${
                group.fieldProperty.styles.marginBottom !== undefined ? group.fieldProperty.styles.marginBottom : 0
              }rem`,
              height: `${group.fieldProperty.styles.height !== undefined && group.fieldProperty.styles.height}rem`,
            }}
            xs={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.xs : 12}
            sm={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.sm : 6}
            md={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.md : 2}
            lg={group.fieldProperty.grid !== undefined ? group.fieldProperty.grid.lg : 2}
          >
            <Typography variant="body2" className={classes.infieldLabel} gutterBottom>
              {group.fieldProperty.infieldLabelText}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.labelText}>
              {getReviewBoxFieldValue(props.Values[group.fieldName], group.fieldName)}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    return '';
  };

  const getSwitchCaseLabelButton = group => {
    switch (props.widgetType) {
      case 'start': {
        const { startWidgetType } = group.fieldProperty;
        return getReviewLabelButton(startWidgetType, group);
      }
      case 'yourannuity': {
        const { yourAnnuityWidgetType } = group.fieldProperty;
        return getReviewLabelButton(yourAnnuityWidgetType, group);
      }
      case 'owner': {
        const { ownerWidgetType } = group.fieldProperty;
        return getReviewLabelButton(ownerWidgetType, group);
      }
      case 'jowner': {
        const { jownerWidgetType } = group.fieldProperty;
        return getReviewLabelButton(jownerWidgetType, group);
      }
      case 'annuitant': {
        const { annuitantWidgetType } = group.fieldProperty;
        return getReviewLabelButton(annuitantWidgetType, group);
      }
      case 'auth': {
        const { authWidgetType } = group.fieldProperty;
        return getReviewLabelButton(authWidgetType, group);
      }
      case 'allocation': {
        const { allocationWidgetType } = group.fieldProperty;
        return getReviewLabelButton(allocationWidgetType, group);
      }
      case 'corp': {
        const { corpWidgetType } = group.fieldProperty;
        return getReviewLabelButton(corpWidgetType, group);
      }
      case 'trust': {
        const { trustWidgetType } = group.fieldProperty;
        return getReviewLabelButton(trustWidgetType, group);
      }
      case 'bene': {
        const { beneficaryWidgetType } = group.fieldProperty;
        return getReviewLabelButton(beneficaryWidgetType, group);
      }
      case 'funds': {
        const { fundsWidgetType } = group.fieldProperty;
        return getReviewLabelButton(fundsWidgetType, group);
      }
      default:
        return '';
    }
  };

  const getSwitchCaseBox = group => {
    switch (props.widgetType) {
      case 'start': {
        const { startWidgetType } = group.fieldProperty;
        return getReviewBox(startWidgetType, group);
      }
      case 'yourannuity': {
        const { yourAnnuityWidgetType } = group.fieldProperty;
        return getReviewBox(yourAnnuityWidgetType, group);
      }
      case 'owner': {
        const { ownerWidgetType } = group.fieldProperty;
        return getReviewBox(ownerWidgetType, group);
      }
      case 'jowner': {
        const { jownerWidgetType } = group.fieldProperty;
        return getReviewBox(jownerWidgetType, group);
      }
      case 'annuitant': {
        const { annuitantWidgetType } = group.fieldProperty;
        return getReviewBox(annuitantWidgetType, group);
      }
      case 'auth': {
        const { authWidgetType } = group.fieldProperty;
        return getReviewBox(authWidgetType, group);
      }
      case 'allocation': {
        const { allocationWidgetType } = group.fieldProperty;
        return getReviewBox(allocationWidgetType, group);
      }
      case 'corp': {
        const { corpWidgetType } = group.fieldProperty;
        return getReviewBox(corpWidgetType, group);
      }
      case 'trust': {
        const { trustWidgetType } = group.fieldProperty;
        return getReviewBox(trustWidgetType, group);
      }
      case 'bene': {
        const { beneficaryWidgetType } = group.fieldProperty;
        return getReviewBox(beneficaryWidgetType, group);
      }
      case 'funds': {
        const { fundsWidgetType } = group.fieldProperty;
        return getReviewBox(fundsWidgetType, group);
      }
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.btnCanav}>
        <Paper className={classes.paperHeader} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <React.Fragment>
              {props.JsonData !== '' &&
                props.JsonData.map(fieldGroup => {
                  const group = CommonFunctions.fillFieldDefaults(fieldGroup);
                  return getSwitchCaseLabelButton(group);
                })}
            </React.Fragment>
          </Grid>
        </Paper>
        <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <React.Fragment>
              {props.JsonData !== '' &&
                props.JsonData.map(fieldGroup => {
                  const group = CommonFunctions.fillFieldDefaults(fieldGroup);
                  return getSwitchCaseBox(group);
                })}
            </React.Fragment>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

Review.propTypes = {
  onTabClick: PropTypes.func,
  Values: PropTypes.object,
  JsonData: PropTypes.array,
  widgetType: PropTypes.string,
};
