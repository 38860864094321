import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

interface NumberChangePayload {
  target: {
    value: string,
    type: string,
  };
  currentTarget: {
    type: string,
  };
}

interface CustomNumberFormatProps {
  inputRef: React.Ref<any> | ((el: HTMLInputElement) => void) | undefined;
  onChange: (vals: NumberChangePayload) => void;
  type: string;
}
function CustomNumberFormat(props: CustomNumberFormatProps) {
  const { inputRef, onChange, type, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            type,
          },
          currentTarget: {
            type: '',
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
    />
  );
}

function CurrencyTextBox({ ...props }) {
  const { name, ...rest } = props;

  return (
    <TextField
      InputProps={{
        // @ts-ignore
        inputComponent: CustomNumberFormat,
        inputProps: { name, maxLength: 14 },
      }}
      {...rest}
    />
  );
}

export default CurrencyTextBox;
CurrencyTextBox.propTypes = {
  name: PropTypes.string,
};
