const getLength = (TrackerSource, index, filterValue) => {
  return TrackerSource[index].SubGroup.filter(p => p.isPageValid === filterValue).length;
};

const removeSubTabIcon = (TrackerSource, index, HideTab, Group) => {
  if (HideTab !== undefined) {
    HideTab.forEach(item => {
      const data = TrackerSource[Group].SubGroup.findIndex(x => x.displayname === item);
      if (data !== -1) {
        // eslint-disable-next-line no-param-reassign
        TrackerSource[Group].SubGroup[data].isPageValid = undefined;
      }
    });
  }
};

const checkSubGroup = (TrackerSource, index, HideTab) => {
  let isSubTab = false;
  let totalSubTabCount = 0;
  let validTabCount = 0;
  if (HideTab !== undefined) {
    HideTab.forEach(itm => {
      if (TrackerSource[index].SubGroup.filter(p => p.displayname === itm).length > 0) {
        totalSubTabCount = TrackerSource[index].SubGroup.length;
        validTabCount += TrackerSource[index].SubGroup.filter(p => p.displayname === itm).length;
        if (totalSubTabCount === validTabCount) {
          isSubTab = true;
        }
      }
    });
  }
  return isSubTab;
};

const calculateCompletePercentage = TrackerSource => {
  let totalTabCount = 0;
  let validTabCount = 0;
  let percentage = 0;
  TrackerSource.forEach((grpName, index) => {
    totalTabCount += grpName.SubGroup.length;
    validTabCount += getLength(TrackerSource, index, true);
  });
  percentage = (validTabCount / totalTabCount) * 100;
  return !percentage ? 0 : Math.round(percentage, 0);
};

export default {
  getLength,
  removeSubTabIcon,
  calculateCompletePercentage,
  checkSubGroup,
};
