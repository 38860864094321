export default {
  paperDownload: {
    padding: '16px',
    border: '1.5px solid #14043c',
    opacity: 1,
    borderRadius:"10px"
  },
  checkDate: {
    marginTop: '15px',
  },
  floatRight: {
    float: 'right',
    marginTop: '23px',
  },
  marginBtmPer: {
    marginBottom: '1%',
  },
  marginBtm: {
    marginBottom: '16px',
  },
  aspidaStyle: {
    fontSize: 'large',
    fontWeight: '800',
    color: '#232223 !important',
    textAlignLast: 'center',
  },
  textstyle: {
    fontWeight:400,
    color: '#2d2d2d !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div':{
    backgroundColor: '#FFFFFF',
    },
  },
  textalign:{
    color: 'rgba(0,0,0,0.87)',
  fontSize: '16px',
  }
};
