import React from 'react';
import AuthPages from 'views/authPages';
import EsignSuccess from 'views/remote/EsignSuccess';
// eslint-disable-next-line import/no-named-default
import { default as AspidaSignOut } from 'views/authPages/signout';
import RemoteSign from 'views/remote';

const AppWithAuth = () => {
  return (
    <div>
      <AuthPages />
    </div>
  );
};

// Reference react hooks router
export default {
  '/esignsuccess': () => <EsignSuccess />,
  '/remotesign': () => <RemoteSign />,
  '/signout': () => <AspidaSignOut />,
  '/*': () => <AppWithAuth />,
};
