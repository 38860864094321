export default {
  label: {
    fontWeight: 600,
  },
  esignLabel: {
    fontWeight: 600,
    fontSize: '16px',
    textWrap: 'nowrap',
  },
  headingLabel: {
    fontWeight: 600,
    fontSize: '1.3em',
  },
  openIcon: {
    color: '#fff',
    backgroundColor: '#EF426F',
    marginTop: '0.5rem',
    textTransform: 'capitalize',
    width: '100%',
  },
  resendIcon: {
    color: '#140F3C',
    backgroundColor: '#fff',
    marginTop: '0.5rem',
    textTransform: 'capitalize',
    border: '1px solid #707070',
    boxShadow: '0 0 black',
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
    height: '66px',
  },
  signhidden: {
    visibility: 'hidden',
    height: '56px',
  },
  esignSTDColor: {
    color: '#6EAB2E',
  },
  sendBtn: {
    color: '#fff',
    backgroundColor: '#EF426F',
    marginTop: '5px',
    marginLeft: '5px',
    '@media (max-width:425px)': {
      width: '30%',
    },
  },
  tableAlign: {
    '@media (max-width:342px)': {
      marginLeft: '-1.3em',
    },
  },
  eSignBarWidth: {
    width: '30%',
  },
  paperHeader: {
    padding: '.5rem',
    opacity: 1,
    borderBottom: '0',
  },
  gridTextAlign: {
    textAlign: 'right',
  },
  labelTop: {
    color: '#8695b1 !important',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: '500',
    overflowWrap: 'break-word',
    marginTop: '.5rem',
  },
  valueTop: {
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    backgroundColor: '#f8fafc !important',
    fontSize: '16px',
    fontWeight: '500',
    overflowWrap: 'break-word',
  },
  papertest: {
    backgroundColor: 'blue',
  },
  completeIcon: {
    color: '#fff',
    fontSize: '1.3rem',
    paddingRight: '2px',
    textTransform: 'uppercase',
    marginBottom: '-0.3rem',
  },
  esignSigned: {
    fontSize: '.8rem',
    color: '#fff !important',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: '.3rem',
  },
  esignComplete: {
    textAlign: 'center',
    borderRadius: '12px',
    backgroundColor: '#497915',
  },
  esignResent: {
    textAlign: 'center',
    borderRadius: '12px',
    backgroundColor: '#5fa19d',
  },
  esignDenied: {
    textAlign: 'center',
    borderRadius: '12px',
    backgroundColor: '#e4573f',
  },
  gridCenterTextAlign: {
    textAlign: 'center',
  },
  reply: {
    color: '#140F3C',
    marginTop: '0.5rem',
    textTransform: 'capitalize',
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  paperHeaderGrid: {
    padding: '1rem',
    opacity: 1,
    borderBottom: '0',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    backgroundColor: '#F2F4F7',
  },
  paperBody: {
    padding: '1rem',
    opacity: 1,
    borderTop: '0',
    backgroundColor: '#F8FAFC',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
    height: '22.5rem',
  },
  appBtnStyle: {
    height: '62px',
    width: '100%',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  appTypeBtn: {
    margin: '.5rem',
  },
  activeButton: {
    height: '62px',
    width: '100%',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontSize: '17px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  signCollect: {
    color: '#768497 !important',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: '300',
  },
  displayNone: {
    display: 'none',
  },
  downloadButton: {
    backgroundColor: '#14043C',
    color: '#fff',
    textAlign: 'right',
  },
  downloadButtonGrid: {
    textAlign: 'right',
  },
};
