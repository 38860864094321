import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    header: {
      height: '4rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: '1.3rem',
      paddingBottom: '1rem',
    },
    appNumberGrid: {
      paddingRight: '0rem',
      paddingLeft: '1rem',
      height: '2.4rem',
    },
    appNumberNoGrid: {
      paddingRight: '0rem',
      paddingLeft: '1rem',
    },
    appNumberClient: {
      height: '2.4rem',
    },
    personIcon: {
      color: '#EF426F',
      fontSize: '25px !important',
    },
    appNumberTxt: {
      color: '#4D4D4D',
      fontFamily: 'Muli',
      fontSize: '14px',
      fontWeight: '700',
      '@media (max-width: 800px)': {
        fontSize: '11px',
      },
    },
    appNumber: {
      color: '#4D4D4D',
      fontFamily: 'Muli',
      fontSize: '14px',
      fontWeight: '400',
      '@media (max-width: 800px)': {
        fontSize: '11px',
      },
    },
    prodNum: {
      fontSize: '1rem',
      padding: '1rem 1.5rem',

      '@media (min-width:59.9375rem) and (max-width:75rem)': {
        marginTop: '.5rem',
        marginLeft: '.5em',
        fontSize: '.7rem',
      },

      '@media (max-width:51.75rem)': {
        marginTop: '0',
        marginLeft: '2em',
        fontSize: '9pt',
      },

      '@media (max-width:31.5rem)': {
        marginTop: '0',
        marginLeft: '2em',
        fontSize: '6pt',
      },
    },
    editIcon: {
      marginRight: '0.3125rem',
      fontSize: '1.3rem',
    },
    gridAlignRightBackButton: {
      textAlign: 'right',
    },
    gridAlignCenterExitButton: {
      textAlign: 'end',
      display: 'block',
      marginRight: '4rem',
    },
    editBtn: {
      textTransform: 'capitalize',
      width: '169px',
      color: '#14043C',
      borderRadius: '2rem',
      boxSizing: 'border-box',
      height: '32px',
      border: '1px solid #F50057',
      cursor: 'default',
      fontSize: '12px',
    },
    displayNone: {
      display: 'none',
    },
    newApp: {
      fontSize: '0.9375rem',
      textDecoration: 'underline',
      fontWeight: '600',
      cursor: 'pointer',

      '@media (min-width:59.9375rem) and (max-width:75rem)': {
        fontSize: '.7235rem',
      },

      '@media (max-width:39.5rem)': {
        fontSize: '0.5375rem',
      },
    },
    progressStyle: {
      width: '100%',
      background: '#fff',
      paddingBottom: '8px',
    },
    label: {
      fontSize: '2.0625rem',
      color: '#EF426F !important',
      textAlign: 'center',
    },
    verticalLine: {
      borderLeft: '1px solid black',
      height: '17rem',
      position: 'absolute',
      left: '49rem',
      top: '14rem',
    },
    titleSuccessHead: {
      color: '#EF426F !important',
      fontFamily: 'Muli',
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: '600',
    },
    logo: {
      height: '94.61px',
      width: '120px',
    },
    createNewButton: {
      fontSize: '16px',
      borderRadius: '4px',
      backgroundColor: '#EF426F',
      height: '36px',
      width: '335px',
    },
    returnToPortal: {
      boxSizing: 'border-box',
      height: '36px',
      width: '335px',
      border: '1px solid #EF426F',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      color: '#EF426F',
    },
    paperBody: {
      padding: '1rem',
      marginTop: '2rem',
    },
    titleSuccess: {
      color: '#4D4D4D !important',
      fontFamily: 'Muli',
      textAlign: 'center',
      fontSize: '19px',
    },
    next: {
      color: '#4D4D4D !important',
      fontFamily: 'Muli',
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: '400',
    },
    agent: {
      color: '#4D4D4D !important',
      fontFamily: 'Muli',
      textAlign: 'center',
      fontWeight: '600',
      fontSize: '17px',
    },
    pendingText: {
      color: '#4D4D4D !important',
      fontFamily: 'Muli',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '600',
    },
    gotoBusiness: {
      color: '#fff',
      backgroundColor: '#EF426F',
      width: '50%',
    },
    Headset: {
      color: '#140F3C',
      fontSize: '59px',
    },
    gridAlignCenterNextButton: {
      textAlign: 'center',
    },
    btnstyle: {
      textAlign: 'center',
      marginTop: '1rem',
    },
    esignComplete: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    groove: {
      borderRight: 'groove',
    },
    box: {
      width: '100%',
      '@media (min-width:140rem)': {
        display: 'block',
        marginTop: '21rem',
      },
    },
  });

export default styles;
