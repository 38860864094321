import * as RemoteSignTypes from './types';

export const initialState = {
  signatureRequestId: '',
  applicationId: '',
  applicationAlias: '',
  socialSecurityNumber: '',
  dateOfBirth: null, // calendar
  firstName: '',
  lastName: '',
  loading: false,
  pageFlow: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RemoteSignTypes.LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }
    case RemoteSignTypes.CLEARREMOTESIGN: {
      return initialState;
    }
    case RemoteSignTypes.SIGNATUREREQUESTID: {
      return {
        ...state,
        signatureRequestId: action.value,
      };
    }
    case RemoteSignTypes.PAGEFLOW: {
      return {
        ...state,
        pageFlow: action.value,
      };
    }
    case RemoteSignTypes.SOCIALSECRUITYNUMBER: {
      return {
        ...state,
        socialSecurityNumber: action.value,
      };
    }
    case RemoteSignTypes.PARTYID: {
      return {
        ...state,
        partyId: action.value,
      };
    }
    case RemoteSignTypes.APPLICATIONID: {
      return {
        ...state,
        applicationId: action.value,
      };
    }
    case RemoteSignTypes.APPLICATIONALIAS: {
      return {
        ...state,
        applicationAlias: action.value,
      };
    }
    case RemoteSignTypes.DATEOFBIRTH: {
      return {
        ...state,
        dateOfBirth: action.value,
      };
    }
    case RemoteSignTypes.FIRSTNAME: {
      return {
        ...state,
        firstName: action.value,
      };
    }
    case RemoteSignTypes.LASTNAME: {
      return {
        ...state,
        lastName: action.value,
      };
    }

    default: {
      return state;
    }
  }
};
