import masterService from 'api/masterService';

const getBrowserInfo = () => {
  return navigator.userAgent;
};
const saveBrowserInfo = async (appId, message) => {
  const payload = {
    applicationId: appId,
    message,
    browserInfo: getBrowserInfo(),
    ipAddress: '-',
  };
  if (appId) await masterService.saveApplicationAuditTrail(payload);
};
const getTabName = (getStarted, group, subGroup) => {
  const mainTabName = getStarted.trackergroupnames[group].displayname;
  const subTabName = getStarted.trackergroupnames[group].SubGroup[subGroup].displayname;
  return `${mainTabName} -> ${subTabName}`;
};
export default {
  saveBrowserInfo,
  getTabName,
};
