export default {
  linearProg: {
    height: '22px',
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
    '& > div': {
      background: 'linear-gradient(90deg, rgb(173, 224, 223) 0%, rgb(107, 198, 197) 52.52%, rgb(73, 154, 152) 100%)',
    },
    '@media (max-width: 800px)': {
      height: '17px',
    },
  },
};
