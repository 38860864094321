import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, IconButton } from '@material-ui/core';
import { CheckCircle, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Utils from 'views/eapp/renderforms/utils';
import store from 'redux/store';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function SummaryCard(props) {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const isReviewComplete = inputData['#isReviewComplete'] || false;
  const classes = useStyles();

  return (
    <Box
      className={classNames(classes.root, classes.border, classes.clickable)}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={2}>
          <IconButton aria-label="edit" name={props.group.fieldName} className={classes.iconStyle}>
            <CheckCircle fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid item xs={8} className={classes.dotStyle}>
          <Typography variant="h6" className={classes.accountNo}>
            Account{' '}
            {inputData[`${props.group.fieldProperty.summaryPrefix}Text1`] !== undefined &&
              inputData[`${props.group.fieldProperty.summaryPrefix}Text1`].replace(/.(?=.{4})/g, 'x')}{' '}
            <br />
            {inputData[`${props.group.fieldProperty.summaryPrefix}Text2`]}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            onClick={event => props.onChange(event, props.group.summaryBoxEdit, 'button', props.group.fieldName)}
            value={props.group.fieldProperty.labelText}
            name={props.group.fieldName}
            className={classes.paddingIcon}
            disabled={Utils.isUserViewOnly() || isReviewComplete}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

SummaryCard.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};
