import PropTypes from 'prop-types';

export const ZendeskAPI = (...args) => {
  if (typeof window !== 'undefined' && window.document && window.document.createElement && window.zE) {
    window.zE.apply(null, args);
  }
};
export default function ZendeskChat(props) {
  if (typeof props.onLoaded === 'function') {
    props.onLoaded();
  }

  return null;
}

ZendeskChat.propTypes = {
  onLoaded: PropTypes.func,
};
