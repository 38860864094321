export default {
  label: {
    fontWeight: 600,
  },
  paperSign: {
    padding: '1.875rem',
    boxShadow: '0rem 0.1875rem 0.375rem #00000029',
    border: '.5px solid #ABABAB',
    borderRadius: '0.25rem 0rem 0rem 0rem',
    opacity: 1,
    width: '125%',
    textAlign: 'left',
  },
  gridAlignCenterExitButton: {
    textAlign: 'center',
  },
  gridAlignRightBackButton: {
    textAlign: 'right',
  },
  gridAlignLeftBackButton: {
    textAlign: 'left',
  },
  rowButton: {
    padding: '2rem',
  },
  leaf: {
    marginTop: '-0.3125rem',
    marginLeft: '-2.0625rem',
    width: '2.1875rem',
  },
  goPaper: {
    color: '#517c22',
    fontSize: '1.7rem',
    marginLeft: '-1.625rem',
  },
  fast: {
    color: '#b6d596',
    fontSize: '0.75rem',
  },
};
