import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useAsyncEffect } from 'use-async-effect';
import _ from 'lodash';
import moment from 'moment';
import store from 'redux/store';
import MasterService from 'api/masterService';
import {
  setReset,
  setIsSubmitted,
  setLoading,
  setCurrentPage,
  setTrackerGroupName,
  setInputValues,
  setPolicyApplicationId,
  setProductName,
  setEligibilityErrors,
  setProductRates,
} from 'redux/getstarted/actions';
import { setAuthenticatedUser } from 'redux/user/actions';
import { setApprovedProducts, setAllStates } from 'redux/appData/actions';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import { navigate } from 'hookrouter';
import Header from 'components/header';
import TabPanel from 'components/tabPanel';
import YesNoModal from 'components/yesNoModal';
import ConfirmModal from 'components/confirmModal';
import AddressModal from 'components/addressValidationModal';
import CopyConfirmation from 'components/copyConfirmationModal';
import ErrorNotification from 'components/errorNotification';
import constants from 'constants/common';
import Utils from 'views/eapp/renderforms/utils';
import BrowserHelper from 'helpers/browserHelper';
import NotificationService from 'services/notificationService';
import EditModal from 'components/editModal';
import SuitabilityWaiver from 'components/suitabilityWaiverModal';
import useZendeskChat from '../../config/useZendeskChat';
import ZendeskChat, { ZendeskAPI } from '../../config/zendeskChat';
import ProductPickerValidation from './utils/productPickerValidation';
import EsignEventValidation from './utils/esignEventValidation';
import CommonFunctions from './utils/commonFunctions';
import GroupTrackerFunctions from './utils/groupTrackerFunctions';
import Helper from '../authPages/utils';
import ProductPicker from './productpicker';
import DtcProductPicker from './dtcProductPicker';
import GroupTracker from './grouptracker';
import RenderFormValidation from './utils/renderFormValidation';
import { addressNames } from './utils/addressValidation/utils';
import Esign from './esign';
import styles from './styles';
import CommonUtils from './utils/index';

const useStyles = makeStyles(styles);

export default function Eapp(props) {
  const classes = useStyles();
  const [Products, setProducts] = useState([]);
  const [SignedStates, setSignedStates] = useState([]);
  const [ApprovedStates, setStates] = useState([]);
  const [ApplicationTypes, setApplicationTypes] = useState([]);
  const [editVisible, setEditVisible] = useState(false);
  const [editModalVisible, setModalEditVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [currentPrefix, setCurrentPrefix] = useState('');
  const [zendeskChatToken, setZendeskChatToken] = useState('');
  const [addresses, setAddresses] = useState({});
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [processingAddress, setProcessingAddress] = useState(false);
  const dispatch = useDispatch();
  const getStarted = useSelector(state => state.getStarted);
  const appData = useSelector(state => state.appData);
  const [roleName, setRoleName] = React.useState('');
  const user = useSelector(state => state.user);
  const scrollRef = useRef(null);
  const { isDtcCustomer } = user;
  const [suitabilityWaiverVisible, setSuitabilityWaiverVisible] = useState(isDtcCustomer);
  const agentPartyId = isDtcCustomer ? constants.dtcCustomerAgentPartyId : user.id;
  let sessionEAppData;

  useZendeskChat(isDtcCustomer);

  const eSignButtonClick = (rName, index) => {
    dispatch(setIsSubmitted(index));
    setRoleName(rName);
  };

  const getStates = async () => {
    const [allStatesError, allSignedStates] = await MasterService.getSignedStates();
    if (CommonFunctions.checkIsNullOrUndefined(allStatesError) !== '') {
      NotificationService.error(allStatesError.data.message);
    }
    allSignedStates.data = allSignedStates.data.filter(x => x.abbreviation !== 'NY');
    dispatch(setAllStates(allSignedStates));
    setStates(
      allSignedStates.data.map(states => {
        return {
          id: states.id.concat('~', states.abbreviation),
          value: states.name,
        };
      }),
    );
  };

  useEffect(() => {
    if (getStarted.currentpage.JsonData !== '') {
      Promise.all(
        getStarted.currentpage.JsonData.map(async fields => {
          if (fields.fieldValidation !== undefined) {
            await CommonFunctions.executeFieldValidation(fields.fieldValidation, fields.fieldName, false, false);
          }
          return false;
        }),
      );
    }
  }, [getStarted]);

  const getProductsfromContracts = async () => {
    const query = `partyId=${agentPartyId}`;
    const [errorContract, contracts] = await MasterService.getContracts(query);
    if (errorContract !== null) {
      NotificationService.error(constants.serverErrorMessages.errorContract);
    }
    const availedProductlist = contracts.data.reduce((acc, contract) => acc.concat(contract.products), []);

    // to remove duplicate products
    const availedProducts = [...new Set(availedProductlist.map(item => item.id))];
    return availedProducts;
  };

  const getProducts = async () => {
    const tempProducts = [];
    const [productsError, allProducts] = await MasterService.getProducts(isDtcCustomer);
    if (productsError || !allProducts) {
      NotificationService.error(productsError.data.message);
    }
    const state = store.getState();
    const applicationData = state.getStarted;
    applicationData.inputvalues['#isDtcCustomer'] = false;
    applicationData.inputvalues['#prevYearContribStDate'] = constants.prevYearContribStDate.replace(
      'YYYY',
      moment().year(),
    );
    applicationData.inputvalues['#prevYearContribEnDate'] = constants.prevYearContribEnDate.replace(
      'YYYY',
      moment().year(),
    );
    applicationData.inputvalues['#currYearContribStDate'] = constants.currYearContribStDate.replace(
      'YYYY',
      moment().year(),
    );
    applicationData.inputvalues['#currYearContribEnDate'] = constants.currYearContribEnDate.replace(
      'YYYY',
      moment().year(),
    );
    applicationData.inputvalues['#currentDate'] = moment().format('YYYY-MM-DD');
    if (isDtcCustomer) {
      applicationData.inputvalues['#annuityTypeQues'] = 'MYGA';
      applicationData.inputvalues['#isDtcCustomer'] = true;
      applicationData.inputvalues['#deathBenefitDtc'] = 'Contract Value';
      if (applicationData.inputvalues && applicationData.product) {
        const cr = applicationData.productrates.find(pr => pr.productId === applicationData.product).creditedRates;
        applicationData.inputvalues['#creditedRates'] = cr;
      }
    }
    const annuityTypeQues =
      applicationData.inputvalues['#annuityTypeQues'] === 'MYGA'
        ? 'MYA'
        : applicationData.inputvalues['#annuityTypeQues'];
    const productRes = allProducts.data.filter(d => d.productTypeAbbr === annuityTypeQues);
    const getProductContracts = !isDtcCustomer ? await getProductsfromContracts() : [];
    CommonFunctions.getMYAFIAProducts(allProducts.data, getProductContracts);
    if (productRes.length > 0) {
      productRes.forEach(prod => {
        const isProductPresent = getProductContracts.find(x => x === prod.id);
        if (isProductPresent || isDtcCustomer) {
          tempProducts.push({ id: prod.id, value: prod.description });
        }
      });
      setProducts(tempProducts);
    }
  };
  const setExistingeAppData = () => {
    if (props.id !== undefined) {
      sessionStorage.removeItem('eAppPolicyData');
      Helper.setSpecification(props.id, user);
      setSuitabilityWaiverVisible(false);
    }
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('eAppPolicyData')) !== '') {
      sessionEAppData = JSON.parse(sessionStorage.getItem('eAppPolicyData'));
      Helper.setSpecification(undefined, user, sessionEAppData);
    }
  };

  const getZendeskChatToken = async () => {
    const [zenTokenError, zenToken] = await MasterService.getZendeskChatToken();
    if (CommonFunctions.checkIsNullOrUndefined(zenTokenError) !== '') {
      NotificationService.error(zenTokenError.data.message);
      setZendeskChatToken('');
      return;
    }
    setZendeskChatToken(zenToken.token);
  };

  const getProductsRates = async () => {
    const products = [
      constants.product.aspidaFriendsAndFamilyMYGA2,
      constants.product.aspidaFriendsAndFamilyMYGA3,
      constants.product.aspidaFriendsAndFamilyMYGA5,
      constants.product.aspidaFriendsAndFamilyMYGA7,
    ].join(',');
    const [productRateError, productRates] = await MasterService.getProductRates(products);
    if (CommonFunctions.checkIsNullOrUndefined(productRateError) !== '') {
      NotificationService.error(productRateError.data.message);
      dispatch(setProductRates([]));
      return;
    }
    dispatch(setProductRates(productRates));
  };

  const productSelecttion = async () => {
    setExistingeAppData();
    dispatch(setLoading(true));

    if (isDtcCustomer) {
      await getProductsRates();
      await getZendeskChatToken();
    }

    await getProducts();
    await getStates();

    const tempApplicationTypes = [];
    const [AppTypesError, allAppTypes] = await MasterService.getApplicationTypes();
    if (AppTypesError !== null) {
      NotificationService.error(AppTypesError.data.message);
    }
    const state = store.getState();
    const signedValue = CommonFunctions.setSignedStatesEvent(state.getStarted.product, state.appData.allStates);
    dispatch(setApprovedProducts(signedValue));

    allAppTypes.data.forEach(AppTypes => tempApplicationTypes.push({ id: AppTypes.id, value: AppTypes.type }));
    setApplicationTypes(tempApplicationTypes);
    dispatch(setLoading(false));
  };

  useAsyncEffect(productSelecttion, []);

  /*
   * This cleanJSON function is used to delete invalid property like value contains
   * null, undefined and empty.
   */
  const cleanJSON = inputFormData => {
    const returnObject = { ...inputFormData };
    Object.keys(returnObject).forEach(key => {
      if (typeof returnObject[key] === 'string') {
        returnObject[key] = returnObject[key].trim();
      }
      if (CommonFunctions.checkIsNullOrUndefined(returnObject[key]) === '') {
        delete returnObject[key];
      }
    });
    return returnObject;
  };

  const setCompletionPercentage = (applicationstatus, inputDataRes) => {
    let currentCompletionPer = '';
    if (applicationstatus === constants.applicationStatusId.newBusinessReview) {
      return 100;
    }
    const prevProgress = GroupTrackerFunctions.calculateCompletePercentage(getStarted.trackergroupnames);
    currentCompletionPer =
      prevProgress < inputDataRes['#completionPercentage'] ? inputDataRes['#completionPercentage'] : prevProgress;
    return currentCompletionPer;
  };

  const saveApplicationData = async applicationstatus => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const state = store.getState();
    const applicationData = state.getStarted;
    if (isDtcCustomer && applicationData.inputvalues && applicationData.product) {
      const cr = applicationData.productrates.find(pr => pr.productId === applicationData.product).creditedRates;
      applicationData.inputvalues['#creditedRates'] = cr;
    }
    if (applicationData.inputvalues['#IsSignatureCreated']) {
      return;
    }
    if (CommonFunctions.checkIsNullOrUndefined(applicationstatus) !== '') {
      if (applicationstatus === constants.applicationStatusId.newBusinessReview) {
        applicationData.inputvalues['#submittedDate'] = moment(new Date()).format('MM/DD/YYYY');
        applicationData.inputvalues['#applicationStatusId'] = applicationstatus;
      }
      if (applicationstatus === constants.applicationStatusId.reviewComplete) {
        applicationData.inputvalues['#applicationStatusId'] = applicationstatus;
      }
      dispatch(setInputValues(applicationData.inputvalues));
    }
    applicationData.inputerrors = cleanJSON(applicationData.inputerrors);
    applicationData.inputvalues = cleanJSON(applicationData.inputvalues);

    const payload = {
      applicationId: applicationData.policyapplicationid,
      completionPercentage: setCompletionPercentage(applicationstatus, applicationData.inputvalues),
      agentPartyId,
      applicationStatusId:
        applicationstatus === undefined ? applicationData.inputvalues['#applicationStatusId'] : applicationstatus,
      response: applicationData,
      productId: applicationData.product,
      signedStateId: applicationData.signedstateid,
      applicationTypeId: applicationData.applicationtype,
    };
    sessionStorage.setItem('eAppPolicyData', JSON.stringify(applicationData));
    const [applicationError, applicationUpdated] = await MasterService.saveApplicationData(payload);
    dispatch(setEligibilityErrors([]));
    if (CommonFunctions.checkIsNullOrUndefined(applicationError) !== '') {
      if (applicationError.status === 400) {
        const JsonErrorData = JSON.parse(applicationError.data.message);
        dispatch(setEligibilityErrors(JsonErrorData));
        dispatch(setLoading(false));
        return;
      }
      NotificationService.error(applicationError.data ? applicationError.data.message : applicationError);
      dispatch(setLoading(false));
      return;
    }
    if (CommonFunctions.checkIsNullOrUndefined(applicationUpdated) !== '') {
      applicationData.inputvalues['#completionPercentage'] = applicationUpdated.completionPercentage;
      dispatch(setInputValues(applicationData.inputvalues));
    }
    if (
      applicationstatus === constants.applicationStatusId.reviewComplete &&
      CommonFunctions.checkIsNullOrUndefined(applicationUpdated) !== ''
    ) {
      applicationData.inputvalues['#isReviewComplete'] = true;
      if (applicationUpdated.response['#writingAgentEmail'] === constants.automationEmailId) {
        applicationData.inputvalues['#ownerIDFlag'] = false;
        applicationUpdated.response['#ownerIDFlag'] = false;
        applicationData.inputvalues['#jointOwnerIDFlag'] = applicationUpdated.response['#ifJointOwner']
          ? false
          : undefined;
        applicationUpdated.response['#jointOwnerIDFlag'] = applicationUpdated.response['#ifJointOwner']
          ? false
          : undefined;
        applicationData.inputvalues['#annuitantIDFlag'] = false;
        applicationUpdated.response['#annuitantIDFlag'] = false;
        applicationUpdated.response['#ownerReason'] = '';
        applicationUpdated.response['#jointOwnerReason'] = '';
        applicationUpdated.response['#annuitantReason'] = '';
      } else {
        applicationData.inputvalues['#ownerIDFlag'] = applicationUpdated.response['#ownerIDFlag'];
        applicationData.inputvalues['#jointOwnerIDFlag'] = applicationUpdated.response['#jointOwnerIDFlag'];
        applicationData.inputvalues['#annuitantIDFlag'] = applicationUpdated.response['#annuitantIDFlag'];
      }
      applicationUpdated.response['#isReviewComplete'] = true;
      const IsMSAwithBackoffice = await CommonFunctions.validateMSAWithBKOFC(getStarted.policyapplicationid);
      applicationData.inputvalues['#IsMSAwithBackoffice'] = IsMSAwithBackoffice;
      applicationUpdated.response['#IsMSAwithBackoffice'] = IsMSAwithBackoffice;
      if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('eAppPolicyData')) !== '') {
        store.dispatch(setInputValues(applicationUpdated.response));
      }
    }
  };

  const checkAllSigners = async e => {
    let signerDetails = [];
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) === '') {
      return;
    }
    e.preventDefault();
    signerDetails = JSON.parse(sessionStorage.getItem('signerDetails'));
    const payload = {
      partyId: signerDetails.partyId,
      docusignEnvelopeId: signerDetails.docusignEnvelopeId,
      docusignClientUserId: signerDetails.docusignClientUserId,
    };
    const beforeMessage = `Before trigger autosubmit on browser tab close event ${getStarted.policyapplicationid}`;
    BrowserHelper.saveBrowserInfo(getStarted.inputvalues['#applicationAliasId'], beforeMessage);
    const [remoteSignResError, remoteSignRes] = await MasterService.browserTabSubmit(
      getStarted.policyapplicationid,
      payload,
    );
    if (remoteSignResError) {
      NotificationService.error(remoteSignResError.data.message);
      return;
    }
    const afterMessage = `App trigger autosubmit on browser tab close event ${getStarted.policyapplicationid}`;
    BrowserHelper.saveBrowserInfo(getStarted.inputvalues['#applicationAliasId'], afterMessage);
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) !== '') {
      sessionStorage.removeItem('signerDetails');
    }
    return remoteSignRes;
  };

  useBeforeunload(async event => {
    const eappdata = getStarted.inputvalues;
    const message = `Agent closed the browser inbetween filling app in
    ${BrowserHelper.getTabName(getStarted, getStarted.currentpage.Group, getStarted.currentpage.SubGroup)}`;
    BrowserHelper.saveBrowserInfo(getStarted.inputvalues['#applicationAliasId'], message);
    if (
      CommonFunctions.checkIsNullOrUndefined(getStarted.policyapplicationid) !== '' &&
      CommonFunctions.checkIsNullOrUndefined(eappdata['#IsMSA']) !== '' &&
      !eappdata['#isReviewComplete'] &&
      !(eappdata['#applicationStatusId'] === constants.applicationStatusId.reviewComplete)
    ) {
      event.preventDefault();
      if (eappdata['#replacementInProgress']) {
        eappdata['#replacementInProgress'] = false;
        const jsonContent = CommonFunctions.getNextPageContent(0, 1, getStarted.trackergroupnames);
        const currentPage = { Group: 0, SubGroup: 1, JsonData: jsonContent };
        dispatch(setCurrentPage(currentPage));
        dispatch(setInputValues(eappdata));
      }
      if (CommonFunctions.getFundingData() === false) {
        eappdata['#isFundingTab'] = true;
        dispatch(setInputValues(eappdata));
      }
      await saveApplicationData();
    }
    await checkAllSigners(event);
  });

  const eSignSubmitClick = (index, submitted) => {
    const trackerGroupNames = getStarted.trackergroupnames;
    dispatch(setIsSubmitted(index));
    trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
    dispatch(setTrackerGroupName(trackerGroupNames));
    if (submitted) saveApplicationData(constants.applicationStatusId.newBusinessReview);
  };

  const setSplitAgentData = () => {
    if (isDtcCustomer) {
      return;
    }
    const inputData = getStarted.inputvalues;
    const SplitAgentData = inputData['#SplitAgentData'];
    if (CommonFunctions.checkIsNullOrUndefined(SplitAgentData) !== '') {
      if (SplitAgentData.length > 0) {
        SplitAgentData.map((agentData, index) => {
          if (index === 0) {
            inputData['#writingAgentPartyId'] = agentData.id;
            inputData['#writingAgentName'] = agentData.name;
            inputData['#writingAgentPercent'] = agentData.percentage;
          } else {
            inputData[`#splitAgent${index}PartyId`] = agentData.id;
            inputData[`#splitAgent${index}Name`] = agentData.name;
            inputData[`#splitAgent${index}Percent`] = agentData.percentage;
          }
          return false;
        });
      } else {
        inputData['#splitAgentTotalPercentage'] = 0;
        inputData['#writingAgentPercent'] = 100;
      }
      dispatch(setInputValues(inputData));
    }
  };

  const pageValidation = () => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const trackerGroupNames = getStarted.trackergroupnames;
    if (getStarted.currentpage.JsonData !== '') {
      trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
      dispatch(setTrackerGroupName(trackerGroupNames));
      getStarted.currentpage.JsonData.forEach(async fields => {
        if (fields.fieldValidation !== undefined) {
          await CommonFunctions.executeFieldValidation(
            fields.fieldValidation,
            fields.fieldName,
            true,
            false,
            getStarted.currentpage.Group,
            getStarted.currentpage.SubGroup,
          );
        }
      });
    } else if (
      getStarted.currentpage.Group === getStarted.trackergroupnames.length - 1 &&
      getStarted.currentpage.SubGroup >= 0
    ) {
      if (
        getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
          .displayname === constants.documentsTabName
      ) {
        const resultDocument = EsignEventValidation.getDocumentPageValidation(getStarted);
        if (!resultDocument) {
          trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
          dispatch(setTrackerGroupName(trackerGroupNames));
        }
        return resultDocument;
      }
      if (
        getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
          .displayname === constants.transferTabName
      ) {
        const resultDocument = EsignEventValidation.getTransferDocumentValidation(getStarted);
        if (!resultDocument) {
          trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
          dispatch(setTrackerGroupName(trackerGroupNames));
        }
        return resultDocument;
      }
      if (
        getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
          .displayname === constants.signTabName
      ) {
        let isAllPartiesSigned = false;
        const esignData = getStarted.partyesignerslist.filter(d => d.signStatus !== 'Signed');
        if (esignData.length === 0) {
          trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
          dispatch(setTrackerGroupName(trackerGroupNames));
          isAllPartiesSigned = true;
        } else {
          trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = false;
          dispatch(setTrackerGroupName(trackerGroupNames));
        }
        return isAllPartiesSigned;
      }
      trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
      dispatch(setTrackerGroupName(trackerGroupNames));
    } else if (getStarted.currentpage.Group === 0 && getStarted.currentpage.SubGroup === 0) {
      if (ProductPickerValidation.getStartedPageValidation(getStarted, ApplicationTypes)) {
        dispatch(setLoading(false));
        return true;
      }
      getStarted.inputvalues = ProductPickerValidation.removeSplitAgentData(getStarted.inputvalues);
      setSplitAgentData();
      trackerGroupNames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].isPageValid = true;
      dispatch(setTrackerGroupName(trackerGroupNames));
      return false;
    }
    return false;
  };

  const nextPageJsonContent = (groupIndex, subGroup) => {
    const jsonContent = CommonFunctions.getNextPageContent(groupIndex, subGroup, getStarted.trackergroupnames);
    const currentPage = { Group: groupIndex, SubGroup: subGroup, JsonData: jsonContent };
    dispatch(setCurrentPage(currentPage));
    const tabName =
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup].displayname;
    window.pendo.track('eAppListofErrors', {
      id: agentPartyId,
      errorMessage: 'The routing number entered is invalid.',
      eappTabName: tabName,
      env: process.env.REACT_APP_ENV,
    });
  };

  const setBeneficiaryTabValue = () => {
    if (
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
        .displayname === constants.beneTabName
    ) {
      const inputData = getStarted.inputvalues;
      inputData['#isBeneficiaryPrimTab'] = true;
      inputData['#isBeneficiaryContingentTab'] = true;
      dispatch(setInputValues(inputData));
    }
  };

  const groupTrackerTabClick = async (event, groupIndex, subGroupIndex) => {
    if (processingAddress) {
      return;
    }
    scrollRef.current.scrollTo(0, 0);
    if (
      getStarted.currentpage.Group === getStarted.trackergroupnames.length - 1 &&
      getStarted.currentpage.SubGroup === 0 &&
      groupIndex === getStarted.trackergroupnames.length - 1 &&
      subGroupIndex > 0 &&
      !getStarted.inputvalues['#isReviewComplete']
    ) {
      const isValidTab = CommonFunctions.getInvalidMenuCount();
      const isValidData = CommonFunctions.checkEappJsonValidation();
      if (isValidTab === null && isValidData === null) {
        if (!Utils.isUserViewOnly()) {
          setEditVisible(true);
        }
      } else {
        NotificationService.error(constants.validationErrorMessages.applicationDataValidation);
      }
    } else if (
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
        .displayname === constants.documentsTabName
    ) {
      const resultDocument = EsignEventValidation.getDocumentPageValidation(getStarted);
      if (pageValidation()) {
        dispatch(setLoading(false));
        return;
      }
      if (!resultDocument || Utils.isUserViewOnly()) {
        nextPageJsonContent(groupIndex, subGroupIndex);
      }
    } else if (
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
        .displayname === constants.signTabName
    ) {
      const esignData = getStarted.partyesignerslist.filter(d => d.signStatus !== 'Signed');
      if (esignData.length === 0) {
        getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[
          getStarted.currentpage.SubGroup
        ].isPageValid = true;
        dispatch(setTrackerGroupName(getStarted.trackergroupnames));
      } else {
        getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[
          getStarted.currentpage.SubGroup
        ].isPageValid = false;
        dispatch(setTrackerGroupName(getStarted.trackergroupnames));
      }
      nextPageJsonContent(groupIndex, subGroupIndex);
    } else if (
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
        .displayname === constants.transferTabName
    ) {
      const resultDocument = EsignEventValidation.getTransferDocumentValidation(getStarted);
      if (!resultDocument) {
        nextPageJsonContent(groupIndex, subGroupIndex);
        getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[
          getStarted.currentpage.SubGroup
        ].isPageValid = true;
        dispatch(setTrackerGroupName(getStarted.trackergroupnames));
      }
    } else if (
      groupIndex === getStarted.trackergroupnames.length - 1 &&
      subGroupIndex === getStarted.trackergroupnames[groupIndex].SubGroup.length - 1 &&
      getStarted.currentpage.SubGroup === 0
    ) {
      const resultDocument = CommonFunctions.validateDocTab();
      if (!resultDocument || Utils.isUserViewOnly()) {
        nextPageJsonContent(groupIndex, subGroupIndex);
      } else {
        NotificationService.error('Complete Document section before proceeding to sign section');
      }
    } else if (
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
        .displayname === constants.fundingTabName
    ) {
      const inputData = getStarted.inputvalues;
      inputData['#isFundingTab'] = true;
      dispatch(setInputValues(inputData));
      if (pageValidation()) {
        dispatch(setLoading(false));
        return;
      }
      nextPageJsonContent(groupIndex, subGroupIndex);
      if (pageValidation()) {
        dispatch(setLoading(false));
        return;
      }
      await saveApplicationData();
    } else if (
      getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
        .displayname === constants.stateReplaceTabName
    ) {
      if (pageValidation()) {
        dispatch(setLoading(false));
        return;
      }
      if (getStarted.inputvalues[`#replacementInProgress`] !== true || Utils.isUserViewOnly()) {
        nextPageJsonContent(groupIndex, subGroupIndex);
        if (pageValidation()) {
          dispatch(setLoading(false));
          return;
        }
        await saveApplicationData();
      } else {
        NotificationService.error(constants.validationErrorMessages.stateReplaceProgress);
      }
    } else {
      if (CommonFunctions.checkIsNullOrUndefined(getStarted.product) !== '') {
        const signedValue = CommonFunctions.setSignedStatesEvent(getStarted.product, appData.allStates);
        setSignedStates(signedValue);
      }
      setBeneficiaryTabValue();
      nextPageJsonContent(groupIndex, subGroupIndex);
      if (pageValidation()) {
        dispatch(setLoading(false));
        return;
      }
      if (CommonFunctions.checkIsNullOrUndefined(getStarted.hidetab) !== '' && getStarted.hidetab.length > 0) {
        const trackerGroupNames = getStarted.trackergroupnames;
        getStarted.hidetab.forEach(item => {
          const data = trackerGroupNames[getStarted.currentpage.Group].SubGroup.findIndex(x => x.displayname === item);
          if (data !== -1) {
            trackerGroupNames[getStarted.currentpage.Group].SubGroup[data].isPageValid = true;
            dispatch(setTrackerGroupName(trackerGroupNames));
          }
        });
      }
      await saveApplicationData();
    }
  };

  const setWritingAgentInfo = () => {
    const inputData = getStarted.inputvalues;
    if (user.postalAddresses.length > 0) {
      user.postalAddresses.map(postalAddress => {
        if (postalAddress.addressType === 'Mailing') {
          inputData['#writingAgentMailingAddr'] = postalAddress.address1;
          inputData['#writingAgentMailingAddr2'] = postalAddress.address2;
          inputData['#writingAgentMailingCity'] = postalAddress.city;
          inputData['#writingAgentMailingState'] = postalAddress.state;
          inputData['#writingAgentMailingZip'] = postalAddress.postalCode;
        }
        if (postalAddress.addressType === 'Business') {
          inputData['#writingAgentOfficeAddr'] = postalAddress.address1;
          inputData['#writingAgentOfficeAddr2'] = postalAddress.address2;
          inputData['#writingAgentOfficeCity'] = postalAddress.city;
          inputData['#writingAgentOfficeState'] = postalAddress.state;
          inputData['#writingAgentOfficeZip'] = postalAddress.postalCode;
        }
        return false;
      });
    }
    dispatch(setInputValues(inputData));
  };

  const getWritingAgentInfo = async () => {
    const inputData = getStarted.inputvalues;
    inputData['#writingAgentPartyId'] = user.id;
    inputData['#writingAgentName'] = `${user.firstName} ${user.lastName}`;
    inputData['#writingAgentEmail'] = user.emailAddresses.length > 0 && user.emailAddresses[0].emailAddress;
    inputData['#writingAgentNPN'] = user.nationalProducerNumber;
    inputData['#writingAgentPhone'] = user.telephoneNumbers.length > 0 && user.telephoneNumbers[0].subscriberNumber;
    inputData['#writingAgentAgencyName'] = user.organizations.length > 0 && user.organizations[0].name;
    setWritingAgentInfo();
    const query = `partyId=${agentPartyId}`;
    const [errorContract, contracts] = await MasterService.getContracts(query);
    if (errorContract !== null) {
      NotificationService.error(constants.serverErrorMessages.errorContract);
    }
    if (user.licenses && user.licenses.length > 0) {
      const agentLicense = user.licenses.filter(x => x.state === getStarted.signedstateabb);
      if (agentLicense.length > 0) {
        inputData['#writingAgentLicenseNumber'] = agentLicense[0].licenseNumber;
      }
    }

    if (contracts && contracts.data.length > 0) {
      contracts.data.map(contractData => {
        if (contractData.products.length > 0) {
          const productData = contractData.products.filter(x => x.id === getStarted.product);
          if (productData.length > 0) {
            inputData['#writingAgentWritingCode'] = contractData.writingCode;
            inputData['#writingAgentContractId'] = contractData.id;
            const uplineId = contractData.upline.id;
            const org = user.organizations.filter(x => x.id === uplineId);

            if (org.length > 0) {
              inputData['#writingAgentAgencyName'] = org[0].name;
              inputData['#IsMSA'] = org[0].hasMasterSellingAgreement;
            }
          }
        }
        return false;
      });
    }
    dispatch(setInputValues(inputData));
  };

  const getJsonSpecification = async () => {
    const inputData = getStarted.inputvalues;
    const payload = {
      productId: getStarted.product,
      signedStateId: getStarted.signedstateid,
      applicationTypeId: getStarted.applicationtype,
      agentPartyId,
      completionPercentage: 6,
      response: getStarted,
    };

    inputData['#signedState'] = getStarted.signedstateabb;
    inputData['#applicationtype'] = getStarted.applicationtype;
    inputData['#product'] = getStarted.product;
    inputData['#signedstateid'] = getStarted.signedstateid;
    inputData['#writingAgentAppointedFlag'] = false;
    inputData['#isReviewComplete'] = false;
    inputData['#splitAgent1AppointedFlag'] = false;
    inputData['#splitAgent2AppointedFlag'] = false;
    inputData['#splitAgent3AppointedFlag'] = false;
    inputData['#applicationDate'] = moment(new Date()).format('MM/DD/YYYY');
    inputData['#applicationTypeName'] = ApplicationTypes.find(p => p.id === getStarted.applicationtype).value;
    inputData['#IsMSA'] = isDtcCustomer ? false : user.organizations[0].hasMasterSellingAgreement;
    inputData['#productName'] = Products.find(p => p.id === getStarted.product).value;
    if (inputData['#applicationTypeName'] !== constants.applicationTypeName.Natural) {
      inputData['#residentStateId'] = getStarted.signedstate;
      inputData['#residentState'] = getStarted.signedstateabb;
    }
    dispatch(setProductName(Products.find(p => p.id === getStarted.product).value));
    if (!isDtcCustomer) {
      await getWritingAgentInfo();
      const eligCheck = await CommonUtils.fnCheckSalesEligibility(getStarted.inputvalues);
      if (eligCheck.length !== 0) {
        dispatch(setEligibilityErrors(eligCheck));
        dispatch(setLoading(false));
        return;
      }
    } else {
      inputData['#writingAgentPartyId'] = agentPartyId;
      inputData['#ownerEmail'] = user.emailAddresses[0].emailAddress;
      inputData['#ownerConfirmEmail'] = user.emailAddresses[0].emailAddress;
      inputData['#loggedInUserEmail'] = user.emailAddresses[0].emailAddress;
      inputData['#loggedInUserPartyId'] = user.id;
      const cr = getStarted.productrates.find(pr => pr.productId === getStarted.product).creditedRates;
      inputData['#creditedRates'] = cr;
    }
    const [PolicyApplicationError, PolicyApplication] = await MasterService.createApplicationPolicyNumber(payload);
    dispatch(setEligibilityErrors([]));
    if (PolicyApplicationError !== null) {
      if (PolicyApplicationError.status === 400) {
        const JsonErrorData = JSON.parse(PolicyApplicationError.data.message);
        dispatch(setEligibilityErrors(JsonErrorData));
        dispatch(setLoading(false));
        return;
      }
      NotificationService.error(PolicyApplicationError.data.message);
      dispatch(setLoading(false));
      return;
    }
    const currentPage = { Group: 0, SubGroup: 1, JsonData: '' };
    dispatch(setCurrentPage(currentPage));
    dispatch(setPolicyApplicationId(PolicyApplication.applicationAlias));
    const inputResData = PolicyApplication.response;
    inputResData['#applicationStatusId'] = PolicyApplication.applicationStatusId;
    inputResData['#applicationAliasId'] = PolicyApplication.id;
    dispatch(setInputValues(inputResData));
    CommonFunctions.getSpecificationName();
    await BrowserHelper.saveBrowserInfo(
      inputResData['#applicationAliasId'],
      `App Created with ${PolicyApplication.applicationAlias}`,
    );
  };

  const checkRadioValue = value => {
    let checkBool = null;
    constants.radioTrueValue.forEach(itm => {
      if (value === itm) {
        checkBool = true;
      }
    });
    constants.radioFalseValue.forEach(itm => {
      if (value === itm) {
        checkBool = false;
      }
    });
    checkBool = checkBool === null ? value : checkBool;
    return checkBool;
  };

  const noCopyModalClose = () => {
    const inputData = getStarted.inputvalues;
    inputData['#openCopyInfoPopUp'] = false;
    dispatch(setInputValues(inputData));
  };

  const copyAppnumber = () => {
    const inputData = getStarted.inputvalues;
    let copyNumber = '';
    if (inputData['#hasCloneAppData']) {
      const clonedAppData = inputData['#cloneAppData'];
      copyNumber = clonedAppData['#cloneapplicationId'];
    }
    return copyNumber;
  };

  const yesCopyModalClose = async () => {
    const inputData = getStarted.inputvalues;

    if (inputData['#cloneAppData']) {
      dispatch(setLoading(true));
      const clonedAppData = inputData['#cloneAppData'];

      Object.keys(clonedAppData).forEach(data => {
        const clonedData = data.replace('clone', '');
        if (!constants.nonClonedFields.includes(clonedData)) {
          inputData[clonedData] = clonedAppData[data];
        }
      });

      inputData['#openCopyInfoPopUp'] = false;
      inputData['#haslockCopyData'] = true;

      dispatch(setInputValues(inputData));
      getStarted.currentpage.JsonData.map(async fields => {
        if (fields.fieldName === '#ownerCopyApplicationsRadio') {
          await CommonFunctions.executeFieldValidation(fields.fieldValidation, fields.fieldName, false, false);
        }
      });
      dispatch(setLoading(false));
    }
  };

  const getAddressPrefix = name => {
    const prefixObject = addressNames();
    let newPrefix = '';

    Object.keys(prefixObject).forEach(prefix => {
      const p = Object.values(prefixObject[prefix]);
      if (p.includes(name.slice(1))) {
        newPrefix = prefix;
      }
    });
    if (newPrefix !== '') {
      setCurrentPrefix(newPrefix);
    }
    return newPrefix;
  };

  const hasAddressChanged = (name, value) => {
    const hasChanged = addresses[name] !== value;
    const inputData = getStarted.inputvalues;
    const prefix = getAddressPrefix(name);
    const isAddressComponent = !(prefix === '');
    if (isAddressComponent && hasChanged) {
      setProcessingAddress(true);
      inputData[`#${prefix}IsValidated`] = false;
      setTimeout(() => {
        if (
          CommonFunctions.checkIsNullOrUndefined(inputData[`#${prefix}Addr`]) !== '' &&
          CommonFunctions.checkIsNullOrUndefined(inputData[`#${prefix}City`]) !== '' &&
          CommonFunctions.checkIsNullOrUndefined(inputData[`#${prefix}State`]) !== '' &&
          CommonFunctions.checkIsNullOrUndefined(inputData[`#${prefix}Zip`]) !== '' &&
          addressModalVisible === false
        ) {
          setAddressModalVisible(true);
        }
        setProcessingAddress(false);
      }, '3000');
    }
    return isAddressComponent && hasChanged;
  };

  const widgetOnChangeEvent = async (widgetObject, fieldValidations, widgetType, fieldName) => {
    const inputData = getStarted.inputvalues;
    let fldName = fieldName;
    let fldValidations = fieldValidations;
    let isSave = false;

    switch (widgetType) {
      case 'date':
        inputData[fieldName] = widgetObject;
        if (CommonFunctions.checkIsNullOrUndefined(widgetObject) !== '') {
          if (widgetObject.type === 'blur') {
            if (moment(widgetObject.target.value, 'MM/DD/YYYY', true).isValid()) {
              inputData[fieldName] = moment(widgetObject.target.value).format('MM/DD/YYYY');
            } else {
              inputData[fieldName] = null;
            }
            isSave = true;
          } else if (moment(widgetObject, 'MM/DD/YYYY').isValid()) {
            isSave = true;
            inputData[fieldName] = moment(widgetObject).format('MM/DD/YYYY');
          } else {
            fldValidations = undefined;
          }
        } else {
          fldValidations = undefined;
        }
        break;
      case 'button':
        inputData[fieldName] = true;
        isSave = true;
        break;
      case 'percent':
        isSave = true;
        inputData[fieldName] =
          widgetObject.target.value === '' ? '' : parseFloat(widgetObject.target.value.replace('%', ''), 0);
        break;
      case 'currency':
        isSave = true;
        inputData[fieldName] =
          widgetObject.target.value === ''
            ? ''
            : Math.abs(parseFloat(widgetObject.target.value.replace('$', '').replace(/,/g, '')));
        inputData[`${fieldName}String`] = widgetObject.target.value;
        break;
      case 'numeric':
        isSave = true;
        inputData[fieldName] = widgetObject.target.value === '' ? '' : parseInt(widgetObject.target.value, 10);
        break;
      case 'textbox':
        isSave = true;
        inputData[fieldName] = widgetObject.target.value.replace(/[^a-zA-Z!@#$%^&* ]/g, '');
        break;
      case 'radio':
      case 'radioQuestion':
        isSave = true;
        if (widgetObject.target === undefined) inputData[fieldName] = widgetObject;
        else if (widgetObject.target.textContent === 'Yes') inputData[fieldName] = true;
        else if (widgetObject.target.textContent === 'No') inputData[fieldName] = false;
        else if (widgetObject.target.textContent !== '')
          inputData[fieldName] = checkRadioValue(widgetObject.target.textContent);
        break;
      case 'checkbox':
        isSave = true;
        if (document.getElementsByName(fieldName).length === 1) {
          if (widgetObject.target.checked) {
            inputData[fieldName] = widgetObject.target.value;
          } else {
            inputData[fieldName] = '';
          }
        } else if (document.getElementsByName(fieldName).length > 0) {
          let checkboxValues = '';
          document.getElementsByName(fieldName).forEach(item => {
            if (item.checked) {
              if (checkboxValues !== '') checkboxValues = `${checkboxValues},${item.value}`;
              else checkboxValues = item.value;
            }
          });
          inputData[fieldName] = checkboxValues;
        }
        break;
      default:
        isSave = true;
        if (widgetObject.target.name === undefined) inputData[fldName] = widgetObject.target.value;
        else inputData[widgetObject.target.name] = widgetObject.target.value;
        break;
    }

    if (fieldValidations && widgetType !== 'date') {
      hasAddressChanged(
        CommonFunctions.checkIsNullOrUndefined(widgetObject) !== '' && widgetObject.target?.name
          ? widgetObject.target.name
          : fieldName,
        widgetObject.target?.value,
      );
    }

    if (fldName === undefined) fldName = String(widgetObject.target.name);
    if (fldValidations) {
      await CommonFunctions.executeFieldValidation(
        fldValidations,
        fldName,
        true,
        true,
        null,
        null,
        undefined,
        fieldName,
      );
    } else if (isSave) {
      dispatch(setInputValues(inputData));
    }
  };
  /*
   *  eApp next button click event
   */
  const jsonPageLoad = (currentGrp, currentSubGrp) => {
    let currentGroup = currentGrp;
    let currentSubGroup = currentSubGrp;
    let totalSubTabCount = 0;
    let validTabCount = 0;
    const state = store.getState();
    if (typeof getStarted.trackergroupnames[currentGroup] !== 'undefined') {
      if (typeof getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup] === 'undefined') {
        currentGroup += 1;
        currentSubGroup = 0;
      }
    }
    if (
      CommonFunctions.checkIsNullOrUndefined(getStarted.hidetab) !== '' &&
      getStarted.hidetab.length > 0 &&
      getStarted.hidetab.filter(
        v => v === getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname,
      ).length === 1
    ) {
      getStarted.hidetab.forEach(itm => {
        if (getStarted.trackergroupnames[currentGroup].SubGroup.filter(p => p.displayname === itm).length > 0) {
          validTabCount += getStarted.trackergroupnames[currentGroup].SubGroup.filter(p => p.displayname === itm)
            .length;
          const data = getStarted.trackergroupnames[currentGroup].SubGroup.findIndex(x => x.displayname === itm);
          if (data !== -1) {
            getStarted.trackergroupnames[currentGroup].SubGroup[data].isPageValid = true;
          }
        }
      });
      totalSubTabCount = getStarted.trackergroupnames[currentGroup].SubGroup.length;
      if (totalSubTabCount === validTabCount) {
        currentGroup += 1;
        currentSubGroup = 0;
      } else if (currentSubGroup === getStarted.trackergroupnames[currentGroup].SubGroup.length - 1) {
        currentGroup += 1;
        currentSubGroup = 0;
      } else {
        currentSubGroup += 1;
      }
    }
    if (
      getStarted.inputvalues['#writingAgentEmail'] === constants.automationEmailId &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.transferTabName
    ) {
      currentSubGroup += 1;
    }
    if (
      CommonFunctions.getCompanySignData().length === 0 &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.transferTabName
    ) {
      currentSubGroup += 1;
    }
    if (
      getStarted.inputvalues['#writingAgentEmail'] === constants.automationEmailId &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.documentsTabName
    ) {
      currentSubGroup += 1;
    }
    if (
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ownerPOA']) === '' ||
        getStarted.inputvalues['#ownerPOA'] === false) &&
      CommonFunctions.getCheckToFollow().length === 0 &&
      CommonFunctions.getCompanySignData().filter(x => x.transferFW !== '').length === 0 &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('owner')) === '' &&
      CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isDtcCustomer']) === false &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('jointOwner')) === '' &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('annuitant')) === '' &&
      getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Natural &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.documentsTabName &&
      (CommonFunctions.checkIsNullOrUndefined(state.getStarted.inputvalues['#IsMSAwithBackoffice']) === '' ||
        state.getStarted.inputvalues['#IsMSAwithBackoffice'] === false)
    ) {
      currentSubGroup += 1;
    }

    nextPageJsonContent(currentGroup, currentSubGroup);
  };

  const eAppNextPageEvent = async isReviewSuccess => {
    scrollRef.current.scrollTo(0, 0);

    if (
      getStarted.currentpage.Group === getStarted.trackergroupnames.length - 1 &&
      getStarted.currentpage.SubGroup === 0 &&
      isReviewSuccess === 0 &&
      !getStarted.inputvalues['#isReviewComplete']
    ) {
      const isValidTab = CommonFunctions.getInvalidMenuCount();
      const isValidData = CommonFunctions.checkEappJsonValidation();
      if (isValidTab === null && isValidData === null) {
        setEditVisible(true);
      } else {
        NotificationService.error(constants.validationErrorMessages.applicationDataValidation);
      }
    } else {
      const currentGroup = getStarted.currentpage.Group;
      const currentSubGroup = getStarted.currentpage.SubGroup + 1;
      setBeneficiaryTabValue();
      if (pageValidation()) {
        dispatch(setLoading(false));
        return;
      }
      if (isReviewSuccess === 0) {
        jsonPageLoad(currentGroup, currentSubGroup);
        await saveApplicationData();
      } else {
        dispatch(setLoading(true));
        await saveApplicationData(constants.applicationStatusId.reviewComplete);
        const state = store.getState();
        if (
          state.getStarted.inputvalues['#isReviewComplete'] &&
          CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('eAppPolicyData')) !== ''
        ) {
          jsonPageLoad(currentGroup, currentSubGroup);
        }
        dispatch(setLoading(false));
      }
    }
  };
  /*
   * eApp previous button click event
   */
  const eAppPreviousPageEvent = async () => {
    let currentGroup = getStarted.currentpage.Group;
    let currentSubGroup = getStarted.currentpage.SubGroup - 1;
    let totalSubTabCount = 0;
    let validTabCount = 0;
    setBeneficiaryTabValue();
    if (pageValidation()) {
      dispatch(setLoading(false));
      return;
    }
    if (typeof getStarted.trackergroupnames[currentGroup] !== 'undefined') {
      if (typeof getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup] === 'undefined') {
        currentGroup -= 1;
        currentSubGroup = getStarted.trackergroupnames[currentGroup].SubGroup.length - 1;
      }
    }
    if (
      CommonFunctions.checkIsNullOrUndefined(getStarted.hidetab) !== '' &&
      getStarted.hidetab.length > 0 &&
      getStarted.hidetab.filter(
        v => v === getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname,
      ).length === 1
    ) {
      getStarted.hidetab.forEach(itm => {
        if (getStarted.trackergroupnames[currentGroup].SubGroup.filter(p => p.displayname === itm).length > 0) {
          validTabCount += getStarted.trackergroupnames[currentGroup].SubGroup.filter(p => p.displayname === itm)
            .length;
          const data = getStarted.trackergroupnames[currentGroup].SubGroup.findIndex(x => x.displayname === itm);
          if (data !== -1) {
            getStarted.trackergroupnames[currentGroup].SubGroup[data].isPageValid = true;
          }
        }
      });
      totalSubTabCount = getStarted.trackergroupnames[currentGroup].SubGroup.length;
      if (totalSubTabCount === validTabCount) {
        currentGroup -= 1;
        currentSubGroup = getStarted.trackergroupnames[currentGroup].SubGroup.length - 1;
      } else if (currentSubGroup === 0) {
        currentGroup -= 1;
        currentSubGroup = getStarted.trackergroupnames[currentGroup].SubGroup.length - 1;
      } else {
        currentSubGroup -= 1;
      }
    }
    if (
      getStarted.inputvalues['#writingAgentEmail'] === constants.automationEmailId &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.documentsTabName
    ) {
      currentSubGroup -= 1;
    }
    if (
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ownerPOA']) === '' ||
        getStarted.inputvalues['#ownerPOA'] === false) &&
      CommonFunctions.getCheckToFollow().length === 0 &&
      CommonFunctions.getCompanySignData().filter(x => x.transferFW !== '').length === 0 &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('owner')) === '' &&
      CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isDtcCustomer']) === false &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('jointOwner')) === '' &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('annuitant')) === '' &&
      getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Natural &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.documentsTabName &&
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#IsMSAwithBackoffice']) === '' ||
        getStarted.inputvalues['#IsMSAwithBackoffice'] === false)
    ) {
      currentSubGroup -= 1;
    }
    if (
      getStarted.inputvalues['#writingAgentEmail'] === constants.automationEmailId &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.transferTabName
    ) {
      currentSubGroup -= 1;
    }
    if (
      CommonFunctions.getCompanySignData().length === 0 &&
      getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname === constants.transferTabName
    ) {
      currentSubGroup -= 1;
    }
    if (
      CommonFunctions.checkIsNullOrUndefined(getStarted.hidetab) !== '' &&
      getStarted.hidetab.length > 0 &&
      getStarted.hidetab.filter(
        v => v === getStarted.trackergroupnames[currentGroup].SubGroup[currentSubGroup].displayname,
      ).length === 1
    ) {
      currentSubGroup -= 1;
    }
    nextPageJsonContent(currentGroup, currentSubGroup);
    await saveApplicationData();
    scrollRef.current.scrollTo(0, 0);
  };

  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    neighborhood: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };

  const addressCallBack = (addressAutoCompleteObject, fieldName, addressPrefixName) => {
    const state = store.getState();
    const inputData = state.getStarted.inputvalues;
    const places = addressAutoCompleteObject.getPlace();
    let streeNumber = '';
    let address = '';
    places.address_components.map(element => {
      const addressType = element.types[0];
      if (componentForm[addressType]) {
        switch (addressType) {
          case 'administrative_area_level_1':
            inputData[`${addressPrefixName}State`] = element[componentForm[addressType]];
            break;
          case 'street_number':
            streeNumber = element[componentForm[addressType]];
            break;
          case 'route':
            address = element[componentForm[addressType]];
            break;
          case 'locality':
          case 'neighborhood':
            inputData[`${addressPrefixName}City`] = element[componentForm[addressType]];
            break;
          case 'postal_code':
            inputData[`${addressPrefixName}Zip`] = element[componentForm[addressType]];
            break;
          default:
            break;
        }
        inputData[fieldName] = `${streeNumber} ${address}`.trim();
      }
      return false;
    });
    hasAddressChanged(fieldName, ''); // '' causes it to always set to true
    getStarted.currentpage.JsonData.map(async fld => {
      if (fld.fieldName === fieldName) {
        await CommonFunctions.executeFieldValidation(fld.fieldValidation, fieldName, true, false);
      }
      return false;
    });
    dispatch(setInputValues(inputData));
  };

  const addressFieldOnChange = () => {
    getStarted.currentpage.JsonData.map(async fld => {
      await CommonFunctions.executeFieldValidation(fld.fieldValidation, fld.fieldName, true, false);
    });
  };

  const noModalClose = () => {
    setEditVisible(false);
  };

  const yesModalClose = () => {
    setEditVisible(false);
    eAppNextPageEvent(1);
  };

  const suitabilityWaiverConfirm = () => {
    setSuitabilityWaiverVisible(false);
    const inputData = { ...getStarted.inputvalues };
    inputData['#suitWaiverVisible'] = true;
    inputData['#annuityTypeQues'] = 'MYGA';
    ProductPickerValidation.handleProductPickOnchangeEvent(constants.applicationTypeId.Natural, 'A');
    store.dispatch(setInputValues({ ...getStarted.inputvalues, ...inputData }));
  };

  const productOnChange = async (event, field, fieldName, index) => {
    if (field === 'P') {
      const signedValue = CommonFunctions.setSignedStatesEvent(event.target.value, appData.allStates);
      setSignedStates(signedValue);
      dispatch(setApprovedProducts(signedValue));
    }
    if (isDtcCustomer) {
      ProductPickerValidation.handleProductPickOnchangeEvent(false, 'radio', '#ifSplitCommission');
    }
    ProductPickerValidation.handleProductPickOnchangeEvent(event, field, fieldName, index);
    if (field === 'AQ') {
      await getProducts();
    }
  };

  const updateSplitAgentData = async () => {
    const state = store.getState();
    const applicationData = state.getStarted;
    const completionPercentage = 7;
    const payload = {
      applicationId: applicationData.policyapplicationid,
      completionPercentage:
        CommonFunctions.checkIsNullOrUndefined(applicationData.trackergroupnames) === ''
          ? completionPercentage
          : GroupTrackerFunctions.calculateCompletePercentage(applicationData.trackergroupnames),
      agentPartyId,
      applicationStatusId: applicationData.inputvalues['#applicationStatusId'],
      response: applicationData,
      productId: applicationData.product,
      signedStateId: applicationData.signedstateid,
      applicationTypeId: applicationData.applicationtype,
    };
    const [applicationError] = await MasterService.saveApplicationData(payload);
    dispatch(setEligibilityErrors([]));
    if (CommonFunctions.checkIsNullOrUndefined(applicationError) !== '') {
      if (applicationError.status === 400) {
        const JsonErrorData = JSON.parse(applicationError.data.message);
        dispatch(setEligibilityErrors(JsonErrorData));
        dispatch(setLoading(false));
        return;
      }
    }
    eAppNextPageEvent(0);
  };

  const productPageButtonClick = () => {
    dispatch(setLoading(true));
    if (ProductPickerValidation.getStartedPageValidation(getStarted, ApplicationTypes)) {
      dispatch(setLoading(false));
      return;
    }
    getStarted.inputvalues = ProductPickerValidation.removeSplitAgentData(getStarted.inputvalues);
    setSplitAgentData();
    if (getStarted.policyapplicationid !== '') {
      updateSplitAgentData();
      dispatch(setLoading(false));
    } else {
      getJsonSpecification();
    }
    return false;
  };

  const productValidate = () => {
    if (ProductPickerValidation.getStartedPageValidation(getStarted, ApplicationTypes)) {
      dispatch(setLoading(false));
      return;
    }
    if (getStarted.policyapplicationid === '') {
      setConfirmVisible(true);
    } else {
      productPageButtonClick();
    }
    return false;
  };

  const noEditModalClose = () => {
    setModalEditVisible(false);
  };

  const yesEditModalClose = async () => {
    setModalEditVisible(false);
    const userObj = user;
    const inputData = { ...getStarted.inputvalues };
    store.dispatch(setInputValues({ ...getStarted.inputvalues, ...inputData }));
    store.dispatch(setLoading(true));
    const [appDataError] = await MasterService.editApplication(getStarted.policyapplicationid);
    if (appDataError !== null) {
      NotificationService.error(appDataError.data.message);
    }
    const message = `Agent Clicked Edit button inbetween filling app in
    ${BrowserHelper.getTabName(getStarted, getStarted.currentpage.Group, getStarted.currentpage.SubGroup)}`;
    BrowserHelper.saveBrowserInfo(getStarted.inputvalues['#applicationAliasId'], message);
    store.dispatch(setLoading(false));
    let isViewOnly = false;
    const viewOnlyRole = RoleHelper.userHasRoles(userObj, ROLES.VIEWONLY);
    if (viewOnlyRole) {
      isViewOnly = true;
    }
    let hasDtcCustomer = false;
    if (RoleHelper.userHasRoles(userObj, ROLES.DTCCUCTOMER)) {
      hasDtcCustomer = true;
    }
    store.dispatch(
      setAuthenticatedUser({ ...userObj, verified: userObj.verified, isViewOnly, isDtcCustomer: hasDtcCustomer }),
    );
    store.dispatch(setReset(getStarted));
    sessionStorage.removeItem('eAppPolicyData');
    navigate('/getStarted');
    navigate(`/applications/${getStarted.policyapplicationid}`);
  };

  const onYesClose = () => {
    setConfirmVisible(false);
    productPageButtonClick();
  };
  const onNoClose = () => {
    setConfirmVisible(false);
  };

  const onAddressModalClose = currPre => {
    // track last validated address to compare against
    const inputData = getStarted.inputvalues;
    const newAddresses = _.cloneDeep(addresses);
    newAddresses[`#${currPre}Addr`] = inputData[`#${currPre}Addr`];
    newAddresses[`#${currPre}Addr2`] = inputData[`#${currPre}Addr2`];
    newAddresses[`#${currPre}City`] = inputData[`#${currPre}City`];
    newAddresses[`#${currPre}State`] = inputData[`#${currPre}State`];
    newAddresses[`#${currPre}Zip`] = inputData[`#${currPre}Zip`];
    setAddresses(newAddresses);

    addressFieldOnChange();
    setAddressModalVisible(false);
  };

  const handleLoadedz = () => {
    if (isDtcCustomer) {
      ZendeskAPI('messenger', 'loginUser', callBack => {
        callBack(zendeskChatToken);
      });
    }
  };

  return (
    <div className={classes.contentContainer}>
      {addressModalVisible && (
        <AddressModal open={addressModalVisible} onClose={onAddressModalClose} currentPrefix={currentPrefix} />
      )}
      {isDtcCustomer && <ZendeskChat onLoaded={handleLoadedz} />}
      <SuitabilityWaiver visible={suitabilityWaiverVisible} onConfirm={suitabilityWaiverConfirm} />
      <YesNoModal visible={editVisible} onNoClose={noModalClose} onYesClose={yesModalClose} />
      <ConfirmModal
        visible={confirmVisible}
        onYesClose={onYesClose}
        onNoClose={onNoClose}
        isDtcCustomer={isDtcCustomer}
      />
      <EditModal editVisible={editModalVisible} onNoEditClose={noEditModalClose} onYesEditClose={yesEditModalClose} />
      <CopyConfirmation
        visible={getStarted.inputvalues['#openCopyInfoPopUp']}
        applicationAlias={copyAppnumber()}
        onNoClose={noCopyModalClose}
        onYesClose={yesCopyModalClose}
      />
      {getStarted.loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <Box className={classes.formContainer}>
          <Box className={classes.headerSection}>
            <Header errorLists={getStarted.errorlists} onTabClick={groupTrackerTabClick} />
          </Box>
          <Grid
            container
            spacing={0}
            className={getStarted.IsSubmitted !== 0 ? classes.tabPanelSectionEsign : classes.tabPanelSection}
          >
            {getStarted.IsSubmitted === 0 && (
              <GroupTracker onTabClick={groupTrackerTabClick} errorLists={getStarted.errorlists} />
            )}
            <Grid
              ref={scrollRef}
              container
              spacing={0}
              className={getStarted.IsSubmitted !== 0 ? classes.tabPanelSectionEsign : classes.tabPanelSectionnew}
            >
              <TabPanel index={0} className={classes.tabPanelWidth}>
                <Grid container spacing={2}>
                  {getStarted.currentpage.Group === 0 && getStarted.currentpage.SubGroup === 0 ? (
                    <>
                      {isDtcCustomer ? (
                        <DtcProductPicker
                          onClick={productValidate}
                          onChange={productOnChange}
                          Products={Products}
                          ApplicationTypes={ApplicationTypes}
                          SignedStates={SignedStates}
                          ApprovedStates={ApprovedStates}
                          ProductRates={getStarted.productrates}
                        />
                      ) : (
                        <ProductPicker
                          onClick={productValidate}
                          onChange={productOnChange}
                          Products={Products}
                          ApplicationTypes={ApplicationTypes}
                          SignedStates={SignedStates}
                          ApprovedStates={ApprovedStates}
                        />
                      )}
                    </>
                  ) : (
                    <RenderFormValidation
                      onChange={widgetOnChangeEvent}
                      placeChangedCallBack={addressCallBack}
                      onTabClick={groupTrackerTabClick}
                    />
                  )}
                  {getStarted.currentpage.Group === getStarted.trackergroupnames.length - 1 &&
                    getStarted.currentpage.SubGroup !== 0 && (
                      <Esign
                        esignRoleName={roleName}
                        onClick={eSignButtonClick}
                        onChange={EsignEventValidation.handleEsignOnchangeEvent}
                        onTabClick={groupTrackerTabClick}
                      />
                    )}
                </Grid>
                {(getStarted.currentpage.Group === 0 && getStarted.currentpage.SubGroup === 0) ||
                getStarted.IsSubmitted !== 0 ? (
                  ''
                ) : (
                  <Box>
                    {CommonFunctions.checkIsNullOrUndefined(getStarted.saleseligibilityerrors) !== '' &&
                      getStarted.saleseligibilityerrors.length > 0 &&
                      getStarted.saleseligibilityerrors.map(eligibilityError => {
                        return (
                          <ErrorNotification
                            errorText={eligibilityError.errorMessage}
                            gotoText={eligibilityError.linkText}
                            linkText={eligibilityError.link}
                            agentName={getStarted.inputvalues['#ifSplitCommission'] && eligibilityError.agentName}
                          />
                        );
                      })}
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                      className={classes.rowButton}
                    >
                      {getStarted.currentpage.Group > 0 && (
                        <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridAlignLeftBackButton}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={eAppPreviousPageEvent}
                            disabled={getStarted.inputvalues['#replacementInProgress'] || Utils.isUserViewOnly()}
                          >
                            BACK
                          </Button>
                        </Grid>
                      )}
                      {getStarted.currentpage.Group === getStarted.trackergroupnames.length - 1 &&
                      getStarted.currentpage.SubGroup === 3 ? (
                        <Grid item xs={12} sm={6} md={3} lg={2} className={classes.displayNone}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => eSignSubmitClick(4, true)}
                            disabled={
                              (CommonFunctions.checkIsNullOrUndefined(getStarted.partyesignerslist) !== '' &&
                                getStarted.partyesignerslist.length > 0) ||
                              getStarted.inputvalues['#applicationStatusId'] ===
                                constants.applicationStatusId.newBusinessReview ||
                              Utils.isUserViewOnly()
                            }
                          >
                            SUBMIT
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => eAppNextPageEvent(0)}
                            disabled={
                              getStarted.inputvalues['#replacementInProgress'] ||
                              Utils.isUserViewOnly() ||
                              processingAddress
                            }
                          >
                            NEXT
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
Eapp.propTypes = {
  id: PropTypes.string,
};
