export default {
  label: {
    fontWeight: 600,
  },
  paperEmptySign: {
    padding: '3.75rem',
    boxShadow: '0rem 0.1875rem 0.375rem #00000029',
    border: '0.03125rem solid #ABABAB',
    borderRadius: '0.25rem 0rem 0rem 0rem',
    opacity: 1,
    width: '65%',
    fontFamily: 'Allura, Regular',
    textAlign: 'center',
  },
  paperSign: {
    padding: '30rem',
    boxShadow: '0rem 0.1875rem 0.375rem #00000029',
    border: '0.03125rem solid #ABABAB',
    borderRadius: '0.25rem 0rem 0rem 0rem',
    opacity: 1,
    width: '50%',
    textAlign: 'center',
  },
  orLabel: {
    color: '#4D4D4D',
    marginTop: '6rem',
    textAlign: 'center',
  },
  open: {
    color: '#fff',
    backgroundColor: '#EF426F',
  },
  gridAlignCenterExitButton: {
    textAlign: 'center',
  },
  gridAlignRightBackButton: {
    textAlign: 'right',
  },
  gridAlignLeftBackButton: {
    textAlign: 'left',
  },
  rowButton: {
    padding: '2rem',
  },
  KBA: {
    marginBottom: '0.9375rem',
  },
  signature: {
    boxShadow: '0rem 0.1875rem 0.375rem #00000029',
    border: '0.03125rem solid #ABABAB',
    borderRadius: '0.25rem 0rem 0rem 0rem',
    opacity: 1,
    width: '100%',
    height: '9.375rem',
  },
};
