/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import styles from './style';

const useStyles = makeStyles(styles);

function UploadBtnComponent(props) {
  const classes = useStyles();

  const { name, displayText, uploadFile, isDisabled, ...rest } = props;

  return (
    <div>
      <input
        disabled={isDisabled}
        accept=".jpg, .jpeg, .png, .pdf, .gif"
        className={classes.input}
        id={name}
        name={name}
        multiple
        type="file"
        onChange={e => {
          uploadFile(e);
          e.target.value = null;
        }}
      />
      <label htmlFor={name}>
        <Button variant="contained" disabled={isDisabled} component="span" {...rest}>
          {displayText}
        </Button>
      </label>
    </div>
  );
}

UploadBtnComponent.propTypes = {
  name: PropTypes.string,
  uploadFile: PropTypes.func.isRequired,
  displayText: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default UploadBtnComponent;
