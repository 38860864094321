import React, { useState } from 'react';
import store from 'redux/store';
import useAsyncEffect from 'use-async-effect';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { LoadingButton } from '@aspida/react-components';
import HouseSearchLogo from '@aspida/react-components/dist/houseSearch.svg';
import NotificationService from 'services/notificationService';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import commonFunctions from 'views/eapp/utils/commonFunctions';
import { AddressTypes, defaultUspsAddress, googleToString, uspsToString } from './utils';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddressModal = ({ open, onClose, currentPrefix }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const userAddress = {
    // usps only has Address2 as required, like our address1
    address1: inputData[`#${currentPrefix}Addr`],
    address2: inputData[`#${currentPrefix}Addr2`] ?? '',
    city: inputData[`#${currentPrefix}City`] ?? '',
    state: inputData[`#${currentPrefix}State`] ?? '',
    postalCode: inputData[`#${currentPrefix}Zip`] ?? '',
  };

  const [uspsAddress, setUspsAddress] = useState(defaultUspsAddress);
  const [selectedAddress, setSelectedAddress] = useState(AddressTypes.user);
  const [errMessage, setErrMessage] = useState();

  useAsyncEffect(async () => {
    // Load the address from USPS
    if (open) {
      setLoading(true);
      const [err, response] = await commonFunctions.validateAddress(currentPrefix);
      if (err) {
        NotificationService.error('Unable to load address.');
        setLoading(false);
        return;
      }

      setLoading(false);
      setUspsAddress(response ?? defaultUspsAddress);

      // If USPS sent back an error, show it to the user
      if (response && response.AddressValidateResponse && response.AddressValidateResponse.Address) {
        setErrMessage(response.AddressValidateResponse.Address.Error.Description);
      } else {
        setErrMessage(undefined);
      }
    }
  }, []);

  const onSubmit = () => {
    if (selectedAddress === AddressTypes.usps) {
      // Overwrite the user's address with the USPS address
      // Reset the form with the new address
      // usps only has Address2 as required, like our address1
      inputData[`#${currentPrefix}Addr`] = uspsAddress.AddressValidateResponse.Address2;
      inputData[`#${currentPrefix}Addr2`] = uspsAddress.AddressValidateResponse.Address1 ?? '';
      inputData[`#${currentPrefix}City`] = uspsAddress.AddressValidateResponse.City ?? '';
      inputData[`#${currentPrefix}State`] = uspsAddress.AddressValidateResponse.State ?? '';
      inputData[`#${currentPrefix}Zip`] = uspsAddress.AddressValidateResponse.Zip5 ?? '';
    }
    inputData[`#${currentPrefix}IsValidated`] = true;
    setErrMessage(undefined);
    onClose(currentPrefix);
  };

  const handleChange = event => {
    setSelectedAddress(event.target?.value);
  };

  const handleAcceptInvalidAddress = () => {
    setSelectedAddress(AddressTypes.user);
    onSubmit();
  };

  return (
    <div>
      {errMessage ? (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle onClose={onClose}>Verify Address</DialogTitle>
          <DialogContent>
            <Grid item container justifyContent="center" alignItems="center" md={12} xs={12}>
              <Box>The address you have entered is invalid.</Box>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" md={12} xs={12}>
              <Box className={classes.pinkCircle}>
                <Grid item container justifyContent="center" alignItems="center">
                  <img src={HouseSearchLogo} className={classes.houseSearchLogo} alt="logo" />
                </Grid>
              </Box>
            </Grid>
            <DialogActions>
              <Grid container justifyContent="center">
                <LoadingButton
                  className="dialogButton"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={handleAcceptInvalidAddress}
                >
                  I Understand
                </LoadingButton>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogTitle>Verify Address</DialogTitle>
          <DialogContent style={{ overflowY: 'hidden' }}>
            <FormControl>
              <Grid container>
                <Grid item md={6} xs={6}>
                  <FormLabel>Address entered</FormLabel>
                </Grid>
                <Grid item md={6} xs={6}>
                  <FormLabel>We recommend</FormLabel>
                </Grid>
              </Grid>
              <RadioGroup
                className={classes.radioGroup}
                aria-labelledby="radio-buttons-group"
                name="radio-buttons-group"
                value={selectedAddress}
                onChange={handleChange}
                row
              >
                <Box
                  className={selectedAddress === AddressTypes.user ? classes.radioSelected : classes.radioNotSelected}
                >
                  <FormControlLabel
                    name="fcl-user"
                    value={AddressTypes.user}
                    control={<Radio color="primary" />}
                    label={googleToString(userAddress)}
                    labelPlacement="end"
                  />
                </Box>
                <Box
                  className={selectedAddress === AddressTypes.usps ? classes.radioSelected : classes.radioNotSelected}
                >
                  <FormControlLabel
                    name="fcl-usps"
                    value={AddressTypes.usps}
                    control={<Radio color="primary" />}
                    label={uspsToString(uspsAddress)}
                    labelPlacement="end"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <Button
                className="dialogButton"
                variant="contained"
                color="secondary"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? <CircularProgress size={16} /> : 'Save'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

AddressModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentPrefix: PropTypes.string,
};

export default AddressModal;
