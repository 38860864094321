/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { makeStyles, Button, Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from 'use-async-effect';
import { Send, Gesture, Reply, Cancel, People, Email, AccessTime, CheckCircle, GetApp } from '@material-ui/icons';
import moment from 'moment';
import IFrameModal from 'components/docSignIframe';
import constants from 'constants/common';
import store from 'redux/store';
import { setInputValues, setPartyEsignersList, setIsSubmitted, setTrackerGroupName } from 'redux/getstarted/actions';
import Utils from 'views/eapp/renderforms/utils';
import BrowserHelper from 'helpers/browserHelper';
import MasterService from 'api/masterService';
import GroupTrackerFunctions from 'views/eapp/utils/groupTrackerFunctions';
import NotificationService from 'services/notificationService';
import CommonFunctions from '../../../utils/commonFunctions';
import styles from './style';

const useStyles = makeStyles(styles);

export default function SignHome(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const [esignersList, setEsignersList] = useState([]);
  const [signURL, setSignURL] = useState('');
  const [signatureID, setSignatureID] = useState('');
  const [signPartyID, setPartyID] = useState('');
  const [signatureRequestID, setSignatureReqID] = useState('');
  const [editVisible, setEditVisible] = useState(false);
  const [esignatureData, setEsignatureData] = useState([]);
  const [isLoading, setEsignLoading] = useState(false);
  const state = store.getState();

  const esignTypes = [
    { id: true, value: 'In Person' },
    { id: false, value: 'Remote' },
  ];
  const esignRemote = [{ id: false, value: 'Remote' }];

  const updateJownerEmail = esignData => {
    const userRoles = ['Annuitant', 'Joint Owner'];
    esignData.forEach((main, esignIndex) => {
      let email = null;
      const ownerEsignData = esignData.filter(d => d.applicationRole === 'Owner');
      if (ownerEsignData.length === 0) {
        const annuitantEsignData = esignData.filter(d => d.applicationRole === 'Annuitant');
        if (annuitantEsignData.length === 0) {
          const poaEsignData = esignData.filter(d => d.applicationRole === 'Power Of Attorney');
          email = poaEsignData[0].emailAddress;
        } else {
          email = annuitantEsignData[0].emailAddress;
        }
      } else {
        email = ownerEsignData[0].emailAddress;
      }
      if (userRoles.includes(main.applicationRole)) {
        esignData[esignIndex].emailAddress =
          CommonFunctions.checkIsNullOrUndefined(main.emailAddress) === '' ? email : main.emailAddress;
      }
    });
    return esignData;
  };

  const getEsignersList = async () => {
    const tempEsignList = [];
    const inputData = state.getStarted.inputvalues;
    const [esignListError, esignListResponse] = await MasterService.getEsignersList(
      state.getStarted.policyapplicationid,
    );
    if (esignListError) {
      NotificationService.error(esignListError.data.message);
      return;
    }
    const esignList = updateJownerEmail(esignListResponse);
    if (getStarted.inputvalues['#applicationTypeName'] === 'Trust') {
      // now returns an array of Party IDs
      const partyEsignData = CommonFunctions.getTrusteeEsign();
      partyEsignData.forEach(itm => {
        const errorObjectIndex = esignList.findIndex(p => p.partyId === itm && p.applicationRole !== 'Annuitant');
        if (errorObjectIndex !== -1) {
          esignList.splice(errorObjectIndex, 1);
        }
      });
    }
    /* Reterive the list of e-signers from the party database and combined
    the parties which have the same party id to display in esign view tab */
    esignList.forEach(group => {
      const partyEsignData = tempEsignList.filter(d => d.partyId === group.partyId);
      if (partyEsignData.length === 0) {
        tempEsignList.push({
          partyId: group.partyId,
          firstName: group.firstName,
          lastName: group.lastName.replace(/(n\/a)+/g, ''),
          middleName: group.middleName,
          suffix: group.suffix,
          emailAddress: group.emailAddress,
          applicationRole: group.applicationRole,
          eSignStatus: group.signStatus,
          isInPerson: group.applicationRole === 'Joint Owner' && state.user.isDtcCustomer ? false : group.isInPerson,
          docusignClientUserId: group.docusignClientUserId,
          docusignEnvelopeId: group.docusignEnvelopeId,
          emailSentOn: group.emailSentOn,
        });
      } else {
        partyEsignData[0].applicationRole = `${group.applicationRole} / ${partyEsignData[0].applicationRole}`;
        tempEsignList.forEach((main, esignIndex) => {
          partyEsignData.forEach((sub, partyIndex) => {
            if (main.partyId === sub.partyId) {
              tempEsignList[esignIndex].applicationRole = partyEsignData[partyIndex].applicationRole;
              if (state.user.isDtcCustomer && tempEsignList[esignIndex].applicationRole.includes('Joint Owner')) {
                tempEsignList[esignIndex].isInPerson = false;
              }
            }
          });
        });
      }
    });

    setEsignersList(tempEsignList);
    const esignData = esignList.filter(d => d.signStatus !== 'Signed');
    dispatch(setPartyEsignersList(esignData));
    if (
      esignData.length === 0 &&
      getStarted.inputvalues['#applicationStatusId'] !== constants.applicationStatusId.newBusinessReview
    ) {
      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `Before Auto submit triggered with ${state.getStarted.policyapplicationid}`,
      );
      /* setTimeout(async () => {
        const [autoSubmit] = await MasterService.triggerAutoSubmit(state.getStarted.policyapplicationid);
        inputData['#eappAutoSubmit'] = autoSubmit;
      }, 2000); */
      const [autoSubmit] = await MasterService.triggerAutoSubmit(state.getStarted.policyapplicationid);
      inputData['#eappAutoSubmit'] = autoSubmit;
      state.getStarted.trackergroupnames[state.getStarted.currentpage.Group].SubGroup[
        state.getStarted.currentpage.SubGroup
      ].isPageValid = true;
      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `After Auto submit triggered with ${state.getStarted.policyapplicationid}`,
      );
      store.dispatch(setTrackerGroupName(state.getStarted.trackergroupnames));
      store.dispatch(setIsSubmitted(4));
      sessionStorage.removeItem('eAppPolicyData');
    }
    tempEsignList.forEach((esignersData, index) => {
      if (esignersData.applicationRole === 'Back Office Reviewer') {
        inputData[`#esign${index}Types`] = false;
      } else inputData[`#esign${index}Types`] = esignersData.isInPerson;
    });
    store.dispatch(setInputValues(inputData));
  };

  const signatureClick = async (signersData, eSignType) => {
    if (eSignType) {
      const signerDetails = {
        partyId: signersData.partyId,
        docusignEnvelopeId: signersData.docusignEnvelopeId,
        docusignClientUserId: signersData.docusignClientUserId,
      };
      sessionStorage.setItem('signerDetails', JSON.stringify(signerDetails));
      const eSignDownloadInput = {
        signatureRequestID: signersData.docusignEnvelopeId,
        applicationAlias: state.getStarted.policyapplicationid,
        emailAddress: signersData.emailAddress,
        applicationId: getStarted.inputvalues['#applicationAliasId'],
      };
      sessionStorage.setItem('eSignDownloadInput', JSON.stringify(eSignDownloadInput));
      setEsignLoading(true);
      const signaturePayload = {
        agentPartyId: signersData.partyId,
        eApplicationAlias: state.getStarted.policyapplicationid,
      };
      const [signatureError, signatureURL] = await MasterService.getSignatureURL(signaturePayload);
      if (signatureError) {
        NotificationService.error(signatureError.data.message);
        return;
      }
      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `In person sign button clicked with signer details
        envelopeId: ${signersData.docusignEnvelopeId}, ClientuserId: ${signersData.docusignClientUserId}`,
      );
      setSignURL(signatureURL.embedded.sign_url);
      setEditVisible(true);
      setSignatureID(signersData.docusignClientUserId);
      setPartyID(signersData.partyId);
      setSignatureReqID(signersData.docusignEnvelopeId);
      setEsignLoading(false);
    } else {
      const remoteSignaturePayload = {
        remoteType: signersData.applicationRole === 'Back Office Reviewer' ? 'backOfficeApprovalMSA' : 'remoteSign',
        eApplicationAlias: state.getStarted.policyapplicationid,
        signerPartyId: signersData.partyId,
        esignStatusId: constants.esignStatusId.Resent,
      };
      const [remoteSignatureError] = await MasterService.getRemoteSignatureURL(remoteSignaturePayload);
      if (remoteSignatureError) {
        if (remoteSignatureError.status === 400) {
          const replaceString = 'The value at "signed" was invalid. signature request already signed.';
          NotificationService.error(
            remoteSignatureError.data.message.replace(replaceString, 'This request has already been signed'),
          );
        } else {
          NotificationService.error(remoteSignatureError.data.message);
        }
        return;
      }
      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `Remote sign button clicked with signer details
        envelopeId: ${signersData.docusignEnvelopeId}, ClientuserId: ${signersData.docusignClientUserId}`,
      );
      const inputData = state.getStarted.inputvalues;
      inputData['#signedDate'] = new Date();
      store.dispatch(setInputValues(inputData));
      getEsignersList();
    }
  };

  /*
   * This cleanJSON function is used to delete invalid property like value contains
   * null, undefined and empty.
   */
  const cleanJSON = inputFormData => {
    const returnObject = { ...inputFormData };
    Object.keys(returnObject).forEach(key => {
      if (CommonFunctions.checkIsNullOrUndefined(returnObject[key]) === '') {
        delete returnObject[key];
      }
    });
    return returnObject;
  };

  const checkIfSignatureCreated = async () => {
    const applicationData = state.getStarted;
    if (applicationData.inputvalues['#IsSignatureCreated']) {
      return;
    }
    applicationData.inputvalues['#IsSignatureCreated'] = true;
    applicationData.inputerrors = cleanJSON(applicationData.inputerrors);
    applicationData.inputvalues = cleanJSON(applicationData.inputvalues);
    store.dispatch(setInputValues(applicationData.inputvalues));
    const payload = {
      applicationId: applicationData.policyapplicationid,
      completionPercentage: GroupTrackerFunctions.calculateCompletePercentage(applicationData.trackergroupnames),
      agentPartyId: applicationData.inputvalues['#writingAgentPartyId'],
      applicationStatusId: applicationData.inputvalues['#applicationStatusId'],
      response: applicationData,
      productId: applicationData.product,
      signedStateId: applicationData.signedstateid,
      applicationTypeId: applicationData.applicationtype,
    };
    sessionStorage.setItem('eAppPolicyData', JSON.stringify(applicationData));
    const [applicationError] = await MasterService.saveApplicationData(payload);
    return applicationError;
  };
  const eSignAPICall = async () => {
    const esignPayload = {
      partyId: getStarted.inputvalues['#writingAgentPartyId'],
      eApplicationAlias: state.getStarted.policyapplicationid,
    };
    if (Utils.isUserViewOnly()) {
      return;
    }
    setEsignLoading(true);
    const [esignatureError, esignature] = await MasterService.createEsignature(esignPayload);
    if (esignatureError) {
      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `eSignature not created with  ${state.getStarted.policyapplicationid}`,
      );
      NotificationService.error(esignatureError.data.message);
      return;
    }
    if (esignature) {
      checkIfSignatureCreated();
      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `eSignature Created with ${state.getStarted.policyapplicationid}`,
      );
    }
    setEsignatureData(esignature);
    getEsignersList();
    setEsignLoading(false);
  };

  useAsyncEffect(eSignAPICall, []);

  const getEsignButton = eSignType => {
    return (() => {
      switch (eSignType) {
        case false:
          return <Send />;
        case true:
          return <Gesture />;
        default:
          return '';
      }
    })();
  };
  const getEsignStatusStyles = eSignStatus => {
    return (() => {
      switch (eSignStatus) {
        case 'Signed':
          return classes.esignComplete;
        case 'Resent':
          return classes.esignResent;
        case 'Denied':
          return classes.esignDenied;
        default:
          return '';
      }
    })();
  };
  const getEsignStatus = eSignType => {
    return (() => {
      switch (eSignType) {
        case false:
          return 'Send';
        case true:
          return 'Sign';
        default:
          return '';
      }
    })();
  };
  const getEsignTypes = eSignRole => {
    return (() => {
      switch (eSignRole) {
        case 'Agent':
          return [];
        case 'Back Office Reviewer':
          return esignRemote;
        case 'Owner / Annuitant':
        case 'Owner': {
          if (state.user.isDtcCustomer) {
            return [];
          }
          return esignTypes;
        }
        case 'Joint Owner / Annuitant':
        case 'Joint Owner': {
          if (state.user.isDtcCustomer) {
            return esignRemote;
          }
          return esignTypes;
        }
        default:
          return esignTypes;
      }
    })();
  };

  const getEsignIconText = (item, index) => {
    if (getStarted.inputvalues[`#esign${index}Types`] === false && item.eSignStatus === 'Resent') {
      return <Reply />;
    }
    return getEsignButton(getStarted.inputvalues[`#esign${index}Types`]);
  };

  const getEsignButtonText = (item, index) => {
    if (getStarted.inputvalues[`#esign${index}Types`] === false && item.eSignStatus === 'Resent') {
      return 'Resend';
    }
    return getEsignStatus(getStarted.inputvalues[`#esign${index}Types`]);
  };
  const noModalClose = async () => {
    BrowserHelper.saveBrowserInfo(
      getStarted.inputvalues['#applicationAliasId'],
      `Signer Pop up closed with this signer details ${sessionStorage.getItem('signerDetails')}`,
    );
    setEsignLoading(true);
    setEditVisible(false);
    const [remoteSignResError, remoteSignRes] = await MasterService.getRemoteSignStatus(
      signatureRequestID,
      signPartyID,
    );
    if (remoteSignResError) {
      setEsignLoading(false);
      NotificationService.error(remoteSignResError.data.message);
      return;
    }
    if (remoteSignRes.status === 'completed') {
      const signData = esignatureData.filter(d => d.docusignClientUserId === signatureID);
      if (signData.length > 0) {
        const eSignUpdateStatus = {
          docusignEnvelopeId: signData[0].docusignEnvelopeId,
          docusignClientUserId: signData[0].docusignClientUserId,
          esignStatusId: constants.esignStatusId.Signed,
          esignCompletionDate: new Date(),
          inPerson: true,
          eApplicationAlias: state.getStarted.policyapplicationid,
        };
        const [updateEsignError] = await MasterService.updateEsignStatus(eSignUpdateStatus);
        if (updateEsignError) {
          setEsignLoading(false);
          NotificationService.error(updateEsignError.data.message);
          return;
        }
        const inputData = state.getStarted.inputvalues;
        inputData['#signedDate'] = eSignUpdateStatus.esignCompletionDate;
        store.dispatch(setInputValues(inputData));
      }

      BrowserHelper.saveBrowserInfo(
        getStarted.inputvalues['#applicationAliasId'],
        `Signature completed with this signer details
        envelopeId: ${signData[0].docusignEnvelopeId}, ClientuserId: ${signData[0].docusignClientUserId}`,
      );
      getEsignersList();
    }
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) !== '') {
      sessionStorage.removeItem('signerDetails');
    }
    setEsignLoading(false);
  };

  const DownloadSignedDoc = async () => {
    if (esignersList.length > 0) {
      const [err, downloadedFile] = await MasterService.downloadSignDocument(
        esignersList[0].docusignEnvelopeId,
        esignersList[0].emailAddress,
      );
      if (err !== null) {
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, state.getStarted.policyapplicationid);
      }
    }
  };

  const getESTTime = senton => {
    const utcTime = new Date(senton);
    const usaTime = utcTime.toLocaleString('en-US', { timeZone: 'America/New_York' });
    return moment(usaTime).format('MM/DD/YYYY hh:mm A');
  };
  return (
    <React.Fragment>
      {signURL && <IFrameModal docuSignURL={signURL} onNoClose={noModalClose} visible={editVisible} />}
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant="h6" gutterBottom className={classes.label}>
          Signatures
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.downloadButtonGrid}>
        <Button
          name="esignDownload"
          variant="contained"
          className={classes.downloadButton}
          color="primary"
          startIcon={<GetApp />}
          onClick={() => DownloadSignedDoc()}
        >
          DOWNLOAD EAPPLICATION
        </Button>
      </Grid>
      {isLoading ? (
        <div className={classes.localLoading}>
          <CircularProgress />
        </div>
      ) : (
        esignersList.length > 0 &&
        esignersList.map((item, index) => {
          const resEsignType = getEsignTypes(item.applicationRole);

          return (
            <Grid item xs={12} sm={12} md={12} lg={6} className={classes.btnCanav}>
              <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom className={classes.esignLabel}>
                      {item.applicationRole === 'Agent' ? 'Producer' : item.applicationRole}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={getEsignStatusStyles(item.eSignStatus)}>
                    {item.eSignStatus === 'Signed' && (
                      <Typography variant="h6" gutterBottom className={classes.esignSigned}>
                        <CheckCircle className={classes.completeIcon} /> Complete
                      </Typography>
                    )}
                    {item.eSignStatus === 'Resent' && (
                      <Typography variant="h6" gutterBottom className={classes.esignSigned}>
                        <AccessTime className={classes.completeIcon} /> Awaiting Signature
                      </Typography>
                    )}
                    {item.eSignStatus === 'Denied' && (
                      <Typography variant="h6" gutterBottom className={classes.esignSigned}>
                        <Cancel className={classes.completeIcon} /> Denied
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="reviewbox">
                    <Typography variant="h6" gutterBottom className={classes.labelTop}>
                      Name
                    </Typography>
                    <Typography variant="h6" gutterBottom className={classes.valueTop}>
                      {item.firstName}{' '}
                      {CommonFunctions.checkIsNullOrUndefined(item.middleName) !== '' && item.middleName}{' '}
                      {item.lastName} {CommonFunctions.checkIsNullOrUndefined(item.suffix) !== '' && item.suffix}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="reviewbox">
                    <Typography variant="h6" gutterBottom className={classes.labelTop}>
                      Email
                    </Typography>
                    <Typography variant="h6" gutterBottom className={classes.valueTop}>
                      {item.emailAddress}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={
                        item.applicationRole === 'Agent' || state.user.isDtcCustomer
                          ? classes.displayNone
                          : classes.signCollect
                      }
                    >
                      How are you collecting the signature?
                    </Typography>
                  </Grid>
                  {resEsignType.length > 0 ? (
                    resEsignType.map(resItem => {
                      return (
                        <Grid item xs={5}>
                          <Button
                            name={`#esign${index}Types`}
                            fullWidth
                            color="#f44336"
                            selectedValue={getStarted.inputvalues[`#esign${index}Types`]}
                            disabled={resItem.eSignStatus === 'Signed' || Utils.isUserViewOnly()}
                            variant="outlined"
                            className={
                              resItem.id === getStarted.inputvalues[`#esign${index}Types`]
                                ? `${classes.activeButton}`
                                : `${classes.appBtnStyle}`
                            }
                            onClick={() => {
                              props.onChange(resItem.id, undefined, undefined, 'R', `#esign${index}Types`);
                            }}
                            startIcon={
                              resItem.id === true ? (
                                <People className={classes.starticonstyle} />
                              ) : (
                                <Email className={classes.starticonstyle} />
                              )
                            }
                          >
                            {resItem.value}
                          </Button>
                        </Grid>
                      );
                    })
                  ) : (
                    <Grid item xs={5} className={classes.hidden}>
                      hidden div
                    </Grid>
                  )}
                  {item.emailSentOn && getStarted.inputvalues[`#esign${index}Types`] === false ? (
                    <Grid item xs={12} className="reviewbox">
                      <Typography variant="h6" className={classes.labelTop}>
                        Sent on
                      </Typography>
                      <Typography variant="h6" className={classes.valueTop}>
                        {`${getESTTime(item.emailSentOn)} EST`}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12} className={classes.hidden}>
                      hidden div
                    </Grid>
                  )}
                  {item.eSignStatus !== 'Signed' ? (
                    <Grid item xs={12} className={classes.gridTextAlign}>
                      <Button
                        name={`#eSign${index}Button`}
                        variant={
                          getStarted.inputvalues[`#esign${index}Types`] === false && item.eSignStatus === 'Resent'
                            ? 'outlined'
                            : 'contained'
                        }
                        className={
                          getStarted.inputvalues[`#esign${index}Types`] === false && item.eSignStatus === 'Resent'
                            ? classes.resendIcon
                            : classes.openIcon
                        }
                        startIcon={getEsignIconText(item, index)}
                        color="secondary"
                        disabled={item.eSignStatus === 'Denied' || Utils.isUserViewOnly()}
                        onClick={() => signatureClick(item, getStarted.inputvalues[`#esign${index}Types`])}
                      >
                        {getEsignButtonText(item, index)}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} className={classes.signhidden}>
                      hidden div
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          );
        })
      )}
    </React.Fragment>
  );
}

SignHome.propTypes = {
  onChange: PropTypes.func,
};
