import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { DesktopWindows } from '@material-ui/icons';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function EsignComplete() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.gridWidth}>
        <Grid item xs={12} className={classes.gridAlignCenterNextButton}>
          <DesktopWindows className={classes.Headset} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.thankyou}>
            Thank You
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.signed}>
            You're all done!
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
