import React, { useState } from 'react';
import PropTypes from 'prop-types';
import store from 'redux/store';
import { makeStyles, Grid, Paper, Typography, LinearProgress, Link } from '@material-ui/core';
import { CloudUpload, Cancel, CheckCircle } from '@material-ui/icons';
import UploadButton from 'components/upload';
import MasterService from 'api/masterService';
import { useSelector } from 'react-redux';
import constants from 'constants/common';
import { setInputValues } from 'redux/getstarted/actions';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import NotificationService from 'services/notificationService';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ESign(props) {
  const classes = useStyles();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [progress, setInProgress] = useState(0);
  const [uploadPercentageEx, setUploadPercentageEx] = useState(0);
  const [extUploadNoteText, setExtUploadNote] = useState('');
  const [repUploadNoteText, setRepUploadNote] = useState('');
  const getStarted = useSelector(state => state.getStarted);
  const inputData = getStarted.inputvalues;
  const externalDocName = 'External Transfer Form';
  const replaceDocName = 'Replacement Comparative Info';
  const extdocFileKey = `${getStarted.policyapplicationid}/${externalDocName}_${props.indexNumber}`;
  const replacedocFileKey = `${getStarted.policyapplicationid}/${replaceDocName}_${props.indexNumber}`;
  if (inputData[`#externalTransferDocument`] === undefined) inputData[`#externalTransferDocument`] = [];
  if (inputData[`#replacementCompDocument`] === undefined) inputData[`#replacementCompDocument`] = [];

  React.useEffect(() => {
    const timer = setInterval(() => {
      setInProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const uploadReplaceCompDoc = async (event, indexNumber) => {
    const file = event.target.files[0];
    setUploadPercentage(0);

    const fileExt = file.name.split('.').at(-1);
    const allowedFileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'gif'];
    if (!allowedFileTypes.includes(fileExt.toLowerCase())) {
      setRepUploadNote('Note: Allowed document types: jpg, jpeg, pdf, png');
      return;
    }

    const tenMB = 10 * 1000 * 1000;
    if (file.size > tenMB) {
      NotificationService.error('Uploaded file is too large: Max 10MB');
      return;
    }

    const fileExtension = CommonFunctions.checkIsNullOrUndefined(file.name) !== '' ? /.+\.(.+)$/.exec(file.name) : '';
    const docFileName = Number.isFinite(indexNumber) ? `${replaceDocName}_${indexNumber}` : replaceDocName;
    const UploadFile = new File([file], `${docFileName}.${fileExtension[1]}`, {
      type: file.type,
      lastModified: file.lastModified,
    });

    if (!UploadFile) {
      return;
    }

    setUploadPercentage(100);
    const partyId = getStarted.inputvalues['#writingAgentPartyId'];
    const docTypeId = constants.documentType.eApplication.replacementNvComparativeInfo;
    const appId = getStarted.policyapplicationid;
    const [err, uploadedfile] = await MasterService.uploadAdditionalDocument(partyId, docTypeId, UploadFile, appId);

    if (err !== null) {
      NotificationService.error(err.data.message);
      return;
    }

    if (!uploadedfile) {
      return;
    }

    const tempDocList = [];
    tempDocList.push({
      fileName: replaceDocName,
      documentTypeId: uploadedfile.documentTypeId,
      documentFileKeyId: uploadedfile.documentFileKeyId,
      completionDate: uploadedfile.completionDate,
      applicationId: uploadedfile.applicationId,
    });

    const uploadDoc = inputData[`#replacementCompDocument`];
    if (uploadDoc.length > 0) {
      const filteredData = uploadDoc.filter(
        d => d.documentTypeId === uploadedfile.documentTypeId && d.documentFileKeyId === uploadedfile.documentFileKeyId,
      );
      if (filteredData.length === 0) {
        uploadDoc.push({
          fileName: replaceDocName,
          documentTypeId: uploadedfile.documentTypeId,
          documentFileKeyId: uploadedfile.documentFileKeyId,
          completionDate: uploadedfile.completionDate,
          applicationId: uploadedfile.applicationId,
        });
      } else {
        uploadDoc.forEach((main, esignIndex) => {
          if (
            main.documentTypeId === uploadedfile.documentTypeId &&
            main.documentFileKeyId === uploadedfile.documentFileKeyId
          ) {
            uploadDoc[esignIndex].documentFileKeyId = uploadedfile.documentFileKeyId;
            uploadDoc[esignIndex].completionDate = uploadedfile.completionDate;
            uploadDoc[esignIndex].fileName = replaceDocName;
          }
        });
      }
    } else {
      inputData[`#replacementCompDocument`].push(tempDocList[0]);
    }

    store.dispatch(setInputValues(inputData));
    setRepUploadNote('');

    setUploadPercentage(0);
  };

  const uploadExternalPaperDoc = async (event, indexNumber) => {
    const file = event.target.files[0];
    setUploadPercentage(0);

    const fileExt = file.name.split('.').at(-1);
    const allowedFileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'gif'];
    if (!allowedFileTypes.includes(fileExt.toLowerCase())) {
      setRepUploadNote('Note: Allowed document types: jpg, jpeg, pdf, png');
      return;
    }

    const tenMB = 10 * 1000 * 1000;
    if (file.size > tenMB) {
      NotificationService.error('Uploaded file is too large: Max 10MB');
      return;
    }

    const fileExtension = CommonFunctions.checkIsNullOrUndefined(file.name) !== '' ? /.+\.(.+)$/.exec(file.name) : '';
    const docFileName = Number.isFinite(indexNumber) ? `${externalDocName}_${indexNumber}` : externalDocName;
    const UploadFile = new File([file], `${docFileName}.${fileExtension[1]}`, {
      type: file.type,
      lastModified: file.lastModified,
    });

    if (!UploadFile) {
      return;
    }

    setUploadPercentageEx(100);
    const partyId = getStarted.inputvalues['#writingAgentPartyId'];
    const docTypeId = constants.documentType.eApplication.externalTransferForm;
    const appId = getStarted.policyapplicationid;
    const [err, uploadedfile] = await MasterService.uploadAdditionalDocument(partyId, docTypeId, UploadFile, appId);

    if (err !== null) {
      NotificationService.error(err.data.message);
      return;
    }

    if (!uploadedfile) {
      return;
    }

    const tempDocList = [];
    tempDocList.push({
      fileName: externalDocName,
      documentTypeId: uploadedfile.documentTypeId,
      documentFileKeyId: uploadedfile.documentFileKeyId,
      completionDate: uploadedfile.completionDate,
      applicationId: uploadedfile.applicationId,
    });

    const uploadDoc = inputData[`#externalTransferDocument`];
    if (uploadDoc.length > 0) {
      const filteredData = uploadDoc.filter(
        d => d.documentTypeId === uploadedfile.documentTypeId && d.documentFileKeyId === uploadedfile.documentFileKeyId,
      );
      if (filteredData.length === 0) {
        uploadDoc.push({
          fileName: externalDocName,
          documentTypeId: uploadedfile.documentTypeId,
          documentFileKeyId: uploadedfile.documentFileKeyId,
          completionDate: uploadedfile.completionDate,
          applicationId: uploadedfile.applicationId,
        });
      } else {
        uploadDoc.forEach((main, esignIndex) => {
          if (
            main.documentTypeId === uploadedfile.documentTypeId &&
            main.documentFileKeyId === uploadedfile.documentFileKeyId
          ) {
            uploadDoc[esignIndex].documentFileKeyId = uploadedfile.documentFileKeyId;
            uploadDoc[esignIndex].completionDate = uploadedfile.completionDate;
            uploadDoc[esignIndex].fileName = externalDocName;
          }
        });
      }
    } else {
      inputData[`#externalTransferDocument`].push(tempDocList[0]);
    }

    store.dispatch(setInputValues(inputData));
    setExtUploadNote('');

    setUploadPercentageEx(0);
  };

  const DeleteDocfromS3 = async (document, documentTypeId, transferData) => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const queryString =
      `?partyIdQuery=${getStarted.inputvalues['#writingAgentPartyId']}` +
      `&docTypeId=${document.documentTypeId}&appAlias=${getStarted.policyapplicationid}` +
      `&docFileKey=${document.documentFileKeyId}`;
    const [deletionError] = await MasterService.deleteDocument(queryString);
    if (deletionError !== null) {
      NotificationService.error(deletionError.data.message);
      return;
    }
    const externalDocs = [...inputData[transferData]];
    const index = externalDocs.indexOf(document);
    externalDocs.splice(index, 1);
    inputData[transferData] = externalDocs;
    store.dispatch(setInputValues(inputData));
    sessionStorage.setItem('eAppPolicyData', JSON.stringify(getStarted));
  };

  const DownloadUploadedDoc = async (doc, documentTypeId) => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    const selectedDoc = response.data.find(record => record.documentTypeId === documentTypeId);
    if (selectedDoc) {
      const [err, downloadedFile] = await MasterService.downloadDocument(
        getStarted.inputvalues['#writingAgentPartyId'],
        selectedDoc.id,
        getStarted.policyapplicationid,
        props.indexNumber,
      );
      if (err !== null) {
        NotificationService.error(err.data.message);
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, doc.fileName);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="body1" className={classes.typoStyle}>
                {`${props.companyData.companyName} ${props.companyData.transferPolicyNumber}`}
              </Typography>
              <Typography variant="subtitle1" gutterBottom className={classes.labelColor}>
                You will sign this on the Sign screen
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            {(getStarted.inputvalues['#replacementQuesFL'] === true ||
              getStarted.inputvalues['#replacementQuesNV'] === true) && (
              <React.Fragment>
                <Grid item xs={9} className="reviewbox">
                  <Typography variant="h6" gutterBottom className={classes.labelTop}>
                    Required Documents*
                  </Typography>
                  <Typography variant="h6" gutterBottom className={classes.valueTop}>
                    Replacement Comparison Form
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.alignRight}>
                  <UploadButton
                    name={`#replaceQuesUpload${props.indexNumber}`}
                    displayText="Upload"
                    variant="contained"
                    className={classes.openIcon}
                    startIcon={<CloudUpload />}
                    uploadFile={event => uploadReplaceCompDoc(event, props.indexNumber)}
                    color="secondary"
                    isDisabled={Utils.isUserViewOnly()}
                  />
                </Grid>
                {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues[`#replacementCompDocument`]) !== '' &&
                  getStarted.inputvalues[`#replacementCompDocument`].map(item => {
                    if (
                      item.documentTypeId === constants.documentType.eApplication.replacementNvComparativeInfo &&
                      item.documentFileKeyId.indexOf(replacedocFileKey) !== -1
                    ) {
                      return (
                        <Grid item xs={12} className={classes.marginTp}>
                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                name={`#replacefileName${props.indexNumber}`}
                                className={classes.uploadName}
                                component={Link}
                                onClick={() =>
                                  DownloadUploadedDoc(
                                    item,
                                    constants.documentType.eApplication.replacementNvComparativeInfo,
                                  )
                                }
                              >
                                {item.fileName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <CheckCircle
                                name={`replacecheckcircle${props.indexNumber}`}
                                className={classes.checkIcon}
                              />
                            </Grid>
                            <Grid item>
                              <Cancel
                                name={`replacecancelicon${props.indexNumber}`}
                                className={classes.cancelIcon}
                                onClick={() =>
                                  DeleteDocfromS3(
                                    item,
                                    constants.documentType.eApplication.replacementNvComparativeInfo,
                                    `#replacementCompDocument`,
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                    return false;
                  })}
                {uploadPercentage > 0 && (
                  <Grid item xs={8}>
                    <LinearProgress
                      name="linearProgressreplace"
                      variant="determinate"
                      value={progress}
                      className={classes.documentProcess}
                      classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
                    />
                  </Grid>
                )}
                {CommonFunctions.checkIsNullOrUndefined(repUploadNoteText) !== '' && (
                  <Grid item xs={12}>
                    <Typography variant="body1" className={classes.docErrorSection}>
                      {repUploadNoteText}
                    </Typography>
                  </Grid>
                )}
              </React.Fragment>
            )}
            {CommonFunctions.checkIsNullOrUndefined(props.companyData.coNote) !== '' && (
              <Grid item xs={12} className="reviewbox">
                <Typography variant="h6" gutterBottom className={classes.labelTop}>
                  Important Info and Instructions
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.valueTop}>
                  {props.companyData.coNote}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                Additional Documents (If applicable)
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.valueTop}>
                External Transfer Paperwork
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <UploadButton
                name={`#externalPaper${props.indexNumber}Upload`}
                displayText="Upload"
                variant="contained"
                className={classes.openIcon}
                startIcon={<CloudUpload />}
                uploadFile={event => uploadExternalPaperDoc(event, props.indexNumber)}
                color="secondary"
                isDisabled={Utils.isUserViewOnly()}
              />
            </Grid>
            {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues[`#externalTransferDocument`]) !== '' &&
              getStarted.inputvalues[`#externalTransferDocument`].map(item => {
                if (
                  item.documentTypeId === constants.documentType.eApplication.externalTransferForm &&
                  item.documentFileKeyId.indexOf(extdocFileKey) !== -1
                ) {
                  return (
                    <Grid item xs={6} className={classes.alignRight}>
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <Typography
                            variant="body1"
                            name={`file${props.indexNumber}name`}
                            className={classes.uploadName}
                            component={Link}
                            onClick={() =>
                              DownloadUploadedDoc(item, constants.documentType.eApplication.externalTransferForm)
                            }
                          >
                            {item.fileName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CheckCircle name={`checkex${props.indexNumber}circle`} className={classes.checkIcon} />
                        </Grid>
                        <Grid item>
                          <Cancel
                            name={`cancelex${props.indexNumber}icon`}
                            className={classes.cancelIcon}
                            onClick={() =>
                              DeleteDocfromS3(
                                item,
                                constants.documentType.eApplication.externalTransferForm,
                                `#externalTransferDocument`,
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
                return false;
              })}
            {uploadPercentageEx > 0 && (
              <Grid item xs={4}>
                <LinearProgress
                  name={`linearProgress${props.indexNumber}external`}
                  variant="determinate"
                  value={progress}
                  className={classes.linerProg}
                  classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
                />
              </Grid>
            )}
            {CommonFunctions.checkIsNullOrUndefined(extUploadNoteText) !== '' && (
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.docErrorSection}>
                  {extUploadNoteText}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

ESign.propTypes = {
  companyData: PropTypes.array,
  indexNumber: PropTypes.number,
};
