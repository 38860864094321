import MasterService from 'api/masterService';
import NotificationService from 'services/notificationService';

class Zendesk {
  getZendeskUrl = async subpath => {
    const [err, response] = await MasterService.getZendeskToken();

    if (err || (response && !response.token)) {
      NotificationService.error('Unable to authenticate with Zendesk.');
      return;
    }

    if (!response.domain) {
      NotificationService.error('No Zendesk domain provided.');
      return;
    }

    const returnToUrl = `${response.domain}/hc/en-us${subpath || ''}`;
    const url = `${response.authUrl}/access/jwt?jwt=${response.token}&return_to=${encodeURIComponent(returnToUrl)}`;
    window.open(url, '_blank');
  };
}

export default new Zendesk();
