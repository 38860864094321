export default () => ({
  addButton: {
    width: '100%',
    '@media (max-width:600px)': {
      width: '75%',
    },
  },
  addButtonHolder: {
    lineHeight: '3',
  },
  producerName: {
    width: '100%',
    pointerEvents: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
  npnSearch: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2.5px solid #A5A4A4 !important',
    },
    '& > div': {
      backgroundColor: '#FFFFFF',
    },
  },
});
