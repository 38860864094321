import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Radio from 'components/radio';
import defaulChoices from 'assets/JSONFiles/defaultChoices.json';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const RadioQuestionComp = props => {
  const classes = useStyles();

  const getListItemValues = choices => {
    if (
      props.group.fieldProperty.existingPolicyNumberforInternal &&
      !props.Values[props.group.fieldProperty.existingPolicyNumberforInternal]
    ) {
      return defaulChoices.choices[props.group.fieldProperty.defaultChoiceValues];
    }
    return choices;
  };

  const fieldRequired = text => {
    if (props.group.fieldProperty.isRequiredAtEnd) {
      return (
        <p style={{ margin: 0 }}>
          {text}
          <span style={{ color: 'red' }}>*</span>
          {props.group.fieldProperty.tooltipText !== '' &&
            CommonFunctions.tooltipMessageFn(props.group.fieldProperty.tooltipText, classes.tooltipLabel)}
        </p>
      );
    }
    return (
      <p style={{ margin: 0 }}>
        <span style={{ color: 'red' }}>*</span>
        {text}
        {props.group.fieldProperty.tooltipText !== '' &&
          CommonFunctions.tooltipMessageFn(props.group.fieldProperty.tooltipText, classes.tooltipLabel)}
      </p>
    );
  };
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12
                        MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        className={[
          props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone,
          'radiobutton',
        ].join(' ')}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
        }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Radio
          name={props.group.fieldName}
          labelText={
            props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.labelText)
              : props.group.fieldProperty.labelText
          }
          listItem={getListItemValues(props.group.fieldProperty.choices)}
          errorText={props.Errors[props.group.fieldName]}
          selectedValue={props.Values[props.group.fieldName]}
          value={props.Values[props.group.fieldName]}
          onClick={event => props.onChange(event, props.group.fieldValidation, 'radioQuestion', props.group.fieldName)}
          color={props.group.fieldProperty.styles.labelFontColor}
          fontSize={props.group.fieldProperty.styles.labelFontSize}
          radioFontSize={props.group.fieldProperty.styles.radioFontSize}
          fontVariant={props.group.fieldProperty.styles.labelFontVariant}
          fontWeight={props.group.fieldProperty.styles.labelFontWeight}
          justifyContent={props.group.fieldProperty.styles.justifyContent}
          isDisabled={Utils.handleElementDisable(props)}
          grid={props.group.fieldProperty.grid}
          group={props.group}
          onChange={props.onChange}
        />
      </Grid>
    </React.Fragment>
  );
};

RadioQuestionComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  Errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default RadioQuestionComp;
