export default {
  label: {
    fontWeight: 600,
  },
  lableColor: {
    fontWeight: '300',
    color: '#000000CC',
  },
  titleStyle: {
    fontWeight: '400',
  },
  uploadValidation: {
    margin: '0',
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: 'Muli,sans-serif',
    fontWeight: '400',
    lineHeight: '1.66',
    color: '#f44336',
  },
  docErrorSection: {
    color: '#f44336 !important',
  },
};
