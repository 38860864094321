import * as GetStartedTypes from './types';

export const setReset = value => dispatch => {
  dispatch({ type: GetStartedTypes.RESET, value });
};

export const setLoading = value => dispatch => {
  dispatch({ type: GetStartedTypes.LOADING, value });
};

export const setIsSubmitted = value => dispatch => {
  dispatch({ type: GetStartedTypes.ISSUBMITTED, value });
};

export const setPolicyApplicationId = value => dispatch => {
  dispatch({ type: GetStartedTypes.POLICYAPPLICATIONID, value });
};

export const setProductName = value => dispatch => {
  dispatch({ type: GetStartedTypes.PRODUCTNAME, value });
};

export const setProduct = value => dispatch => {
  dispatch({ type: GetStartedTypes.PRODUCT, value });
};

export const setSignedState = value => dispatch => {
  dispatch({ type: GetStartedTypes.SIGNEDSTATE, value });
};

export const setSignedStateAbb = value => dispatch => {
  dispatch({ type: GetStartedTypes.SIGNEDSTATEABB, value });
};

export const setSignedStateId = value => dispatch => {
  dispatch({ type: GetStartedTypes.SIGNEDSTATEID, value });
};

export const setApplicationType = value => dispatch => {
  dispatch({ type: GetStartedTypes.APPLICATIONTYPE, value });
};

export const setJSONFilename = value => dispatch => {
  dispatch({ type: GetStartedTypes.JSONFILENAME, value });
};

export const setCurrentPage = value => dispatch => {
  dispatch({ type: GetStartedTypes.CURRENTPAGE, value });
};

export const setTrackerGroupName = value => dispatch => {
  dispatch({ type: GetStartedTypes.TRACKERGROUPNAMES, value });
};

export const setInputValues = value => dispatch => {
  dispatch({ type: GetStartedTypes.INPUTVALUES, value });
};

export const setInputErrors = value => dispatch => {
  dispatch({ type: GetStartedTypes.INPUTERRORS, value });
};

export const setErrorLists = value => dispatch => {
  dispatch({ type: GetStartedTypes.ERRORLISTS, value });
};

export const setValue = value => dispatch => {
  dispatch({ type: GetStartedTypes.SETVALUE, value });
};

export const setLockFields = value => dispatch => {
  dispatch({ type: GetStartedTypes.LOCKFIELDS, value });
};

export const setSpecialErrors = value => dispatch => {
  dispatch({ type: GetStartedTypes.SPECIALERRORS, value });
};

export const setErrorPanelLists = value => dispatch => {
  dispatch({ type: GetStartedTypes.ERRORPANELLISTS, value });
};

export const setEligibilityErrors = value => dispatch => {
  dispatch({ type: GetStartedTypes.SALESELIGIBILITYERRORS, value });
};

export const setPartyEsignersList = value => dispatch => {
  dispatch({ type: GetStartedTypes.PARTYESIGNERSLIST, value });
};

export const setHideTab = value => dispatch => {
  dispatch({ type: GetStartedTypes.HIDETAB, value });
};

export const setProductRates = value => dispatch => {
  dispatch({ type: GetStartedTypes.PRODUCTRATES, value });
};
