import React from 'react';
import PropTypes from 'prop-types';
import DownloadLink from 'components/pdfDownload';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styles from '../../styles';

const useStyles = makeStyles(styles);

const DownloadComp = props => {
  const classes = useStyles();
  const fundType = props.values[`#${props.values[props.group.fieldProperty.transferInProgress]}FundType`];
  let downloadUrl = `${process.env.REACT_APP_SERVICE_URL}/v1/`;
  if (fundType?.toLowerCase() === 'internal') {
    downloadUrl += props.group.fieldProperty.altDownloadUrl;
  } else {
    downloadUrl += props.group.fieldProperty.downloadUrl;
  }

  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 2}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 2}
        className={props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
        }}
      >
        <DownloadLink
          downloadLink={downloadUrl}
          labelText={props.group.fieldProperty.labelText}
          fileName={props.group.fieldProperty.downloadName}
        />
      </Grid>
    </React.Fragment>
  );
};

DownloadComp.propTypes = {
  group: PropTypes.object,
  values: PropTypes.object,
};

export default DownloadComp;
