import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import store from 'redux/store';
import { setAuthenticatedUser } from 'redux/user/actions';
import { setReset, setLoading, setInputValues } from 'redux/getstarted/actions';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import BrowserHelper from 'helpers/browserHelper';
import { navigate, useQueryParams } from 'hookrouter';
import { Grid, Box, Button } from '@material-ui/core';
import NotificationService from 'services/notificationService';
import MasterService from 'api/masterService';
import EditModal from 'components/editModal';
import { TreeItem, TreeView } from '@material-ui/lab';
import { CheckCircle, Warning, PanoramaFishEye, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import GroupTrackerFunctions from 'views/eapp/utils/groupTrackerFunctions';
import ErrorList from 'components/errorList';
import Utils from 'views/eapp/renderforms/utils';
import constants from 'constants/common';
import CommonFunctions from '../utils/commonFunctions';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function GroupTracker(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const [expanded, setExpanded] = React.useState([]);
  const [queryParams] = useQueryParams();
  /* const [selected, setSelected] = React.useState([]); */
  const [editModalVisible, setModalEditVisible] = useState(false);
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const noEditModalClose = () => {
    setModalEditVisible(false);
  };

  const yesEditModalClose = async () => {
    setModalEditVisible(false);
    const userObj = user;
    const inputData = { ...getStarted.inputvalues };
    store.dispatch(setInputValues({ ...getStarted.inputvalues, ...inputData }));
    store.dispatch(setLoading(true));
    const [appDataError] = await MasterService.editApplication(getStarted.policyapplicationid);
    if (appDataError !== null) {
      NotificationService.error(appDataError.data.message);
    }
    const message = `Agent Clicked Edit button inbetween filling app in
    ${BrowserHelper.getTabName(getStarted, getStarted.currentpage.Group, getStarted.currentpage.SubGroup)}`;
    BrowserHelper.saveBrowserInfo(getStarted.inputvalues['#applicationAliasId'], message);
    store.dispatch(setLoading(false));
    let isViewOnly = false;
    const viewOnlyRole = RoleHelper.userHasRoles(userObj, ROLES.VIEWONLY);
    if (viewOnlyRole) {
      isViewOnly = true;
    }
    let isDtcCustomer = false;
    const { source = '' } = queryParams;
    if (source === constants.customerSource && RoleHelper.userHasRoles(userObj, ROLES.DTCCUCTOMER)) {
      isDtcCustomer = true;
    }
    store.dispatch(setAuthenticatedUser({ ...userObj, verified: userObj.verified, isViewOnly, isDtcCustomer }));
    store.dispatch(setReset(getStarted));
    sessionStorage.removeItem('eAppPolicyData');
    navigate('/getStarted');
    navigate(`/applications/${getStarted.policyapplicationid}`);

    if (isDtcCustomer) {
      navigate(`/applications/${getStarted.policyapplicationid}?source=customer`);
    } else {
      navigate(`/applications/${getStarted.policyapplicationid}`);
    }
  };
  /* const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    console.log(selected);
  }; */

  const onErrorClick = (event, item) => {
    props.onTabClick(event, item.groupIndex, item.subGroupIndex);
  };

  const getIcon = isValid => {
    const tabIcon = {
      true: <CheckCircle className={classes.completeIcon} />,
      false: <Warning className={classes.inCompleteIcon} />,
      default: <PanoramaFishEyeIcon className={classes.iconStyle} />,
    };
    return tabIcon[isValid] || tabIcon.default;
  };
  const getSubTabs = (subgrp, index) => (
    <TreeItem
      /* key={subgrp.displayname}
      value={index} */
      /* defaultCollapseIcon={<PanoramaFishEyeIcon />}
      defaultExpandIcon={<PanoramaFishEyeIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />} */
      key={subgrp.displayname}
      nodeId={subgrp.displayname}
      icon={getIcon(subgrp.isPageValid)}
      /* expandIcon={getIcon(subgrp.isPageValid)} */
      onClick={event => props.onTabClick(event, getStarted.currentpage.Group, index)}
      className={[
        getStarted.currentpage.SubGroup === index ? classes.activeSubTab : classes.subTab,
        classes.activeSubTabSpace,
      ].join(' ')}
      label={
        <span>
          {/* {getIcon(subgrp.isPageValid)} */}
          {subgrp.displayname}
        </span>
      }
      labelIcon={getIcon(subgrp.isPageValid)}
    />
  );

  const getSubFilterdTabs = (subgrp, index) => {
    if (!_.isNil(getStarted.hidetab) && getStarted.hidetab.filter(v => v === subgrp.displayname).length === 1) {
      return '';
    }
    if (
      getStarted.inputvalues['#writingAgentEmail'] === constants.automationEmailId &&
      (subgrp.displayname === constants.transferTabName || subgrp.displayname === constants.documentsTabName)
    ) {
      return '';
    }
    if (CommonFunctions.getCompanySignData().length === 0 && subgrp.displayname === constants.transferTabName) {
      return '';
    }
    if (
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ownerPOA']) === '' ||
        getStarted.inputvalues['#ownerPOA'] === false) &&
      CommonFunctions.getCompanySignData().filter(x => x.transferFW !== '').length === 0 &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('owner')) === '' &&
      CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#isDtcCustomer']) === false &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('jointOwner')) === '' &&
      CommonFunctions.checkIsNullOrUndefined(CommonFunctions.checkIdologyReason('annuitant')) === '' &&
      CommonFunctions.getCheckToFollow().length === 0 &&
      getStarted.inputvalues['#applicationTypeName'] === constants.applicationTypeName.Natural &&
      subgrp.displayname === constants.documentsTabName &&
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#IsMSAwithBackoffice']) === '' ||
        getStarted.inputvalues['#IsMSAwithBackoffice'] === false)
    ) {
      return '';
    }

    return getSubTabs(subgrp, index);
  };

  const getTrackerSourceTabs = getStarted.trackergroupnames.map((group, index) => {
    if (GroupTrackerFunctions.checkSubGroup(getStarted.trackergroupnames, index, getStarted.hidetab)) {
      return '';
    }

    if (expanded && expanded.length > 1) {
      const res = expanded.filter(x => x === getStarted.trackergroupnames[getStarted.currentpage.Group].displayname);
      setExpanded(res);
    }
    const validLength = GroupTrackerFunctions.getLength(getStarted.trackergroupnames, index, false);
    const invalidLength = GroupTrackerFunctions.getLength(getStarted.trackergroupnames, index, undefined);
    const totalSubGroupLength = getStarted.trackergroupnames[index].SubGroup.length;

    let checkMarkDisplay = null;
    if (totalSubGroupLength !== invalidLength) {
      checkMarkDisplay = validLength === 0 && invalidLength === 0;
    }

    return (
      <TreeItem
        /* key={group.displayname}
        value={index} */
        key={group.displayname}
        nodeId={group.displayname}
        icon={getIcon(checkMarkDisplay)}
        /* expandIcon={getIcon(checkMarkDisplay)} */
        onClick={event => props.onTabClick(event, index, 0)}
        className={[getStarted.currentpage.Group === index && classes.activeTab, classes.activeTabSpace].join(' ')}
        label={
          <span>
            {/*  {getIcon(checkMarkDisplay)} */}
            {group.displayname}
          </span>
        }
        labelIcon={getIcon(checkMarkDisplay)}
      >
        {getStarted.trackergroupnames.length > 0 &&
          getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup.map((subgrp, subindex) => (
            <div>{getSubFilterdTabs(subgrp, subindex)}</div>
          ))}
      </TreeItem>
    );
  });

  const getTabs = () => {
    if (getStarted.trackergroupnames.length === 0) {
      return (
        <TreeItem
          key="Start"
          nodeId="Start"
          icon={<PanoramaFishEye className={classes.iconStyle} />}
          className={[classes.activeTab, classes.activeTabSpace].join(' ')}
          label={<span>Product</span>}
        >
          <TreeItem
            key="Product"
            nodeId="Product"
            icon={<PanoramaFishEye className={classes.iconStyle} />}
            className={[classes.activeSubTab, classes.activeSubTabSpace].join(' ')}
            label={<span>Product</span>}
          />
        </TreeItem>
      );
    }

    return getTrackerSourceTabs;
  };

  return (
    <Box className={classes.container}>
      <EditModal editVisible={editModalVisible} onNoEditClose={noEditModalClose} onYesEditClose={yesEditModalClose} />
      {getStarted.inputvalues['#isReviewComplete'] &&
        Utils.isUserViewOnly !== true &&
        getStarted.inputvalues['#applicationStatusId'] !== constants.applicationStatusId.inProgress && (
          <Grid item xs={12} sm={6} md={3} lg={8} className={classes.gridAlignCenterExitButton}>
            <Button
              variant="contained"
              color="secondary"
              name="editEappBtn"
              className={classes.editBtn}
              onClick={() => setModalEditVisible(true)}
              startIcon={<Edit />}
            >
              EDIT
            </Button>
          </Grid>
        )}
      <Box className={classes.tabContainer}>
        <TreeView
          /*
          value={getStarted.currentpage.Group}
          classes={{ indicator: classes.indicator }}
          indicatorColor="primary"
           variant="scrollable"
          scrollButtons="auto" */
          /* className={classes.tab} */
          /* defaultCollapseIcon={<PanoramaFishEye />}
          defaultExpandIcon={<PanoramaFishEye />}
          defaultEndIcon={<PanoramaFishEye />} */
          className={classes.tab}
          /*   expanded={["Start"]}
          selected="Product" */
          expanded={
            getStarted.trackergroupnames.length === 0
              ? ['Start']
              : [getStarted.trackergroupnames[getStarted.currentpage.Group].displayname]
          }
          selected={
            getStarted.trackergroupnames.length === 0
              ? 'Product'
              : getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup[getStarted.currentpage.SubGroup]
                  .displayname
          }
          onNodeToggle={handleToggle}
          /* onNodeSelect={handleSelect} */
        >
          {getTabs()}
        </TreeView>
        {/* <Tabs
          classes={{ indicator: classes.subIndicator }}
          value={getStarted.currentpage.SubGroup}
          variant="scrollable"
          scrollButtons="auto"
        >
          {getStarted.trackergroupnames.length > 0 ? (
            getStarted.trackergroupnames[getStarted.currentpage.Group].SubGroup.map((subgrp, index) => (
              <div>{getSubFilterdTabs(subgrp, index)}</div>
            ))
          ) : (
            <Tab
              value={0}
              className={[classes.activeSubTab, classes.activeSubTabSpace].join(' ')}
              label={<span>Product</span>}
            />
          )}
        </Tabs> */}
      </Box>
      {/* <Box>
        <Typography className={classes.errorWidget}>
          {getStarted.inputvalues['#applicationStatusId'] === constants.applicationStatusId.newBusinessReview
            ? 100
            : GroupTrackerFunctions.calculateCompletePercentage(getStarted.trackergroupnames)}
          % done
        </Typography>
      </Box> */}
      <Grid item xs={12} sm={6} md={8} lg={8}>
        <ErrorList errors={props.errorLists} onErrorClick={onErrorClick} />
      </Grid>
    </Box>
  );
}

GroupTracker.propTypes = {
  onTabClick: PropTypes.func,
  errorLists: PropTypes.array,
};
