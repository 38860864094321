import React from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';
import styles from './style';

const useStyles = makeStyles(styles);

const DocSignModal = ({ visible, onNoClose, docuSignURL }) => {
  const classes = useStyles();

  return (
    <Dialog open={visible} aria-labelledby="form-dialog-title" maxWidth="lg">
      <IconButton aria-label="close" title="Close" className={classes.modalClear} onClick={onNoClose}>
        <ClearIcon />
      </IconButton>
      <DialogContent className={classes.modal}>
        <Iframe url={docuSignURL} className={classes.IframeStyle} id="docusignID" display="block" position="relative" />
      </DialogContent>
    </Dialog>
  );
};
export default DocSignModal;
DocSignModal.propTypes = {
  onNoClose: PropTypes.func,
  visible: PropTypes.bool,
  docuSignURL: PropTypes.string,
};
