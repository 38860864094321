import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SubdirectoryArrowLeft, NoteAdd, ChevronLeft, ChevronRight, ExitToApp, Menu } from '@material-ui/icons';
import pinkWhiteLogo from '@aspida/react-components/dist/pinkWhite.svg';
import whiteAspidaLogo from 'assets/images/WhiteAspidaLogo.png';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import MasterService from 'api/masterService';
import Utils from 'views/eapp/renderforms/utils';
import GroupTrackerFunctions from 'views/eapp/utils/groupTrackerFunctions';
import NotificationService from 'services/notificationService';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import store from 'redux/store';
import { setInputValues } from 'redux/getstarted/actions';
import style from './styles';

const useStyles = makeStyles(style);

export default function TabletMenu({ openMenu, setOpenMenu }) {
  const theme = useTheme();
  const classes = useStyles();
  const getStartedData = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const state = store.getState();
  const currentYear = new Date().getFullYear();
  const esignSuccess = window.location.href.includes('/esignsuccess');

  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };
  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  const logOut = async () => {
    sessionStorage.removeItem('eAppPolicyData');
    navigate('/signout');
    if (getStartedData.policyapplicationid !== '') {
      const applicationData = state.getStarted;
      if (Utils.isUserViewOnly()) {
        return;
      }
      if (applicationData.inputvalues['#IsSignatureCreated']) {
        return;
      }
      applicationData.inputerrors = CommonFunctions.cleanJSON(applicationData.inputerrors);
      applicationData.inputvalues = CommonFunctions.cleanJSON(applicationData.inputvalues);
      store.dispatch(setInputValues(applicationData.inputvalues));
      const payload = {
        applicationId: applicationData.policyapplicationid,
        completionPercentage: GroupTrackerFunctions.calculateCompletePercentage(applicationData.trackergroupnames),
        agentPartyId: applicationData.inputvalues['#writingAgentPartyId'],
        applicationStatusId: applicationData.inputvalues['#applicationStatusId'],
        response: applicationData,
        productId: applicationData.product,
        signedStateId: applicationData.signedstateid,
        applicationTypeId: applicationData.applicationtype,
      };
      const [applicationError] = await MasterService.saveApplicationData(payload);
      if (applicationError) {
        NotificationService.error(applicationError.data ? applicationError.data.message : applicationError);
      }
    }
    setOpenMenu(false);
  };

  return (
    <Container>
      <Box className={esignSuccess ? classes.displayNone : classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: openMenu,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, openMenu && classes.hide)}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.title}>
              Aspida
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={openMenu}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose} className={classes.iconWhite}>
              {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" className={classes.box}>
            <Box className={classes.textAlign}>
              <Box tabIndex={0} role="link" onClick={() => navigate('/')} onKeyPress={() => navigate('/')}>
                <img src={pinkWhiteLogo} className={classes.iconLogo} alt="logo" />
              </Box>
              <Box>
                {user !== false && (
                  <Box display="flex" flexDirection="column" justifyContent="space-between">
                    <Button
                      variant="outlined"
                      className={classes.logoutButton}
                      startIcon={<SubdirectoryArrowLeft />}
                      href={
                        user.isDtcCustomer
                          ? `${process.env.REACT_APP_CUSTOMERS_URL}`
                          : `${process.env.REACT_APP_DISTRIBUTOR_URL}`
                      }
                      size="large"
                    >
                      Back to Portal
                    </Button>
                    {getStartedData.policyapplicationid !== '' && (
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={CommonFunctions.handleExitClick}
                        className={classes.logoutButton}
                        startIcon={<NoteAdd />}
                      >
                        New Application
                      </Button>
                    )}
                    <Divider className={classes.dividerStyle} />
                    <Button
                      size="large"
                      onClick={logOut}
                      variant="outlined"
                      className={classes.logoutButton}
                      startIcon={<ExitToApp />}
                    >
                      Log Out
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            <div className={classes.legalRoot}>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyle}>
                  <img src={whiteAspidaLogo} className={classes.iconStyle} alt="logo" />
                </Link>
                <IconButton href="https://linkedin.com/company/aspida-insurance/" target="_blank">
                  <LinkedInIcon className={classes.icon} />
                </IconButton>
                <IconButton href="https://www.facebook.com/aspida_ins/" target="_blank">
                  <FacebookIcon className={classes.icon} />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Link className={classes.link} href="https://aspida.com/privacy.html" target="_blank">
                  Privacy Policy
                </Link>
                <Link className={classes.link} href="https://aspida.com/terms.html" target="_blank">
                  Legal Disclaimer
                </Link>
              </Box>
              <Typography variant="body2">
                &copy; {currentYear} Aspida Life Insurance Co.
                <br />
                All rights reserved
              </Typography>
            </div>
          </Box>
        </Drawer>
      </Box>
    </Container>
  );
}
TabletMenu.propTypes = {
  openMenu: PropTypes.bool,
  setOpenMenu: PropTypes.func,
};
