import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core';
import CircleOutline from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';

const useStyles = makeStyles(styles);

function AllocationComponent({ ...props }) {
  const {
    labelText,
    name,
    listItem,
    selectedValue,
    errorText,
    color,
    fontVariant,
    fontWeight,
    isError,
    isDisabled,
    textWrap,
    ...rest
  } = props;
  const delimiter = ',';
  const classes = useStyles();
  const delimitedSelectedValue = delimiter.concat(selectedValue, delimiter);
  const funcSmall = () => {
    let checkValue = false;
    listItem.map(object => (checkValue = delimitedSelectedValue.indexOf(delimiter + object.id + delimiter) !== -1));
    return checkValue;
  };
  const BoolValue = funcSmall();
  return (
    <FormControl
      error={isError}
      component="fieldset"
      variant="outlined"
      style={{ textWrap }}
      className={BoolValue ? classes.checkStyleafter : classes.checkStylebefore}
    >
      <FormLabel style={{ color, fontWeight }} component="legend">
        <Typography variant={fontVariant || 'body1'} style={{ display: 'inline-block' }}>
          {labelText}
        </Typography>
      </FormLabel>
      <FormGroup {...rest} name={name}>
        {listItem.map(object => (
          <FormControlLabel
            key={object.id}
            value={object.id}
            disabled={isDisabled}
            checked={delimitedSelectedValue.indexOf(delimiter + object.id + delimiter) !== -1}
            control={
              <Checkbox
                name={name}
                id={`chk_${name}_${+object.id}`}
                color="primary"
                icon={<CircleOutline className={classes.iconbefore} />}
                checkedIcon={<CheckCircle className={classes.iconafter} />}
              />
            }
            label={
              <>
                <span className={BoolValue ? classes.labelTextafter : classes.labelTextbefore}>
                  {object.value.split('|')[0]}
                </span>{' '}
                <span className={classes.spanRed}>{object.value.split('|')[1]}</span>
              </>
            }
          />
        ))}
      </FormGroup>
      {!!errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
}

AllocationComponent.propTypes = {
  labelText: PropTypes.string,
  name: PropTypes.string,
  selectedValue: PropTypes.string,
  errorText: PropTypes.string,
  color: PropTypes.string,
  fontVariant: PropTypes.string,
  fontWeight: PropTypes.string,
  listItem: PropTypes.array,
  labelClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  textWrap: PropTypes.string,
};

export default AllocationComponent;
