const errorMessage = `An unspecified error occurred. Please contact customer support for assistance.`;

const sessiontimeOutMessage = `Your session is expiring, but we're saving your progress.
                              Please log in again to continue.`;
const appSubmittedAlready = `Requested Application Already submitted.`;

export default {
  customerSource: 'customer',
  dtcCustomerAgentPartyId: 'e2695b7b-a5b4-4f5e-8f96-5466570767cc',
  prevYearContribStDate: 'YYYY-04-16',
  prevYearContribEnDate: 'YYYY-12-31',
  currYearContribStDate: 'YYYY-01-01',
  currYearContribEnDate: 'YYYY-04-16',
  appSubmittedAlready,
  errorMessage,
  sessiontimeOutMessage,
  sessionBufferTime: 30000,
  automationEmailId: 'automationid@mailinator.com',
  signTabName: 'Sign',
  beneTabName: 'Beneficiaries',
  stateReplaceTabName: 'State Replacement',
  transferTabName: 'Transfer Docs',
  documentsTabName: 'Documents',
  fundingTabName: 'Funding',
  qualifiedType: ['Inherited IRA', 'Roth IRA'],
  relationType: ['Trust', 'Organization'],
  popTabName: ['Beneficiaries', 'Funding', 'State Replacement'],
  upperCutOffAge: 90,
  disabledBGColor: '#0000001f',
  disabledColor: '#00000042',
  FieldListIndex: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  defaultFieldListIndex: [1, 2, 3],
  radioTrueValue: [
    'Yes',
    'Yes. The spouse of the Owner will be the sole Primary Beneficiary.',
    'I do have conflicts of interest regarding this transaction',
    'Yes, mail to Producer',
    'By Client or Producer',
  ],
  radioFalseValue: [
    'No',
    // eslint-disable-next-line max-len
    'No. The spouse of the Owner has been made aware of and consents that they will not be the sole Primary Beneficiary of the contract. The beneficiary(ies) will be named in the Beneficiary section of this application. At the death of the Owner before the Annuity Start Date, any death benefit will be paid to the name beneficiary(ies). At the death of the Owner after the Annuity Start Date, any death benefit will be paid according to the annuitization option elected.',
    'I do not have any conflicts of interest regarding this transaction',
    'No, save the trees',
    'By Aspida',
  ],
  reviewPageValidation: [
    '#ownerDoB',
    '#jointOwnerDoB',
    '#authPerson1DoB',
    '#authPerson2DoB',
    '#authPerson3DoB',
    '#annuitantDoB',
    '#ownerPOADoB',
    '#jointOwnerDoB',
    '#trustee1DoB',
  ],
  agentInfo: [
    'FN',
    'LN',
    'MN',
    'Marital',
    'Sex',
    'SF',
    'Email',
    'ConfirmEmail',
    'ResAddr',
    'ResAddr2',
    'ResCity',
    'ResState',
    'ResZip',
    'MailAddr',
    'MailAddr2',
    'MailCity',
    'MailState',
    'MailZip',
    'Phone',
  ],
  documentType: {
    agent: {
      amlCertificate: '9f4405dd-80f3-4406-9749-d5400c4ee9d7',
      annuitySuitabilityCertificate: '26133f1d-1146-41ac-b20b-10ce08200671',
      consumerAuthorization: '29c08887-11a6-4596-98b2-9253fb358108',
      w9: 'd317cbc9-20c3-4b42-b567-0aba15c8164d',
      agentAgreement: '09276538-002c-43c1-9d45-c55189493ac8',
      appointmentQuestionnaire: 'c8fdf882-d8cc-4624-a4af-fcc8bd896468',
      bestInterestSupplementalCertificate: '2654c4f9-f723-4295-be11-f12820a70769',
      producerSupporting: '1ad5ed2d-ed21-458f-b347-da5e3cdf47eb',
    },
    agency: {
      commissionCoding: 'f20c3392-e7dd-42b2-b6e1-2971cea28a58',
      appointmentQuestionnaire: '93c13a37-3654-43c6-b9e0-70c4edbe37d8',
      agencyAgreement: '32d69c19-d664-428a-acbc-72fcae93b8fc',
      consumerAuthorization: 'd99bf05a-92ff-4916-b76c-df3716bc6c05',
      administratorForm: '5b2a6ab1-469d-4a91-ba08-0abd719658f6',
      brokerDealerQuestionnaire: '3100b9d1-02ae-4582-ac32-fd738000cfb6',
      authorizationDirectDeposit: 'bfbe87fa-88b9-4f4b-b494-d7f4ef702684',
      masterSellingAgreement: 'f44c0268-959a-412b-b812-0a29313ccbd2',
      financialInstitutionQuestionnaire: '0b004427-6845-4acf-aea1-32cb1f7d7af4',
      agencyAppointmentQuestionnaire: 'bbc16f83-29d6-4ae8-af9f-f2982ee43bcc',
      agencyIrsW9: 'cdcb37d8-f3c4-4270-b05d-0c21462fcd79',
      financialInstitutionCommssionCodingForm: 'aeb1f1b2-424d-4e68-8f81-f074a26b7693',
    },
    eApplication: {
      annuitantPassport: 'dd50efdd-0811-4479-b484-091253db51be',
      annuitantPhotoIdOrDriversLicense: '5b64b8ad-421a-46b4-993a-123d70194c98',
      annuitantW9: '6bd31f27-22f5-4db9-86e5-5c2c52f9e98e',
      annuitantSocialSecurityCard: '13f115d6-6e12-40d1-bcc3-7a415e5bfbd4',
      articlesOfIncorporation: '40c1db31-61c1-4fe7-890f-233165fa623c',
      caVoluntaryElectronicOptInEDelivery: '82b5eb5f-63a9-4571-80e7-8847deb4c1ac',
      corporationProofOfExistence: '7e09c9a6-bc54-43d5-8fab-e41104c8593d',
      deathCertificate: '9d112bab-cf60-41b3-9c6c-38c2946d2fd6',
      externalTransferForm: '0e8cd20a-d5e4-408f-986e-33df802ab93f',
      fiaApplicationCalifornia: '0ec482f7-f993-4d51-9f65-9e9b9c9512dd',
      fiaApplicationFlorida: '5d7c0419-6428-4e37-a401-333253114e78',
      irsCertificateOfDetermination: '8ba4e315-5787-4232-8daa-58995805589c',
      jointOwnerPassport: '6c256969-dccd-4372-be7c-e2455c0f98b0',
      jointOwnerPhotoIdDriversLicense: '16c96f30-598d-4c1a-b94c-4cbacac646c8',
      jointOwnerW9: 'ff3cd319-e76e-4b7d-9c41-6ddc4d1f7dd5',
      jointOwnerSocialSecurityCard: '128fa451-214f-4e45-a507-c87d55e1edf1',
      mygaMsgApplicationCalifornia: '1c22b5c4-22d9-42cb-ba92-62bd9c10d82e',
      mygaMsgApplicationFlorida: 'd00bf2c5-561e-4416-b392-80fdda3c14f4',
      ownerPassport: '4a3ea9d1-b85d-4b53-9c38-51634e219264',
      ownerPhotoIdDriversLicense: '35b90fb5-a091-4d24-96d8-89657749d744',
      ownerSocialSecurityCard: 'f74af60f-5240-4849-8dda-83accf4dcf37',
      ownerW9: 'c8149e55-27ff-40af-83fb-9e895416822f',
      replacementFlLifeToAnnuityComparativeInfo: '2c080b9b-b2ae-421b-bf16-0927c3865c71',
      replacementFlAnnuityToAnnuityComparativeInfo: 'f6aef22b-87bd-4b04-a7eb-ff1de9e4e038',
      replacementNvComparativeInfo: '3200d8d2-67d7-4d3d-bf36-3a171d2ea32b',
      stateOfFlStateReplacementNotice: 'd0b2a73d-f9bc-45fe-b346-50ac8e3b6c51',
      stateOfNvStateReplacementNotice: '222bfb92-a341-4c9f-a74b-d72f228a737b',
      surrenderingCompanyOriginalContract: 'c229b13a-d6ec-4091-9935-d3e685d8bfb4',
      trustDocument: '2aca371a-bf7d-4e02-a556-3dd04936ad50',
      backOfficeDecisionForm: 'eee36a75-0193-4df1-8b7c-07287ed8c142',
      msaBackOfficeDocument: '67746da3-a898-43a6-82e9-3be207176852',
      policyContract: '84c36875-5bca-4c9c-b0af-3801b023203e',
      applicationSupporting: '52b17e4e-2ac9-4769-8a08-4ba91b632eea',
    },
    smartComm: {
      exchangeRolloverTransferEform: '9cb89b58-4f2d-4934-8723-04a1528d740a',
      powerOfAttorney: '9feeb4c3-3f19-4c4f-9848-13bfcbac8757',
      agentCertification: '9ec13f85-1ed1-4e8a-8b94-3a4477955c1d',
      agentCertificationFlorida: '71798e30-ffac-4f70-9c12-2679529ddb68',
      corporateResolutionCertificate: 'fd1e4120-034e-42ba-a05c-bbe1c51abca0',
      understandingYourAnnuityMsg: 'f12104bc-beb1-45ff-8a6e-16b225360f15',
      understandingYourAnnuityWv: 'c83086c3-17d5-4e77-883e-80ef7f791d65',
      eftInitialPremiumAuthorization: '929d3983-cf47-44d6-9afb-c593b71067dc',
      mygaMsgAppIcc: 'cc0ee93f-79a6-453b-bf2d-70cd10783808',
      mygaWealthvestAppIcc: 'afb6ea8b-22b1-4db2-b641-1fcc4072870f',
      fiaAppIcc: '7de68b86-3b9b-407d-aaa8-f90c6854d979',
      insuranceAgentProducerDisclosureForAnnuities: '946255d7-a89e-4eb4-817a-be42f2f83632',
      largeCaseException: 'ef44c721-fc21-4a03-a0fc-81641f701319',
      nonUsCitizenSupplementalInformationForm: 'ba65d52a-ebb4-4a84-9bb4-67140c9bfbc0',
      nonResidentSale: '96346ae1-6baa-4112-ac61-76bab1ba8306',
      producerReleaseForm: 'b71dc782-0f02-469b-9be6-4cde57788717',
      trustCertification: 'd9f1aef7-0dbc-417c-a36b-b606b114cde2',
      replacementPaLifAnn_01_20: '73961492-162f-453b-8b98-eca48d4354e5',
      replacementFlAnn_01_2017: 'cfb8e90f-e243-41d1-ae70-b81771f91627',
      replacementWyLifAnn_01_20: '6f8cf067-940b-4a3e-997f-ae6bd93c7181',
      replacementFlAnn_01_20: 'f395459d-9230-4f42-9fb4-692b88dae089',
      replacementNvAnn_01_20: 'e839f34d-6865-4e95-9a59-14766d2727d3',
      replacementCaLifAnn_01_20: 'c8228c68-9f30-4356-b280-58264c724c95',
      replacementIdLifAnn_01_20: '20e89ef9-3c35-4416-9936-227fb4d0e7bd',
      replacementIlLifAnn_01_20: '6d59dcd8-e9d9-464c-b845-a409c8db2670',
      replacementInLifAnn_01_20: '82e53dd4-fa53-4628-b580-7f8bde51f45a',
      replacementMaLifAnn_01_20: 'b8cae564-336d-48e5-9522-95d2f4edb6e6',
      replacementMnLifAnn_01_20: '7b2b838c-1c03-461f-aca4-f058c72ed6a2',
      replacementMstLifAnn_01_20: '1d6ec3b6-c791-4d84-91fb-1e1d045cf7aa',
      replacementMspLifAnn_03_21: 'd9532ff8-3c1d-4701-97bd-7d26ee5370b5',
      replacementOkLifAnn_01_20: 'aa8cb156-179a-4c6c-8e0a-e6927dade68a',
      replacementPrLifAnn_01_20: '66549cc1-4ee3-4dfd-9d45-a2e89a078bba',
      replacementKsLifAnn_01_20: '57d90541-af91-4eab-9da4-c158db531a6f',
      replacementWaLifAnn_01_20: '9cd9804f-0c2d-4d8b-918c-71e708bd624a',
      suitabilityForm: '44b5911d-9cd1-4d0d-abcf-f9ed74b576e2',
      understandingYourAnnuityFia: '537dec62-ddf1-410c-9c57-3436a188f514',
      replacementDELifAnn_01_20: 'dcfcff45-ee51-4a08-8696-3ea8ae54eec8',
      replacementGALifAnn_01_20: 'df9cc3ef-c7d0-4e91-bd6b-ddd52f265fef',
      replacementMILifAnn_01_20: '355dc0e7-6313-4982-971b-a170350a2dbb',
      replacementTNLifAnn_01_20: 'a4390300-cd08-49ab-b740-69645670231c',
      letterOfAssignment: '257f052b-6143-4332-9a3c-5908b5698b20',
      checkToFollow: '68f0ced6-d52d-4366-b1a9-fddd4b3fa72c',
      transferRollOver: 'eedb7fb5-0a18-4617-919b-17c532234798',
      costBasis: 'f1834395-e13f-4f20-81f7-a6108fed43ce',
      backOfficeReviewerForm: 'eee36a75-0193-4df1-8b7c-07287ed8c142',
      supplementarySignatureForm: 'df136d70-7c28-4e3f-8d30-36d35f1eeb6f',
      buyersGuide: '0798df8d-0644-4974-b76c-d141d62cf4ba',
    },
  },
  documentGroup: {
    agency: '1024eb4e-b786-46bb-982a-43631a5002b9',
    agent: '6fa17720-fa78-4fbb-97ec-f961b1ce7aae',
    eApplication: '61d2dcb6-90f3-4d6c-af80-59865e3c3a09',
    smartComm: '4079a3f9-d435-4c3c-ae07-0e6d23060a2e',
    producer: '8e185f30-9165-4582-9f66-65e4754891e6',
  },
  eapplicationRoles: {
    owner: 'e03da9cc-2618-4f64-baaf-30a5e90682cb',
    jointOwner: 'f4187fff-78a5-41d2-bbea-d744f4fc8703',
    beneficiary: '1d1350f5-ea8e-46bc-8e99-a02909d4ff69',
    agent: '0a93a9a4-29d2-45e6-a2ca-f275e5c24e1e',
    splitAgent: 'eee37e29-a3fb-44dd-b41a-eacc46a5a9be',
    annuitant: '188eee1a-5275-46c1-b167-75fd6e8ef64a',
    jointAnnuitant: '3dd1ba80-f2e0-4706-8ad8-3eb98d2920cf',
    primaryBeneficiary: 'd6475067-d60e-45a2-ad40-b6877aefec8b',
    contigentBeneficiary: '4e6b98b5-01ed-464b-9cad-ab968c484405',
    trustGrantor: 'a1aa6dbb-d204-488c-977b-86b5c97fa020',
    trust: '34bb84ec-c41c-4f52-b56d-8c0974e2a33d',
    trustee: 'ec95790e-a1b8-4cdc-91e9-6197a3b2fbc9',
    organization: 'd73583a5-b21b-4a58-b86e-d472dc1fb084',
    irrevocableBeneficiary: 'b02d420b-d0ae-4ba0-ace9-542e070edc2d',
    ownerSpouse: 'b3bfe282-c696-4ac7-b4a1-e8ae653f1e51',
    powerOfAttorney: 'd6902425-f8aa-4d8c-8abf-301f03fa27f0',
    authorizedOfficer: '17f6be25-10a4-4d19-b8b3-b5c5555caf3b',
    applicationOwner: 'e591b890-7f04-4dd3-911c-eb85e8a60aa4',
    applicationJointOwner: '6887ac40-bdf4-4eb7-b762-81b3602757dc',
    backOfficeReviewer: 'c0c56b96-8f7e-47f6-a38d-e918e731ef91',
  },
  personRole: {
    rosterAdmin: '183bf7bf-8355-40e5-a9c5-71cb86ce4bbe',
    reportingAdmin: '7cf90f52-df22-4768-b61b-155fd7f46a6f',
    limitedReportingAdmin: '3deae0b1-e488-4f1c-a72a-596c0131da72',
    producer: '26bbb353-96fb-4c13-ab80-f42634c31619',
    licensingOps: '63dc4c0b-0ce0-4a27-9f94-ae3452484cb8',
    principalAgent: '06e30b04-9ea2-4b05-b676-a7492eb8d31c',
    producingPrincipalAgent: '06e30b04-9ea2-4b05-b676-a7492eb8d31c',
    nonProducingPrincipalAgent: 'd4530c12-ebdb-40ed-af5c-bf2f851e8f05',
    dtcCustomer: '1521f16d-a822-444c-a9c5-7c8b5ef7e34b',
  },
  applicationStatusId: {
    inProgress: '08932f71-7b2c-4e21-a14a-de8932e575f5',
    cancelled: '3a59c079-a8c0-452c-95e1-e604a31173c9',
    newBusinessReview: 'f7864b73-57ad-49af-b1bd-ebbfc51dd596',
    deleted: '553051b6-8209-4084-8b75-8ecd4ddce6ae',
    approved: '652d00e3-e242-4a12-b401-063b415ac2b6',
    approvedWithModifiedAmount: '5f3d6a7c-5e08-43f0-ba61-5b8149c95bac',
    reviewComplete: '388b83ba-d301-4b40-94aa-397f95d3bf11',
    withdrawn: 'ea2f09ad-143e-40fd-9582-ae491c2fff02',
    denied: '91e64510-4bd2-4741-9504-c144f550ef90',
    backOfficePending: '4753b44e-deb4-46bf-ac23-88373ca32a2c',
    backOfficeDeclined: '11b9eafa-7f4a-476c-a901-47a4bb30ce60',
    backOfficeApproved: '3bc429f0-6263-44ff-8f7a-c2c63f67c966',
    issued: '875b9829-9275-4683-b30d-6f269e4c9748',
  },
  applicationTypeId: {
    Natural: '543f1d6f-4d9e-4df8-9a4d-53399f43168d',
    Corporation: 'ad0199af-f829-4a96-9088-0d42a1db6525',
    Trust: 'd3dddb48-4ec7-4cea-9b65-566f0226a53b',
  },
  applicationTypeName: {
    Natural: 'Natural Person(s)',
    Corporation: 'Corporation/Entity',
    Trust: 'Trust',
  },
  corpTypeofEntity: {
    corporation: 'Corporation',
    taxExemptEntity: 'Tax-Exempt Entity',
    govEntity: 'State Or Other Government Entity',
  },
  beneficiaryType: {
    Primary: 'Primary',
    Contingent: 'Contingent',
    SurvivingSpouse: 'Surviving Spouse',
  },
  validationErrorMessages: {
    BenePercentage100: 'Percentage must be 100%',
    applicationDataValidation: 'Please go through all the Tabs and fill the mandatory data',
    fundingProgress: 'Unsaved fund data will be lost. Please click on Save button prior to leaving Funding tab.',
    stateReplaceProgress:
      'Unsaved replacement data will be lost. Please click on Save button prior to leaving State Replacement tab.',
  },
  serverErrorMessages: {
    errorContract: 'unable to load contracts',
  },
  esignStatusId: {
    Review: '388b83ba-d301-4b40-94aa-397f95d3bf11',
    Requested: 'cd5383a8-b147-4325-bcfc-be8b273fcd00',
    Signed: '2718edc9-d9fc-4e54-8385-1ed37fe24b97',
    Resent: '79a4830a-def7-4864-8979-d2dd89b6c9b3',
    AgencyReviewed: '03e23e8e-a234-4e0c-b849-d689d6f3959b',
    AgencyApproved: '2d680e90-1351-4753-978b-bea458bb330e',
  },
  addlDocTypeIdOwnerSSN: [
    {
      id: 'f74af60f-5240-4849-8dda-83accf4dcf37',
      description: 'Social Security Card',
    },
    {
      id: 'c8149e55-27ff-40af-83fb-9e895416822f',
      description: 'Tax Form (W-2, W-9, etc.)',
    },
  ],
  addlDocTypeIdOwnerDOB: [
    {
      id: '35b90fb5-a091-4d24-96d8-89657749d744',
      description: 'ID / License',
    },
    {
      id: '4a3ea9d1-b85d-4b53-9c38-51634e219264',
      description: 'Passport',
    },
  ],
  addlDocTypeIdJOwnerSSN: [
    {
      id: '128fa451-214f-4e45-a507-c87d55e1edf1',
      description: 'Social Security Card',
    },
    {
      id: 'ff3cd319-e76e-4b7d-9c41-6ddc4d1f7dd5',
      description: 'Tax Form (W-2, W-9, etc.)',
    },
  ],
  addlDocTypeIdJOwnerDOB: [
    {
      id: '16c96f30-598d-4c1a-b94c-4cbacac646c8',
      description: 'ID / License',
    },
    {
      id: '6c256969-dccd-4372-be7c-e2455c0f98b0',
      description: 'Passport',
    },
  ],
  addlDocTypeIdAnnutSSN: [
    {
      id: '13f115d6-6e12-40d1-bcc3-7a415e5bfbd4',
      description: 'Social Security Card',
    },
    {
      id: '6bd31f27-22f5-4db9-86e5-5c2c52f9e98e',
      description: 'Tax Form (W-2, W-9, etc.)',
    },
  ],
  addlDocTypeIdAnnutDOB: [
    {
      id: '5b64b8ad-421a-46b4-993a-123d70194c98',
      description: 'ID / License',
    },
    {
      id: 'dd50efdd-0811-4479-b484-091253db51be',
      description: 'Passport',
    },
  ],
  downloadDocTypeId: {
    transferForm: [
      {
        id: '9cb89b58-4f2d-4934-8723-04a1528d740a',
        description: 'exchangeRolloverTransferEform',
      },
    ],
    replacementForm: [
      {
        id: '73961492-162f-453b-8b98-eca48d4354e5',
        description: 'Replacement Form',
        stateId: '7fac84b7-20ad-4177-8db9-6f5a63092bb6',
      },
      {
        id: 'f395459d-9230-4f42-9fb4-692b88dae089',
        description: 'Replacement Form',
        stateId: '6525ac13-0e85-49b3-83e2-40f5e4a8ba92',
      },
      {
        id: 'e839f34d-6865-4e95-9a59-14766d2727d3',
        description: 'Replacement Form',
        stateId: 'cc5c4fef-2078-4823-aeae-5109165ca781',
      },
      {
        id: '6f8cf067-940b-4a3e-997f-ae6bd93c7181',
        description: 'Replacement Form',
        stateId: '2cb3d65a-ee17-4d71-968e-37ae43056a6e',
      },
      {
        id: 'c8228c68-9f30-4356-b280-58264c724c95',
        description: 'Replacement Form',
        stateId: '12e4307b-36d7-4dba-8507-783af87d5c74',
      },
      {
        id: '20e89ef9-3c35-4416-9936-227fb4d0e7bd',
        description: 'Replacement Form',
        stateId: 'baa23a16-91f3-47ec-b642-f96738bb1893',
      },
      {
        id: '6d59dcd8-e9d9-464c-b845-a409c8db2670',
        description: 'Replacement Form',
        stateId: '3649e99f-4cc4-4e6d-a962-fc6f16762f0a',
      },
      {
        id: '82e53dd4-fa53-4628-b580-7f8bde51f45a',
        description: 'Replacement Form',
        stateId: 'b5bbc1fe-56a8-4c1e-b6af-a43a37ae9584',
      },
      {
        id: 'b8cae564-336d-48e5-9522-95d2f4edb6e6',
        description: 'Replacement Form',
        stateId: '88e3fe9a-1dfd-4cb2-bcfe-1224d8441bc2',
      },
      {
        id: '7b2b838c-1c03-461f-aca4-f058c72ed6a2',
        description: 'Replacement Form',
        stateId: 'd9042d02-7b75-4665-bb6f-c1d48e6d4918',
      },
      {
        id: 'aa8cb156-179a-4c6c-8e0a-e6927dade68a',
        description: 'Replacement Form',
        stateId: 'dc6edad8-c2d8-41e1-9d2f-ee2ab53efb7d',
      },
      {
        id: '57d90541-af91-4eab-9da4-c158db531a6f',
        description: 'Replacement Form',
        stateId: 'fc771950-f460-4e7c-a96a-217873cdb54d',
      },
      {
        id: '9cd9804f-0c2d-4d8b-918c-71e708bd624a',
        description: 'Replacement Form',
        stateId: 'b37bd878-8104-4eca-a1f1-1b020f758dde',
      },
      {
        id: 'dcfcff45-ee51-4a08-8696-3ea8ae54eec8',
        description: 'Replacement Form',
        stateId: '21b91b57-8310-4689-b97a-5e5d26f4f802',
      },
      {
        id: 'df9cc3ef-c7d0-4e91-bd6b-ddd52f265fef',
        description: 'Replacement Form',
        stateId: 'ac8354a6-3429-43ab-a996-ea52664ba811',
      },
      {
        id: '355dc0e7-6313-4982-971b-a170350a2dbb',
        description: 'Replacement Form',
        stateId: 'b3591004-9631-4ff7-8060-d7bebab6cb8c',
      },
      {
        id: 'a4390300-cd08-49ab-b740-69645670231c',
        description: 'Replacement Form',
        stateId: 'cdd4020a-18c6-4758-9551-308298976185',
      },
      {
        id: 'd9532ff8-3c1d-4701-97bd-7d26ee5370b5',
        description: 'Replacement Form',
        stateId: '9e063b69-2952-4cba-992d-80454a67b435',
      },
      {
        id: 'd9532ff8-3c1d-4701-97bd-7d26ee5370b5',
        description: 'Replacement Form',
        stateId: 'f327fda2-e964-4ca3-972e-2910f7503310',
      },
      {
        id: 'd9532ff8-3c1d-4701-97bd-7d26ee5370b5',
        description: 'Replacement Form',
        stateId: 'f627ede1-3f6d-4c1d-91e6-c3a3dc30e1b0',
      },
      {
        id: 'd9532ff8-3c1d-4701-97bd-7d26ee5370b5',
        description: 'Replacement Form',
        stateId: '837106db-3a1e-4b4f-bbf8-e517c524328c',
      },
      {
        id: '1d6ec3b6-c791-4d84-91fb-1e1d045cf7aa',
        description: 'Replacement Form',
        stateId: '',
      },
    ],
  },
  analyticsCollection: {
    noResponse: 'cd930b35-7954-4b33-bf74-bfa0b141ccda',
    accepted: 'bb0149eb-3df7-4104-83ce-37b94e393d47',
    optOut: '0447a80f-33dc-4ccb-9ed3-b9b8bf85d524',
  },
  FieldList: [
    '#ownerSSN',
    '#ownerDoB',
    '#jointOwnerSSN',
    '#jointOwnerDoB',
    '#existingPolicyNumberforInternal',
    '#bene2Type',
    '#bene2Percent',
    '#bene2Rel',
    '#bene2SSN',
    '#bene2DoB',
    '#bene2Phone',
    '#bene2Email',
    '#bene2FN',
    '#bene2MN',
    '#bene2LN',
    '#bene2SF',
    '#bene2ResAddr',
    '#bene2ResAddr2',
    '#bene2ResCity',
    '#bene2ResState',
    '#bene2ResZip',
    '#bene3Type',
    '#bene3Percent',
    '#bene3Rel',
    '#bene3SSN',
    '#bene3DoB',
    '#bene3Phone',
    '#bene3Email',
    '#bene3FN',
    '#bene3MN',
    '#bene3LN',
    '#bene3SF',
    '#bene3ResAddr',
    '#bene3ResAddr2',
    '#bene3ResCity',
    '#bene3ResState',
    '#bene3ResZip',
    '#bene4Type',
    '#bene4Percent',
    '#bene4Rel',
    '#bene4SSN',
    '#bene4DoB',
    '#bene4Phone',
    '#bene4Email',
    '#bene4FN',
    '#bene4MN',
    '#bene4LN',
    '#bene4SF',
    '#bene4ResAddr',
    '#bene4ResAddr2',
    '#bene4ResCity',
    '#bene4ResState',
    '#bene4ResZip',
    '#bene5Type',
    '#bene5Percent',
    '#bene5Rel',
    '#bene5SSN',
    '#bene5DoB',
    '#bene5Phone',
    '#bene5Email',
    '#bene5FN',
    '#bene5MN',
    '#bene5LN',
    '#bene5SF',
    '#bene5ResAddr',
    '#bene5ResAddr2',
    '#bene5ResCity',
    '#bene5ResState',
    '#bene5ResZip',
    '#bene6Type',
    '#bene6Percent',
    '#bene6Rel',
    '#bene6SSN',
    '#bene6DoB',
    '#bene6Phone',
    '#bene6Email',
    '#bene6FN',
    '#bene6MN',
    '#bene6LN',
    '#bene6SF',
    '#bene6ResAddr',
    '#bene6ResAddr2',
    '#bene6ResCity',
    '#bene6ResState',
    '#bene6ResZip',
    '#bene7Type',
    '#bene7Percent',
    '#bene7Rel',
    '#bene7SSN',
    '#bene7DoB',
    '#bene7Phone',
    '#bene7Email',
    '#bene7FN',
    '#bene7MN',
    '#bene7LN',
    '#bene7SF',
    '#bene7ResAddr',
    '#bene7ResAddr2',
    '#bene7ResCity',
    '#bene7ResState',
    '#bene7ResZip',
    '#bene8Type',
    '#bene8Percent',
    '#bene8Rel',
    '#bene8SSN',
    '#bene8DoB',
    '#bene8Phone',
    '#bene8Email',
    '#bene8FN',
    '#bene8MN',
    '#bene8LN',
    '#bene8SF',
    '#bene8ResAddr',
    '#bene8ResAddr2',
    '#bene8ResCity',
    '#bene8ResState',
    '#bene8ResZip',
    '#bene9Type',
    '#bene9Percent',
    '#bene9Rel',
    '#bene9SSN',
    '#bene9DoB',
    '#bene9Phone',
    '#bene9Email',
    '#bene9FN',
    '#bene9MN',
    '#bene9LN',
    '#bene9SF',
    '#bene9ResAddr',
    '#bene9ResAddr2',
    '#bene9ResCity',
    '#bene9ResState',
    '#bene9ResZip',
    '#bene10Type',
    '#bene10Percent',
    '#bene10Rel',
    '#bene10SSN',
    '#bene10DoB',
    '#bene10Phone',
    '#bene10Email',
    '#bene10FN',
    '#bene10MN',
    '#bene10LN',
    '#bene10SF',
    '#bene10ResAddr',
    '#bene10ResAddr2',
    '#bene10ResCity',
    '#bene10ResState',
    '#bene10ResZip',
    '#contributionPrev',
    '#contributionCurr',
    '#eft1IndirectRolloverCompanyName',
    '#eft1IndirectRolloverContractNumber',
    '#eft1AccountType',
    '#eft1RoutingNumber',
    '#eft1BankName',
    '#eft1Amount',
    '#eft1AccountNumber',
    '#eft1ConfirmAccountNumber',
    '#eft2IndirectRolloverCompanyName',
    '#eft2IndirectRolloverContractNumber',
    '#eft2AccountType',
    '#eft2RoutingNumber',
    '#eft2BankName',
    '#eft2Amount',
    '#eft2AccountNumber',
    '#eft2ConfirmAccountNumber',
    '#eft3IndirectRolloverCompanyName',
    '#eft3IndirectRolloverContractNumber',
    '#eft3AccountType',
    '#eft3RoutingNumber',
    '#eft3BankName',
    '#eft3Amount',
    '#eft3AccountNumber',
    '#eft3ConfirmAccountNumber',
    '#eft4IndirectRolloverCompanyName',
    '#eft4IndirectRolloverContractNumber',
    '#eft4AccountType',
    '#eft4RoutingNumber',
    '#eft4BankName',
    '#eft4Amount',
    '#eft4AccountNumber',
    '#eft4ConfirmAccountNumber',
    '#check1Amount',
    '#check1InstitutionName',
    '#check1PolicyNumber',
    '#check1PlanType',
    '#check1ETA',
    '#check2Amount',
    '#check2InstitutionName',
    '#check2PolicyNumber',
    '#check2PlanType',
    '#check2ETA',
    '#check3Amount',
    '#check3InstitutionName',
    '#check3PolicyNumber',
    '#check3PlanType',
    '#check3ETA',
    '#transferInitiation',
    '#transfer1Type',
    '#transfer1Amount',
    '#transfer1CompanyName',
    '#transfer1PolicyNumber',
    '#transfer1Addr',
    '#transfer1Addr2',
    '#transfer1City',
    '#transfer1State',
    '#transfer1Zip',
    '#transfer1Phone',
    '#transfer1Extn',
    '#transfer1Fax',
    '#transfer1Email',
    '#transfer1PlanType',
    '#transfer1ProductType',
    '#transfer1AccountOpenedDate',
    '#transfer1IRSNotification',
    '#transfer1IfJointAnnuitant',
    '#transfer1IfIrrevocableBeneficiary',
    '#transfer1SurrInst',
    '#transfer1SurrInstOptions',
    '#transfer1SurrInstPartAmt',
    '#transfer1SurrInstPartPercent',
    '#transfer1SurrInstFW',
    '#transfer1PenaltyFreeAmount',
    '#transfer1TransferedFrom',
    '#transfer1RolloverIfAnnuity',
    '#transfer1TransferedFromOther',
    '#transfer1TransferedFromIRA',
    '#transfer1Timing',
    '#transfer1TimingDate',
    '#transfer1MaturityDate',
    '#transfer1IrrevocableBeneFN',
    '#transfer1IrrevocableBeneMN',
    '#transfer1IrrevocableBeneLN',
    '#transfer1IrrevocableBeneSF',
    '#transfer1IrrevocableBeneEmail',
    '#transfer1IrrevocableBeneSSN',
    '#transfer1IrrevocableBeneDoB',
    '#transfer1IrrevocableBeneAddr',
    '#transfer1IrrevocableBeneAddr2',
    '#transfer1IrrevocableBeneCity',
    '#transfer1IrrevocableBeneState',
    '#transfer1IrrevocableBeneZip',
    '#transfer1Sign',
    '#transfer2Type',
    '#transfer2Amount',
    '#transfer2CompanyName',
    '#transfer2PolicyNumber',
    '#transfer2Addr',
    '#transfer2Addr2',
    '#transfer2City',
    '#transfer2State',
    '#transfer2Zip',
    '#transfer2Phone',
    '#transfer2Extn',
    '#transfer2Fax',
    '#transfer2Email',
    '#transfer2PlanType',
    '#transfer2ProductType',
    '#transfer2AccountOpenedDate',
    '#transfer2IRSNotification',
    '#transfer2IfJointAnnuitant',
    '#transfer2IfIrrevocableBeneficiary',
    '#transfer2SurrInst',
    '#transfer2SurrInstOptions',
    '#transfer2SurrInstPartAmt',
    '#transfer2SurrInstPartPercent',
    '#transfer2SurrInstFW',
    '#transfer2PenaltyFreeAmount',
    '#transfer2TransferedFrom',
    '#transfer2RolloverIfAnnuity',
    '#transfer2TransferedFromOther',
    '#transfer2TransferedFromIRA',
    '#transfer2Timing',
    '#transfer2TimingDate',
    '#transfer2MaturityDate',
    '#transfer2IrrevocableBeneFN',
    '#transfer2IrrevocableBeneMN',
    '#transfer2IrrevocableBeneLN',
    '#transfer2IrrevocableBeneSF',
    '#transfer2IrrevocableBeneEmail',
    '#transfer2IrrevocableBeneSSN',
    '#transfer2IrrevocableBeneDoB',
    '#transfer2IrrevocableBeneAddr',
    '#transfer2IrrevocableBeneAddr2',
    '#transfer2IrrevocableBeneCity',
    '#transfer2IrrevocableBeneState',
    '#transfer2IrrevocableBeneZip',
    '#transfer2Sign',
    '#transfer3Type',
    '#transfer3Amount',
    '#transfer3CompanyName',
    '#transfer3PolicyNumber',
    '#transfer3Addr',
    '#transfer3Addr2',
    '#transfer3City',
    '#transfer3State',
    '#transfer3Zip',
    '#transfer3Phone',
    '#transfer3Extn',
    '#transfer3Fax',
    '#transfer3Email',
    '#transfer3PlanType',
    '#transfer3ProductType',
    '#transfer3AccountOpenedDate',
    '#transfer3IRSNotification',
    '#transfer3IfJointAnnuitant',
    '#transfer3IfIrrevocableBeneficiary',
    '#transfer3SurrInst',
    '#transfer3SurrInstOptions',
    '#transfer3SurrInstPartAmt',
    '#transfer3SurrInstPartPercent',
    '#transfer3SurrInstFW',
    '#transfer3PenaltyFreeAmount',
    '#transfer3TransferedFrom',
    '#transfer3RolloverIfAnnuity',
    '#transfer3TransferedFromOther',
    '#transfer3TransferedFromIRA',
    '#transfer3Timing',
    '#transfer3TimingDate',
    '#transfer3MaturityDate',
    '#transfer3IrrevocableBeneFN',
    '#transfer3IrrevocableBeneMN',
    '#transfer3IrrevocableBeneLN',
    '#transfer3IrrevocableBeneSF',
    '#transfer3IrrevocableBeneEmail',
    '#transfer3IrrevocableBeneSSN',
    '#transfer3IrrevocableBeneDoB',
    '#transfer3IrrevocableBeneAddr',
    '#transfer3IrrevocableBeneAddr2',
    '#transfer3IrrevocableBeneCity',
    '#transfer3IrrevocableBeneState',
    '#transfer3IrrevocableBeneZip',
    '#transfer3Sign',
    '#transfer4Type',
    '#transfer4Amount',
    '#transfer4CompanyName',
    '#transfer4PolicyNumber',
    '#transfer4Addr',
    '#transfer4Addr2',
    '#transfer4City',
    '#transfer4State',
    '#transfer4Zip',
    '#transfer4Phone',
    '#transfer4Extn',
    '#transfer4Fax',
    '#transfer4Email',
    '#transfer4PlanType',
    '#transfer4ProductType',
    '#transfer4AccountOpenedDate',
    '#transfer4IRSNotification',
    '#transfer4IfJointAnnuitant',
    '#transfer4IfIrrevocableBeneficiary',
    '#transfer4SurrInst',
    '#transfer4SurrInstOptions',
    '#transfer4SurrInstPartAmt',
    '#transfer4SurrInstPartPercent',
    '#transfer4SurrInstFW',
    '#transfer4PenaltyFreeAmount',
    '#transfer4TransferedFrom',
    '#transfer4RolloverIfAnnuity',
    '#transfer4TransferedFromOther',
    '#transfer4TransferedFromIRA',
    '#transfer4Timing',
    '#transfer4TimingDate',
    '#transfer4MaturityDate',
    '#transfer4IrrevocableBeneFN',
    '#transfer4IrrevocableBeneMN',
    '#transfer4IrrevocableBeneLN',
    '#transfer4IrrevocableBeneSF',
    '#transfer4IrrevocableBeneEmail',
    '#transfer4IrrevocableBeneSSN',
    '#transfer4IrrevocableBeneDoB',
    '#transfer4IrrevocableBeneAddr',
    '#transfer4IrrevocableBeneAddr2',
    '#transfer4IrrevocableBeneCity',
    '#transfer4IrrevocableBeneState',
    '#transfer4IrrevocableBeneZip',
    '#transfer4Sign',
    '#transfer5Type',
    '#transfer5Amount',
    '#transfer5CompanyName',
    '#transfer5PolicyNumber',
    '#transfer5Addr',
    '#transfer5Addr2',
    '#transfer5City',
    '#transfer5State',
    '#transfer5Zip',
    '#transfer5Phone',
    '#transfer5Extn',
    '#transfer5Fax',
    '#transfer5Email',
    '#transfer5PlanType',
    '#transfer5ProductType',
    '#transfer5AccountOpenedDate',
    '#transfer5IRSNotification',
    '#transfer5IfJointAnnuitant',
    '#transfer5IfIrrevocableBeneficiary',
    '#transfer5SurrInst',
    '#transfer5SurrInstOptions',
    '#transfer5SurrInstPartAmt',
    '#transfer5SurrInstPartPercent',
    '#transfer5SurrInstFW',
    '#transfer5PenaltyFreeAmount',
    '#transfer5TransferedFrom',
    '#transfer5RolloverIfAnnuity',
    '#transfer5TransferedFromOther',
    '#transfer5TransferedFromIRA',
    '#transfer5Timing',
    '#transfer5TimingDate',
    '#transfer5MaturityDate',
    '#transfer5IrrevocableBeneFN',
    '#transfer5IrrevocableBeneMN',
    '#transfer5IrrevocableBeneLN',
    '#transfer5IrrevocableBeneSF',
    '#transfer5IrrevocableBeneEmail',
    '#transfer5IrrevocableBeneSSN',
    '#transfer5IrrevocableBeneDoB',
    '#transfer5IrrevocableBeneAddr',
    '#transfer5IrrevocableBeneAddr2',
    '#transfer5IrrevocableBeneCity',
    '#transfer5IrrevocableBeneState',
    '#transfer5IrrevocableBeneZip',
    '#transfer5Sign',
    '#transfer6Type',
    '#transfer6Amount',
    '#transfer6CompanyName',
    '#transfer6PolicyNumber',
    '#transfer6Addr',
    '#transfer6Addr2',
    '#transfer6City',
    '#transfer6State',
    '#transfer6Zip',
    '#transfer6Phone',
    '#transfer6Extn',
    '#transfer6Fax',
    '#transfer6Email',
    '#transfer6PlanType',
    '#transfer6ProductType',
    '#transfer6AccountOpenedDate',
    '#transfer6IRSNotification',
    '#transfer6IfJointAnnuitant',
    '#transfer6IfIrrevocableBeneficiary',
    '#transfer6SurrInst',
    '#transfer6SurrInstOptions',
    '#transfer6SurrInstPartAmt',
    '#transfer6SurrInstPartPercent',
    '#transfer6SurrInstFW',
    '#transfer6PenaltyFreeAmount',
    '#transfer6TransferedFrom',
    '#transfer6RolloverIfAnnuity',
    '#transfer6TransferedFromOther',
    '#transfer6TransferedFromIRA',
    '#transfer6Timing',
    '#transfer6TimingDate',
    '#transfer6MaturityDate',
    '#transfer6IrrevocableBeneFN',
    '#transfer6IrrevocableBeneMN',
    '#transfer6IrrevocableBeneLN',
    '#transfer6IrrevocableBeneSF',
    '#transfer6IrrevocableBeneEmail',
    '#transfer6IrrevocableBeneSSN',
    '#transfer6IrrevocableBeneDoB',
    '#transfer6IrrevocableBeneAddr',
    '#transfer6IrrevocableBeneAddr2',
    '#transfer6IrrevocableBeneCity',
    '#transfer6IrrevocableBeneState',
    '#transfer6IrrevocableBeneZip',
    '#transfer6Sign',
    '#transfer7Type',
    '#transfer7Amount',
    '#transfer7CompanyName',
    '#transfer7PolicyNumber',
    '#transfer7Addr',
    '#transfer7Addr2',
    '#transfer7City',
    '#transfer7State',
    '#transfer7Zip',
    '#transfer7Phone',
    '#transfer7Extn',
    '#transfer7Fax',
    '#transfer7Email',
    '#transfer7PlanType',
    '#transfer7ProductType',
    '#transfer7AccountOpenedDate',
    '#transfer7IRSNotification',
    '#transfer7IfJointAnnuitant',
    '#transfer7IfIrrevocableBeneficiary',
    '#transfer7SurrInst',
    '#transfer7SurrInstOptions',
    '#transfer7SurrInstPartAmt',
    '#transfer7SurrInstPartPercent',
    '#transfer7SurrInstFW',
    '#transfer7PenaltyFreeAmount',
    '#transfer7TransferedFrom',
    '#transfer7RolloverIfAnnuity',
    '#transfer7TransferedFromOther',
    '#transfer7TransferedFromIRA',
    '#transfer7Timing',
    '#transfer7TimingDate',
    '#transfer7MaturityDate',
    '#transfer7IrrevocableBeneFN',
    '#transfer7IrrevocableBeneMN',
    '#transfer7IrrevocableBeneLN',
    '#transfer7IrrevocableBeneSF',
    '#transfer7IrrevocableBeneEmail',
    '#transfer7IrrevocableBeneSSN',
    '#transfer7IrrevocableBeneDoB',
    '#transfer7IrrevocableBeneAddr',
    '#transfer7IrrevocableBeneAddr2',
    '#transfer7IrrevocableBeneCity',
    '#transfer7IrrevocableBeneState',
    '#transfer7IrrevocableBeneZip',
    '#transfer7Sign',
    '#transfer8Type',
    '#transfer8Amount',
    '#transfer8CompanyName',
    '#transfer8PolicyNumber',
    '#transfer8Addr',
    '#transfer8Addr2',
    '#transfer8City',
    '#transfer8State',
    '#transfer8Zip',
    '#transfer8Phone',
    '#transfer8Extn',
    '#transfer8Fax',
    '#transfer8Email',
    '#transfer8PlanType',
    '#transfer8ProductType',
    '#transfer8AccountOpenedDate',
    '#transfer8IRSNotification',
    '#transfer8IfJointAnnuitant',
    '#transfer8IfIrrevocableBeneficiary',
    '#transfer8SurrInst',
    '#transfer8SurrInstOptions',
    '#transfer8SurrInstPartAmt',
    '#transfer8SurrInstPartPercent',
    '#transfer8SurrInstFW',
    '#transfer8PenaltyFreeAmount',
    '#transfer8TransferedFrom',
    '#transfer8RolloverIfAnnuity',
    '#transfer8TransferedFromOther',
    '#transfer8TransferedFromIRA',
    '#transfer8Timing',
    '#transfer8TimingDate',
    '#transfer8MaturityDate',
    '#transfer8IrrevocableBeneFN',
    '#transfer8IrrevocableBeneMN',
    '#transfer8IrrevocableBeneLN',
    '#transfer8IrrevocableBeneSF',
    '#transfer8IrrevocableBeneEmail',
    '#transfer8IrrevocableBeneSSN',
    '#transfer8IrrevocableBeneDoB',
    '#transfer8IrrevocableBeneAddr',
    '#transfer8IrrevocableBeneAddr2',
    '#transfer8IrrevocableBeneCity',
    '#transfer8IrrevocableBeneState',
    '#transfer8IrrevocableBeneZip',
    '#transfer8Sign',
    '#transfer9Type',
    '#transfer9Amount',
    '#transfer9CompanyName',
    '#transfer9PolicyNumber',
    '#transfer9Addr',
    '#transfer9Addr2',
    '#transfer9City',
    '#transfer9State',
    '#transfer9Zip',
    '#transfer9Phone',
    '#transfer9Extn',
    '#transfer9Fax',
    '#transfer9Email',
    '#transfer9PlanType',
    '#transfer9ProductType',
    '#transfer9AccountOpenedDate',
    '#transfer9IRSNotification',
    '#transfer9IfJointAnnuitant',
    '#transfer9IfIrrevocableBeneficiary',
    '#transfer9SurrInst',
    '#transfer9SurrInstOptions',
    '#transfer9SurrInstPartAmt',
    '#transfer9SurrInstPartPercent',
    '#transfer9SurrInstFW',
    '#transfer9PenaltyFreeAmount',
    '#transfer9TransferedFrom',
    '#transfer9RolloverIfAnnuity',
    '#transfer9TransferedFromOther',
    '#transfer9TransferedFromIRA',
    '#transfer9Timing',
    '#transfer9TimingDate',
    '#transfer9MaturityDate',
    '#transfer9IrrevocableBeneFN',
    '#transfer9IrrevocableBeneMN',
    '#transfer9IrrevocableBeneLN',
    '#transfer9IrrevocableBeneSF',
    '#transfer9IrrevocableBeneEmail',
    '#transfer9IrrevocableBeneSSN',
    '#transfer9IrrevocableBeneDoB',
    '#transfer9IrrevocableBeneAddr',
    '#transfer9IrrevocableBeneAddr2',
    '#transfer9IrrevocableBeneCity',
    '#transfer9IrrevocableBeneState',
    '#transfer9IrrevocableBeneZip',
    '#transfer9Sign',
    '#fundTotalAmount',
    '#transferTotal',
    '#transferInProgress',
    '#ifExistingFunds',
    '#ifNewFunds',
    '#replacementQues1',
    '#replacementQues2',
    '#replacement1Text1',
    '#replacement1Text2',
    '#replacement2Text1',
    '#replacement2Text2',
    '#replacement3Text1',
    '#replacement3Text2',
    '#replacement4Text1',
    '#replacement4Text2',
    '#replacement5Text1',
    '#replacement5Text2',
    '#replacement6Text1',
    '#replacement6Text2',
    '#replacement7Text1',
    '#replacement7Text2',
    '#replacement8Text1',
    '#replacement8Text2',
    '#replacement9Text1',
    '#replacement9Text2',
    '#transfer1OwnerFN',
    '#transfer1OwnerLN',
    '#transfer1OwnerSSN',
    '#transfer1AmountString',
    '#transfer1AnnuitantFN',
    '#transfer1AnnuitantLN',
    '#transfer1AnnuitantSSN',
    '#transfer2OwnerFN',
    '#transfer2OwnerLN',
    '#transfer2OwnerSSN',
    '#transfer2AmountString',
    '#transfer2AnnuitantFN',
    '#transfer2AnnuitantLN',
    '#transfer2AnnuitantSSN',
    '#transfer3OwnerFN',
    '#transfer3OwnerLN',
    '#transfer3OwnerSSN',
    '#transfer3AmountString',
    '#transfer3AnnuitantFN',
    '#transfer3AnnuitantLN',
    '#transfer3AnnuitantSSN',
  ],
  consentFormStates: ['CA', 'SC', 'NV'],
  nonClonedFields: [
    '#ifJointOwner',
    '#applicationId',
    '#ownerSource1Type',
    '#ownerSource1Amount',
    '#ownerSource1Describe',
    '#ownerSourceAddMore2',
    '#ownerSource2Type',
    '#ownerSource2Amount',
    '#ownerSource2Describe',
    '#ownerSourceRemove2',
    '#ownerSourceAddMore3',
    '#ownerSource3Type',
    '#ownerSource3Amount',
    '#ownerSource3Describe',
    '#ownerSourceRemove3',
    '#ownerSourceAddMore4',
    '#ownerSource4Type',
    '#ownerSource4Amount',
    '#ownerSource4Describe',
    '#ownerSourceRemove4',
    '#ownerSourceAddMore5',
    '#ownerSource5Type',
    '#ownerSource5Amount',
    '#ownerSource5Describe',
    '#ownerSourceRemove5',
    '#ownerSourceAddMore6',
    '#ownerSource6Type',
    '#ownerSource6Amount',
    '#ownerSource6Describe',
    '#ownerSourceRemove6',
    '#ownerSourceAddMore7',
    '#ownerSource7Type',
    '#ownerSource7Amount',
    '#ownerSource7Describe',
    '#ownerSourceRemove7',
    '#ownerSourceAddMore8',
    '#ownerSource7Type',
    '#ownerSource7Amount',
    '#ownerSource7Describe',
    '#ownerSourceRemove7',
    '#ownerSourceAddMore8',
    '#ownerSource8Type',
    '#ownerSource8Amount',
    '#ownerSource8Describe',
    '#ownerSourceRemove8',
    '#ownerSourceAddMore9',
    '#ownerSource9Type',
    '#ownerSource9Amount',
    '#ownerSource9Describe',
    '#ownerSourceRemove9',
    '#ownerSourceAddMore10',
    '#ownerSource10Type',
    '#ownerSource10Amount',
    '#ownerSource10Describe',
    '#ownerSourceRemove10',
    '#ownerSourceTotal',
  ],
  clonePrefixes: ['owner', 'annuitant'],
  excludeRadioFields: '#communityStateQues',
  product: {
    aspidaFriendsAndFamilyMYGA2: '3b4d615a-e5e5-4398-aa4c-bbb3005727ce',
    aspidaFriendsAndFamilyMYGA3: '8fa57dd7-cfc4-427f-8298-b630b2a763ab',
    aspidaFriendsAndFamilyMYGA5: '52f7a737-2028-4e6c-812f-bd48f5aa73a8',
    aspidaFriendsAndFamilyMYGA7: '9cfa354e-0a39-4480-80cf-4e0e056cfa5d',
  },
  datafromAPI: ['#transferDisplayPolicyNumbers'],
};
