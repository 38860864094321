import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import styles from './styles';

const useStyles = makeStyles(styles);
// Remove GoTo link if split agent selected No
export default function ErrorNotification(props) {
  const { errorText, linkText, gotoText, agentName } = props;
  const classes = useStyles();
  return (
    <Alert
      severity="error"
      className={classes.alert}
      action={
        gotoText ? (
          <Button
            color="inherit"
            size="small"
            classname={classes.button}
            endIcon={<ChevronRight />}
            href={`${process.env.REACT_APP_DISTRIBUTOR_URL}${linkText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {gotoText}
          </Button>
        ) : (
          undefined
        )
      }
    >
      {!agentName ? errorText : `${agentName} : - ${errorText}`}
    </Alert>
  );
}

ErrorNotification.propTypes = {
  errorText: PropTypes.string,
  linkText: PropTypes.string,
  gotoText: PropTypes.string,
  agentName: PropTypes.string,
};
