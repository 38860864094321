import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './style';

const useStyles = makeStyles(styles);

const SuitabilityWaiverModal = ({ visible, onConfirm }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <div>
      <Dialog
        open={visible}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          Suitability Waiver
        </DialogTitle>
        <DialogContent dividers={false} className={classes.modal}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} className={classes.modalText}>
              Please read the following and confirm that you accept and acknowledge the following terms.
            </Grid>
            <Grid item md={12} xs={12} className={classes.iUnderstand}>
              <b>I</b> understand that I am buying an annuity. Annuities are considered financial products and are
              usually recommended by an agent based on the owner’s needs, objectives, and situation.
            </Grid>
            <Grid item md={12} xs={12} className={classes.iUnderstand}>
              I understand that Aspida Life Insurance Company does not make any recommendations on whether this annuity
              is suitable for me. By continuing the application, I understand the benefits of the contract and confirm
              the decision to purchase this annuity is mine alone. I also understand that the statements below are for
              Aspida’s internal purposes and are not intended to assist me with whether this annuity is suitable for me.
            </Grid>
            <Grid item md={12} xs={12} className={classes.theSinglePremiumCopy}>
              I confirm the following:
            </Grid>
            <Grid item md={12} xs={12} className={classes.theSinglePremiumCopy}>
              <List className={classes.list}>
                <ListItem>The single premium amount is not greater than 60% of my net worth.</ListItem>
                <ListItem>
                  I plan to keep the annuity for the number of years shown in the Withdrawal Charge Schedule.
                </ListItem>
                <ListItem>This annuity will not be funded by a Reverse Mortgage.</ListItem>
                <ListItem>I currently do not reside in a Nursing Home.</ListItem>
                <ListItem>I have an emergency fund sufficient to cover unexpected expenses.</ListItem>
              </List>
            </Grid>
            <Grid item md={12} xs={12} className={classes.iUnderstand}>
              <p>
                If you believe you need assistance with whether this annuity is suitable for you then this annuity may
                not be the right product for your financial needs. Please contact Aspida at{' '}
                <a className={classes.link} href="mailto:clientsupport@aspida.com">
                  clientsupport@aspida.com
                </a>{' '}
                or <br />
                <b>+1 (833) 427-1872</b> and we can provide you with the name of a financial professional to assist you
                with your purchase.
              </p>
            </Grid>
            <Grid item md={12} xs={12} className={classes.checkbox}>
              <FormControlLabel
                name="chkAgree"
                className={classes.checkbox}
                value={checked}
                control={<Checkbox color="secondary" onClick={handleChange} />}
                label={`By checking, you are confirming that you have read, understood and agree to Aspida's
                    term and conditions.`}
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Button
              className={classes.modalButton}
              variant="contained"
              color="secondary"
              onClick={onConfirm}
              disabled={!checked}
            >
              CONFIRM & PROCEED
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default SuitabilityWaiverModal;

SuitabilityWaiverModal.propTypes = {
  visible: PropTypes.bool,
  onConfirm: PropTypes.func,
};
