import React, { useState } from 'react';
import PropTypes from 'prop-types';
import store from 'redux/store';
import { makeStyles, Grid, LinearProgress, Link, Paper, Typography } from '@material-ui/core';
import { CloudUpload, Cancel, CheckCircle } from '@material-ui/icons';
import MasterService from 'api/masterService';
import { useSelector } from 'react-redux';
import { setInputValues } from 'redux/getstarted/actions';
import UploadButton from 'components/upload';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import NotificationService from 'services/notificationService';
import constants from 'constants/common';
import styles from '../style';

const useStyles = makeStyles(styles);

export default function UploadDoc(props) {
  const classes = useStyles();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadNoteText, setUploadNote] = useState('');
  const [progress, setInProgress] = useState(0);
  const getStarted = useSelector(state => state.getStarted);
  const inputData = getStarted.inputvalues;
  const docFileKey = Number.isFinite(props.indexNumber)
    ? `${getStarted.policyapplicationid}/${props.value}_${props.indexNumber}`
    : `${getStarted.policyapplicationid}/${props.value}`;
  if (inputData[`#uploadDocument`] === undefined) {
    inputData[`#uploadDocument`] = [];
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      setInProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const uploadReplacementDoc = async event => {
    const file = event.target.files[0];
    setUploadPercentage(0);

    const fileExt = file.name.split('.').at(-1);
    const allowedFileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'gif'];
    if (!allowedFileTypes.includes(fileExt.toLowerCase())) {
      setUploadNote('Note: Allowed document types: jpg, jpeg, pdf, png');
      return;
    }

    const tenMB = 10 * 1000 * 1000;
    if (file.size > tenMB) {
      NotificationService.error('Uploaded file is too large: Max 10MB');
      return;
    }

    const fileExtension = CommonFunctions.checkIsNullOrUndefined(file.name) !== '' ? /.+\.(.+)$/.exec(file.name) : '';
    const docFileName = Number.isFinite(props.indexNumber) ? `${props.value}_${props.indexNumber}` : `${props.value}`;
    const UploadFile = new File([file], `${docFileName}.${fileExtension[1]}`, {
      type: file.type,
      lastModified: file.lastModified,
    });

    if (!UploadFile) {
      return;
    }

    setUploadPercentage(100);
    const partyId = getStarted.inputvalues['#writingAgentPartyId'];
    const docTypeId = props.documentTypeId;
    const appId = getStarted.policyapplicationid;
    const [err, uploadedfile] = await MasterService.uploadAdditionalDocument(partyId, docTypeId, UploadFile, appId);

    if (err !== null) {
      NotificationService.error(err.data.message);
      return;
    }

    if (!uploadedfile) {
      return;
    }

    const tempDocList = [];
    tempDocList.push({
      fileName: UploadFile.name,
      documentTypeId: uploadedfile.documentTypeId,
      documentFileKeyId: uploadedfile.documentFileKeyId,
      completionDate: uploadedfile.completionDate,
      applicationId: uploadedfile.applicationId,
    });

    const uploadDoc = inputData[`#uploadDocument`];
    if (uploadDoc.length > 0) {
      const filteredData = uploadDoc.filter(
        d => d.documentTypeId === uploadedfile.documentTypeId && d.documentFileKeyId === uploadedfile.documentFileKeyId,
      );
      if (filteredData.length === 0) {
        uploadDoc.push({
          fileName: UploadFile.name,
          documentTypeId: uploadedfile.documentTypeId,
          documentFileKeyId: uploadedfile.documentFileKeyId,
          completionDate: uploadedfile.completionDate,
          applicationId: uploadedfile.applicationId,
        });
      } else {
        uploadDoc.forEach((main, esignIndex) => {
          if (
            main.documentTypeId === uploadedfile.documentTypeId &&
            main.documentFileKeyId === uploadedfile.documentFileKeyId
          ) {
            uploadDoc[esignIndex].documentFileKeyId = uploadedfile.documentFileKeyId;
            uploadDoc[esignIndex].completionDate = uploadedfile.completionDate;
            uploadDoc[esignIndex].fileName = UploadFile.name;
          }
        });
      }
    } else {
      inputData[`#uploadDocument`].push(tempDocList[0]);
    }

    store.dispatch(setInputValues(inputData));
    setUploadNote('');

    setUploadPercentage(0);
  };

  const DeleteDocfromS3 = async document => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const queryString = `?partyIdQuery=${getStarted.inputvalues['#writingAgentPartyId']}
&docTypeId=${document.documentTypeId}&appAlias=${getStarted.policyapplicationid}
&docFileKey=${document.documentFileKeyId}`;
    const [deletionError] = await MasterService.deleteDocument(queryString);
    if (deletionError !== null) {
      NotificationService.error(deletionError.data.message);
      return;
    }
    const uploadedDocuments = [...inputData[`#uploadDocument`]];
    const index = uploadedDocuments.indexOf(document);
    uploadedDocuments.splice(index, 1);
    inputData[`#uploadDocument`] = uploadedDocuments;
    store.dispatch(setInputValues(inputData));
  };

  const DownloadUploadedDoc = async doc => {
    if (Utils.isUserViewOnly()) {
      return;
    }
    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    const selectedDoc = response.data.find(record => record.documentTypeId === doc.documentTypeId);
    if (selectedDoc) {
      const [err, downloadedFile] = await MasterService.downloadDocument(
        getStarted.inputvalues['#writingAgentPartyId'],
        selectedDoc.id,
        getStarted.policyapplicationid,
        props.indexNumber,
      );
      if (err !== null) {
        NotificationService.error(err.data.message);
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, doc.fileName);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={props.pageFromWetMail && classes.addMoreTop}>
        <Paper className={classes.paperBodyDoc} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                {props.label}
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.fileLabelStyle}>
                {props.value}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridTextAlign}>
              <UploadButton
                name={`#${props.name}btn`}
                displayText="Upload"
                variant="contained"
                className={classes.openIcon}
                startIcon={<CloudUpload />}
                uploadFile={uploadReplacementDoc}
                color="secondary"
                isDisabled={Utils.isUserViewOnly()}
              />
            </Grid>
            {CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues[`#uploadDocument`]) !== '' &&
              getStarted.inputvalues[`#uploadDocument`].map(item => {
                if (item.documentTypeId === props.documentTypeId && item.documentFileKeyId.indexOf(docFileKey) !== -1) {
                  return (
                    <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridTextAlign}>
                      <Grid container spacing={1}>
                        <Grid item xs={9} sm={9} md={10} lg={10}>
                          <Typography
                            variant="body1"
                            className={classes.uploadName}
                            component={Link}
                            onClick={() => DownloadUploadedDoc(item)}
                          >
                            {item.fileName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CheckCircle name={`#${props.name}check`} className={classes.checkIcon} />
                        </Grid>
                        <Grid item>
                          <Cancel
                            name={`#${props.name}cancel`}
                            className={classes.cancelIcon}
                            onClick={() => DeleteDocfromS3(item)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
                return false;
              })}
            {uploadPercentage > 0 && (
              <Grid item xs={3}>
                <LinearProgress
                  name="linearProgress"
                  variant="determinate"
                  value={progress}
                  className={classes.linerProg}
                  classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
                />
              </Grid>
            )}
            {CommonFunctions.checkIsNullOrUndefined(uploadNoteText) !== '' && (
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.docErrorSection}>
                  {uploadNoteText}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
UploadDoc.propTypes = {
  indexNumber: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  documentTypeId: PropTypes.string,
  pageFromWetMail: PropTypes.bool,
};
