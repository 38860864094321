import axios from 'axios';
import logger from 'logger';
import { navigate } from 'hookrouter';
import BlobHelper from './blobHelper';
import uniqid from './clientId';

/**
 * Axios instance pointing to the document service
 */
const AxiosConfig = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_URL}/v1`,
  timeout: 60000,
  withCredentials: true,
  headers: {
    'x-request-id': uniqid,
  },
});

/**
 *  Handles sucessful response form the server. Returns response data object.
 * @param {Object} response
 * @returns data of successful response
 */
function handleSuccess(response) {
  logger.info(response);
  if (response && response.data) {
    const { data } = response;
    return data || response;
  }
}

/**
 * Handles an error response.
 * @returns Returns a error string
 */
async function handleError(error) {
  const err = BlobHelper.isBlob(error) ? await BlobHelper.transformBlob(error.response) : error.response;
  logger.error(err);
  if (err.status === 401) {
    navigate('/signout');
  }
  return Promise.reject(err);
}

AxiosConfig.interceptors.response.use(handleSuccess, handleError);

export default AxiosConfig;
