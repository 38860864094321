import React from 'react';
import { RadioGroupProps, Typography, RadioButtonComponentProps, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Group,
  ThumbUp,
  ThumbDown,
  Error,
  MonetizationOn,
  AccountBalance,
  AssignmentTurnedIn,
  CheckCircle,
  Cancel,
  Email,
  OfflineBolt,
  CompareArrows,
  CalendarToday,
  Block,
  SystemUpdateAlt,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import constants from 'constants/common';
import styles from './style';

const useStyles = makeStyles(styles);

function RadioButtonComponent({
  grid,
  labelText,
  radioFontSize,
  justifyContentButton,
  name,
  listItem,
  selectedValue,
  errorText,
  color,
  fontSize,
  fontVariant,
  fontWeightTypo,
  isDisabled,
  isError,
  tooltipText,
  isSpecialError,
  textPlacement,
  group,
  onChange,
  ...rest
}: RadioButtonComponentProps & RadioGroupProps) {
  const classes = useStyles();
  const activeStyleValue = name === constants.excludeRadioFields ? classes.splActiveButton : classes.activeButton;
  const styleValue = name === constants.excludeRadioFields ? classes.splAppBtnStyle : classes.appBtnStyle;
  const getIcon = (iconName, Id) => {
    const tabIcon = {
      Group: (
        <Group
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      ThumbUp: (
        <ThumbUp
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      ThumbDown: (
        <ThumbDown
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      Error: (
        <Error
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      AssignmentTurnedIn: (
        <AssignmentTurnedIn
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      CheckCircle: (
        <CheckCircle
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      Cancel: (
        <Cancel
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      OfflineBolt: (
        <OfflineBolt
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      Email: (
        <Email
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      MonetizationOn: (
        <MonetizationOn
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      CompareArrows: (
        <CompareArrows
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      CalendarToday: (
        <CalendarToday
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      AccountBalance: (
        <AccountBalance
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      SystemUpdateAlt: (
        <SystemUpdateAlt
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      Block: (
        <Block
          className={classes.starticonstyle}
          onClick={() => onChange(Id, group.fieldValidation, 'radioQuestion', group.fieldName)}
        />
      ),
      default: '',
    };
    return tabIcon[iconName] || tabIcon.default;
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant={fontVariant || 'body1'}
          style={{
            fontSize: `${fontSize}rem`,
            fontWeight: fontWeightTypo,
          }}
        >
          {labelText}
        </Typography>
      </Grid>
      {listItem.length > 0 &&
        listItem.map(item => {
          return (
            <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} className={classes.appTypeBtn}>
              <Button
                name={name}
                fullWidth
                disabled={isDisabled}
                style={{
                  fontSize: `${radioFontSize}rem`,
                  justifyContent: justifyContentButton,
                }}
                title={item.id}
                variant="outlined"
                className={item.id === selectedValue ? `${activeStyleValue}` : `${styleValue}`}
                startIcon={getIcon(item.iconName, item.id)}
                {...rest}
              >
                {item.value}
              </Button>
            </Grid>
          );
        })}
      {errorText !== undefined && errorText !== '' && (
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.docErrorSection}>
            {errorText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default RadioButtonComponent;
RadioButtonComponent.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};
