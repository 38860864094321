import store from 'redux/store';
import { setInputValues, setInputErrors, setTrackerGroupName, setErrorLists } from 'redux/getstarted/actions';
import constants from 'constants/common';
import CommonFunctions from 'views/eapp/utils/commonFunctions';

const handleErrorLists = (fieldName, isError) => {
  const state = store.getState();
  const inputErrorData = state.getStarted.inputerrors;
  const errorLists = state.getStarted.errorlists;
  const groupIndex = state.getStarted.currentpage.Group;
  const subGroupIndex = state.getStarted.currentpage.SubGroup;
  const trackerGroupNames = state.getStarted.trackergroupnames;
  if (isError) {
    trackerGroupNames[groupIndex].SubGroup[subGroupIndex].isPageValid = false;
    store.dispatch(setTrackerGroupName(trackerGroupNames));
    if (errorLists.findIndex(p => p.widgetName === fieldName) === -1) {
      errorLists.push({
        groupIndex,
        subGroupIndex,
        errorMessage: inputErrorData[fieldName],
        widgetName: fieldName,
      });
    }
  } else {
    const errorObjectIndex = errorLists.findIndex(p => p.widgetName === fieldName);
    if (errorObjectIndex !== -1) {
      errorLists.splice(errorObjectIndex, 1);
    }
  }
  store.dispatch(setErrorLists(errorLists));
  CommonFunctions.getErrorPanelLists();
};

const handleEsignOnchangeEvent = (obj, documentTypes, description, type, fieldName) => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrorData = state.getStarted.inputerrors;
  if (type === 'R') {
    inputData[fieldName] = obj;
    /*  if (obj.target.value === 'true') inputData[obj.target.name] = true;
    else if (obj.target.value === 'false') inputData[obj.target.name] = false; */
  }
  if (type === 'D') {
    if (obj.target.value !== undefined) {
      inputData[obj.target.name] = obj.target.value;
      inputData[`#${description}AddlDocTypeName`] = documentTypes.find(p => p.id === obj.target.value).value;
    }
  }

  store.dispatch(setInputValues(inputData));
  store.dispatch(setInputErrors(inputErrorData));
};

const documentFilterResult = (uploadDocResult, documentTypeName) => {
  let documentRes = [];
  if (CommonFunctions.checkIsNullOrUndefined(uploadDocResult) !== '' && uploadDocResult.length > 0) {
    documentRes = uploadDocResult.filter(p => p.documentTypeId === documentTypeName);
  }
  return documentRes;
};

const getCorpDocType = (applicationType, corpType, uploadDocResult) => {
  if (applicationType !== constants.applicationTypeName.Corporation) {
    return true;
  }
  const documentResPE = documentFilterResult(
    uploadDocResult,
    constants.documentType.eApplication.corporationProofOfExistence,
  );
  const documentResAL = documentFilterResult(
    uploadDocResult,
    constants.documentType.eApplication.articlesOfIncorporation,
  );
  const documentResIS = documentFilterResult(
    uploadDocResult,
    constants.documentType.eApplication.irsCertificateOfDetermination,
  );
  switch (corpType) {
    case constants.corpTypeofEntity.corporation:
      return documentResPE.length > 0 && documentResAL.length > 0;
    case constants.corpTypeofEntity.taxExemptEntity:
      return documentResPE.length > 0 && documentResAL.length > 0 && documentResIS.length > 0;
    case constants.corpTypeofEntity.govEntity:
      return documentResPE.length > 0 && documentResAL.length > 0;
    default:
      return false;
  }
};

const validateRequiredDocument = () => {
  const state = store.getState();
  const uploadDocResult = state.getStarted.inputvalues['#uploadDocument'] || [];
  const applicationType = state.getStarted.inputvalues['#applicationTypeName'];
  const corpType = state.getStarted.inputvalues['#corpTypeofEntity'];
  const ownerPOA = state.getStarted.inputvalues['#ownerPOA'];
  const isGrantorLiving = state.getStarted.inputvalues['#isGrantorLiving'];
  if (ownerPOA) {
    const documentRes = documentFilterResult(uploadDocResult, constants.documentType.smartComm.powerOfAttorney);
    if (documentRes.length === 0) {
      return false;
    }
  }
  if (CommonFunctions.checkIsNullOrUndefined(isGrantorLiving) !== '' && !isGrantorLiving) {
    const documentRes = documentFilterResult(uploadDocResult, constants.documentType.eApplication.trustDocument);
    if (documentRes.length === 0) {
      return false;
    }
  }
  if (!getCorpDocType(applicationType, corpType, uploadDocResult)) {
    return false;
  }
  const companySignData = CommonFunctions.getCompanySignData();
  if (companySignData.length > 0) {
    const transferData = companySignData.filter(x => x.transferFW !== '');
    const documentRes = documentFilterResult(
      uploadDocResult,
      constants.documentType.eApplication.surrenderingCompanyOriginalContract,
    );
    if (documentRes.length !== transferData.length) {
      return false;
    }
  }
  return true;
};

function getDocumentPageValidation(getStarted, isDocTab) {
  const inputErrors = Object.assign(getStarted.inputerrors, {
    UploadDocument: validateRequiredDocument() ? '' : 'Upload Document is Required',
    OwneradditionalUploadDocument:
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ownerSSNAddlUploadDoc']) !== '' &&
        getStarted.inputvalues['#ownerSSNAddlUploadDoc'].length === 0) ||
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ownerDOBAddlUploadDoc']) !== '' &&
        getStarted.inputvalues['#ownerDOBAddlUploadDoc'].length === 0)
        ? 'Owner Additional Document is Required'
        : '',
    JointOwneradditionalUploadDocument:
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#jointOwnerSSNAddlUploadDoc']) !== '' &&
        getStarted.inputvalues['#jointOwnerSSNAddlUploadDoc'].length === 0) ||
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#jointOwnerDOBAddlUploadDoc']) !== '' &&
        getStarted.inputvalues['#jointOwnerDOBAddlUploadDoc'].length === 0)
        ? 'Joint Owner Additional Document is Required'
        : '',
    AnnuitantadditionalUploadDocument:
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#annuitantSSNAddlUploadDoc']) !== '' &&
        getStarted.inputvalues['#annuitantSSNAddlUploadDoc'].length === 0) ||
      (CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#annuitantDOBAddlUploadDoc']) !== '' &&
        getStarted.inputvalues['#annuitantDOBAddlUploadDoc'].length === 0)
        ? 'Annuitant Additional Document is Required'
        : '',
  });
  if (!isDocTab) {
    if (inputErrors.UploadDocument !== '') {
      handleErrorLists('UploadDocument', true);
    } else handleErrorLists('UploadDocument', false);
    store.dispatch(setInputErrors(inputErrors));
  }
  return (
    inputErrors.OwneradditionalUploadDocument !== '' ||
    inputErrors.JointOwneradditionalUploadDocument !== '' ||
    inputErrors.AnnuitantadditionalUploadDocument !== '' ||
    inputErrors.UploadDocument !== ''
  );
}

const isShowuploadReplacement = index => {
  const state = store.getState();
  const { getStarted } = state;
  const stateType = ['MSP', 'MST'];
  const transferRolloverAnnuityYes = '#transfer__RolloverIfAnnuity';
  const transferTypeField = '#transfer__Type';
  const transferType = transferTypeField.replace(/__/g, index);
  const annuityType = getStarted.inputvalues['#annuityType'];
  const replacementStateType = getStarted.inputvalues['#replacementStateType'] || '';
  const newMoneyByAspida = getStarted.inputvalues['#newMoneyByAspida'];
  const transferRolloverIfAnnuity = transferRolloverAnnuityYes.replace(/__/g, index);
  const rolloverIfAnnuityNo =
    annuityType === 'Qualified' &&
    getStarted.inputvalues[transferType] === 'Rollover' &&
    !getStarted.inputvalues[transferRolloverIfAnnuity];
  let flag = false;
  if (getStarted.inputvalues['#replacementQues1'] || getStarted.inputvalues['#replacementQues2']) {
    flag = true;
    if (!stateType.includes(replacementStateType.toUpperCase()) && (newMoneyByAspida || rolloverIfAnnuityNo)) {
      flag = false;
    }
  }
  return flag;
};

function getTransferDocumentValidation(getStarted) {
  let isReplaceComparision = false;
  const replacementCompDocument = getStarted.inputvalues['#replacementCompDocument'];
  const inputErrors = getStarted.inputerrors;

  const trCompanyData = CommonFunctions.getCompanySignData();
  const noDocumentsFlag = [];

  trCompanyData.forEach((data, index) => {
    const documentIndex = index + 1;
    if (data.coSignType === 'WetMail') {
      const excRollForm = `Exchange rollover 1035 form_${documentIndex}`;
      const repForm = `Replacement form_${documentIndex}`;
      const uploadedFile = getStarted.inputvalues['#uploadDocument'];
      const isExcRollForm = uploadedFile.some(x => x.fileName.includes(excRollForm));
      const isRepForm = isShowuploadReplacement(documentIndex)
        ? uploadedFile.some(x => x.fileName.includes(repForm))
        : true;
      noDocumentsFlag.push(isExcRollForm && isRepForm);
    }
  });

  isReplaceComparision = noDocumentsFlag.some(x => !x);

  if (
    (getStarted.inputvalues['#replacementQuesFL'] === true || getStarted.inputvalues['#replacementQuesNV'] === true) &&
    CommonFunctions.checkIsNullOrUndefined(replacementCompDocument) !== ''
  ) {
    const companySignData = CommonFunctions.getCompanySignData();
    if (companySignData.length > 0) {
      const transferData = companySignData.filter(x => x.coSignType === 'Fax' || x.coSignType === 'Email');
      const documentRes = replacementCompDocument.filter(
        p => p.documentTypeId === constants.documentType.eApplication.replacementNvComparativeInfo,
      );
      if (documentRes.length !== transferData.length) {
        isReplaceComparision = true;
      }
    }
  }
  if (isReplaceComparision) inputErrors.replacementComparision = 'Upload Transfer Document is Required';
  else inputErrors.replacementComparision = '';

  if (inputErrors.replacementComparision !== '') {
    handleErrorLists('replacementComparision', true);
  } else handleErrorLists('replacementComparision', false);
  store.dispatch(setInputErrors(inputErrors));
  return inputErrors.replacementComparision !== '';
}

export default {
  handleEsignOnchangeEvent,
  getDocumentPageValidation,
  getTransferDocumentValidation,
};
