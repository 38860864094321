import store from 'redux/store';
import {
  setProduct,
  setApplicationType,
  setSignedState,
  setInputErrors,
  setSignedStateAbb,
  setSignedStateId,
  setInputValues,
  setEligibilityErrors,
  setTrackerGroupName,
  setErrorLists,
} from 'redux/getstarted/actions';
import CommonFunctions from '../commonFunctions';

const handleErrorLists = (fieldName, isError) => {
  const state = store.getState();
  const inputErrorData = state.getStarted.inputerrors;
  const errorLists = state.getStarted.errorlists;
  const groupIndex = 0;
  const subGroupIndex = 0;
  const trackerGroupNames = state.getStarted.trackergroupnames;
  if (isError) {
    if (trackerGroupNames.length > 0) {
      trackerGroupNames[groupIndex].SubGroup[subGroupIndex].isPageValid = false;
      store.dispatch(setTrackerGroupName(trackerGroupNames));
    }
    if (errorLists.findIndex(p => p.widgetName === fieldName) === -1) {
      errorLists.push({
        groupIndex,
        subGroupIndex,
        errorMessage: inputErrorData[fieldName],
        widgetName: fieldName,
      });
    }
  } else {
    const errorObjectIndex = errorLists.findIndex(p => p.widgetName === fieldName);
    if (errorObjectIndex !== -1) {
      errorLists.splice(errorObjectIndex, 1);
    }
  }
  store.dispatch(setErrorLists(errorLists));
  CommonFunctions.getErrorPanelLists();
};

const calculateBenePercentage = () => {
  const state = store.getState();
  const inputData = state.getStarted.inputvalues;
  const inputErrors = state.getStarted.inputerrors;

  if (inputData['#isDtcCustomer'] === true) {
    return;
  }
  inputData['#splitAgentTotalPercentage'] = 0;
  inputErrors.splitAgentTotalPercentage = '';
  inputData['#SplitAgentData'].forEach(element => {
    if (CommonFunctions.checkIsNullOrUndefined(element.percentage) !== '') {
      inputData['#splitAgentTotalPercentage'] =
        parseFloat(inputData['#splitAgentTotalPercentage'], 0) + parseFloat(element.percentage, 0);
    }
  });
  inputData['#SplitAgentData'].forEach(element => {
    if (CommonFunctions.checkIsNullOrUndefined(element.percentage) === '') {
      inputErrors.splitAgentTotalPercentage = 'Split agent commission field is required, and total amount must be 100%';
    }
  });
  if (
    inputData['#splitAgentTotalPercentage'] !== 0 &&
    Number.isNaN(parseFloat(inputData['#splitAgentTotalPercentage'], 0)) === false
  ) {
    if (inputData['#splitAgentTotalPercentage'] !== 100) {
      inputErrors.splitAgentTotalPercentage = 'Split agent commission field is required, and total amount must be 100%';
    }
  }
  store.dispatch(setInputErrors(inputErrors));
  store.dispatch(setInputValues(inputData));
};

function getStartedPageValidation(getStarted, ApplicationTypes) {
  calculateBenePercentage();
  const appTypeName = getStarted.inputvalues['#annuityTypeQues']
    ? ApplicationTypes.find(p => p.id === getStarted.applicationtype).value
    : '';
  const inputErrors = Object.assign(getStarted.inputerrors, {
    annuityTypeQues: getStarted.inputvalues['#annuityTypeQues'] ? '' : 'Required',
    product: getStarted.product === '' ? 'Required' : '',
    applicationtype: getStarted.applicationtype === '' ? 'Required' : '',
    signedstate: getStarted.signedstate === '' ? 'Required' : '',
    residentState:
      CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#residentState']) === '' &&
      appTypeName === 'Natural Person(s)'
        ? 'Required'
        : '',
    ifSplitCommission:
      CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#ifSplitCommission']) === '' ? 'Required' : '',
    splitAgentTotalPercentage:
      getStarted.inputvalues['#ifSplitCommission'] &&
      (getStarted.inputerrors.splitAgentTotalPercentage !== '' ||
        CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#splitAgentTotalPercentage']) === '' ||
        CommonFunctions.checkIsNullOrUndefined(getStarted.inputvalues['#splitAgentTotalPercentage']) !== 100)
        ? 'Split agent commission field is required, and total amount must be 100%'
        : '',
    IsSplitAgent:
      getStarted.inputvalues['#ifSplitCommission'] && getStarted.inputvalues['#SplitAgentData'].length < 1
        ? 'Split Agent is Required'
        : '',
  });
  if (inputErrors.splitAgentTotalPercentage !== '') {
    handleErrorLists('splitAgentTotalPercentage', true);
  } else handleErrorLists('splitAgentTotalPercentage', false);
  if (inputErrors.IsSplitAgent !== '') {
    handleErrorLists('IsSplitAgent', true);
  } else handleErrorLists('IsSplitAgent', false);
  store.dispatch(setInputErrors(inputErrors));
  return (
    inputErrors.annuityTypeQues !== '' ||
    inputErrors.product !== '' ||
    inputErrors.applicationtype !== '' ||
    inputErrors.signedstate !== '' ||
    inputErrors.residentState !== '' ||
    inputErrors.ifSplitCommission !== '' ||
    inputErrors.splitAgentTotalPercentage !== '' ||
    inputErrors.IsSplitAgent !== ''
  );
}

const removeSplitAgentData = inputFormData => {
  const returnObject = { ...inputFormData };
  const splitAgentCount = 3;
  for (let count = 1; count <= splitAgentCount; count += 1) {
    if (Object.entries(returnObject).filter(([k]) => k.indexOf(`splitAgent${count}`) !== -1).length !== 0) {
      Object.entries(returnObject)
        .filter(([k]) => k.indexOf(`splitAgent${count}`) !== -1)
        .forEach(splitAgent => {
          if (splitAgent[0] !== `#splitAgent${count}AppointedFlag`) {
            delete returnObject[splitAgent[0]];
          }
        });
    }
  }
  return returnObject;
};

const handleProductPickOnchangeEvent = (event, field, fieldName, index) => {
  const state = store.getState();
  let inputData = state.getStarted.inputvalues;
  const inputErrors = state.getStarted.inputerrors;
  switch (field) {
    case 'AQ': // store the product value in state
      inputErrors.annuityTypeQues = '';
      inputData[fieldName] = event.target.value;
      store.dispatch(setProduct(''));
      store.dispatch(setSignedStateId(''));
      store.dispatch(setSignedStateAbb(''));
      store.dispatch(setSignedState(''));
      inputData['#residentStateId'] = '';
      inputData['#residentState'] = '';
      break;
    case 'P': // store the product value in state
      inputErrors.product = '';
      store.dispatch(setProduct(event.target.value));
      break;
    case 'S': // store the agent state value in state
      inputErrors.signedstate = '';
      store.dispatch(setSignedStateId(event.target.value.split('~')[0]));
      store.dispatch(setSignedStateAbb(event.target.value.split('~')[1]));
      store.dispatch(setSignedState(event.target.value));
      break;
    case 'R': // store the agent state value in state
      inputErrors.residentState = '';
      inputData[`${fieldName}Id`] = event.target.value;
      // eslint-disable-next-line prefer-destructuring
      inputData[fieldName] = event.target.value.split('~')[1];
      break;
    case 'percent': // store the split agent commission value in state
      inputData['#splitAgentTotalPercentage'] = 0;
      inputErrors.splitAgentTotalPercentage = '';
      // eslint-disable-next-line no-case-declarations
      const agentpercent = event.target.value === '' ? '' : parseFloat(event.target.value.replace('%', ''), 0);
      inputData['#SplitAgentData'].forEach((element, agentIndex) => {
        // eslint-disable-next-line no-param-reassign
        if (agentIndex === index) element.percentage = agentpercent;
      });
      calculateBenePercentage();
      break;
    case 'A': // store the applicationtype value in state
      inputErrors.applicationtype = '';
      store.dispatch(setApplicationType(event));
      break;
    case 'radio':
    case 'radioQuestion': // store the split agent type in state
      inputErrors.ifSplitCommission = '';
      if (event === true) {
        inputData[fieldName] = true;
        inputData['#SplitAgentData'].push({
          id: state.user.id,
          name: `${state.user.firstName} ${state.user.lastName}`,
          percentage: '',
        });
      } else if (event === false) {
        inputData[fieldName] = false;
        inputData['#SplitAgentData'] = [];
        inputData = removeSplitAgentData(inputData);
        store.dispatch(setEligibilityErrors([]));
      }
      break;
    case 'delete': // agent able to remove the split agent commission value in state
      inputData['#SplitAgentData'].splice(index, 1);
      calculateBenePercentage();
      break;
    default:
      inputData[fieldName] = event.target.value;
      break;
  }
  store.dispatch(setInputErrors(inputErrors));
  store.dispatch(setInputValues(inputData));
};

export default {
  getStartedPageValidation,
  handleProductPickOnchangeEvent,
  removeSplitAgentData,
};
