import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, IconButton } from '@material-ui/core';
import { Edit, Delete, CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import DeleteModal from 'components/deleteModal';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import Utils from 'views/eapp/renderforms/utils';
import store from 'redux/store';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function SummaryCard(props) {
  const state = store.getState();
  const [editVisible, setEditVisible] = useState(false);
  const inputData = state.getStarted.inputvalues;
  const beneLastName = CommonFunctions.checkIsNullOrUndefined(
    inputData[`${props.group.fieldProperty.summaryPrefix}LN`],
  );
  const formatLastName = beneLastName === 'n/a' ? '' : beneLastName;
  const communityQues = inputData['#communityStateQues'];
  const isDtcCustomer = inputData['#isDtcCustomer'] === true;
  const beneName = `${CommonFunctions.checkIsNullOrUndefined(inputData[`${props.group.fieldProperty.summaryPrefix}FN`])}
  ${CommonFunctions.checkIsNullOrUndefined(inputData[`${props.group.fieldProperty.summaryPrefix}MN`])}
  ${formatLastName}
  ${CommonFunctions.checkIsNullOrUndefined(inputData[`${props.group.fieldProperty.summaryPrefix}SF`])}`;
  const benePercentage = inputData[`${props.group.fieldProperty.summaryPrefix}Percent`];
  const beneType = inputData[`${props.group.fieldProperty.summaryPrefix}Type`];
  const isReviewComplete = inputData['#isReviewComplete'] || false;
  const classes = useStyles();

  const noModalClose = () => {
    setEditVisible(false);
  };

  const yesModalClose = () => {
    setEditVisible(false);
  };

  const callBackModal = () => {
    setEditVisible(true);
  };

  return (
    <Box
      className={classNames(classes.root, classes.border, classes.clickable)}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <DeleteModal
        mainText="Are you sure you want to delete this beneficiary?"
        visible={editVisible}
        onNoClose={noModalClose}
        onYesClose={event => {
          yesModalClose();
          props.onChange(event, props.group.beneSummaryBoxRemove, 'button', props.group.fieldName);
        }}
      />
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={2}>
          <IconButton aria-label="edit" name={props.group.fieldName} className={classes.iconStyle}>
            <CheckCircle fontSize="medium" />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h5" className={classes.accountNo}>
            {beneName}
            <br />
            <span>{benePercentage !== undefined && `${benePercentage}%`}</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={(beneType === 'Surviving Spouse' || communityQues === true) && classes.displayNone}
        >
          <IconButton
            aria-label="edit"
            onClick={event => props.onChange(event, props.group.beneSummaryBoxEdit, 'button', props.group.fieldName)}
            value={props.group.fieldProperty.labelText}
            name={props.group.fieldName}
            className={classes.paddingIcon}
            disabled={Utils.isUserViewOnly() || isReviewComplete}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={1}
          className={props.group.fieldProperty.summaryPrefix === '#bene1' && !isDtcCustomer && classes.displayNone}
        >
          <IconButton
            aria-label="edit"
            onClick={callBackModal}
            value={props.group.fieldProperty.labelText}
            name={props.group.fieldName}
            className={classes.paddingIcon}
            disabled={Utils.isUserViewOnly() || isReviewComplete}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

SummaryCard.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};
