/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import {
  AccountCircle,
  Phone,
  Home,
  Email,
  AssignmentTurnedIn,
  CheckCircle,
  Cancel,
  Business,
} from '@material-ui/icons';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from '../../styles';

const useStyles = makeStyles(styles);

const LabelComp = props => {
  const classes = useStyles();
  const fieldRequired = text => {
    if (String(text).indexOf('||') !== -1) {
      return;
    }
    return (
      <p style={{ margin: 0 }}>
        <span style={{ color: 'red' }}>*</span>
        {text}
      </p>
    );
  };

  const fieldIsHtml = (str, isHtml) => {
    if (String(str).indexOf('||') !== -1) {
      return;
    }
    if (isHtml) return CommonFunctions.renderHTML(str);
    if (
      props.group.fieldProperty.isShowValue &&
      CommonFunctions.checkIsNullOrUndefined(props.Values['#plannedValueString']) !== ''
    )
      return CommonFunctions.renderHTML(`${str} <b>${props.Values['#plannedValueString']}</b>`);
    return str;
  };

  const getIcon = iconName => {
    const tabIcon = {
      AccountCircle: <AccountCircle className={classes.labelIconstyle} />,
      Phone: <Phone className={classes.labelIconstyle} />,
      Home: <Home className={classes.labelIconstyle} />,
      Email: <Email className={classes.labelIconstyle} />,
      AssignmentTurnedIn: <AssignmentTurnedIn className={classes.labelIconstyle} />,
      CheckCircle: <CheckCircle className={classes.labelIconstyle} />,
      Cancel: <Cancel className={classes.labelIconstyle} />,
      Business: <Business className={classes.labelIconstyle} />,
      default: '',
    };
    return tabIcon[iconName] || tabIcon.default;
  };
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-sm-12
                          MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        key={props.group.fieldName}
        item
        id={`grd${props.group.fieldName}`}
        className={props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
        style={
          props.group.fieldProperty.specialLabel
            ? {
                maxWidth: `${props.group.fieldProperty.styles.maxWidth}%`,
                borderRadius: `${props.group.fieldProperty.styles.borderRadius}px`,
                backgroundColor: `${props.group.fieldProperty.styles.backgroundColor}`,
                marginTop: `${
                  props.group.fieldProperty.styles.marginTop !== undefined
                    ? props.group.fieldProperty.styles.marginTop
                    : 0
                }rem`,
              }
            : {
                marginTop: `${
                  props.group.fieldProperty.styles.marginTop !== undefined
                    ? props.group.fieldProperty.styles.marginTop
                    : 0
                }rem`,
              }
        }
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 4}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 4}
      >
        {props.group.fieldProperty.isDivider === true ? (
          <Divider />
        ) : (
          <Typography
            variant={props.group.fieldProperty.styles.labelFontVariant}
            gutterBottom
            className={props.group.fieldProperty.isSpecialLabel && classes.spllabelStyle}
            style={{
              color: props.group.fieldProperty.styles.labelFontColor,
              fontStyle: props.group.fieldProperty.styles.labelFontStyle,
              fontWeight: `${props.group.fieldProperty.styles.labelFontWeight}`,
              fontSize: `${props.group.fieldProperty.styles.labelFontSize}em`,
              marginBottom: `${props.group.fieldProperty.styles.marginBottom}em`,
              marginLeft: `${props.group.fieldProperty.styles.marginLeft}em`,
              marginRight: `${props.group.fieldProperty.styles.marginRight}em`,
              margin: `${props.group.fieldProperty.styles.margin}`,
              borderRadius: `${props.group.fieldProperty.styles.borderRadius}px`,
              backgroundColor: `${props.group.fieldProperty.styles.backgroundColor}`,
              width: `${props.group.fieldProperty.styles.width}`,
              padding: `${props.group.fieldProperty.styles.padding}`,
              boxSizing: `${props.group.fieldProperty.styles.boxSizing}`,
              height: `${props.group.fieldProperty.styles.height}`,
              border: `${props.group.fieldProperty.styles.border}`,
              cursor: `${props.group.fieldProperty.styles.cursor}`,
            }}
          >
            {props.group.fieldProperty.iconName !== undefined && getIcon(props.group.fieldProperty.iconName)}
            {String(props.group.fieldProperty.labelText).indexOf('||') !== -1 &&
              props.group.fieldProperty.labelText.split('||').map(place => (
                <span>
                  {place} <br />
                </span>
              ))}
            {props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.labelText)
              : fieldIsHtml(props.group.fieldProperty.labelText, props.group.fieldProperty.isHtml)}
            {props.group.fieldProperty.tooltipText !== '' &&
              CommonFunctions.tooltipMessageFn(props.group.fieldProperty.tooltipText, classes.tooltipLabel)}
          </Typography>
        )}
      </Grid>
    </React.Fragment>
  );
};

LabelComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
};

export default LabelComp;
