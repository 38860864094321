import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import CommonFunctions from 'views/eapp/utils/commonFunctions';

const CustomInputAdornment = props => {
  const { tooltipText, tooltipLabel } = props;
  return (
    <InputAdornment position="start">
      {tooltipText !== '' && CommonFunctions.tooltipMessageFn(tooltipText, tooltipLabel)}
    </InputAdornment>
  );
};

CustomInputAdornment.propTypes = {
  tooltipText: PropTypes.string,
  tooltipLabel: PropTypes.string,
};

export default CustomInputAdornment;
