export default {
  signed: {
    color: '#4D4D4D !important',
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontWeight: '400',
  },
  thankyou: {
    color: '#EF426F !important',
    fontSize: '32px',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontWeight: '600',
  },
  Headset: {
    color: '#140F3C',
    fontSize: '5rem',
    padding: '2rem',
  },
  logo: {
    width: '8.75rem',
  },
  gridAlignCenterNextButton: {
    textAlign: 'center',
  },
  email: {
    color: '#000000cc !important',
    textAlign: 'center',
    fontFamily: 'Muli',
    fontWeight: '400',
  },
  gridWidth: {
    padding: '3rem',
  },
};
