import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '@aspida/react-components';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Typography, Grid } from '@material-ui/core';
import constants from 'constants/common';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import defaultState from 'assets/JSONFiles/defaultState.json';
import defaultSuffix from 'assets/JSONFiles/defaultSuffix.json';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const DropdownComp = props => {
  const classes = useStyles();

  const fieldRequired = text => {
    if (text) {
      return (
        <p style={{ margin: 0 }}>
          <span style={{ color: 'red' }}>*</span>
          {text}
        </p>
      );
    }
  };
  const getListItemValues = widget => {
    const tabIcon = {
      state: defaultState.state,
      suffix: defaultSuffix.suffix,
      default: props.group.fieldProperty.choices,
    };
    const appNumberBySSN = props.Values['#existingPolicyNumberforInternal'];
    if (constants.datafromAPI.includes(props.group.fieldName) && appNumberBySSN) {
      const existingPolicyNumberforInternal = [];
      appNumberBySSN.forEach(policyData => {
        existingPolicyNumberforInternal.push({ id: policyData, value: policyData });
      });
      return existingPolicyNumberforInternal;
    }
    return tabIcon[widget] || tabIcon.default;
  };
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div className="MuiGrid-root MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12" />
      )}
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        className={[props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone, 'dropdown'].join(
          ' ',
        )}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
        }}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 3}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 3}
      >
        {!!props.group.fieldProperty.labelText && (
          <Typography
            variant={props.group.fieldProperty.styles.labelFontVariant}
            gutterBottom
            style={{
              color: props.group.fieldProperty.styles.labelFontColor,
              fontWeight: `${props.group.fieldProperty.styles.labelFontWeight}`,
              fontSize: `${props.group.fieldProperty.styles.labelFontSize}em`,
              marginBottom: `${props.group.fieldProperty.styles.marginBottom}em`,
            }}
          >
            {props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.labelText)
              : props.group.fieldProperty.labelText}
          </Typography>
        )}
        <DropDown
          listItem={getListItemValues(props.widget)}
          name={props.group.fieldName}
          isFullWidth
          value={props.Values[props.group.fieldName] !== undefined ? props.Values[props.group.fieldName] : ''}
          onChange={event => props.onChange(event)}
          onBlur={event => props.onChange(event, props.group.fieldValidation)}
          isError={props.Errors[props.group.fieldName] !== '' && props.Errors[props.group.fieldName] !== undefined}
          errorText={
            CommonFunctions.checkIsNullOrUndefined(props.SpecialErrors[props.group.fieldName]) === '' &&
            props.Errors[props.group.fieldName]
          }
          disabled={Utils.handleElementDisable(props)}
          labelText={
            props.group.fieldProperty.isRequired
              ? fieldRequired(props.group.fieldProperty.infieldLabelText)
              : props.group.fieldProperty.infieldLabelText
          }
          labelClass={props.group.fieldProperty.isEditable && classes.labelBackgroundColor}
          variant="outlined"
          endAdornment={
            props.group.fieldProperty.tooltipText !== '' &&
            CommonFunctions.tooltipMessageFn(props.group.fieldProperty.tooltipText, classes.tooltipLabel)
          }
          className={
            props.group.fieldProperty.tooltipText !== '' ? classes.dropdownDownArrowMark : classes.percentageStyle
          }
        />
      </Grid>
      {CommonFunctions.checkIsNullOrUndefined(props.SpecialErrors[props.group.fieldName]) !== '' && (
        <Alert severity="error" className={classes.alert}>
          {props.Errors[props.group.fieldName]}
        </Alert>
      )}
    </React.Fragment>
  );
};

DropdownComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  SpecialErrors: PropTypes.array,
  Errors: PropTypes.object,
  onChange: PropTypes.func,
  widget: PropTypes.string,
};

export default DropdownComp;
