import React, { useState, useEffect } from 'react';
import store from 'redux/store';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ConcealedField, DateField, LoadingButton } from '@aspida/react-components';
import standardLogo from 'assets/images/standard-logo.svg';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import moment from 'moment';
import IFrameModal from 'components/docSignIframe';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import BrowserHelper from 'helpers/browserHelper';
import { Grid, Typography, Paper } from '@material-ui/core';
import constants from 'constants/common';
import { setSSN, setDOB, setPageFlow, setApplicationId, setApplicationAlias } from 'redux/remoteSign/action';
import masterService from 'api/masterService';
import MaskedInput from 'react-text-mask';
import NotificationService from 'services/notificationService';
import styles from '../styles';

const useStyles = makeStyles(styles);

const defaultCreateForm = {
  ssn: '',
  dob: null,
};

const SSNMasking = props => {
  const { inputRef, pattern, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={pattern}
      guide={false}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};
SSNMasking.propTypes = {
  inputRef: PropTypes.func,
  pattern: PropTypes.array,
};

export default function RemoteSignHome(props) {
  const classes = useStyles();
  const [createForm, setCreateForm] = useState(defaultCreateForm);
  const { errors, formState, handleSubmit, register, setValue, trigger } = useForm();
  const [submitCounter, setSubmitCounter] = useState(0);
  const [signURL, setSignURL] = useState('');
  const [appAlias, setAppAlias] = useState('');
  const [appId, setAppId] = useState('');
  const [editVisible, setEditVisible] = useState(false);
  const dispatch = useDispatch();
  const state = store.getState();
  const { remoteSign } = state;
  const SSNpattern = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  const handleTextChange = name => event => {
    setCreateForm({ ...createForm, [name]: event.target.value });
    if (formState.isSubmitted) {
      trigger({ name });
    }
  };

  const handleDateChange = (event, name) => {
    setCreateForm({ ...createForm, [name]: event });
    setValue(name, event);
    if (formState.isSubmitted) {
      trigger({ name });
    }
  };

  const onSubmit = async () => {
    dispatch(setSSN(createForm.ssn));
    dispatch(setDOB(createForm.dob));
    setSubmitCounter(submitCounter + 1);
    const [inputRemoteSignError, inputRemoteSignData] = await masterService.getRemoteSignStatus(
      props.SignatureRequestId,
      props.SignerId,
    );
    if (inputRemoteSignError) {
      NotificationService.error(inputRemoteSignError.data.message);
    }
    if (inputRemoteSignData) {
      const signerDetails = {
        partyId: props.SignerId,
        docusignEnvelopeId: props.SignatureRequestId,
        docusignClientUserId: inputRemoteSignData.clientUserId,
      };
      sessionStorage.setItem('signerDetails', JSON.stringify(signerDetails));
    }
    const formatDate = moment(createForm.dob).format('MM/DD/YYYY');
    const query = `?SSN=${createForm.ssn}&personDateOfBirth=${formatDate}`;
    const [error, remoteSignResponse] = await masterService.getRemoteSignatureData(
      props.SignatureRequestId,
      props.SignerId,
      query,
    );
    if (error) {
      NotificationService.error(error.data.message);
      return;
    }
    if (remoteSignResponse) {
      setAppAlias(remoteSignResponse.applicationAlias);
      setAppId(remoteSignResponse.applicationId);
      dispatch(setApplicationId(remoteSignResponse.applicationId));
      setSignURL(remoteSignResponse.docusignUrl);
      setEditVisible(true);
      dispatch(setApplicationAlias(remoteSignResponse.applicationAlias));
      BrowserHelper.saveBrowserInfo(
        remoteSignResponse.applicationId,
        `RemoteSign: next button clicked with signer details
        envelopeId: ${props.SignatureRequestId}, ClientuserId: ${inputRemoteSignData.clientUserId}`,
      );
      const remoteSignerData = {
        applicationId: remoteSignResponse.applicationId,
        applicationAlias: remoteSignResponse.applicationAlias,
        SignatureRequestId: props.SignatureRequestId,
      };
      sessionStorage.setItem('remoteSignerData', JSON.stringify(remoteSignerData));
    }
  };

  useEffect(() => {
    register({ name: 'dob', type: 'text' }, { required: true });
  }, [register]);

  const noModalClose = async () => {
    BrowserHelper.saveBrowserInfo(
      appId,
      `RemoteSign: Signer Pop up closed with this signer details ${sessionStorage.getItem('signerDetails')}`,
    );
    setEditVisible(false);
    const [remoteSignResError, remoteSignRes] = await masterService.getRemoteSignStatus(
      props.SignatureRequestId,
      props.SignerId,
    );
    if (remoteSignResError) {
      NotificationService.error(remoteSignResError.data.message);
      return;
    }
    if (remoteSignRes.status === 'completed') {
      const eSignUpdateStatus = {
        docusignEnvelopeId: props.SignatureRequestId,
        docusignClientUserId: remoteSignRes.clientUserId,
        esignStatusId: constants.esignStatusId.Signed,
        esignCompletionDate: new Date(),
        inPerson: false,
        eApplicationAlias: appAlias,
      };
      const [updateEsignError, updateEsignData] = await masterService.updateRemoteEsignStatus(eSignUpdateStatus);
      if (updateEsignError) {
        NotificationService.error(updateEsignError.data.message);
        return;
      }
      if (updateEsignData) {
        dispatch(setPageFlow(1));
      }
      BrowserHelper.saveBrowserInfo(
        appId,
        `RemoteSign: Signature completed with this signer details
        envelopeId: ${props.SignatureRequestId}, ClientuserId: ${remoteSignRes.clientUserId}`,
      );
    }
    if (CommonFunctions.checkIsNullOrUndefined(sessionStorage.getItem('signerDetails')) !== '') {
      sessionStorage.removeItem('signerDetails');
    }
  };

  return (
    <React.Fragment>
      {signURL && <IFrameModal docuSignURL={signURL} onNoClose={noModalClose} visible={editVisible} />}
      <Grid container className={classes.esignComplete}>
        <Grid item xs={12}>
          <img src={standardLogo} alt="logo" className={classes.aspidaIcon} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.esignText}>
            Hello {props.remoteData.firstName}{' '}
            {props.remoteData.lastName !== undefined && props.remoteData.lastName.replace(/(n\/a)+/g, '')}! Let's get
            started.
          </Typography>
          <Typography gutterBottom variant="h4" className={classes.esignSubText}>
            You've been asked to sign your contract agreement.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperGrid} elevation={0}>
            <Grid container className={classes.paperBody} spacing={1}>
              <Grid item xs={12} sm={10} md={10} lg={12}>
                <Typography variant="body1" className={classes.esignSubTextNew}>
                  Please provide a few more details to confirm your identity:
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} className={classes.container} justify="center">
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className={classes.ssnContainer}>
                      <RHFInput
                        as={<ConcealedField />}
                        label="SSN"
                        name="ssn"
                        register={register}
                        setValue={setValue}
                        rules={{ required: true, pattern: /^\d{3}-\d{2}-\d{4}$/ }}
                        className={classes.textField}
                        variant="outlined"
                        defaultValue={createForm.ssn}
                        onChange={handleTextChange('ssn')}
                        InputProps={{
                          inputComponent: SSNMasking,
                          inputProps: {
                            pattern: SSNpattern,
                          },
                        }}
                        error={!!errors.ssn}
                        helperText={
                          errors.ssn
                            ? (errors.ssn.type === 'required' && 'Social Security is required.') ||
                              (errors.ssn.type === 'pattern' && 'Social Security must be in the format XXX-XX-XXXX.')
                            : 'format: XXX-XX-XXXX'
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <DateField
                      className={classes.percentageStyle}
                      autoOk="true"
                      inputVariant="outlined"
                      name="dob"
                      openTo="year"
                      label="Date of Birth"
                      format="MM/dd/yyyy"
                      margin="none"
                      fullWidth
                      value={createForm.dob}
                      onChange={event => handleDateChange(event, 'dob')}
                      helperText={errors.dob ? 'Date of Birth is required.' : 'format: MM/DD/YYYY'}
                      error={!!errors.dob}
                    />
                  </Grid>
                </Grid>
                {remoteSign.isError && (
                  <div>
                    <p className={classes.esignMessage}>
                      {remoteSign.message === 'This request has already been signed '
                        ? remoteSign.message
                        : 'The information you entered does not match our records'}
                    </p>
                    <br />
                  </div>
                )}
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.rowButton}
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="medium"
                      className={classes.loadingButton}
                    >
                      NEXT
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

RemoteSignHome.propTypes = {
  remoteData: PropTypes.array,
  SignatureRequestId: PropTypes.string,
  SignerId: PropTypes.string,
};
