import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button, Grid, Paper, Typography } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import MasterService from 'api/masterService';
import constants from 'constants/common';
import Utils from 'views/eapp/renderforms/utils';
import NotificationService from 'services/notificationService';
import UploadSection from '../../requiredDocs/docs/UploadDoc';
import CommonFunctions from '../../../utils/commonFunctions';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function WetSign(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);

  const DownloadTransferDocs = async () => {
    const documents = JSON.parse(JSON.stringify(constants.downloadDocTypeId));
    let replacementFormData = [];
    const stateType = ['MSP', 'MST'];
    const transferRolloverAnnuityYes = '#transfer__RolloverIfAnnuity';
    const transferTypeField = '#transfer__Type';
    const transferType = transferTypeField.replace(/__/g, props.indexNumber);
    const annuityType = getStarted.inputvalues['#annuityType'];
    const replacementStateType = getStarted.inputvalues['#replacementStateType'] || '';
    const newMoneyByAspida = getStarted.inputvalues['#newMoneyByAspida'];
    const transferRolloverIfAnnuity = transferRolloverAnnuityYes.replace(/__/g, props.indexNumber);
    const rolloverIfAnnuityNo =
      annuityType === 'Qualified' &&
      getStarted.inputvalues[transferType] === 'Rollover' &&
      !getStarted.inputvalues[transferRolloverIfAnnuity];
    Object.keys(documents).forEach(key => {
      if (
        key === 'replacementForm' &&
        (getStarted.inputvalues['#replacementQues1'] || getStarted.inputvalues['#replacementQues2'])
      ) {
        if (!stateType.includes(replacementStateType.toUpperCase()) && (newMoneyByAspida || rolloverIfAnnuityNo)) {
          return;
        }
        const splited = getStarted.inputvalues['#residentStateId'].split('~');
        const matchedStateDoc = documents[key].filter(d => d.stateId === splited[0]);

        if (Array.isArray(matchedStateDoc) && matchedStateDoc.length) {
          replacementFormData = matchedStateDoc;
        } else {
          const allStatesDoc = documents[key].filter(d => d.stateId === '');
          replacementFormData = Array.isArray(allStatesDoc) && allStatesDoc.length ? allStatesDoc : [];
        }
      }
    });

    const docsData = [...documents.transferForm, ...replacementFormData];

    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
      constants.documentGroup.smartComm,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    for await (const doc of docsData) {
      const docFilekeyId = `${getStarted.policyapplicationid}/${doc.description}_${props.indexNumber}.pdf`;
      const selectedDoc = response.data.find(
        record => record.documentTypeId === doc.id && record.documentFileKeyId === docFilekeyId,
      );
      if (selectedDoc) {
        const [err, downloadedFile] = await MasterService.downloadDocument(
          getStarted.inputvalues['#writingAgentPartyId'],
          selectedDoc.id,
          getStarted.policyapplicationid,
          props.indexNumber,
        );
        if (err !== null) {
          NotificationService.error(err.data.message);
          return;
        }
        if (downloadedFile) {
          CommonFunctions.downloadDoc(downloadedFile, doc.description);
        }
      }
    }
  };

  const isShowuploadReplcement = () => {
    const stateType = ['MSP', 'MST'];
    const transferRolloverAnnuityYes = '#transfer__RolloverIfAnnuity';
    const transferTypeField = '#transfer__Type';
    const transferType = transferTypeField.replace(/__/g, props.indexNumber);
    const annuityType = getStarted.inputvalues['#annuityType'];
    const replacementStateType = getStarted.inputvalues['#replacementStateType'] || '';
    const newMoneyByAspida = getStarted.inputvalues['#newMoneyByAspida'];
    const transferRolloverIfAnnuity = transferRolloverAnnuityYes.replace(/__/g, props.indexNumber);
    const rolloverIfAnnuityNo =
      annuityType === 'Qualified' &&
      getStarted.inputvalues[transferType] === 'Rollover' &&
      !getStarted.inputvalues[transferRolloverIfAnnuity];
    let flag = false;
    if (getStarted.inputvalues['#replacementQues1'] || getStarted.inputvalues['#replacementQues2']) {
      flag = true;
      if (!stateType.includes(replacementStateType.toUpperCase()) && (newMoneyByAspida || rolloverIfAnnuityNo)) {
        flag = false;
      }
    }
    return flag;
  };

  const getUploadSection = (name, label, value, documentTypeId, isPageFromWet) => {
    const docuName = `${name}_${props.indexNumber}`;
    return (
      <UploadSection
        name={docuName}
        label={label}
        value={value}
        onChange={props.onChange}
        indexNumber={props.indexNumber}
        documentTypeId={documentTypeId}
        pageFromWetMail={isPageFromWet}
      />
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="body1" gutterBottom className={classes.typoStyle}>
                {`${props.companyData.companyName} ${props.companyData.transferPolicyNumber}`}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.alignRight}>
              <Button
                variant="contained"
                className={classes.openIcon}
                startIcon={<SaveAlt />}
                onClick={DownloadTransferDocs}
                disabled={Utils.isUserViewOnly()}
                color="secondary"
              >
                Download All Forms
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <Grid item xs={12} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                Download, Sign and Upload Form
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.valueTop}>
                Transfer Form
              </Typography>
            </Grid>
            {CommonFunctions.checkIsNullOrUndefined(props.companyData.coNote) !== '' && (
              <Grid item xs={12} className="reviewbox">
                <Typography variant="h6" gutterBottom className={classes.labelTop}>
                  Important Info and Instructions
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.valueTop}>
                  {props.companyData.coNote}
                </Typography>
              </Grid>
            )}
            <Grid item xs={7} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                Upload signed Documents below:
              </Typography>
            </Grid>
          </Grid>
          {getUploadSection(
            'Transfer forms',
            'For Transfer *',
            'Exchange rollover 1035 form',
            constants.documentType.eApplication.externalTransferForm
          )}
          {isShowuploadReplcement() &&
            getUploadSection(
              'Replacement forms',
              'For Replacement *',
              'Replacement form',
              constants.documentType.eApplication.externalTransferForm,
              true
            )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

WetSign.propTypes = {
  onChange: PropTypes.func,
  companyData: PropTypes.array,
  indexNumber: PropTypes.number,
};
