export default {
  label: {
    fontSize: '2.0625rem',
    color: '#EF426F !important',
    textAlign: 'center',
  },
  verticalLine: {
    borderLeft: '1px solid black',
    height: '17rem',
    position: 'absolute',
    left: '49rem',
    top: '14rem',
  },
  titleSuccessHead: {
    color: '#EF426F !important',
    fontFamily: 'Muli',
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: '600',
  },
  paperGrid: {
    borderRadius: '22px',
    backgroundColor: '#F2F4F7',
  },
  paperBody: {
    padding: '1rem',
    marginTop: '2rem',
  },
  titleSuccess: {
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    textAlign: 'center',
    fontSize: '19px',
  },
  next: {
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '400',
  },
  agent: {
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '24px',
  },
  pendingText: {
    color: '#4D4D4D !important',
    fontFamily: 'Muli',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
  },
  gotoBusiness: {
    color: '#fff',
    backgroundColor: '#EF426F',
    width: '50%',
  },
  Headset: {
    color: '#140F3C',
    fontSize: '59px',
  },
  gridAlignCenterNextButton: {
    textAlign: 'center',
  },
  btnstyle: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  esignComplete: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  groove: {
    borderRight: 'groove',
  },
};
