import React from 'react';
import 'babel-polyfill';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from '@aspida/react-components';
import { Provider } from 'react-redux';
import Store from './redux/store';
import AppWrapper from './app/appwrapper';
import './index.css';

theme.overrides = {
  MuiTabs: {
    indicator: {
      height: '5px',
    },
  },
};

ReactDOM.render(
  <Provider store={Store}>
    <ThemeProvider theme={theme}>
      <AppWrapper />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
