import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import MasterService from 'api/masterService';
import Utils from 'views/eapp/renderforms/utils';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import constants from 'constants/common';
import NotificationService from 'services/notificationService';
import styles from '../style';

const useStyles = makeStyles(styles);

export default function DownloadDocs(props) {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);

  const DownloadDocFromS3 = async () => {
    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
      constants.documentGroup.smartComm,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    const docFilekeyId = `${getStarted.policyapplicationid}/Check To Follow_${props.indexNumber}.pdf`;
    const doc = response.data.find(
      record => record.documentTypeId === props.documentTypeId && record.documentFileKeyId === docFilekeyId,
    );
    if (doc) {
      const [err, downloadedFile] = await MasterService.downloadDocument(
        getStarted.inputvalues['#writingAgentPartyId'],
        doc.id,
        getStarted.policyapplicationid,
        props.indexNumber,
      );
      if (err !== null) {
        NotificationService.error(err.data.message);
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, `CheckToFollow_${props.indexNumber}`);
      }
    }
  };

  const loaDownloadDocFromS3 = async () => {
    const [error, response] = await MasterService.getDocumentsByAlias(getStarted.policyapplicationid, [
      constants.documentGroup.eApplication,
      constants.documentGroup.smartComm,
    ]);

    if (error || !response) {
      NotificationService.error('Unable to load document list.');
      return;
    }
    const docFilekeyId = `${getStarted.policyapplicationid}/letter Of Assignment-check_${props.indexNumber}.pdf`;
    const doc = response.data.find(
      record => record.documentTypeId === props.loaDocumentTypeId && record.documentFileKeyId === docFilekeyId,
    );
    if (doc) {
      const [err, downloadedFile] = await MasterService.downloadDocument(
        getStarted.inputvalues['#writingAgentPartyId'],
        doc.id,
        getStarted.policyapplicationid,
        props.indexNumber,
      );
      if (err !== null) {
        NotificationService.error(err.data.message);
        return;
      }
      if (downloadedFile) {
        CommonFunctions.downloadDoc(downloadedFile, `Letter Of Assignment_${props.indexNumber}`);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom className={classes.downlaodLabel}>
                Download these documents (required)
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
          <Grid container spacing={1}>
            <Grid item xs={8} className="reviewbox">
              <TextField
                label="Required to Issue Business*"
                name="testt"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value="Aspida mailing instructions for Client/Producer Initiated Funds"
              />
            </Grid>
            <Grid item xs={4} className={classes.gridTextAlign}>
              <Button
                variant="contained"
                className={classes.uploadBtn}
                startIcon={<SaveAlt />}
                onClick={DownloadDocFromS3}
                disabled={Utils.isUserViewOnly()}
                color="secondary"
              >
                Download
              </Button>
            </Grid>
            <Grid item xs={12} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                Instructions
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.valueTop}>
                This form must be downloaded and given to surrendering company to send in with transferred funds. Please
                ensure that funds are made payable to Aspida and not directly to the client.
              </Typography>
            </Grid>
            <Grid item xs={12} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                Estimated Amount:
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.valueTop}>
                {props.checkData.checkAmount}
              </Typography>
            </Grid>
            <Grid item xs={12} className="reviewbox">
              <Typography variant="h6" gutterBottom className={classes.labelTop}>
                Mail Transfer Checks to:
              </Typography>
              <Typography variant="h6" className={classes.valueTop}>
                Aspida Life Insurance Company
              </Typography>
              <Typography variant="h6" className={classes.valueTop}>
                PO Box 7410670
              </Typography>
              <Typography variant="h6" className={classes.valueTop}>
                Chicago, IL 60674
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {props.loaDocumentTypeId && (
        <Grid item xs={12}>
          <Paper className={classes.loaPaperBody} elevation={3} variant="elevation" square>
            <Grid container spacing={1}>
              <Grid item xs={8} className="reviewbox">
                <TextField
                  label=""
                  name="testt"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value="Letter of Acceptance"
                />
              </Grid>
              <Grid item xs={4} className={classes.gridTextAlign}>
                <Button
                  variant="contained"
                  className={classes.uploadBtn}
                  startIcon={<SaveAlt />}
                  onClick={loaDownloadDocFromS3}
                  disabled={Utils.isUserViewOnly()}
                  color="secondary"
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  );
}

DownloadDocs.propTypes = {
  checkData: PropTypes.array,
  documentTypeId: PropTypes.string,
  indexNumber: PropTypes.number,
  loaDocumentTypeId: PropTypes.string,
};
