import React from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import styles from './style';

const useStyles = makeStyles(styles);

const ConfirmModal = ({ visible, onYesClose, onNoClose, isDtcCustomer }) => {
  const classes = useStyles();

  return (
    <Dialog open={visible} aria-labelledby="form-dialog-title">
      <IconButton aria-label="close" title="Close" className={classes.modalClear} onClick={onNoClose}>
        <ClearIcon />
      </IconButton>
      <DialogContent className={classes.modal}>
        <Typography variant="h4" className={classes.modalTitle}>
          Before You Proceed
        </Typography>
        <Typography variant="body1" className={classes.modalText}>
          Please note, that the selections made on this page
          <span className={isDtcCustomer ? classes.modalTextBold : ''}> cannot be changed </span>
          and a new application will need to be created if there are adjustments. Do you wish to continue?
        </Typography>
        {isDtcCustomer && (
          <Alert className={classes.alertStyles} severity="info">
            Rates are subject to change at any time without prior notice. To lock in these rates you must submit your
            application.
          </Alert>
        )}
        <Button color="secondary" variant="contained" size="large" className={classes.modalButton} onClick={onYesClose}>
          Yes, Continue
        </Button>
        <Button color="secondary" variant="outlined" size="large" className={classes.modalButton} onClick={onNoClose}>
          No, Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmModal;
ConfirmModal.propTypes = {
  onNoClose: PropTypes.func,
  onYesClose: PropTypes.func,
  visible: PropTypes.bool,
  isDtcCustomer: PropTypes.bool,
};
