export default {
  label: {
    fontWeight: 600,
  },
  gridAlignCenterExitButton: {
    textAlign: 'center',
  },
  gridAlignRightBackButton: {
    textAlign: 'right',
  },
  gridAlignLeftBackButton: {
    textAlign: 'left',
  },
  rowButton: {
    padding: '2rem',
  },
  KBA: {
    marginBottom: '0.9375rem',
  },
};
