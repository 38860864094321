import React from 'react';
import { useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './styles';

export default function AlertDialog() {
  const classes = useStyles();
  const alertInfo = useSelector(state => state.appConfig.config.maintenance_alert);
  const [open, setOpen] = React.useState(false);
  const key = `${alertInfo?.title}-${alertInfo?.message}`;

  React.useEffect(() => {
    if (!alertInfo) return;
    const hasClosed = window.localStorage.getItem(key) === 'true';
    if (!hasClosed && alertInfo.enabled) {
      setOpen(true);
    }
  }, [alertInfo, key]);

  const handleClose = () => {
    window.localStorage.setItem(key, 'true');
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={alertInfo.dismissable ? handleClose : undefined}
      onClick={() => (alertInfo.url ? window.open(alertInfo.url) : {})}
      open={open}
    >
      <Alert
        severity={alertInfo.severity}
        onClose={alertInfo.dismissable ? handleClose : undefined}
        classes={{ action: classes.alertAction }}
      >
        {alertInfo.title && <AlertTitle>{alertInfo.title}</AlertTitle>}
        {alertInfo.message}
      </Alert>
    </Snackbar>
  );
}
