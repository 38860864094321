import masterService from 'api/masterService';
import * as types from './types';

export const setFlags = () => async dispatch => {
  const [err, value] = await masterService.getAppConfig({
    configurationApplicationId: process.env.REACT_APP_APPLICATION_ID,
    environmentId: process.env.REACT_APP_ENVIRONMENT_ID,
    configurationProfileId: process.env.REACT_APP_CONFIGURATION_PROFILE_ID,
  });
  if (err) return;
  dispatch({ type: types.SET_FLAGS, value });
};
