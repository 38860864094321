import { combineReducers } from 'redux';
import getStarted from './getstarted/reducer';
import user from './user/reducer';
import remoteSign from './remoteSign/reducer';
import appConfig from './appConfig/reducer';
import appData from './appData/reducer';

const rootReducer = combineReducers({
  getStarted,
  remoteSign,
  user,
  appConfig,
  appData,
});

export default rootReducer;
