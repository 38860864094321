import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'hookrouter';
import store from 'redux/store';
import { setInputValues } from 'redux/getstarted/actions';
import { Box, Button, Typography, Divider, Link, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pinkWhiteLogo from '@aspida/react-components/dist/pinkWhite.svg';
import aspidalogo from 'assets/images/miniLogo.png';
import whiteAspidaLogo from 'assets/images/WhiteAspidaLogo.png';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { SubdirectoryArrowLeft, NoteAdd, ExitToApp } from '@material-ui/icons';
import MasterService from 'api/masterService';
import Utils from 'views/eapp/renderforms/utils';
import GroupTrackerFunctions from 'views/eapp/utils/groupTrackerFunctions';
import NotificationService from 'services/notificationService';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from './styles';

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();
  const getStartedData = useSelector(state => state.getStarted);
  const user = useSelector(state => state.user);
  const state = store.getState();
  const esignSuccess = window.location.href.includes('/esignsuccess');
  const [editVisible, setEditVisible] = useState(false);
  const currentYear = new Date().getFullYear();

  const logOut = async () => {
    sessionStorage.removeItem('eAppPolicyData');
    navigate('/signout');
    if (getStartedData.policyapplicationid !== '') {
      const applicationData = state.getStarted;
      if (Utils.isUserViewOnly()) {
        return;
      }
      if (applicationData.inputvalues['#IsSignatureCreated']) {
        return;
      }
      applicationData.inputerrors = CommonFunctions.cleanJSON(applicationData.inputerrors);
      applicationData.inputvalues = CommonFunctions.cleanJSON(applicationData.inputvalues);
      store.dispatch(setInputValues(applicationData.inputvalues));
      const payload = {
        applicationId: applicationData.policyapplicationid,
        completionPercentage: GroupTrackerFunctions.calculateCompletePercentage(applicationData.trackergroupnames),
        agentPartyId: applicationData.inputvalues['#writingAgentPartyId'],
        applicationStatusId: applicationData.inputvalues['#applicationStatusId'],
        response: applicationData,
        productId: applicationData.product,
        signedStateId: applicationData.signedstateid,
        applicationTypeId: applicationData.applicationtype,
      };
      const [applicationError] = await MasterService.saveApplicationData(payload);
      if (applicationError) {
        NotificationService.error(applicationError.data ? applicationError.data.message : applicationError);
      }
    }
  };

  return (
    <Box className={esignSuccess && classes.displayNone}>
      <Box
        id="mySidebar"
        className={[editVisible ? classes.openside : classes.closeside, 'sidebar'].join(' ')}
        onMouseEnter={() => setEditVisible(true)}
        onMouseLeave={() => setEditVisible(false)}
      >
        {editVisible ? (
          <React.Fragment>
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.box}>
              <Box className={classes.textAlign}>
                <Box tabIndex={0} role="link" onClick={() => navigate('/')} onKeyPress={() => navigate('/')}>
                  <img src={pinkWhiteLogo} className={classes.iconLogo} alt="logo" />
                </Box>
                <Box>
                  {user !== false && (
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                      <Button
                        variant="outlined"
                        className={classes.logoutButton}
                        startIcon={<SubdirectoryArrowLeft />}
                        href={
                          user.isDtcCustomer
                            ? `${process.env.REACT_APP_CUSTOMERS_URL}`
                            : `${process.env.REACT_APP_DISTRIBUTOR_URL}`
                        }
                        size="large"
                      >
                        Back to Portal
                      </Button>
                      {getStartedData.policyapplicationid !== '' && (
                        <Button
                          size="large"
                          variant="outlined"
                          onClick={CommonFunctions.handleExitClick}
                          className={classes.logoutButton}
                          startIcon={<NoteAdd />}
                        >
                          New Application
                        </Button>
                      )}
                      <Divider className={classes.dividerStyle} />
                      <Button
                        size="large"
                        onClick={logOut}
                        variant="outlined"
                        className={classes.logoutButton}
                        startIcon={<ExitToApp />}
                      >
                        Log Out
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
              <div className={classes.legalRoot}>
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyle}>
                    <img src={whiteAspidaLogo} className={classes.iconStyle} alt="logo" />
                  </Link>
                  <IconButton href="https://linkedin.com/company/aspida-insurance/" target="_blank">
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                  <IconButton href="https://www.facebook.com/aspida_ins/" target="_blank">
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Link className={classes.link} href="https://aspida.com/privacy.html" target="_blank">
                    Privacy Policy
                  </Link>
                  <Link className={classes.link} href="https://aspida.com/terms.html" target="_blank">
                    Legal Disclaimer
                  </Link>
                </Box>
                <Typography variant="body2">
                  &copy; {currentYear} Aspida Life Insurance Co.
                  <br />
                  All rights reserved
                </Typography>
              </div>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.box}>
              <Box className={classes.legalRoot}>
                <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyleIcon}>
                  <img src={aspidalogo} className={classes.iconStyle} alt="logo" />
                </Link>
                <Box>
                  {user !== false && (
                    <div>
                      <IconButton
                        href={`${process.env.REACT_APP_DISTRIBUTOR_URL}`}
                        target="_blank"
                        className={classes.linkStyle}
                      >
                        <SubdirectoryArrowLeft className={classes.icon} />
                      </IconButton>
                      {getStartedData.policyapplicationid !== '' && (
                        <IconButton onClick={CommonFunctions.handleExitClick} className={classes.linkExitStyle}>
                          <NoteAdd className={classes.icon} />
                        </IconButton>
                      )}
                      <Divider className={classes.dividerStyleIcon} />
                      <IconButton onClick={logOut} className={classes.linkExitStyle}>
                        <ExitToApp className={classes.icon} />
                      </IconButton>
                    </div>
                  )}
                </Box>
              </Box>
              <div className={classes.legalRoot}>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Link href="https://aspida.com/privacy.html" target="_blank" className={classes.linkStyle}>
                    <img src={whiteAspidaLogo} className={classes.iconStyle} alt="logo" />
                  </Link>
                  <IconButton href="https://linkedin.com/company/aspida-insurance/" target="_blank">
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                  <IconButton href="https://www.facebook.com/aspida_ins/" target="_blank">
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                </Box>
              </div>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};
