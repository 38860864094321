export const SIGNATUREREQUESTID = 'signatureRequestId';
export const SOCIALSECRUITYNUMBER = 'socialSecurityNumber';
export const PARTYID = 'partyId';
export const APPLICATIONID = 'applicationId';
export const APPLICATIONALIAS = 'applicationAlias';
export const DATEOFBIRTH = 'dateOfBirth';
export const FIRSTNAME = 'firstName';
export const LASTNAME = 'lastName';
export const ISVALIDPERSON = 'isValidPerson';
export const SETREMOTESIGN = 'setRemoteSign';
export const LOADING = 'loading';
export const CLEARREMOTESIGN = 'clearRemoteSign';
export const PAGEFLOW = 'pageFlow';
