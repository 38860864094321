import React from 'react';
import { navigate, useRoutes, useQueryParams } from 'hookrouter';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from 'use-async-effect';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Constants from 'constants/common';
import EApp from 'views/eapp';
import masterService from 'api/masterService';
import logger from 'logger';
import ErrorBoundary from 'app/ErrorBoundary';
import RoleHelper, { ROLES } from 'helpers/roleHelper';
import { setAuthenticatedUser } from 'redux/user/actions';
import styles from './styles';

const useStyles = makeStyles(styles);

const AuthedPages = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [queryParams] = useQueryParams();

  const authRoutes = [
    {
      route: '/',
      result: () => <EApp />,
    },
    {
      route: '/getStarted',
      result: () => <EApp />,
      feature: true,
    },
    {
      route: 'applications/:id',
      result: ({ id }) => <EApp id={id} />,
    },
  ];

  const filteredRoutes = authRoutes.reduce((acc, route) => {
    if (!route.feature) {
      return { ...acc, [route.route]: route.result };
    }
    return acc;
  }, {});
  const routeResult = useRoutes(filteredRoutes);

  useAsyncEffect(async isMounted => {
    if (user.id) return;
    try {
      const [err, userData] = await masterService.getCurrentUser();
      if (err) {
        navigate('/signout');
      }
      const [errorResponse, userObj] = await masterService.getPeopleById(userData['custom.partyId']);

      if (errorResponse) {
        navigate('/signout');
        return;
      }

      if (!isMounted()) {
        return;
      }

      const pendoConfig = {
        sanitizeUrl: url => {
          return url.split('?')[0]; // chop off query parameters
        },
        visitor: {},
      };

      if (userObj && userObj.analyticsCollectionId !== Constants.analyticsCollection.optOut) {
        pendoConfig.visitor = {
          id: userObj.id,
          role: userObj.types.map(t => t.description),
          env: process.env.REACT_APP_ENV,
          isSeller: true,
        };
      } else {
        pendoConfig.visitor.isSeller = true;
      }

      window.pendo.initialize(pendoConfig);

      let isViewOnly = false;
      const viewOnlyRole = RoleHelper.userHasRoles(userObj, ROLES.VIEWONLY);
      if (viewOnlyRole) {
        isViewOnly = true;
      }

      let isDtcCustomer = false;
      const { source = '' } = queryParams;
      if (source === Constants.customerSource && RoleHelper.userHasRoles(userObj, ROLES.DTCCUCTOMER)) {
        isDtcCustomer = true;
      }

      dispatch(setAuthenticatedUser({ ...userObj, verified: userObj.verified, isViewOnly, isDtcCustomer }));
    } catch (err) {
      logger.error(err);
    }
  }, []);

  return user ? (
    <div>
      <ErrorBoundary>{routeResult}</ErrorBoundary>
    </div>
  ) : (
    <div className={classes.localLoading}>
      <CircularProgress />
    </div>
  );
};

export default AuthedPages;
