import store from 'redux/store';
import { setValue, setLoading, setInputValues } from 'redux/getstarted/actions';
import moment from 'moment';
import masterService from 'api/masterService';
import constants from 'constants/common';
import NotificationService from 'services/notificationService';
import commonFunctions from '../../eapp/utils/commonFunctions';

const checkIsAppSubmitted = appStatusId => {
  switch (appStatusId) {
    case constants.applicationStatusId.inProgress:
    case constants.applicationStatusId.reviewComplete:
    case constants.applicationStatusId.backOfficePending:
    case constants.applicationStatusId.backOfficeApproved:
      return false;
    default:
      return true;
  }
};
const setSpecification = async (applicationId, user, applicationData) => {
  const state = store.getState();
  let inputData = state.getStarted.inputvalues;
  if (commonFunctions.checkIsNullOrUndefined(applicationId) !== '') {
    const [appDataError, policyAppData] = await masterService.getApplicationData(applicationId);
    if (appDataError !== null) {
      NotificationService.error(appDataError.data.message);
      store.dispatch(setLoading(false));
      return;
    }
    if (checkIsAppSubmitted(policyAppData.applicationStatusId)) {
      NotificationService.error(constants.appSubmittedAlready);
      return;
    }
    const originalMsg = JSON.stringify(policyAppData.response.trackergroupnames);
    const reviewAndSignData = originalMsg.replaceAll('Review & Sign', 'Summary').replaceAll('Review', 'Summary');
    policyAppData.response.trackergroupnames = JSON.parse(reviewAndSignData);
    store.dispatch(setValue(policyAppData.response));
    state.getStarted = policyAppData.response;
    inputData = policyAppData.response.inputvalues;
    if (user.id !== policyAppData.agentPartyId && policyAppData.agentPartyId !== constants.dtcCustomerAgentPartyId) {
      inputData['#isViewOnly'] = true;
    }
  }
  if (commonFunctions.checkIsNullOrUndefined(applicationData) !== '') {
    if (
      commonFunctions.checkIsNullOrUndefined(applicationData.inputvalues['#applicationStatusId']) !== '' &&
      checkIsAppSubmitted(applicationData.inputvalues['#applicationStatusId'])
    ) {
      NotificationService.error(constants.appSubmittedAlready);
      return;
    }
    inputData = applicationData.inputvalues;
    inputData['#isViewOnly'] = false;
    store.dispatch(setValue(applicationData));
    state.getStarted = applicationData;
  }

  if (
    commonFunctions.checkIsNullOrUndefined(user) !== '' &&
    commonFunctions.checkIsNullOrUndefined(applicationId) === ''
  ) {
    inputData['#writingAgentPartyId'] = user.isDtcCustomer ? constants.dtcCustomerAgentPartyId : user.id;
    if (!user.isDtcCustomer) {
      inputData['#writingAgentName'] = `${user.firstName} ${user.lastName}`;
      inputData['#writingAgentEmail'] = user.emailAddresses.length > 0 && user.emailAddresses[0].emailAddress;
      inputData['#writingAgentNPN'] = user.nationalProducerNumber;
      inputData['#writingAgentPhone'] = user.telephoneNumbers.length > 0 && user.telephoneNumbers[0].subscriberNumber;
      if (user.postalAddresses.length > 0) {
        user.postalAddresses.map(postalAddress => {
          if (postalAddress.addressType === 'Mailing') {
            inputData['#writingAgentMailingAddr'] = postalAddress.address1;
            inputData['#writingAgentMailingAddr2'] = postalAddress.address2;
            inputData['#writingAgentMailingCity'] = postalAddress.city;
            inputData['#writingAgentMailingState'] = postalAddress.state;
            inputData['#writingAgentMailingZip'] = postalAddress.postalCode;
          }
          return false;
        });
      }
    }
    inputData['#writingAgentAppointedFlag'] = false;
  }
  inputData['#applicationDate'] = moment(new Date()).format('MM/DD/YYYY');
  store.dispatch(setInputValues(inputData));
  commonFunctions.getSpecificationName();
};

export default { setSpecification };
