import * as UserTypes from './types';

export const initialState = {
  approvedProducts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.APPROVEDPRODUCTS: {
      return { ...state, approvedProducts: action.value };
    }

    case UserTypes.ALLSTATES: {
      return { ...state, allStates: action.value };
    }

    default: {
      return state;
    }
  }
};
