export const LOADING = 'loading';
export const ISSUBMITTED = 'IsSubmitted';
export const PRODUCT = 'product';
export const SIGNEDSTATE = 'signedstate';
export const SIGNEDSTATEABB = 'signedstateabb';
export const SIGNEDSTATEID = 'signedstateid';
export const APPLICATIONTYPE = 'applicationtype';
export const JSONFILENAME = 'jsonfilename';
export const CURRENTPAGE = 'currentpage';
export const TRACKERGROUPNAMES = 'trackergroupnames';
export const INPUTVALUES = 'inputvalues';
export const INPUTERRORS = 'inputerrors';
export const POLICYAPPLICATIONID = 'policyapplicationid';
export const PRODUCTNAME = 'productname';
export const RESET = 'reset';
export const ERRORLISTS = 'errorlists';
export const ERRORPANELLISTS = 'errorpanellists';
export const SETVALUE = 'setvalue';
export const LOCKFIELDS = 'lockfields';
export const SPECIALERRORS = 'specialerrors';
export const SALESELIGIBILITYERRORS = 'saleseligibilityerrors';
export const PARTYESIGNERSLIST = 'partyesignerslist';
export const HIDETAB = 'hidetab';
export const PRODUCTRATES = 'productrates';
