import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import styles from '../../styles';
import Utils from '../utils';

const useStyles = makeStyles(styles);

const TrashButtonComp = props => {
  const classes = useStyles();
  return (
    <Grid
      key={props.group.fieldName}
      item
      id={`grd${props.group.fieldName}`}
      className={props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
      style={{
        textAlign: `${
          props.group.fieldProperty.styles.textAlign !== undefined ? props.group.fieldProperty.styles.textAlign : 0
        }`,
        marginTop: `${
          props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
        }`,
      }}
      xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
      sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 12}
      md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 12}
      lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 12}
    >
      <IconButton
        aria-label="delete"
        onClick={event => props.onChange(event, props.group.fieldValidation, 'button', props.group.fieldName)}
        value={props.group.fieldProperty.labelText}
        name={props.group.fieldName}
        className={
          CommonFunctions.checkIsNullOrUndefined(props.Values['#isReviewComplete']) === true
            ? classes.displayNone
            : classes.displayBlock
        }
        disabled={Utils.isUserViewOnly()}
      >
        <Delete fontSize="small" />
      </IconButton>
    </Grid>
  );
};

TrashButtonComp.propTypes = {
  group: PropTypes.object,
  Values: PropTypes.object,
  onChange: PropTypes.func,
};

export default TrashButtonComp;
