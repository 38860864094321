import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import EsignHome from './esignHome';
import SignHome from './signhome';

export default function ReviewSign(props) {
  const getStarted = useSelector(state => state.getStarted);

  return (
    <React.Fragment>
      {(() => {
        switch (getStarted.IsSubmitted) {
          case 0:
            return <SignHome onClick={props.onClick} onChange={props.onChange} />;
          case 1:
          case 2:
          case 3:
          case 4:
            return <EsignHome esignRoleName={props.esignRoleName} onClick={props.onClick} onChange={props.onChange} />;
          default:
            return '';
        }
      })()}
    </React.Fragment>
  );
}

ReviewSign.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  esignRoleName: PropTypes.string,
};
