import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import TransferDocs from './transferDocs';
import RequiredDocs from './requiredDocs';
import ReviewSign from './reviewSign';

export default function ESign(props) {
  const getStarted = useSelector(state => state.getStarted);

  return (
    <React.Fragment>
      {(() => {
        switch (getStarted.currentpage.SubGroup) {
          case 1:
            return <TransferDocs />;
          case 2:
            return <RequiredDocs onChange={props.onChange} onTabClick={props.onTabClick} />;
          case 3:
            return <ReviewSign esignRoleName={props.esignRoleName} onClick={props.onClick} onChange={props.onChange} />;
          default:
            return '';
        }
      })()}
    </React.Fragment>
  );
}

ESign.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  esignRoleName: PropTypes.string,
  onTabClick: PropTypes.func,
};
