import React from 'react';
import store from 'redux/store';
import { makeStyles, Grid, Paper, Button, Typography, Divider } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Utils from 'views/eapp/renderforms/utils';
import { setInputValues } from 'redux/getstarted/actions';
import CommonFunctions from 'views/eapp/utils/commonFunctions';
import constants from 'constants/common';
import styles from '../style';
import MSAContent from './MSAContent';

const useStyles = makeStyles(styles);

export default function UploadMSADoc() {
  const classes = useStyles();
  const getStarted = useSelector(state => state.getStarted);
  const inputData = getStarted.inputvalues;
  if (inputData[`#uploadMSADocument`] === undefined) {
    inputData[`#uploadMSADocument`] = [
      {
        fileName: '',
        documentTypeId: constants.documentType.eApplication.msaBackOfficeDocument,
        documentFileKeyId: '',
        completionDate: '',
        applicationId: getStarted.policyapplicationid,
      },
    ];
  }
  const IsFileUploaded = inputData[`#uploadMSADocument`].filter(d => d.fileName === '').length > 0;

  const addNewDocMSA = () => {
    inputData[`#uploadMSADocument`].push({
      fileName: '',
      documentTypeId: constants.documentType.eApplication.msaBackOfficeDocument,
      documentFileKeyId: '',
      completionDate: '',
      applicationId: getStarted.policyapplicationid,
    });
    store.dispatch(setInputValues(inputData));
  };

  return (
    <React.Fragment>
      <Paper className={classes.paperHeaderGrid} elevation={3} variant="elevation" square>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom className={classes.headStyle}>
            Financial Institution Document Upload (Optional; 5 Max)
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.label}>
            These documents will only be visible to your back office.
          </Typography>
        </Grid>
      </Paper>
      <Paper className={classes.paperBody} elevation={3} variant="elevation" square>
        <Grid container spacing={1}>
          {CommonFunctions.checkIsNullOrUndefined(inputData[`#uploadMSADocument`]) !== '' &&
            inputData[`#uploadMSADocument`].map((itm, index) => {
              return (
                <React.Fragment>
                  <MSAContent document={itm} indexNumber={index} />
                  <Grid item xs={12} className={classes.dividerStyle}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              );
            })}

          {IsFileUploaded === false && inputData['#uploadMSADocument'].length <= 4 && (
            <Grid item xs={4} className={classes.addMoreTop}>
              <Button
                name="esignButton"
                variant="contained"
                startIcon={<AddCircleOutlineOutlined />}
                onClick={() => addNewDocMSA()}
                disabled={Utils.isUserViewOnly() || getStarted.inputvalues['#IsSignatureCreated']}
                color="primary"
                fullWidth
              >
                Add more
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
