import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BeneSummaryBox from 'components/beneSummaryBox';
import styles from '../../styles';

const useStyles = makeStyles(styles);

const BeneSummaryComp = props => {
  const classes = useStyles();
  return (
    <React.Fragment key={props.group.fieldName}>
      {props.group.fieldProperty.position.newLine === true && (
        <div
          className="MuiGrid-root MuiGrid-grid-xs-12
                    MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12"
        />
      )}
      <Grid
        item
        id={`grd${props.group.fieldName}`}
        xs={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.xs : 12}
        sm={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.sm : 6}
        md={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.md : 2}
        lg={props.group.fieldProperty.grid !== undefined ? props.group.fieldProperty.grid.lg : 2}
        className={props.group.fieldProperty.isVisible ? classes.displayBlock : classes.displayNone}
        style={{
          marginTop: `${
            props.group.fieldProperty.styles.marginTop !== undefined ? props.group.fieldProperty.styles.marginTop : 0
          }rem`,
          marginLeft: `${
            props.group.fieldProperty.styles.marginLeft !== undefined ? props.group.fieldProperty.styles.marginLeft : 0
          }rem`,
          margin: `${
            props.group.fieldProperty.styles.margin !== undefined ? props.group.fieldProperty.styles.margin : 0
          }rem`,
        }}
      >
        <BeneSummaryBox group={props.group} onChange={props.onChange} />
      </Grid>
    </React.Fragment>
  );
};

BeneSummaryComp.propTypes = {
  group: PropTypes.object,
  onChange: PropTypes.func,
};

export default BeneSummaryComp;
