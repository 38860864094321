/* eslint-disable no-undef */
import to from 'await-to-js';
import axios from './config/axiosConfig';

class MasterService {
  healthcheck = () => {
    return to(axios.get('/healthcheck'));
  };

  getProducts = isDtcProduct => {
    return to(axios.get('/products', { params: { sort: 'productOrder', isDtcProduct } }));
  };

  getSignedStates = () => {
    return to(axios.get('/states?sort=name'));
  };

  getApplicationTypes = () => {
    return to(axios.get('/applicationTypes'));
  };

  getSpecificationName = query => {
    return to(axios.get(`/specification?${query}`));
  };

  getApplicationData = applicationId => {
    return to(axios.get(`/application/${applicationId}`));
  };

  createApplicationPolicyNumber = payload => {
    return to(axios.post(`/application`, payload));
  };

  saveApplicationData = payload => {
    return to(axios.put(`/application/${payload.applicationId}`, payload));
  };

  editApplication = applicationId => {
    return to(axios.delete(`/application/${applicationId}`));
  };

  getBankInformation = routingNumber => {
    return to(axios.get(`/bankInformation/${routingNumber}`));
  };

  validateAddress = payload => {
    return to(axios.post('/usps/address', payload));
  };

  getPeopleById = id => {
    return to(axios.get(`/people/${id}`));
  };

  getSearchByAgentName = (agentName, product) => {
    const params = { q: agentName, p: product };
    return to(axios.get('/searchProducers', { params }));
  };

  getContracts = query => {
    const queryString = query ? `?${query}` : '';
    return to(axios.get(`/contracts${queryString}`));
  };

  getProducerAppointments = id => {
    return to(axios.get(`/producer/${id}/appointments`));
  };

  getEsignersList = id => {
    return to(axios.get(`/application/${id}/signers`));
  };

  getRemoteSignData = (signatureRequestId, partyId) => {
    return to(axios.get(`/people/${partyId}/validateSignatureRequest/${signatureRequestId}`));
  };

  saveApplicationAuditTrail = payload => {
    return to(axios.post(`/applicationAuditTrail`, payload));
  };

  getRemoteSignStatus = (signatureRequestId, signatureId) => {
    return to(axios.get(`/esignatures/envelopes/${signatureRequestId}/signers/${signatureId}`));
  };

  createEsignature = payload => {
    return to(axios.post(`/esignatures`, payload));
  };

  getSignatureURL = signaturePayload => {
    const queryString = `?eapplicationAlias=${signaturePayload.eApplicationAlias}`;
    return to(axios.get(`/esignatures/parties/${signaturePayload.agentPartyId}${queryString}`));
  };

  updateEsignStatus = payload => {
    return to(axios.put(`/esignatures`, payload));
  };

  getRemoteSignatureURL = payload => {
    return to(axios.post(`/esignatures/remote`, payload));
  };

  updateRemoteEsignStatus = payload => {
    return to(axios.put(`/esignatures/remote`, payload));
  };

  getRemoteSignatureData = (signatureRequestId, partyId, query) => {
    const queryString = `${query}`;
    return to(axios.get(`/people/${partyId}/validateSignatureRequest/${signatureRequestId}${queryString}`));
  };

  uploadAdditionalDocument = (partyId, documentTypeId, file, applicationId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('completionDate', new Date());
    formData.append('applicationId', applicationId);

    return to(
      axios.post(`parties/${partyId}/documents?docTypeId=${documentTypeId}`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
    );
  };

  getDocumentTypes = () => {
    return to(axios.get('/documentTypes'));
  };

  getDocumentsByAlias = (applicationAlias, documentGroups) => {
    const queryString = documentGroups ? `?documentGroups=${documentGroups.join(',')}&active=true` : '';
    return to(axios.get(`/application/${applicationAlias}/documents${queryString}`));
  };

  downloadSignDocument = (signatureRequestId, emailAddress) => {
    return to(
      axios({
        url: `/esignatures/downloadEnvelopes/${signatureRequestId}/recipient/${emailAddress}`,
        method: 'GET',
        responseType: 'blob',
      }),
    );
  };

  downloadDocument = (partyId, documentId, applicationAlias, index) => {
    const fileOrder = Number.isFinite(index) ? `_${index}` : '';
    const queryString = applicationAlias ? `?applicationAlias=${applicationAlias}${fileOrder}` : '';

    return to(
      axios({
        url: `parties/${partyId}/documents/${documentId}${queryString}`,
        method: 'GET',
        responseType: 'blob',
      }),
    );
  };

  downloadTemplate = path => {
    return to(
      axios({
        url: path,
        method: 'GET',
        responseType: 'blob',
      }),
    );
  };

  deleteDocument = queryString => {
    return to(axios.delete(`/documents${queryString}`));
  };

  getPeopleInfo = payload => {
    return to(axios.post('/people/info/', payload));
  };

  getTotalPremium = payload => {
    return to(axios.post('/people/totalPremium/', payload));
  };

  verifyPartyDetails = payload => {
    return to(axios.post('/identity/', payload));
  };

  triggerAutoSubmit = id => {
    return to(axios.post(`/applications/${id}/autoSubmit`));
  };

  getZendeskToken = () => {
    return to(axios.get('/zendesk'));
  };

  validateMSABackOffice = applicationAlias => {
    return to(axios.get(`/applications/${applicationAlias}/parties`));
  };

  browserTabSubmit = (applicationAlias, payload) => {
    return to(axios.post(`/applications/${applicationAlias}/browserSubmit`, payload));
  };

  appValidation = applicationAlias => {
    return to(axios.post(`/applications/${applicationAlias}/validate`));
  };

  updatePerson = (partyId, payload) => {
    return to(axios.put(`/people/${partyId}`, payload));
  };

  checkAgentEligibility = (partyId, queryString) => {
    return to(axios.get(`/producers/${partyId}/eligibility${queryString}`));
  };

  getProductRates = productIds => {
    return to(axios.get(`/productRates`, { params: { productIds } }));
  };

  getCurrentUser = () => to(axios.get('/auth0/userInfo'));

  getAppConfig = params => to(axios.get('/appConfig', { params }));

  signOut = () => to(axios.get('/auth0/logout'));

  getZendeskChatToken = () => {
    return to(axios.get(`/zendesk/chat/token`));
  };
}

export default new MasterService();
