// Application JSON
import MSG_MYGA_IND from './ApplicationJson/MSG_MYGA_IND.json';
import MSG_MYGA_CORP from './ApplicationJson/MSG_MYGA_CORP.json';
import MSG_MYGA_TRUST from './ApplicationJson/MSG_MYGA_TRUST.json';
import MSG_FIA_IND from './ApplicationJson/MSG_FIA_IND.json';
import MSG_FIA_IND_MSA from './ApplicationJson/MSG_FIA_IND_MSA.json';
import MSG_FIA_TRUST_MSA from './ApplicationJson/MSG_FIA_TRUST_MSA.json';
import MSG_FIA_TRUST from './ApplicationJson/MSG_FIA_TRUST.json';
import MSG_FIA_CORP_MSA from './ApplicationJson/MSG_FIA_CORP_MSA.json';
import MSG_FIA_CORP from './ApplicationJson/MSG_FIA_CORP.json';
import WV_FIA_IND from './ApplicationJson/WV_FIA_IND.json';
import WV_FIA_CORP from './ApplicationJson/WV_FIA_CORP.json';
import WV_FIA_TRUST from './ApplicationJson/WV_FIA_TRUST.json';
import WV_MYGA_IND from './ApplicationJson/WV_MYGA_IND.json';
import WV_MYGA_CORP from './ApplicationJson/WV_MYGA_CORP.json';
import WV_MYGA_TRUST from './ApplicationJson/WV_MYGA_TRUST.json';
import AA_MYGA_IND from './ApplicationJson/AA_MYGA_IND.json';
import AA_MYGA_CORP from './ApplicationJson/AA_MYGA_CORP.json';
import AA_MYGA_TRUST from './ApplicationJson/AA_MYGA_TRUST.json';

import MSG_MYGA_CORP_MSA from './ApplicationJson/MSG_MYGA_CORP_MSA.json';
import MSG_MYGA_IND_MSA from './ApplicationJson/MSG_MYGA_IND_MSA.json';
import MSG_MYGA_TRUST_MSA from './ApplicationJson/MSG_MYGA_TRUST_MSA.json';
import WV_FIA_CORP_MSA from './ApplicationJson/WV_FIA_CORP_MSA.json';
import WV_FIA_IND_MSA from './ApplicationJson/WV_FIA_IND_MSA.json';
import WV_FIA_TRUST_MSA from './ApplicationJson/WV_FIA_TRUST_MSA.json';
import WV_MYGA_CORP_MSA from './ApplicationJson/WV_MYGA_CORP_MSA.json';
import WV_MYGA_IND_MSA from './ApplicationJson/WV_MYGA_IND_MSA.json';
import WV_MYGA_TRUST_MSA from './ApplicationJson/WV_MYGA_TRUST_MSA.json';
import AA_MYGA_CORP_MSA from './ApplicationJson/AA_MYGA_CORP_MSA.json';
import AA_MYGA_IND_MSA from './ApplicationJson/AA_MYGA_IND_MSA.json';
import AA_MYGA_TRUST_MSA from './ApplicationJson/AA_MYGA_TRUST_MSA.json';

import AFF_MYGA_IND from './ApplicationJson/AFF_MYGA_IND.json';

// SubGroup
import administration from './ApplicationJson/SubGroup/administration.json';
import esign from './ApplicationJson/SubGroup/esign.json';
import fundsFIA from './ApplicationJson/SubGroup/fundsFIA.json';
import fundsMYGA from './ApplicationJson/SubGroup/fundsMYGA.json';
import fundAFFMYGA from './ApplicationJson/SubGroup/fundAFFMYGA.json';
import peopleIND from './ApplicationJson/SubGroup/peopleIND.json';
import peopleCORP from './ApplicationJson/SubGroup/peopleCORP.json';
import peopleTRUST from './ApplicationJson/SubGroup/peopleTRUST.json';
import startMSGMYGA from './ApplicationJson/SubGroup/startMSGMYGA.json';
import startWVFIA from './ApplicationJson/SubGroup/startWVFIA.json';
import startWVMYGA from './ApplicationJson/SubGroup/startWVMYGA.json';
import startAFFMYGA from './ApplicationJson/SubGroup/startAFFMYGA.json';
import startAAMYGA from './ApplicationJson/SubGroup/startAAMYGA.json';
import suitability from './ApplicationJson/SubGroup/suitability.json';
import suitabilityTC from './ApplicationJson/SubGroup/suitabilityTC.json';
import start from './ApplicationJson/SubGroup/start.json';
import eligibility from './ApplicationJson/SubGroup/eligibility.json';
import eligibilityTC from './ApplicationJson/SubGroup/eligibilityTC.json';

// Administration
import agentCert from './Administration/agentCert.json';
import agentDisclosure from './Administration/agentDisclosure.json';

// Corporation
import corp from './Corporation/corp.json';
import authorizedPerson from './Corporation/authorizedPerson.json';

// Funds
import allocation from './Funds/allocation.json';
import funding from './Funds/funding.json';
import replacement from './Funds/replacement.json';
import stateReplacement from './Funds/stateReplacement.json';
import Automation1035 from './Funds/Automation1035.json';
import fundingDef from './Funds/funding.def.json';
import fundingAFFMYGA from './Funds/fundingAFFMYGA.json';

// People
import beneficiaries from './People/beneficiaries.json';
import owner from './People/owner.json';

// Start
import yourAnnuity from './Start/yourAnnuity.json';
import yourAnnuityAFFMYGA from './Start/yourAnnuityAFFMYGA.json';

// Suitability
import eligibilityInfo from './Suitability/eligibilityInfo.json';
import eligibilityTrustCorp from './Suitability/eligibilityTrustCorp.json';
import suitabilityFinancialInfo from './Suitability/suitabilityFinancialInfo.json';
import suitabilityAddlQuestions from './Suitability/suitabilityAddlQuestions.json';
import suitabilityTrustCorp from './Suitability/suitabilityTrustCorp.json';

// Trust
import trust from './Trust/trust.json';
import trustee from './Trust/trustee.json';

// eSign
import review from './eSign/review.json';
import reviewDef from './eSign/review.def.json';

export default {
  MSG_MYGA_IND,
  MSG_MYGA_CORP,
  MSG_MYGA_TRUST,
  MSG_FIA_IND,
  MSG_FIA_IND_MSA,
  MSG_FIA_TRUST_MSA,
  MSG_FIA_TRUST,
  MSG_FIA_CORP_MSA,
  MSG_FIA_CORP,
  WV_FIA_IND,
  WV_FIA_CORP,
  WV_FIA_TRUST,
  WV_MYGA_IND,
  WV_MYGA_CORP,
  WV_MYGA_TRUST,
  AA_MYGA_IND,
  AA_MYGA_CORP,
  AA_MYGA_TRUST,
  AFF_MYGA_IND,

  MSG_MYGA_CORP_MSA,
  MSG_MYGA_IND_MSA,
  MSG_MYGA_TRUST_MSA,
  WV_FIA_CORP_MSA,
  WV_FIA_IND_MSA,
  WV_FIA_TRUST_MSA,
  WV_MYGA_CORP_MSA,
  WV_MYGA_IND_MSA,
  WV_MYGA_TRUST_MSA,
  AA_MYGA_CORP_MSA,
  AA_MYGA_IND_MSA,
  AA_MYGA_TRUST_MSA,

  administration,
  esign,
  fundsFIA,
  fundsMYGA,
  fundAFFMYGA,
  peopleCORP,
  peopleIND,
  peopleTRUST,
  startMSGMYGA,
  startWVFIA,
  startWVMYGA,
  startAAMYGA,
  startAFFMYGA,
  start,
  suitability,
  suitabilityTC,
  eligibility,
  eligibilityTC,

  agentCert,
  agentDisclosure,

  corp,
  authorizedPerson,

  allocation,
  funding,
  fundingDef,
  replacement,
  stateReplacement,
  Automation1035,
  fundingAFFMYGA,

  beneficiaries,
  owner,

  yourAnnuity,
  yourAnnuityAFFMYGA,

  eligibilityInfo,
  eligibilityTrustCorp,
  suitabilityFinancialInfo,
  suitabilityAddlQuestions,
  suitabilityTrustCorp,

  trust,
  trustee,

  review,
  reviewDef,
};
