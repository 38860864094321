import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import DeskCheckMarks from '../../../assets/images/desktop_checkmarks.svg';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function MSASuccess() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.gridWidth}>
        <Grid item xs={12} className={classes.gridAlignCenterNextButton}>
          <img src={DeskCheckMarks} alt="logo" className={classes.logo} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary" className={classes.text1}>
            Thank You
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.text2}>
            You're all done!
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
