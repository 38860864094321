export default {
  appType: {
    padding: '1.5rem 0 0.5rem',
    fontSize: '1.3rem',
    fontWeight: '400',
  },
  appTypeBtn: {
    margin: '.5rem',
  },
  activeButton: {
    height: '62px',
    width: '100%',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      height: '55px',
    },
  },
  splActiveButton: {
    width: '100%',
    border: '3px solid #14043C',
    borderRadius: '10px',
    backgroundColor: '#D0CADC',
    color: '#14043C',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  splAppBtnStyle: {
    width: '100%',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      fontSize: '.8rem !important',
    },
  },
  appBtnStyle: {
    height: '62px',
    width: '100%',
    border: '3px solid #4D4D4D',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#4D4D4D',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      height: '55px',
      fontSize: '.8rem !important',
    },
  },
  starticonstyle: {
    fontSize: '26px !important',
  },
  docErrorSection: {
    color: '#f44336 !important',
    marginLeft: '0.9rem',
    fontSize: '1rem',
  },
};
