import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import masterService from 'api/masterService';
import commonFunctions from 'views/eapp/utils/commonFunctions';
import styles from './style';

const useStyles = makeStyles(styles);
export default function PdfDownloadComponent(props) {
  const classes = useStyles();
  const { downloadLink, labelText } = props;
  const triggerDownload = async () => {
    masterService.downloadTemplate(downloadLink);
    const [err, downloadedFile] = await masterService.downloadTemplate(downloadLink);
    if (err !== null) {
      return;
    }
    if (downloadedFile) {
      commonFunctions.downloadDoc(downloadedFile, props.fileName);
    }
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        name="editEappBtn"
        onClick={triggerDownload}
        className={classes.editBtn}
        startIcon={<GetApp className={classes.personIcon} />}
      >
        {labelText}
      </Button>
    </React.Fragment>
  );
}
PdfDownloadComponent.propTypes = {
  downloadLink: PropTypes.string,
  fileName: PropTypes.string,
  labelText: PropTypes.string,
};
